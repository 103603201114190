import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import moment from 'moment/moment';
import ReactFC from 'react-fusioncharts';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getColorForSeries } from '@/components/Account/utils';
import { palettecolors } from '@/components/ReportChart/utils';
import i18n from '@/i18n';
import StyledSelect from '@/mui-styled-components/select';
import { AppDispatch } from '@/store';
import { EReportTypes } from '@/store/enums';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getReport, loadReport } from '@/store/reportSlice';
import { EChartTypes, EGraphTypes, TReportItem } from '@/store/types';
import { InputLabel, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import './ReportChartPerfomance.scss';
import '../Display/Count.scss';
import '../SpriteIcon/SpriteIcon.scss';

//@ts-ignore
FusionCharts.options.license({
  key: process.env.REACT_APP_FUSION_CHARTS_KEY || '',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

interface IGraphItem {
  year: string;
  month: string;
  seriesOne: number | null;
  seriesTwo: number | null;
  seriesThree: number | null;
}

const QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW = 12;
const DEBOUNCE_DELAY = 250;

const dataList = (
  reportType: EReportTypes
): { value: string; label: string }[] => {
  let criteria = [
    { value: 'totalUnit', label: 'field.units' },
    { value: 'totalVolume', label: 'field.volume' },
    { value: 'avgPrice', label: 'field.average_price' },
    { value: 'medianPrice', label: 'field.median_price' },
  ];

  if (
    reportType === EReportTypes.LISTING ||
    reportType === EReportTypes.SELLING
  ) {
    criteria = [
      { value: 'totalUnit', label: 'field.units' },
      { value: 'totalVolume', label: 'field.volume' },
      { value: 'avgPrice', label: 'field.average_price' },
      { value: 'medianPrice', label: 'field.median_price' },
      { value: 'avgRatio', label: 'field.sold_asked_ratio' },
      { value: 'medianRatio', label: 'field.median_sold_asked_ratio' },
      { value: 'avgDOM', label: 'field.average_dom' },
      { value: 'medianDOM', label: 'field.median_dom' },
    ];
  }

  return criteria;
};

const getChartType = (
  chartType: EChartTypes,
  items: TReportItem[],
  showColumnValues: boolean
) => {
  if (chartType === EChartTypes.COLUMN) {
    return items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW ||
      showColumnValues
      ? 'stackedcolumn2d'
      : 'scrollstackedcolumn2d';
  } else if (chartType === EChartTypes.LINE) {
    return 'msline';
  }
};

const seriesMappings = {
  multiple: {
    totalUnit: {
      seriesOne: 'field.list_units',
      seriesTwo: 'field.sold_units',
      seriesThree: 'field.total_units',
    },
    totalVolume: {
      seriesOne: 'field.list_volume',
      seriesTwo: 'field.sold_volume',
      seriesThree: 'field.total_volume',
    },
  },
  stacked: {
    totalUnit: {
      seriesOne: 'field.list_units',
      seriesTwo: 'field.sold_units',
    },
    totalVolume: {
      seriesOne: 'field.list_volume',
      seriesTwo: 'field.sold_volume',
    },
  },
  simple: {
    totalUnit: {
      seriesOne: 'field.units',
    },
    totalVolume: {
      seriesOne: 'field.volume',
    },
    avgPrice: {
      seriesOne: 'field.average_price',
    },
    medianPrice: {
      seriesOne: 'field.median_price',
    },
    avgRatio: {
      seriesOne: 'field.sold_asked_ratio',
    },
    medianRatio: {
      seriesOne: 'field.median_sold_asked_ratio',
    },
    avgDOM: {
      seriesOne: 'field.average_dom',
    },
    medianDOM: {
      seriesOne: 'field.median_dom',
    },
  },
};

const generateDataset = (
  items: any,
  graphType: EGraphTypes,
  data: string,
  seriesMappings: any
) => {
  const dataset = [];

  if (graphType && data && items && Array.isArray(items)) {
    //@ts-ignore
    const seriesMapping = seriesMappings[graphType][data];

    for (const series in seriesMapping) {
      const seriesTranslation = i18n.t(seriesMapping[series]);

      if (items.some((item) => item[seriesTranslation] !== null)) {
        dataset.push({
          seriesname: seriesTranslation,
          data: items
            .filter((item) => item[seriesTranslation] !== null)
            .map((item) => ({
              value: item[series],
              toolText: '$label {br} $dataValue - $seriesName',
              color: getColorForSeries(series),
              anchorBorderColor: getColorForSeries(series),
              anchorBgColor: getColorForSeries(series),
              legendBgColor: getColorForSeries(series),
              legendBorderColor: getColorForSeries(series),
            })),
        });
      }
    }
  }

  return dataset;
};

interface Props {
  showColumnValues: boolean;
  setShowColumnValues: Dispatch<SetStateAction<boolean>>;
}

const ReportChartPerformance = ({
  showColumnValues,
  setShowColumnValues,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { reportName, reportType, agentIds, brandIds, firmIds, officeIds } =
    useSelector(getKeySettings);
  //@ts-ignore
  const { items, graphType, data, chartType, timePeriod } =
    useSelector(getReport);
  const [height, setHeight] = useState<number>(0);

  const handleChangeData = (e: any) => {
    dispatch(
      setKeySettings({
        key: 'data',
        value: e.target.value,
      })
    );

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const handleTimePeriod = (e: any) => {
    dispatch(
      setKeySettings({
        key: 'timePeriod',
        value: e.target.value,
      })
    );

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const handleChartType = (e: any) => {
    setShowColumnValues(e.target.value === EChartTypes.COLUMN_COMPACT_VIEW);

    dispatch(
      setKeySettings({
        key: 'chartType',
        value:
          e.target.value === EChartTypes.COLUMN_COMPACT_VIEW
            ? EChartTypes.COLUMN
            : e.target.value,
      })
    );

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const performanceName = () => {
    if (agentIds?.length) {
      return t('chart.agent');
    } else if (officeIds?.length) {
      return t('chart.office');
    } else if (firmIds?.length) {
      return t('chart.firm');
    } else if (brandIds?.length) {
      return t('chart.brand');
    }

    return t(`reports.${reportName}`);
  };

  const calculateNewHeight = () => {
    const reportHeightWrapper = document.querySelector('[data-report-height]');
    const reportHeaderPerformanceWrapper = document.querySelector(
      '[data-report-header]'
    );
    const reportChartPerformanceWrapper = document.querySelector(
      '[data-report-chart]'
    );

    let newHeight = height;

    if (
      reportHeightWrapper &&
      reportHeaderPerformanceWrapper &&
      reportChartPerformanceWrapper
    ) {
      newHeight =
        reportHeightWrapper.clientHeight -
        reportHeaderPerformanceWrapper.clientHeight -
        reportChartPerformanceWrapper.clientHeight -
        10;
    } else if (window.innerWidth <= 1679) {
      newHeight = 320;
    } else {
      newHeight = 580;
    }

    setHeight(newHeight);
  };

  const debounce = (callback: () => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(callback, delay);
    };
  };

  const handleResize = debounce(calculateNewHeight, DEBOUNCE_DELAY);

  useEffect(() => {
    if (items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW) {
      setShowColumnValues(false);
    }

    calculateNewHeight();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items]);

  const chartConfigs = {
    type: getChartType(chartType, items, showColumnValues),
    width: '100%',
    height: height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        palettecolors: palettecolors(),
        theme: 'fusion',
        exportEnabled: '1',
        exportFormats: 'PNG|JPG|PDF|SVG',
        exportFileName: reportName + '-graph',
        formatNumberScale: '0',
        decimalSeparator: '.',
        thousandSeparator: ',',
        showSum: `${Number(!showColumnValues)}`,
        showValues: '0',
        numberSuffix: data === 'medianRatio' ? '%' : '',
        drawcrossline: '1',
        setAdaptiveYMin: '1',
      },
      categories: [
        {
          category: items.map((i: any) => {
            return {
              label: `${
                i.month ? moment.utc(`${i.month}`, 'M').format('MMM') : ''
              } ${moment.utc(`${i.year}`, 'YYYY').format('YYYY')}`,
            };
          }),
        },
      ],
      dataset: generateDataset(items, graphType, data, seriesMappings),
    },
  };

  const dataLabel = dataList(reportType).find(
    (item) => item.value === data
  )?.label;

  return (
    <>
      <div
        className="report-chart-header report-chart-performance"
        data-report-chart
      >
        <div>
          {performanceName()}{' '}
          <span style={{ textTransform: 'capitalize' }}>{timePeriod}</span>{' '}
          {t('chart.by')} {t(`${dataLabel}`)} -{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {reportType === EReportTypes.COMBINED
              ? t('reports.listing_and_selling_combined')
              : t('reports.' + reportType)}
          </span>
          {' ' + t('reports.performance')}
        </div>
        <div className="report-chart-panel">
          {data && (
            <FormControl>
              <InputLabel
                id="data-select-label"
                sx={{
                  '&.Mui-focused': {
                    color: `var(--blue-color)`,
                  },
                }}
              >
                {t('chart.data')}
              </InputLabel>
              <StyledSelect
                labelId="data-select-label"
                value={data}
                onChange={handleChangeData}
                label={t('chart.data')}
                title={dataLabel}
              >
                {dataList(reportType).map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Trans i18nKey={item.label}></Trans>
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
          {timePeriod && (
            <FormControl>
              <InputLabel
                id="timePeriod-select-label"
                sx={{
                  '&.Mui-focused': {
                    color: `var(--blue-color)`,
                  },
                }}
              >
                {t('chart.time_period')}
              </InputLabel>
              <StyledSelect
                labelId="timePeriod-select-label"
                value={timePeriod}
                onChange={handleTimePeriod}
                label={t('chart.time_period')}
              >
                {[
                  { value: 'monthly', label: 'chart.monthly' },
                  { value: 'yearly', label: 'chart.yearly' },
                ].map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Trans i18nKey={item.label}></Trans>
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
          {chartType && (
            <FormControl>
              <InputLabel
                id="chartType-select-label"
                sx={{
                  '&.Mui-focused': {
                    color: `var(--blue-color)`,
                  },
                }}
              >
                {t('chart.chart_type')}
              </InputLabel>
              <StyledSelect
                labelId="chartType-select-label"
                value={
                  showColumnValues ? EChartTypes.COLUMN_COMPACT_VIEW : chartType
                }
                onChange={handleChartType}
                label={t('chart.chart_type')}
              >
                {[
                  { value: EChartTypes.LINE, label: t('chart.line') },
                  { value: EChartTypes.COLUMN, label: t('chart.column') },
                  {
                    value: EChartTypes.COLUMN_COMPACT_VIEW,
                    label: t('chart.column_compact_view'),
                  },
                ].map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    disabled={
                      item.value === EChartTypes.COLUMN_COMPACT_VIEW &&
                      items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW
                    }
                  >
                    <Trans i18nKey={item.label}></Trans>
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
        </div>
      </div>
      <div className="report-chart">
        {height && <ReactFC {...chartConfigs} />}
      </div>
    </>
  );
};

export default ReportChartPerformance;
