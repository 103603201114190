import moment from 'moment';

import {
  getColorByPropertyName,
  getColorForSeries,
} from '@/components/Account/utils';
import i18n from '@/i18n';
import { EGraphTypes, IGraphMarketTrendsList } from '@/store/types';

import { capitalizedTimePeriod, getChartType, palettecolors } from './utils';

const timePeriodOptions = [
  { value: 'twoYears', label: 'chart.2_years' },
  { value: 'fiveYears', label: 'chart.5_years' },
];

const replaceSeriesName = (data: string | undefined, series: string) => {
  const replacedLabel = data?.replace(/\$seriesName/g, series);

  return replacedLabel;
};

const dataList = (timePeriod?: string): IGraphMarketTrendsList[] => {
  const data = [
    {
      value: 'totalUnit',
      label: 'field.units',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Sold Units`,
      toolText: '$label {br} $seriesName: Units Sold: $dataValue',
    },
    {
      value: 'totalVolume',
      label: 'field.volume',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Sold Volume`,
      toolText: '$label {br} $seriesName: Volume Sold: $dataValue',
    },
    {
      value: 'avgPrice',
      label: 'field.average_price',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by  Average Sold Price`,
      toolText: '$label {br} $seriesName: Average Price Sold: $dataValue',
    },
    {
      value: 'medianPrice',
      label: 'field.median_price',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Median Sold Price`,
      toolText: '$label {br} $seriesName: Median Price Sold: $dataValue',
    },
    {
      value: 'avgRatio',
      label: 'field.sold_asked_ratio',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Average Sold/Asked Ratio of Sold Units`,
      toolText:
        '$label {br} $seriesName: Average Sold To Asked Ratio: $dataValue',
    },
    {
      value: 'medianRatio',
      label: 'field.median_sold_asked_ratio',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Median Sold/Asked Ratio of Sold Units`,
      toolText:
        '$label {br} $seriesName: Median Sold To Asked Ratio: $dataValue',
    },
    {
      value: 'avgDOM',
      label: 'field.average_dom',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Average Days on Market of Sold Units`,
      toolText: '$label {br} $seriesName: Average Days On Market: $dataValue',
    },
    {
      value: 'medianDOM',
      label: 'field.median_dom',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Comparative Trends by Median Days on Market of Sold Units`,
      toolText: '$label {br} $seriesName: Media Days on Market: $dataValue',
    },
  ];

  return data;
};

const seriesMappings = {
  seriesMultipleTwo: {
    totalUnit: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    totalVolume: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    avgPrice: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    medianPrice: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    avgRatio: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    medianRatio: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    avgDOM: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
    medianDOM: {
      seriesOne: 'series.current_criteria',
      seriesTwo: 'series.1_year_before',
    },
  },
  seriesMultipleFive: {
    totalUnit: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    totalVolume: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    avgPrice: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    medianPrice: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    avgRatio: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    medianRatio: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    avgDOM: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
    medianDOM: {
      seriesOne: 'series.current',
      seriesTwo: 'series.1_year_back',
      seriesThree: 'series.2_years_back',
      seriesFour: 'series.3_years_back',
      seriesFive: 'series.4_years_back',
    },
  },
  simple: {
    totalUnit: {
      seriesOne: 'field.units',
    },
    totalVolume: {
      seriesOne: 'field.volume',
    },
    avgPrice: {
      seriesOne: 'field.average_price',
    },
    medianPrice: {
      seriesOne: 'field.median_price',
    },
    avgRatio: {
      seriesOne: 'field.sold_asked_ratio',
    },
    medianRatio: {
      seriesOne: 'field.median_sold_asked_ratio',
    },
    avgDOM: {
      seriesOne: 'field.average_dom',
    },
    medianDOM: {
      seriesOne: 'field.median_dom',
    },
  },
};

const getChartConfig = (
  items: Array<any>,
  chartType: string,
  showColumnValues: boolean,
  height: number,
  data: string
) => {
  const commonConfig = {
    type: getChartType(chartType, items, showColumnValues),
    width: '100%',
    height: height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        palettecolors: palettecolors(),
        theme: 'fusion',
        exportEnabled: '1',
        exportFormats: 'PNG|JPG|PDF|SVG',
        exportFileName: 'market-comparative-graph',
        formatNumberScale: '0',
        decimalSeparator: '.',
        thousandSeparator: ',',
        showSum: `${Number(!showColumnValues)}`,
        showValues: '0',
        numberSuffix: data === 'medianRatio' || data === 'avgRatio' ? '%' : '',
        numberPrefix:
          data === 'avgPrice' ||
          data === 'medianPrice' ||
          data === 'totalVolume'
            ? '$'
            : '',
        drawcrossline: '1',
        setAdaptiveYMin: '1',
      },
      categories: [
        {
          category: items.map((i: any) => {
            return {
              label: `${
                i.month && !i.week
                  ? moment.utc(`${i.month}`, 'M').format('MMM')
                  : ''
              } ${
                i.year ? moment.utc(`${i.year}`, 'YYYY').format('YYYY') : ''
              } ${
                i.seriesOneYear
                  ? moment(i.seriesOneYear, 'YYYY').format('YY')
                  : ''
              } ${i.week ? `Week ${i.week}` : ''}`,
            };
          }),
        },
      ],
      dataset: [{}],
    },
  };

  return commonConfig;
};

const generateDataset = (
  items: any,
  graphType: EGraphTypes,
  data: string,
  seriesMappings: any
) => {
  const dataset = [];

  if (graphType && data && items && Array.isArray(items)) {
    //@ts-ignore
    const seriesMapping =
      seriesMappings[graphType] && seriesMappings[graphType][data];

    for (const series in seriesMapping) {
      const seriesTranslation = i18n.t(seriesMapping[series]);

      if (items.some((item) => item[seriesTranslation] !== null)) {
        if (graphType === EGraphTypes.DUAL_SIMPLE && series !== 'seriesOne') {
          continue;
        }

        let seriesname;
        const startMonth = moment.utc(`${items[0].month}`, 'M').format('MMM');
        const endMonth = moment
          .utc(`${items[items.length - 1].month}`, 'M')
          .format('MMM');

        switch (series) {
          case 'seriesOne':
            seriesname = `${seriesTranslation} (${startMonth} ${
              items[0].seriesOneYear
            } - ${endMonth} ${items[items.length - 1].seriesOneYear})`;
            break;
          case 'seriesTwo':
            seriesname = `${seriesTranslation} (${startMonth} ${
              items[0].seriesTwoYear
            } - ${endMonth} ${items[items.length - 1].seriesTwoYear})`;
            break;
          case 'seriesThree':
            seriesname = `${seriesTranslation} (${startMonth} ${
              items[0].seriesThreeYear
            } - ${endMonth} ${items[items.length - 1].seriesThreeYear})`;
            break;
          case 'seriesFour':
            seriesname = `${seriesTranslation} (${startMonth} ${
              items[0].seriesFourYear
            } - ${endMonth} ${items[items.length - 1].seriesFourYear})`;
            break;
          case 'seriesFive':
            seriesname = `${seriesTranslation} (${startMonth} ${
              items[0].seriesFiveYear
            } - ${endMonth} ${items[items.length - 1].seriesFiveYear})`;
            break;
          default:
            seriesname = seriesTranslation;
            break;
        }

        dataset.push({
          seriesname,
          data: items
            .filter((item) => item[seriesTranslation] !== null)
            .map((item) => ({
              value: item[series],
              toolText: replaceSeriesName(
                dataList().find((item) => item.value === data)?.toolText,
                seriesTranslation
              ),
              color: getColorForSeries(series),
              anchorBorderColor: getColorForSeries(series),
              anchorBgColor: getColorForSeries(series),
              legendBgColor: getColorForSeries(series),
              legendBorderColor: getColorForSeries(series),
            })),
        });
      }
    }
  }

  return dataset;
};

const generateDualSimpleChartConfig = (
  items: any,
  graphType: EGraphTypes,
  data: string,
  seriesMappings: any
) => {
  if (
    graphType &&
    data &&
    items &&
    Array.isArray(items) &&
    graphType === EGraphTypes.DUAL_SIMPLE
  ) {
    //@ts-ignore
    const seriesMapping =
      seriesMappings['dual-simple'] && seriesMappings['dual-simple'][data];

    const dataset = [];

    for (const series in seriesMapping) {
      const seriesTranslation = i18n.t(seriesMapping[series]);
      let seriesname;
      const startMonth = moment.utc(`${items[0].month}`, 'M').format('MMM');
      const endMonth = moment
        .utc(`${items[items.length - 1].month}`, 'M')
        .format('MMM');

      switch (series) {
        case 'seriesOne':
          seriesname = `${seriesTranslation} (${startMonth} ${
            items[0].seriesOneYear
          } - ${endMonth} ${items[items.length - 1].seriesOneYear})`;
          break;
        case 'seriesTwo':
          seriesname = `${seriesTranslation} (${startMonth} ${
            items[0].seriesTwoYear
          } - ${endMonth} ${items[items.length - 1].seriesTwoYear})`;
          break;
        case 'seriesThree':
          seriesname = `${seriesTranslation} (${startMonth} ${
            items[0].seriesThreeYear
          } - ${endMonth} ${items[items.length - 1].seriesThreeYear})`;
          break;
        case 'seriesFour':
          seriesname = `${seriesTranslation} (${startMonth} ${
            items[0].seriesFourYear
          } - ${endMonth} ${items[items.length - 1].seriesFourYear})`;
          break;
        case 'seriesFive':
          seriesname = `${seriesTranslation} (${startMonth} ${
            items[0].seriesFiveYear
          } - ${endMonth} ${items[items.length - 1].seriesFiveYear})`;
          break;
        default:
          seriesname = seriesTranslation;
          break;
      }

      dataset.push({
        seriesname,
        data: items
          .filter((item) => item['seriesTwo'] !== null)
          .map((item) => ({
            value: item[series],
            toolText: replaceSeriesName(
              dataList().find((item) => item.value === data)?.toolText,
              seriesMapping[series]
            ),
            color: getColorForSeries(series),
            anchorBorderColor: getColorForSeries(series),
            anchorBgColor: getColorForSeries(series),
            legendBgColor: getColorForSeries(series),
            legendBorderColor: getColorForSeries(series),
          })),
      });
    }

    return dataset;
  }

  return [];
};

export const dataLabel = (timePeriod: string, data: string) =>
  dataList(timePeriod).find((item) => item.value === data)?.title;

export {
  dataList,
  generateDataset,
  generateDualSimpleChartConfig,
  getChartConfig,
  seriesMappings,
  timePeriodOptions,
};
