import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Account } from '@/components/Account';
import Settings from '@/components/Account/Settings';
import Help from '@/components/Help/Help';
import { KeyReports } from '@/components/KeyReports';
import { Main } from '@/components/Main';
import { Map } from '@/components/Map';
import TokenLogin from '@/components/TokenLogin/TokenLogin';
import { ROUTE } from '@/router/consts';

import Hotsheets from './components/Hotsheets/Hotsheets';

export const authRoutes: RouteObject[] = [
  {
    path: ROUTE.ACCOUNT,
    element: <Account />,
  },
  {
    path: ROUTE.SETTINGS,
    element: <Settings />,
  },
  {
    path: ROUTE.KEY_REPORTS,
    element: <KeyReports />,
  },
  {
    path: ROUTE.HELP,
    element: <Help />,
  },
  {
    path: ROUTE.HOTSHEETS,
    element: <Hotsheets />,
  },
];

export const publicRoutes: RouteObject[] = [
  {
    path: ROUTE.MAIN,
    element: <Main />,
  },
  {
    path: ROUTE.TOKEN_LOGIN,
    element: <TokenLogin />,
  },
  {
    path: ROUTE.MAP,
    element: <Map />,
  },
];
