import { Checkbox } from '@mui/material';
import { styled } from '@mui/system';

const StyledCheckbox = styled(Checkbox)`
  color: var(--blue-color);

  &.Mui-checked {
    color: var(--blue-color);
  }

  &.MuiCheckbox-indeterminate {
    color: var(--blue-color);
  }
`;

export default StyledCheckbox;
