import { useTranslation } from 'react-i18next';

import { getColorByPropertyName } from '@/components/Account/utils';
import { EChartTypes, TReportItem } from '@/store/types';

export const QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW = 12;

export const capitalizedTimePeriod = (timePeriod?: string) => {
  const { t } = useTranslation();

  if (timePeriod) {
    if (timePeriod === 'twoYears') {
      return t('chart.two_years');
    } else if (timePeriod === 'fiveYears') {
      return t('chart.five_years');
    }

    return timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1);
  }
};

export const getChartType = (
  chartType: string,
  items: TReportItem[],
  showColumnValues: boolean
) => {
  if (chartType === EChartTypes.COLUMN) {
    return items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW ||
      showColumnValues
      ? 'mscolumn2d'
      : 'mscolumn2d';
  } else if (chartType === EChartTypes.LINE) {
    return 'msline';
  }
};

export const Debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
) => {
  let debounceTimer: NodeJS.Timeout;
  return (...args: Parameters<F>) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

export const handleResize = (
  data: string,
  height: number,
  setHeight: (newHeight: number) => void
) => {
  const reportHeightWrapper = document.querySelector('[data-report-height]');
  const reportHeaderPerformanceWrapper = document.querySelector(
    '[data-report-header]'
  );
  const reportChartMarketComparativeWrapper = document.querySelector(
    '[data-report-chart]'
  );

  let newHeight = height;

  if (
    reportHeightWrapper &&
    reportHeaderPerformanceWrapper &&
    reportChartMarketComparativeWrapper
  ) {
    newHeight =
      reportHeightWrapper.clientHeight -
      reportHeaderPerformanceWrapper.clientHeight -
      reportChartMarketComparativeWrapper.clientHeight -
      5;
  } else if (window.innerWidth <= 1679) {
    newHeight = 320;
  } else {
    newHeight = 580;
  }

  if (data === 'avgRatioWOMMOM' || data === 'totalUnitWOMMOM') {
    const heightForDoubleGraph = newHeight;

    newHeight = heightForDoubleGraph / 2;
  }

  setHeight(newHeight);
};

const colorProperties = [
  'graphs.color1',
  'graphs.color2',
  'graphs.color3',
  'graphs.color4',
  'graphs.color5',
];

export const palettecolors = () =>
  colorProperties
    .map((property) => getColorByPropertyName(property))
    .join(', ');
