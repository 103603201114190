import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { IKeySettings } from '@/store/types';
import {
  maxNumber,
  onlyNumbers,
  onlyNumbersWithOneComma,
  onlyNumbersWithOneDot,
} from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';

import './common.scss';

const toNumberOrNull = (val: any): number | null => {
  if (val === '-' || val === '') return null;
  return parseFloat(val.toString().replace(/,/g, ''));
};

const MinMaxFilter = ({
  propertyName,
  secondaryOrderBy,
  float,
  isMinusSign,
}: {
  propertyName: string;
  secondaryOrderBy: string;
  float: string;
  isMinusSign?: boolean;
}) => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [minValue, setMinValue] = useState<string>('');
  const [maxValue, setMaxValue] = useState<string>('');
  const open = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        [`${propertyName}Min`]: Yup.string()
          .nullable()
          .test('noOnlyMinusSign', '', (val: any) => val !== '-')
          .test(
            'maxNumber',
            i18n.t('max_number_qty', { qty: maxNumber }),
            (val: any) => {
              const numberVal = toNumberOrNull(val);

              if (numberVal === null) return true; // Bypass further checks if value is null
              return numberVal <= maxNumber && numberVal >= -maxNumber;
            }
          ),
        [`${propertyName}Max`]: Yup.string()
          .nullable()
          .test('noOnlyMinusSign', '', (val: any) => val !== '-')
          .when(`${propertyName}Min`, {
            is: (min: string) => {
              const numberMin = toNumberOrNull(min);
              return numberMin !== null && !isNaN(numberMin);
            },
            then: Yup.string().test({
              name: 'correctMinMaxRelation',
              message: '',
              test() {
                const minVal = toNumberOrNull(
                  this.parent[`${propertyName}Min`]
                );
                const maxVal = toNumberOrNull(
                  this.parent[`${propertyName}Max`]
                );

                if (minVal === null || maxVal === null) return true;

                return maxVal >= minVal;
              },
            }),
          })
          .test(
            'maxNumber',
            i18n.t('max_number_qty', { qty: maxNumber }),
            (val: any) => {
              const numberVal = toNumberOrNull(val);

              if (numberVal === null) return true;
              return numberVal <= maxNumber && numberVal >= -maxNumber;
            }
          ),
      })
    ),
  });

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = async () => {
    setMinValue('');
    setMaxValue('');

    if (secondaryOrderBy) {
      const removeKeySettingForYear = (
        secondaryOrderBy: string,
        keySettingsList: string
      ): string => {
        const array = keySettingsList.split(',');

        const index = array.indexOf(secondaryOrderBy);

        if (index === -1 || index + 1 >= array.length) {
          return keySettingsList;
        }

        array.splice(index, 2);

        return array.join(',');
      };

      const newKeySettingsListMin = removeKeySettingForYear(
        secondaryOrderBy,
        //@ts-ignore
        keySettings[`${propertyName}Min`]
      );
      const newKeySettingsListMax = removeKeySettingForYear(
        secondaryOrderBy,
        //@ts-ignore
        keySettings[`${propertyName}Max`]
      );

      await dispatch(
        setKeySettings({
          key: `${propertyName}Min`,
          value: newKeySettingsListMin,
        })
      );
      await dispatch(
        setKeySettings({
          key: `${propertyName}Max`,
          value: newKeySettingsListMax,
        })
      );
    } else {
      await dispatch(
        setKeySettings({
          key: `${propertyName}Min`,
          value: '',
        })
      );
      await dispatch(
        setKeySettings({
          key: `${propertyName}Max`,
          value: '',
        })
      );
    }
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const applyFilter = async () => {
    handleClose();

    if (secondaryOrderBy) {
      await dispatch(
        setKeySettings({
          key: `${propertyName}Min`,
          value: keySettings[`${propertyName}Min` as keyof IKeySettings]
            ? `${keySettings[`${propertyName}Min` as keyof IKeySettings]}${
                minValue ? ',' + secondaryOrderBy : ''
              }${minValue ? ',' + minValue.replace(/,/g, '') : ''}`
            : `${minValue ? secondaryOrderBy : ''}${
                minValue ? ',' + minValue.replace(/,/g, '') : ''
              }`,
        })
      );

      await dispatch(
        setKeySettings({
          key: `${propertyName}Max`,
          value: keySettings[`${propertyName}Max` as keyof IKeySettings]
            ? `${keySettings[`${propertyName}Max` as keyof IKeySettings]}${
                maxValue ? ',' + secondaryOrderBy : ''
              }${maxValue ? ',' + maxValue.replace(/,/g, '') : ''}`
            : `${maxValue ? secondaryOrderBy : ''}${
                maxValue ? ',' + maxValue.replace(/,/g, '') : ''
              }`,
        })
      );
    } else {
      await dispatch(
        setKeySettings({
          key: `${propertyName}Min`,
          value: minValue.replace(/,/g, ''),
        })
      );

      await dispatch(
        setKeySettings({
          key: `${propertyName}Max`,
          value: maxValue.replace(/,/g, ''),
        })
      );
      await dispatch(
        setKeySettings({
          key: 'page',
          value: 1,
        })
      );
    }
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const floatFunction =
    float && float === 'comma'
      ? onlyNumbersWithOneComma
      : float && float === 'dot'
      ? onlyNumbersWithOneDot
      : onlyNumbers;

  const handleMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value === '' ||
      floatFunction(e.target.value, isMinusSign ? isMinusSign : false)
    ) {
      setMinValue(e.target.value);
      setValue(`${propertyName}Min`, e.target.value, { shouldValidate: true });
    }
  };

  const handleMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value === '' ||
      floatFunction(e.target.value, isMinusSign ? isMinusSign : false)
    ) {
      setMaxValue(e.target.value);
      setValue(`${propertyName}Max`, e.target.value, { shouldValidate: true });
    }
  };

  const icon = (() => {
    //@ts-ignore
    const minKey = keySettings[`${propertyName}Min`];
    //@ts-ignore
    const maxKey = keySettings[`${propertyName}Max`];

    if (secondaryOrderBy) {
      if (
        (minKey?.includes(secondaryOrderBy) && minKey !== '') ||
        (maxKey?.includes(secondaryOrderBy) && maxKey !== '')
      ) {
        return 'filter';
      }
    } else {
      if (minKey !== '' || maxKey !== '') {
        return 'filter';
      }
    }

    return 'search';
  })();

  const getValueForYear = (yearToFind: string, pairsString: string) => {
    if (!yearToFind) {
      return pairsString;
    }

    if (!pairsString) return null;
    const pairs = pairsString.split(',');
    const index = pairs.indexOf(yearToFind.toString());

    return index !== -1 && index + 1 < pairs.length ? pairs[index + 1] : null;
  };

  const getTooltipText = (key: string, translationKey: string) => {
    return (key?.includes(secondaryOrderBy) && key !== '') || key !== ''
      ? t(translationKey, { quantity: getValueForYear(secondaryOrderBy, key) })
      : '';
  };

  const initTooltip = (): string => {
    //@ts-ignore
    const minKey = keySettings[`${propertyName}Min`];
    //@ts-ignore
    const maxKey = keySettings[`${propertyName}Max`];

    const minTooltip =
      minKey !== '' ? getTooltipText(minKey, 'from_selected') : '';
    const maxTooltip =
      maxKey !== '' ? getTooltipText(maxKey, 'to_selected') : '';

    return `${minTooltip} ${maxTooltip} ${t('click_clear')}`;
  };

  const tooltipText: string = initTooltip();

  const handleClick = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    //@ts-ignore
    const minKey = keySettings[`${propertyName}Min`];
    //@ts-ignore
    const maxKey = keySettings[`${propertyName}Max`];

    if (secondaryOrderBy) {
      if (
        (minKey?.includes(secondaryOrderBy) && minKey !== '') ||
        (maxKey?.includes(secondaryOrderBy) && maxKey !== '')
      ) {
        handleClear();
      } else {
        handleOpen(e);
      }
    } else {
      if (minKey !== '' || maxKey !== '') {
        handleClear();
      } else {
        handleOpen(e);
      }
    }
  };

  const RenderSearchButton = () => {
    //@ts-ignore
    const minKey = keySettings[`${propertyName}Min`];
    //@ts-ignore
    const maxKey = keySettings[`${propertyName}Max`];
    let shouldShowTooltip;

    if (secondaryOrderBy) {
      shouldShowTooltip =
        (minKey?.includes(secondaryOrderBy) && minKey !== '') ||
        (maxKey?.includes(secondaryOrderBy) && maxKey !== '');
    } else {
      shouldShowTooltip = minKey !== '' || maxKey !== '';
    }

    return shouldShowTooltip ? (
      <Tooltip title={tooltipText}>
        <IconButton aria-label="search" className="searchBtn">
          <SpriteIcon icon={icon} />
        </IconButton>
      </Tooltip>
    ) : (
      <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
    );
  };

  return (
    <div className="column-search-menu-wrap">
      <div onClick={handleClick} onTouchStart={handleClick}>
        <RenderSearchButton />
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 filter-block-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className="d-flex">
          <div className="d-flex flex-column">
            <label className="form-label" htmlFor={`${propertyName}Min`}>
              {t('min')}
            </label>
            <StyledTextField
              id={`${propertyName}Min`}
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={minValue}
              variant="outlined"
              sx={{ paddingLeft: '0', paddingRight: '6px', width: '64px' }}
              error={!!errors[`${propertyName}Min`]}
              //@ts-ignore
              helperText={errors?.[`${propertyName}Min`]?.message}
              {...register(`${propertyName}Min`)}
              onChange={handleMin}
            />
          </div>
          <div className="d-flex flex-column">
            <label
              style={{ paddingLeft: '6px' }}
              className="form-label"
              htmlFor={`${propertyName}Max`}
            >
              {t('max')}
            </label>
            <StyledTextField
              id={`${propertyName}Max`}
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={maxValue}
              variant="outlined"
              sx={{ paddingLeft: '6px', paddingRight: '0', width: '64px' }}
              error={!!errors[`${propertyName}Max`]}
              //@ts-ignore
              helperText={errors?.[`${propertyName}Max`]?.message}
              {...register(`${propertyName}Max`)}
              onChange={handleMax}
            />
          </div>
        </form>
        <div
          className="filter-footer"
          style={{ padding: 0, marginTop: '10px' }}
        >
          <Button
            type="submit"
            style={{ width: '100%' }}
            className="filled-btn"
            disabled={minValue === '' && maxValue === ''}
            onClick={handleSubmit(applyFilter)}
            onTouchStart={handleSubmit(applyFilter)}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default MinMaxFilter;
