import React, { useState } from 'react';
import moment from 'moment/moment';

import NotesClose from '@/assets/images/notes-close.png';
import NotesEdit from '@/assets/images/notes-edit.png';
import NotesNote from '@/assets/images/notes-note.png';
import NotesOk from '@/assets/images/notes-ok.png';
import NotesOkHover from '@/assets/images/notes-ok-hover.png';
import NotesRemove from '@/assets/images/notes-remove.png';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { TReportItem } from '@/store/types';
import { Button, IconButton, Tooltip } from '@mui/material';

import './Notes.scss';

interface Note {
  id: number;
  text: string;
  editing: boolean;
}

interface NotesProps {
  row: TReportItem;
  toggleDrawer: (id: number | string) => (open: boolean) => void;
}

const Notes: React.FC<NotesProps> = (props: NotesProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState<string>('');
  const { toggleDrawer, row } = props;
  const { agentName } = row;

  const handleAddNote = () => {
    if (newNote.length > 0) {
      const newNoteObj: Note = {
        id: notes.length + 1,
        text: newNote,
        editing: false,
      };

      setNotes([newNoteObj, ...notes]);
      setNewNote('');
    }
  };

  const handleEditNote = (id: number) => {
    const updatedNotes = notes.map((note) =>
      note.id === id ? { ...note, editing: !note.editing } : note
    );

    setNotes(updatedNotes);
  };

  const handleUpdateNote = (id: number, newText: string) => {
    const updatedNotes = notes.map((note) =>
      note.id === id ? { ...note, text: newText } : note
    );

    setNotes(updatedNotes);
  };

  const handleSaveNote = (id: number) => {
    const updatedNotes = notes.map((note) =>
      note.id === id ? { ...note, editing: false } : note
    );

    setNotes(updatedNotes);
  };

  const handleRemoveNote = (id: number) => {
    const updatedNotes = notes.filter((note) => note.id !== id);
    setNotes(updatedNotes);
  };

  return (
    <div className="add-note">
      <div className="add-note__header">
        <p className="add-note__title">Add Note</p>
        <img
          style={{ cursor: 'pointer' }}
          src={NotesClose}
          alt="NotesClose"
          className="add-note__header__close"
          onClick={() => toggleDrawer(row.id)(false)}
          onTouchStart={() => toggleDrawer(row.id)(false)}
        />
      </div>
      <div className="add-note__subheader">
        <p className="add-note__note-count">{notes.length} Notes</p>
        <p className="add-note__date">
          Date: {moment.utc().format('MM/DD/YYYY')}
        </p>
      </div>
      <div className="add-note__form">
        <p className="add-note__form_name">{agentName}</p>
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Write note here..."
        />
        <div className="add-note__form-btn">
          <Button
            variant={'outlined'}
            className="filled-btn"
            onClick={handleAddNote}
            onTouchStart={handleAddNote}
          >
            Add Note
          </Button>
        </div>
      </div>
      <div className="add-note__list">
        {notes.map((note) => (
          <div
            className={`add-note__list-item ${note.editing ? 'active' : ''}`}
            key={note.id}
          >
            <div className="add-note__list-item-header">
              <p className="add-note__date-time">
                Date: {moment.utc().format('MM/DD/YYYY')}{' '}
                {moment.utc().format('h:mm a')}
              </p>
              <div className="add-note__list-item-header-actions">
                {note.editing ? (
                  <Tooltip title="SAVE" placement="top" arrow>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={NotesOk}
                      alt="NotesOk"
                      onClick={() => handleSaveNote(note.id)}
                      onTouchStart={() => handleSaveNote(note.id)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="EDIT" placement="top" arrow>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={NotesEdit}
                      alt="NotesEdit"
                      onClick={() => handleEditNote(note.id)}
                      onTouchStart={() => handleEditNote(note.id)}
                    />
                  </Tooltip>
                )}
                <Tooltip title="DELETE" placement="top" arrow>
                  <img
                    style={{ cursor: 'pointer' }}
                    src={NotesRemove}
                    alt="NotesRemove"
                    onClick={() => handleRemoveNote(note.id)}
                    onTouchStart={() => handleRemoveNote(note.id)}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="add-note__list-item-divider"></div>
            <textarea
              value={note.text}
              disabled={!note.editing}
              onChange={(e) => handleUpdateNote(note.id, e.target.value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notes;
