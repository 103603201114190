import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Draggable from 'react-draggable';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import NotesNote from '@/assets/images/notes-note.png';
import CustomSelect from '@/components/CustomElements/CustomSelect';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import AgentNameFilter from '@/components/ReportTable/AgentNameFilter';
import AgentsFilter from '@/components/ReportTable/AgentsFilter';
import AvgDOMFilter from '@/components/ReportTable/AvgDOMFilter';
import AvgPriceFilter from '@/components/ReportTable/AvgPriceFilter';
import AvgRatioFilter from '@/components/ReportTable/AvgRatioFilter';
import BrandFilter from '@/components/ReportTable/BrandFilter';
import FirmFilter from '@/components/ReportTable/FirmFilter';
import FirmsFilter from '@/components/ReportTable/FirmsFilter';
import ListingUnitFilter from '@/components/ReportTable/ListingUnitFilter';
import ListingUnitPerAgentFilter from '@/components/ReportTable/ListingUnitPerAgentFilter';
import ListingUnitPerRegisteredAgentFilter from '@/components/ReportTable/ListingUnitPerRegisteredAgentFilter';
import ListingVolumeFilter from '@/components/ReportTable/ListingVolumeFilter';
import ListingVolumePerAgentFilter from '@/components/ReportTable/ListingVolumePerAgentFilter';
import ListingVolumePerRegisteredAgentFilter from '@/components/ReportTable/ListingVolumePerRegisteredAgentFilter';
import MinMaxFilter from '@/components/ReportTable/MinMaxFilter';
import Notes from '@/components/ReportTable/Notes';
import OfficeFilter from '@/components/ReportTable/OfficeFilter';
import OfficesFilter from '@/components/ReportTable/OfficesFilter';
import RegisteredAgentsFilter from '@/components/ReportTable/RegisteredAgentsFilter';
import SellingUnitFilter from '@/components/ReportTable/SellingUnitFilter';
import SellingUnitPerAgentFilter from '@/components/ReportTable/SellingUnitPerAgentFilter';
import SellingUnitPerRegisteredAgentFilter from '@/components/ReportTable/SellingUnitPerRegisteredAgentFilter';
import SellingVolumeFilter from '@/components/ReportTable/SellingVolumeFilter';
import SellingVolumePerAgentFilter from '@/components/ReportTable/SellingVolumePerAgentFilter';
import SellingVolumePerRegisteredAgentFilter from '@/components/ReportTable/SellingVolumePerRegisteredAgentFilter';
import TotalUnitFilter from '@/components/ReportTable/TotalUnitFilter';
import TotalUnitPerAgentFilter from '@/components/ReportTable/TotalUnitPerAgentFilter';
import TotalUnitPerRegisteredAgentFilter from '@/components/ReportTable/TotalUnitPerRegisteredAgentFilter';
import TotalVolumeFilter from '@/components/ReportTable/TotalVolumeFilter';
import TotalVolumePerAgentFilter from '@/components/ReportTable/TotalVolumePerAgentFilter';
import TotalVolumePerRegisteredAgentFilter from '@/components/ReportTable/TotalVolumePerRegisteredAgentFilter';
import {
  adjustColumnPositions,
  findAndCheckFreeze,
} from '@/components/ReportTable/utils';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledCheckbox from '@/mui-styled-components/checkbox';
import { objToQueryString } from '@/router/router';
import { AppDispatch } from '@/store';
import {
  EOrder,
  EReportName,
  EReportTypes,
  EReportUnit,
  ETimeFrames,
} from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import {
  getKeySettings,
  getSavedSearchList,
  resetKeySettings,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getConfig, initConfig } from '@/store/reportFieldsSlice';
import { getReport, loadReport } from '@/store/reportSlice';
import {
  HeadCell,
  IAvailableReports,
  ReportTableData,
  RowWithButton,
  TReportItem,
  TTotals,
} from '@/store/types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Modal,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

import { ReportProfileButton } from '../ReportProfileButton/ReportProfileButton';
import { filterQueryParams } from '../ReportTypesPanel/utils';

import './ReportTable.scss';
import './Filter.scss';
import '../SpriteIcon/SpriteIcon.scss';

const hasValueGreaterThanZero = (obj: any): boolean => {
  for (const key in obj) {
    if (obj[key] > 0) {
      return true;
    }
  }
  return false;
};

const propertyTypes = [
  'listingUnit',
  'sellingUnit',
  'totalUnit',
  'listingVolume',
  'sellingVolume',
  'totalVolume',
  'avgPrice',
  'avgRatio',
  'avgDOM',
  'previousAgents',
];

interface IReportTableProps {
  headCells: Array<HeadCell>;
  rows: Array<TReportItem>;
}

interface EnhancedTableProps {
  selected: Array<TReportItem>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown> | React.TouchEvent<unknown>,
    property: keyof ReportTableData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  headCells: Array<HeadCell>;
}

const includedReports = [
  EReportName.AGENT_TRANSACTIONS,
  EReportName.OFFICE_TRANSACTIONS,
  EReportName.FIRM_TRANSACTIONS,
  EReportName.BRAND_TRANSACTIONS,
];

const maxQtyOfRecordSelection = 10;

const sortable = [
  'agentName',
  'officeName',
  'firmName',
  'brandName',
  'firms',
  'offices',
  'agents',
  'registeredAgents',
  'listingUnit',
  'listingUnitPerAgent',
  'listingUnitPerRegisteredAgent',
  'listingUnitMarketShare',
  'listingVolume',
  'listingVolumePerAgent',
  'listingVolumePerRegisteredAgent',
  'listingVolumeMarketShare',
  'sellingUnit',
  'sellingUnitPerAgent',
  'sellingUnitPerRegisteredAgent',
  'sellingUnitMarketShare',
  'sellingVolume',
  'sellingVolumePerAgent',
  'sellingVolumePerRegisteredAgent',
  'sellingVolumeMarketShare',
  'totalUnit',
  'totalUnitPerAgent',
  'totalUnitPerRegisteredAgent',
  'totalUnitMarketShare',
  'totalVolume',
  'totalVolumePerAgent',
  'totalVolumePerRegisteredAgent',
  'totalVolumeMarketShare',
  'avgPrice',
  'avgRatio',
  'avgDOM',
  'rankU',
  'rankV',
  'percentRankU',
  'percentRankV',
  'officeCounty',
  'officeCity',
  'officeZip',
  'previousUnit',
  'currentUnit',
  'growthUnit',
  'previousVolume',
  'currentVolume',
  'growthVolume',
  'previousAvgPrice',
  'currentAvgPrice',
  'growthAvgPrice',
  'previousAvgRatio',
  'currentAvgRatio',
  'growthAvgRatio',
  'previousAvgDOM',
  'currentAvgDOM',
  'growthAvgDOM',
  'mls',
  'closedDate',
  'listingAgentName',
  'listingBrandName',
  'listingFirmName',
  'listingOfficeName',
  'sellingAgentName',
  'sellingBrandName',
  'sellingFirmName',
  'sellingOfficeName',
  'unitCity',
  'unitZip',
  'unitDistrict',
  'unitClass',
  'unitType',
  'unitPrice',
  'unitRatio',
  'unitDOM',
  'changeDate',
  'closedUnit',
  'closedVolume',
  'pendingUnit',
  'pendingVolume',
  'expiredUnit',
  'expiredVolume',
  'withdrawnUnit',
  'withdrawnVolume',
  'percentageUnit',
  'percentageVolume',
  'priceRange',
  'avgRatioRange',
  'month',
  'quarter',
  'propertyType',
  'type',
  'style',
  'county',
  'city',
  'zipCode',
  'district',
  'subdivision',
  'fromBeforeDate',
  'officePreviousName',
  'officeStatus',
];

const findCellProperty = (
  id: string,
  property: string,
  items: Array<HeadCell>
): string | boolean | null => {
  const cell = items.find((item) => item.id === id);

  if (cell) {
    const result = (Object.keys(cell) as (keyof typeof cell)[]).find((key) => {
      if (key === property) {
        return cell[key];
      }
    });

    return result || null;
  }

  return null;
};

const columnFiltersComponents = {
  agentName: {
    component: AgentNameFilter,
  },
  officeName: {
    component: OfficeFilter,
  },
  firmName: {
    component: FirmFilter,
  },
  brandName: {
    component: BrandFilter,
  },
  firms: {
    component: FirmsFilter,
  },
  offices: {
    component: OfficesFilter,
  },
  agents: {
    component: AgentsFilter,
  },
  registeredAgents: {
    component: RegisteredAgentsFilter,
  },
  listingUnit: {
    component: ListingUnitFilter,
    props: {
      pluralName: true,
    },
  },
  listingUnitPerAgent: {
    component: ListingUnitPerAgentFilter,
  },
  listingUnitPerRegisteredAgent: {
    component: ListingUnitPerRegisteredAgentFilter,
  },
  sellingUnit: {
    component: SellingUnitFilter,
    props: {
      pluralName: true,
    },
  },
  sellingUnitPerAgent: {
    component: SellingUnitPerAgentFilter,
  },
  sellingUnitPerRegisteredAgent: {
    component: SellingUnitPerRegisteredAgentFilter,
  },
  totalUnit: {
    component: TotalUnitFilter,
    props: {
      pluralName: true,
    },
  },
  totalUnitPerAgent: {
    component: TotalUnitPerAgentFilter,
  },
  totalUnitPerRegisteredAgent: {
    component: TotalUnitPerRegisteredAgentFilter,
  },
  listingVolume: {
    component: ListingVolumeFilter,
    props: {
      float: 'dot',
    },
  },
  listingVolumePerAgent: {
    component: ListingVolumePerAgentFilter,
  },
  listingVolumePerRegisteredAgent: {
    component: ListingVolumePerRegisteredAgentFilter,
  },
  sellingVolume: {
    component: SellingVolumeFilter,
    props: {
      float: 'dot',
    },
  },
  sellingVolumePerAgent: {
    component: SellingVolumePerAgentFilter,
  },
  sellingVolumePerRegisteredAgent: {
    component: SellingVolumePerRegisteredAgentFilter,
  },
  totalVolume: {
    component: TotalVolumeFilter,
    props: {
      float: 'dot',
    },
  },
  totalVolumePerAgent: {
    component: TotalVolumePerAgentFilter,
  },
  totalVolumePerRegisteredAgent: {
    component: TotalVolumePerRegisteredAgentFilter,
  },
  avgPrice: {
    component: AvgPriceFilter,
  },
  avgRatio: {
    component: AvgRatioFilter,
    props: {
      float: 'dot',
    },
  },
  avgDOM: {
    component: AvgDOMFilter,
  },
  previousUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  currentUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  growthUnit: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
      pluralName: true,
      isMinusSign: true,
    },
  },
  previousVolume: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  currentVolume: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  growthVolume: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
      isMinusSign: true,
    },
  },
  previousAvgPrice: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  currentAvgPrice: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  growthAvgPrice: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
      isMinusSign: true,
    },
  },
  previousAvgRatio: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  currentAvgRatio: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  growthAvgRatio: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  previousAvgDOM: {
    component: MinMaxFilter,
  },
  currentAvgDOM: {
    component: MinMaxFilter,
  },
  growthAvgDOM: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
      isMinusSign: true,
    },
  },
  unitPrice: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  unitRatio: {
    component: MinMaxFilter,
    props: {
      float: 'dot',
    },
  },
  unitDOM: {
    component: MinMaxFilter,
  },
  closedUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  closedVolume: {
    component: MinMaxFilter,
  },
  pendingUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  pendingVolume: {
    component: MinMaxFilter,
  },
  expiredUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  expiredVolume: {
    component: MinMaxFilter,
  },
  withdrawnUnit: {
    component: MinMaxFilter,
    props: {
      pluralName: true,
    },
  },
  withdrawnVolume: {
    component: MinMaxFilter,
  },
};

const includedColumns = {
  [EReportName.AGENT_ROSTER_MIGRATION]: [
    'agentName',
    'officeName',
    'firmName',
    'brandName',
    'listingUnit',
    'sellingUnit',
    'totalUnit',
    'listingVolume',
    'sellingVolume',
    'totalVolume',
    'avgPrice',
  ],
};

const excludedColumns = {
  [EReportName.AGENTS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.OFFICES_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.FIRMS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.BRANDS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.AGENT_HISTORY]: [
    'agentName',
    'firmName',
    'officeName',
    'brandName',
  ],
  [EReportName.AGENT_DISTRIBUTION]: ['totalUnit', 'agents'],
  [EReportName.OFFICE_DISTRIBUTION]: ['totalUnit', 'offices'],
  [EReportName.FIRM_DISTRIBUTION]: ['totalUnit', 'firms'],
  [EReportName.BRAND_DISTRIBUTION]: ['totalUnit', 'brands'],
  [EReportName.OFFICES_REGISTERED_AGENT_COUNT]: ['agents'],
  [EReportName.FIRMS_REGISTERED_AGENT_COUNT]: ['agents'],
  [EReportName.BRANDS_REGISTERED_AGENT_COUNT]: ['agents'],
};

const includedSortableColumns = {
  [EReportName.AGENT_ROSTER_MIGRATION]: ['agentName', 'changeDate'],
};

const excludedSortableColumns = {
  [EReportName.AGENTS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.OFFICES_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.FIRMS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.BRANDS_DISTRIBUTION]: ['agents', 'firms', 'offices', 'brands'],
  [EReportName.AGENT_DISTRIBUTION]: ['agents'],
  [EReportName.OFFICE_DISTRIBUTION]: ['offices'],
  [EReportName.FIRM_DISTRIBUTION]: ['firms'],
  [EReportName.BRAND_DISTRIBUTION]: ['brands'],
  [EReportName.OFFICES_REGISTERED_AGENT_COUNT]: ['agents'],
  [EReportName.FIRMS_REGISTERED_AGENT_COUNT]: ['agents'],
  [EReportName.BRANDS_REGISTERED_AGENT_COUNT]: ['agents'],
};

const EmptyReport = React.memo(
  ({ isInsideTable = false }: { isInsideTable?: boolean }) => {
    const { t } = useTranslation();
    const { reset } = useSelector(getReport);
    const text = reset
      ? t('messages.reset_report')
      : t('messages.empty_report');

    const WrapperElement = isInsideTable ? 'tr' : 'div';
    const ChildElement = isInsideTable ? 'td' : 'div';

    return (
      <WrapperElement
        className={`empty-report ${isInsideTable ? 'table-layout' : ''}`}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <ChildElement>
          <div className="circle-wrap">
            <SpriteIcon icon="warning_amber" />
          </div>
          <div>
            <p className="empty-text">{text}</p>
          </div>
        </ChildElement>
      </WrapperElement>
    );
  }
);

EmptyReport.displayName = 'EmptyReport';

const EnhancedTableHead = React.memo((props: EnhancedTableProps) => {
  const { t } = useTranslation();
  const {
    orderDirection,
    orderBy,
    secondaryOrderBy,
    agentIds,
    officeIds,
    firmIds,
    brandIds,
    reportName,
  } = useSelector(getKeySettings);
  const { items, loading } = useSelector(getReport);
  const reportFields = useSelector(getConfig);
  const [indeterminate, setIsIndeterminate] = useState<boolean>(false);
  const [checked, setIsChecked] = useState<boolean>(false);
  const location = useLocation();

  const includedSortableColumnsForReport =
    //@ts-ignore
    includedSortableColumns[reportName] || [];

  const excludedSortableColumnsForReport =
    //@ts-ignore
    excludedSortableColumns[reportName] || [];

  const filteredSortableColumns = (() => {
    const includedColumns = sortable.filter((column) =>
      includedSortableColumnsForReport.includes(column.replace(/\d{4}$/, ''))
    );

    if (includedColumns.length === 0) {
      return sortable.filter(
        (column) =>
          !excludedSortableColumnsForReport.includes(
            column.replace(/\d{4}$/, '')
          )
      );
    } else if (includedColumns.length !== 0) {
      return includedColumns;
    } else {
      return sortable;
    }
  })();

  //@ts-ignore
  const reportSpecificColumns = includedColumns[reportName] || [];
  //@ts-ignore
  const reportSpecificExcludedColumns = excludedColumns[reportName] || [];

  const filteredColumnFiltersComponents = (() => {
    const includedColumns = Object.keys(columnFiltersComponents).filter(
      (column) => reportSpecificColumns.includes(column.replace(/\d{4}$/, ''))
    );

    if (includedColumns.length === 0) {
      const filteredObject = {};
      Object.keys(columnFiltersComponents).forEach((column) => {
        if (
          !reportSpecificExcludedColumns.includes(column.replace(/\d{4}$/, ''))
        ) {
          //@ts-ignore
          filteredObject[column] = columnFiltersComponents[column];
        }
      });
      return filteredObject;
    } else if (includedColumns.length !== 0) {
      const filteredObject = {};
      includedColumns.forEach((key) => {
        //@ts-ignore
        filteredObject[key] = columnFiltersComponents[key];
      });

      return filteredObject;
    } else {
      return columnFiltersComponents;
    }
  })();

  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    selected,
  } = props;
  const createSortHandler =
    (property: keyof ReportTableData) =>
    (event: React.MouseEvent<unknown> | React.TouchEvent<unknown>) => {
      onRequestSort(event, property);
    };

  useEffect(() => {
    setIsIndeterminate(
      items.some((item) =>
        selected.some((s) => {
          if (includedReports.includes(reportName as EReportName)) {
            return s.mls === item.mls;
          } else {
            return s.id === item.id;
          }
        })
      )
    );
    setIsChecked(
      items.every((item) =>
        selected.some((s) => {
          if (includedReports.includes(reportName as EReportName)) {
            return s.mls === item.mls;
          } else {
            return s.id === item.id;
          }
        })
      )
    );
  }, [selected, items]);

  useEffect(() => {
    if (selected?.length > maxQtyOfRecordSelection) {
      toast.error(
        t('messages.error_qty_record_selection', {
          qty: maxQtyOfRecordSelection,
        })
      );
    }
  }, [selected]);

  useEffect(() => {
    setIsIndeterminate(false);
    setIsChecked(false);
  }, [reportName]);

  return (
    <TableHead>
      <TableRow>
        {![
          EReportName.AGENTS_DISTRIBUTION,
          EReportName.OFFICES_DISTRIBUTION,
          EReportName.FIRMS_DISTRIBUTION,
          EReportName.BRANDS_DISTRIBUTION,
          EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
          EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
          EReportName.AGENT_DISTRIBUTION,
          EReportName.OFFICE_DISTRIBUTION,
          EReportName.FIRM_DISTRIBUTION,
          EReportName.BRAND_DISTRIBUTION,
          EReportName.AGENT_TRANSACTIONS,
          EReportName.OFFICE_TRANSACTIONS,
          EReportName.FIRM_TRANSACTIONS,
          EReportName.BRAND_TRANSACTIONS,
          EReportName.AGENT_HISTORY,
        ].includes(reportName as EReportName) && (
          <TableCell sx={{ width: '20px' }}>
            <StyledCheckbox
              checkedIcon={
                loading && checked ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: 'var(--blue-color)' }}
                  />
                ) : (
                  <CheckBoxIcon />
                )
              }
              color={
                selected?.length > maxQtyOfRecordSelection ? 'error' : 'primary'
              }
              indeterminate={checked ? false : indeterminate}
              checked={checked}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': t('select_all'),
              }}
              size="small"
              sx={{
                padding: '5px',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => {
          const replacedHeadCellId = headCell.id.replace(/\d{4}$/, '');
          const numericPart = headCell.id.match(/\d{4}$/);
          const numericValue = numericPart ? numericPart[0] : '';
          // @ts-ignore
          const FilterComponent = numericPart
            ? MinMaxFilter
            : // @ts-ignore
              filteredColumnFiltersComponents[replacedHeadCellId]?.component ||
              null;
          // @ts-ignore
          const propertyName = filteredColumnFiltersComponents[
            replacedHeadCellId
          ]?.props?.pluralName
            ? replacedHeadCellId + 's'
            : replacedHeadCellId || null;
          const filterProps =
            // @ts-ignore
            filteredColumnFiltersComponents[replacedHeadCellId]?.props || {};
          const headCellsFromStorage = sessionStorage.getItem('HeadCells');
          //@ts-ignore
          const { data } = JSON.parse(headCellsFromStorage);
          const cellFromStorage = data.find(
            (cell: any) => cell.id === headCell.id
          );

          const HeadCells = cellFromStorage || headCells;

          return HeadCells?.visible ? (
            <TableCell
              key={index}
              className={`${
                findAndCheckFreeze(reportFields, headCell.id) ? 'freeze' : ''
              }`}
              align={headCell.numeric ? 'center' : 'left'}
              //@ts-ignore
              sortDirection={
                headCell.id === `${orderBy}${secondaryOrderBy}`
                  ? orderDirection
                  : false
              }
            >
              <TableSortLabel
                active={headCell.id === `${orderBy}${secondaryOrderBy}`}
                //@ts-ignore
                direction={
                  orderDirection === EOrder.TOP
                    ? 'asc'
                    : orderDirection || undefined
                }
                hideSortIcon={
                  !filteredSortableColumns.includes(
                    headCell.id.replace(/\d{4}$/, '')
                  )
                }
                IconComponent={() => (
                  <SpriteIcon
                    icon="sort-icon"
                    style={{ width: 10, marginLeft: 5 }}
                    onClick={createSortHandler(headCell.id)}
                    onTouchStart={createSortHandler(headCell.id)}
                  />
                )}
              >
                {FilterComponent ? (
                  <FilterComponent
                    propertyName={propertyName}
                    secondaryOrderBy={numericValue}
                    {...filterProps}
                  />
                ) : null}
                <span
                  onClick={createSortHandler(headCell.id)}
                  onTouchStart={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </span>
              </TableSortLabel>
            </TableCell>
          ) : null;
        })}
        {location.pathname === '/hotsheets' && (
          <>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Notes</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
});

EnhancedTableHead.displayName = 'EnhancedTableHead';

const keysToChange: string[] = [
  'avgRatio',
  'percentRankV',
  'percentRankU',
  'growthUnit',
  'growthVolume',
  'growthAvgPrice',
  'growthAvgRatio',
  'growthAvgDOM',
  'fractionOfTotalMLSUnits',
  'fractionOfTotalMLSVolume',
  'listingUnitMarketShare',
  'sellingUnitMarketShare',
  'totalUnitMarketShare',
  'listingVolumeMarketShare',
  'sellingVolumeMarketShare',
  'totalVolumeMarketShare',
  'currentAvgRatio',
  'previousAvgRatio',
  'unitRatio',
  'percentageUnit',
  'percentageVolume',
  'percentageAgents',
  'percentageFirms',
  'percentageOffices',
  'percentageBrands',
  'runningSumAgents',
  'runningSumFirms',
  'runningSumOffices',
  'runningSumBrands',
  'runningSumVolume',
  'runningSumUnit',
];

const addPercentSignToKeys = (row: {
  [key: string]: number | string | { [year: string]: number };
}): { [key: string]: number | string | { [year: string]: string } } => {
  const newRow = JSON.parse(JSON.stringify(row));

  keysToChange.forEach((key) => {
    if (Object.keys(newRow).includes(key)) {
      if (typeof newRow[key] === 'number') {
        newRow[key] = `${newRow[key]}%`;
      } else if (newRow[key] !== null && typeof newRow[key] === 'object') {
        Object.keys(newRow[key]).forEach((year) => {
          newRow[key][year] = `${newRow[key][year]}%`;
        });
      }
    }
  });

  return newRow;
};

const keysHrefToChange: string[] = [
  'agents',
  'registeredAgents',
  'offices',
  'firms',
];

const CustomTableCell = (props: {
  row: TReportItem;
  headCells: HeadCell[];
  orderBy: keyof TReportItem;
  modifiedStartDate: string | null;
  modifiedEndDate: string | null;
  handleOpenModal: () => void;
}) => {
  const { t } = useTranslation();
  const {
    row,
    headCells,
    orderBy,
    modifiedStartDate,
    modifiedEndDate,
    handleOpenModal,
  } = props;
  const keys = headCells.map((item) => item.id as keyof TReportItem);
  const { lastDate } = useSelector(getFilterLists);
  const { reportUnit, reportName, mls, orderDirection, chartType, perPage } =
    useSelector(getKeySettings);
  const reportFields = useSelector(getConfig);
  const dispatch = useDispatch();

  const getHref = (params: Record<string, string>): string => {
    const urlParams = new URLSearchParams(
      filterQueryParams(window.location.search)
    );

    Object.entries(params).forEach(([key, value]) => {
      urlParams.set(key, value);

      if (value === '') {
        urlParams.delete(key);
      }

      urlParams.set('page', '1');
    });

    if (modifiedStartDate && modifiedEndDate) {
      urlParams.set('startDate', modifiedStartDate);
      urlParams.set('endDate', modifiedEndDate);
    }

    return `${window.location.origin}${
      window.location.pathname
    }?${urlParams.toString()}`;
  };

  const dispatchSettings = () => {
    const startDate = moment
      .utc(lastDate)
      .subtract(1, 'years')
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    dispatch(
      resetKeySettings({
        mls,
        timeFrame: ETimeFrames.YEAR,
        startDate,
        endDate: lastDate,
        reportUnit,
        reportName,
        orderDirection,
        chartType,
        perPage,
      })
    );
  };

  const handleFilterClick = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    row: {
      [key: string]: number | string;
    },
    params: Record<string, string>
  ) => {
    e.preventDefault();
    dispatchSettings();
    Object.keys(params).forEach((paramKey) => {
      if (paramKey.includes('Ids')) {
        if (params[paramKey] !== '') {
          dispatch(
            setKeySettings({ key: paramKey, value: row.id?.toString() ?? '' })
          );
        }
      }
    });
    dispatch(setKeySettings({ key: 'reportName', value: params.reportName }));
    dispatch(setKeySettings({ key: 'reportUnit', value: params.reportUnit }));
    //@ts-ignore
    dispatch(loadReport());
    //@ts-ignore
    dispatch(getSavedSearchList());
  };

  const addHrefToKeys = (row: {
    [key: string]: number | string;
  }): {
    [key: string]: number | string;
  } => {
    if (
      [
        EReportName.AGENTS_DISTRIBUTION,
        EReportName.OFFICES_DISTRIBUTION,
        EReportName.FIRMS_DISTRIBUTION,
        EReportName.BRANDS_DISTRIBUTION,
        EReportName.AGENT_DISTRIBUTION,
        EReportName.OFFICE_DISTRIBUTION,
        EReportName.FIRM_DISTRIBUTION,
        EReportName.BRAND_DISTRIBUTION,
      ].includes(reportName as EReportName)
    ) {
      return row;
    }
    keysHrefToChange.forEach((key: string) => {
      if (Object.keys(row).includes(key)) {
        const params: Record<string, string> = {};

        if (key === 'agents') {
          if (reportUnit === EReportUnit.BRANDS) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS;
            params.reportUnit = EReportUnit.AGENTS;
            params.officeIds = '';
            params.firmIds = '';
          }
          if (reportUnit === EReportUnit.FIRMS) {
            params.firmIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS;
            params.reportUnit = EReportUnit.AGENTS;
            params.officeIds = '';
            params.brandIds = '';
          }
          if (reportUnit === EReportUnit.OFFICES) {
            params.officeIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS;
            params.reportUnit = EReportUnit.AGENTS;
            params.firmIds = '';
            params.brandIds = '';
          }
        } else if (key === 'registeredAgents') {
          if (reportName === EReportName.OFFICES_REGISTERED) {
            params.officeIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS_REGISTERED;
            params.reportUnit = EReportUnit.AGENTS;
            params.firmIds = '';
            params.brandIds = '';
          }
          if (reportName === EReportName.FIRMS_REGISTERED) {
            params.firmIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS_REGISTERED;
            params.reportUnit = EReportUnit.AGENTS;
            params.officeIds = '';
            params.brandIds = '';
          }
          if (reportName === EReportName.BRANDS_REGISTERED) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.AGENTS_REGISTERED;
            params.reportUnit = EReportUnit.AGENTS;
            params.officeIds = '';
            params.firmIds = '';
          }
        } else if (key === 'offices') {
          if (reportUnit === EReportUnit.BRANDS) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.OFFICES;
            params.reportUnit = EReportUnit.OFFICES;
            params.officeIds = '';
            params.firmIds = '';
          }
          if (reportUnit === EReportUnit.FIRMS) {
            params.firmIds = row.id?.toString() ?? '';
            params.reportName = EReportName.OFFICES;
            params.reportUnit = EReportUnit.OFFICES;
            params.officeIds = '';
            params.brandIds = '';
          }
          if (reportName === EReportName.FIRMS_REGISTERED) {
            params.firmIds = row.id?.toString() ?? '';
            params.reportName = EReportName.OFFICES_REGISTERED;
            params.reportUnit = EReportUnit.OFFICES;
            params.officeIds = '';
            params.brandIds = '';
          }
          if (reportName === EReportName.BRANDS_REGISTERED) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.OFFICES_REGISTERED;
            params.reportUnit = EReportUnit.OFFICES;
            params.officeIds = '';
            params.firmIds = '';
          }
        } else if (key === 'firms') {
          if (reportUnit === EReportUnit.BRANDS) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.FIRMS;
            params.reportUnit = EReportUnit.FIRMS;
            params.officeIds = '';
            params.firmIds = '';
          }
          if (reportName === EReportName.BRANDS_REGISTERED) {
            params.brandIds = row.id?.toString() ?? '';
            params.reportName = EReportName.FIRMS_REGISTERED;
            params.reportUnit = EReportUnit.FIRMS;
            params.officeIds = '';
            params.firmIds = '';
          }
        }
        //@ts-ignore
        row = {
          ...row,
          [key]: (
            <a
              className="filter-link"
              href={getHref(params)}
              target="_blank"
              title={`Click to filter. + ${key}`}
              rel="noreferrer"
              onClick={(e) => {
                handleFilterClick(e, row, params);
              }}
              onTouchStart={(e) => {
                handleFilterClick(e, row, params);
              }}
            >
              {row[key]}
            </a>
          ),
        };
      }
    });

    return row;
  };

  //@ts-ignore
  const reportSpecificExcludedColumns = excludedColumns[reportName] || [];

  const addButtonMenu = (row: RowWithButton): RowWithButton => {
    const createButtonMenu = (key: string): string | JSX.Element => {
      if (!row[key]) {
        return '';
      }

      switch (row[key].toString()) {
        case 'Non Member':
          return t('report_values.non_member');
        case 'Administrator':
          return t('report_values.administrator');
      }

      return (
        <ReportProfileButton
          row={row}
          id={''}
          label={row[key]}
          fieldKey={key}
          allowFilter={!reportSpecificExcludedColumns.includes(key)}
        ></ReportProfileButton>
      );
    };

    const KEYS_BUTTON_MENU_TO_CHANGE: string[] = [
      'agentName',
      'brandName',
      'firmName',
      'officeName',
    ];

    const updatedRow: RowWithButton = { ...row };

    KEYS_BUTTON_MENU_TO_CHANGE.forEach((key) => {
      if (Object.keys(row).includes(key)) {
        updatedRow[key] = createButtonMenu(key);
      }
    });

    return updatedRow;
  };

  const numeric = (id: string): boolean => {
    const cell = headCells.find((item) => item.id === id);
    return cell?.numeric || false;
  };

  const updateMonthValue = (row: Record<string, any>): Record<string, any> => {
    if ('month' in row) {
      const currentMonth = row.month;

      const monthMappings: Record<number, string> = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };

      if (typeof currentMonth === 'number' && currentMonth in monthMappings) {
        return { ...row, month: monthMappings[currentMonth] };
      }
    }

    return row;
  };

  const modifiedRow = addButtonMenu(
    addHrefToKeys(addPercentSignToKeys(updateMonthValue(row)) as any)
  );

  return (
    <>
      {keys.map((key, index) => {
        const headCellsFromStorage = sessionStorage.getItem('HeadCells');
        //@ts-ignore
        const { data } = JSON.parse(headCellsFromStorage);

        const HeadCells = data || headCells;

        const show = findCellProperty(key, 'visible', HeadCells);
        const year = key.match(/\d+/);

        let propertyType = '';
        const regex = new RegExp(propertyTypes.join('|'));
        const match = key.match(regex);

        if (match) {
          propertyType = match[0];
        }

        let cellContent = null;

        if (show) {
          if (numeric(key) && year && propertyType) {
            //@ts-ignore
            const propertyValue = modifiedRow[propertyType]?.[year];

            if (propertyValue !== undefined) {
              cellContent = propertyValue;
            }
          } else {
            cellContent = modifiedRow[key];
          }
        }

        return show ? (
          <TableCell
            key={index}
            align={numeric(key) ? 'center' : 'left'}
            className={`${orderBy === key ? 'sorted-column ' : ''}${
              findAndCheckFreeze(reportFields, key) ? 'freeze' : ''
            }`}
          >
            {numeric(key) && Number(cellContent)
              ? cellContent?.toLocaleString(moment.locale())
              : cellContent}
          </TableCell>
        ) : null;
      })}
    </>
  );
};

const TotalInfo = (props: { secondary?: boolean }) => {
  const { t } = useTranslation();
  const { reportType, reportUnit, reportName, startDate, data } =
    useSelector(getKeySettings);
  const report = useSelector(getReport);
  const reportFields = useSelector(getConfig);

  if (reportName === EReportName.AGENTS_REGISTERED) {
    if (reportType === EReportTypes.COMBINED) {
      return (
        <>{t(`report_total.agent-performance-registered-agents_combined`)}</>
      );
    } else {
      return (
        <>
          {t(`report_total.agent-performance-registered-agents_not_combined`)}
        </>
      );
    }
  } else if (
    reportName === EReportName.AGENT_TRANSACTIONS ||
    reportName === EReportName.OFFICE_TRANSACTIONS ||
    reportName === EReportName.FIRM_TRANSACTIONS ||
    reportName === EReportName.BRAND_TRANSACTIONS
  ) {
    const countTotal =
      typeof report.totalItems === 'object' &&
      report.totalItems.totalItems?.toLocaleString(moment.locale());
    const countUnitTotal =
      typeof report.totalItems === 'object' &&
      report.totalItems.totalUnitItems?.toLocaleString(moment.locale());
    const countSelling =
      typeof report.totalItems === 'object' &&
      report.totalItems.sellingEndItems?.toLocaleString(moment.locale());
    const countListing =
      typeof report.totalItems === 'object' &&
      report.totalItems.listingEndItems?.toLocaleString(moment.locale());
    const countDoubleEnd =
      typeof report.totalItems === 'object' &&
      report.totalItems.doubleEndItems?.toLocaleString(moment.locale());

    if (reportType === EReportTypes.COMBINED) {
      return (
        <>
          <p>
            <span className="label">
              {t(`report_total.total_transactions`)}
            </span>{' '}
            {countTotal}
          </p>
          <p>
            <span className="label">
              {t(`report_total.total_transactions_${reportUnit}`)}
            </span>{' '}
            {countUnitTotal}
          </p>
          <p>
            <span className="label">
              {t(`report_total.listing_end_transactions`)}
            </span>{' '}
            {countListing}
          </p>
          <p>
            <span className="label">
              {t(`report_total.selling_end_transactions`)}
            </span>{' '}
            {countSelling}
          </p>
          <p>
            <span className="label">
              {t(`report_total.double_end_transactions`)}
            </span>{' '}
            {countDoubleEnd}
          </p>
        </>
      );
    } else {
      return (
        <p>
          <span className="label">{t(`report_total.total_transactions`)}</span>{' '}
          {countTotal}
        </p>
      );
    }
  } else if (
    reportName === EReportName.AGENTS_NEW_REGISTERED ||
    reportName === EReportName.AGENT_ROSTER_MIGRATION ||
    reportName === EReportName.OFFICES_COMPARATIVE_PERFORMANCE ||
    reportName === EReportName.FIRMS_COMPARATIVE_PERFORMANCE ||
    reportName === EReportName.BRANDS_COMPARATIVE_PERFORMANCE ||
    reportName === EReportName.AGENTS_STATUS ||
    reportName === EReportName.AGENT_HISTORY ||
    reportName === EReportName.OFFICES_REGISTERED_AGENT_COUNT ||
    reportName === EReportName.FIRMS_REGISTERED_AGENT_COUNT ||
    reportName === EReportName.BRANDS_REGISTERED_AGENT_COUNT ||
    reportName === EReportName.OFFICE_MIGRATION
  ) {
    return <>{t(`report_total.${reportName}`)}</>;
  } else if (
    reportName === EReportName.AGENT_DISTRIBUTION ||
    reportName === EReportName.OFFICE_DISTRIBUTION ||
    reportName === EReportName.FIRM_DISTRIBUTION ||
    reportName === EReportName.BRAND_DISTRIBUTION
  ) {
    return <>{t(`report_total.${reportName}`)}</>;
  } else if (reportName === EReportName.AGENTS_YEARBOOK) {
    return (
      <>
        {t(`report_total.${reportName}`, {
          year: moment.utc(startDate).year(),
        })}
      </>
    );
  } else if (
    [
      EReportName.AGENTS_DISTRIBUTION,
      EReportName.OFFICES_DISTRIBUTION,
      EReportName.FIRMS_DISTRIBUTION,
      EReportName.BRANDS_DISTRIBUTION,
    ].includes(reportName as EReportName)
  ) {
    const capitalize = (str: string) => {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    };
    const label = reportFields.length
      ? reportFields[0].label === 'Year'
        ? capitalize(data)
        : reportFields[0].label
      : '';
    const unitLabel =
      props.secondary &&
      label !== 'Price Range' &&
      label !== 'Sold/Asked Ratio Range'
        ? t(`report_unit.${reportUnit}`).slice(0, -1)
        : '';
    if (unitLabel !== '') {
      return <>{`${unitLabel} Total`}</>;
    }
    return <>{`Total ${label}`}</>;
  } else if (
    [
      EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
      EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
    ].includes(reportName as EReportName)
  ) {
    const capitalize = (str: string) => {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    };
    const label = reportFields.length
      ? reportFields[0].label === 'Year'
        ? capitalize(data)
        : reportFields[0].label
      : '';
    return <>{`Total ${label}`}</>;
  } else {
    return (
      <>
        {t(`report_total.${reportType}`, {
          unit: t(`report_unit.${reportUnit}`),
        })}
      </>
    );
  }
};

const TotalsRow = (props: { headCells: Array<HeadCell> }) => {
  const { headCells } = props;
  const { t } = useTranslation();
  const { orderBy, reportName } = useSelector(getKeySettings);
  const reportFields = useSelector(getConfig);
  const report = useSelector(getReport);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleOpen = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen2 = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const keys = headCells
    .map((item: HeadCell) => item.id as keyof TTotals)
    .slice(1);

  return (
    <TableRow
      style={{
        verticalAlign: [
          EReportName.AGENTS_DISTRIBUTION,
          EReportName.OFFICES_DISTRIBUTION,
          EReportName.FIRMS_DISTRIBUTION,
          EReportName.BRANDS_DISTRIBUTION,
        ].includes(reportName as EReportName)
          ? 'top'
          : 'center',
      }}
      className="totals-row"
    >
      {![
        EReportName.AGENTS_DISTRIBUTION,
        EReportName.OFFICES_DISTRIBUTION,
        EReportName.FIRMS_DISTRIBUTION,
        EReportName.BRANDS_DISTRIBUTION,
        EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
        EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
        EReportName.AGENT_DISTRIBUTION,
        EReportName.OFFICE_DISTRIBUTION,
        EReportName.FIRM_DISTRIBUTION,
        EReportName.BRAND_DISTRIBUTION,
        EReportName.AGENT_TRANSACTIONS,
        EReportName.OFFICE_TRANSACTIONS,
        EReportName.FIRM_TRANSACTIONS,
        EReportName.BRAND_TRANSACTIONS,
        EReportName.AGENT_HISTORY,
      ].includes(reportName as EReportName) && <TableCell />}
      <TableCell
        className={`${
          findAndCheckFreeze(reportFields, headCells[0]?.id) ? 'freeze' : ''
        }`}
      >
        <div
          className="ml-auto d-flex"
          style={{
            alignItems: 'center',
            justifyContent: headCells[0]?.numeric ? 'center' : 'left',
          }}
        >
          <p className="m-0">
            {report.totalItems && (
              <b style={{ marginLeft: 10 }}>
                {typeof report.totalItems === 'object'
                  ? report.totalItems.totalItems?.toLocaleString(
                      moment.locale()
                    )
                  : report.totalItems?.toLocaleString(moment.locale())}
              </b>
            )}
          </p>
          <IconButton
            className="info-button"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            aria-label={t('buttons.info')}
            aria-describedby="mouse-over-popover"
            onMouseEnter={handleOpen}
            onTouchStart={handleOpen}
            onMouseLeave={handleClose}
            onTouchEnd={handleClose}
            disableFocusRipple
            disableRipple
          >
            <SpriteIcon icon="info" className="info" />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className="info-popper"
          >
            <div className="info-content">
              <TotalInfo secondary />
            </div>
          </Popover>
        </div>
        {[
          EReportName.AGENTS_DISTRIBUTION,
          EReportName.OFFICES_DISTRIBUTION,
          EReportName.FIRMS_DISTRIBUTION,
          EReportName.BRANDS_DISTRIBUTION,
        ].includes(reportName as EReportName) &&
        hasValueGreaterThanZero(report.secondTotals) ? (
          <div className="ml-auto d-flex" style={{ alignItems: 'center' }}>
            <IconButton
              className="info-button"
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              aria-label={t('buttons.info')}
              aria-describedby="mouse-over-popover"
              onMouseEnter={handleOpen2}
              onMouseLeave={handleClose2}
              disableFocusRipple
              disableRipple
            >
              <SpriteIcon icon="info" className="info" />
            </IconButton>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              className="info-popper"
            >
              <div className="info-content">
                <TotalInfo />
              </div>
            </Popover>
          </div>
        ) : null}
      </TableCell>
      {keys.map((key, index) => {
        const headCellsFromStorage = sessionStorage.getItem('HeadCells');
        //@ts-ignore
        const { data } = JSON.parse(headCellsFromStorage);

        const HeadCells = data || headCells;

        const show = findCellProperty(key, 'visible', HeadCells);
        const year = key.match(/\d+/);

        let propertyType = '';
        const regex = new RegExp(propertyTypes.join('|'));
        const match = key.match(regex);

        if (match) {
          propertyType = match[0];
        }

        const modifiedValue = addPercentSignToKeys(report.totals);

        let cellContent = null;

        if (show) {
          if (
            propertyType &&
            year &&
            //@ts-ignore
            report.totals[propertyType] &&
            //@ts-ignore
            report.totals[propertyType][year]
          ) {
            //@ts-ignore
            cellContent = modifiedValue[propertyType]?.[year];
          } else {
            cellContent = modifiedValue[key];
          }
        }

        const secondModifiedValue = addPercentSignToKeys(report.secondTotals);

        let secondaryCellContent = null;

        if (show) {
          if (
            propertyType &&
            year &&
            //@ts-ignore
            report.secondTotals[propertyType] &&
            //@ts-ignore
            report.secondTotals[propertyType][year]
          ) {
            //@ts-ignore
            secondaryCellContent = secondModifiedValue[propertyType]?.[year];
          } else {
            secondaryCellContent = secondModifiedValue[key];
          }
        }

        return show ? (
          <TableCell
            key={index}
            align="center"
            className={`${orderBy === key ? 'sorted-column ' : ''}${
              findAndCheckFreeze(reportFields, key) ? 'freeze' : ''
            }`}
          >
            <b>
              {cellContent ? cellContent.toLocaleString(moment.locale()) : ''}
              {secondaryCellContent ? (
                <>
                  <br />
                  {secondaryCellContent.toLocaleString(moment.locale())}
                </>
              ) : (
                ''
              )}
            </b>
          </TableCell>
        ) : null;
      })}
    </TableRow>
  );
};

const MenuModal = (props: { name: string; menuItem: Array<any> }) => {
  const { name, menuItem } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getHref = (params: Record<string, string>): string => {
    const urlParams = new URLSearchParams(
      filterQueryParams(window.location.search)
    );

    for (const [key, value] of Object.entries(params)) {
      if (value === '') {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value);
      }
    }

    const queryString = urlParams.toString();
    const url = `${window.location.origin}${window.location.pathname}`;

    return queryString ? `${url}?${queryString}` : url;
  };

  return (
    <>
      <Button
        className="menu-btn"
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {name} {menuItem.length ? <SpriteIcon icon="arrow_down" /> : null}
      </Button>
      {menuItem.length ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          className="w-100"
          onClick={handleClose}
          onTouchStart={handleClose}
        >
          {menuItem.map((item, index) => (
            <a
              key={index}
              href={getHref(item.params)}
              role="button"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => item.func(e)}
              onTouchStart={(e) => item.func(e)}
              style={{
                color: '#000',
                textDecoration: 'none',
              }}
            >
              <MenuItem className="report-menu-item">{item.name}</MenuItem>
            </a>
          ))}
        </Menu>
      ) : null}
    </>
  );
};

interface DrawerStates {
  [key: number]: boolean;
}

const ReportTable = (props: IReportTableProps) => {
  const { headCells, rows } = props;
  // eslint-disable-next-line
  const {
    mls,
    orderBy,
    orderDirection,
    reportName,
    reportType,
    timeFrame,
    agentIds,
    officeIds,
    firmIds,
    brandIds,
    savedSearch,
    savedSearchList,
    startDate,
    endDate,
    reportUnit,
    chartType,
    perPage,
    ...fields
  } = useSelector(getKeySettings);
  const { lastDate } = useSelector(getFilterLists);
  const [order, setOrder] = useState<EOrder>(orderDirection);
  const [orderByValue, setOrderBy] = useState<string>(orderBy);
  const [secondaryOrderByValue, setSecondaryOrderBy] =
    useState<string>(orderBy);
  const [selected, setSelected] = useState<TReportItem[]>([]);
  const report = useSelector(getReport);
  const reportFields = useSelector(getConfig);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { availableReports } = useSelector(getReport);
  const [modifiedStartDate, setModifiedStartDate] = React.useState<
    string | null
  >('');
  const [modifiedEndDate, setModifiedEndDate] = React.useState<string | null>(
    ''
  );
  const dispatch = useDispatch<AppDispatch>();
  const [drawerStates, setDrawerStates] = useState<DrawerStates>({});
  const location = useLocation();

  useEffect(() => {
    setSelected([]);
  }, [perPage]);

  const toggleDrawer = (id: number | string) => (open: boolean) => {
    setDrawerStates((prevState) => ({
      ...prevState,
      [id]: open,
    }));
  };

  const handleButtonClick = (id: number | string) => {
    toggleDrawer(id)(true);
  };

  const findChangeReportDatesByKey = (
    keyToFind: string,
    reportsData: IAvailableReports
  ): boolean | undefined => {
    for (const category in reportsData) {
      if (Object.prototype.hasOwnProperty.call(reportsData, category)) {
        const reports = reportsData[category];
        if (reports) {
          const report = reports.find((report) => report.key === keyToFind);
          if (report) {
            return report.changeReportDates;
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    const changeReportDates = findChangeReportDatesByKey(
      reportName,
      availableReports
    );

    const query = objToQueryString({
      startDate,
      endDate,
      ...fields,
    });

    if (changeReportDates) {
      apiClient
        .getDate(mls, reportName, query)
        .then((data: any) => {
          setModifiedStartDate(moment.utc(data.startDate).format('YYYY-MM-DD'));
          setModifiedEndDate(moment.utc(data.endDate).format('YYYY-MM-DD'));
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  }, [startDate, endDate, availableReports, reportName]);

  useEffect(() => {
    setSelected([]);
    setModifiedStartDate(null);
    setModifiedEndDate(null);
  }, [reportName]);

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: '#fff',
    boxShadow: 24,
    padding: '32px 42px',
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown> | React.TouchEvent<unknown>,
    property: keyof ReportTableData
  ) => {
    setOrderBy('');
    setSecondaryOrderBy('');
    const replacedProperty = property.replace(/\d{4}$/, '');
    const numericPart = property.match(/\d+/);
    const numericValue = numericPart ? numericPart[0] : '';

    const includedSortableColumnsForReport =
      //@ts-ignore
      includedSortableColumns[reportName] || [];

    const excludedSortableColumnsForReport =
      //@ts-ignore
      excludedSortableColumns[reportName] || [];

    const filteredSortableColumns = (() => {
      const includedColumns = sortable.filter((column) =>
        includedSortableColumnsForReport.includes(column.replace(/\d{4}$/, ''))
      );

      if (includedColumns.length === 0) {
        return sortable.filter(
          (column) =>
            !excludedSortableColumnsForReport.includes(
              column.replace(/\d{4}$/, '')
            )
        );
      } else if (includedColumns.length !== 0) {
        return includedColumns;
      } else {
        return sortable;
      }
    })();

    if (filteredSortableColumns.includes(replacedProperty)) {
      const isAsc = orderByValue === replacedProperty && order === EOrder.ASC;

      setTimeout(() => {
        orderByValue === replacedProperty || replacedProperty
          ? setOrder(isAsc ? EOrder.DESC : EOrder.ASC)
          : null;
        setSecondaryOrderBy(numericValue);
        setOrderBy(replacedProperty);
      }, 500);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.filter(
        (row) =>
          !selected.some((item) => {
            if (includedReports.includes(reportName as EReportName)) {
              return item.mls === row.mls;
            } else {
              return item.id === row.id;
            }
          })
      );
      setSelected([...selected, ...newSelected]);
    } else {
      const removedSelected = selected.filter(
        (item) =>
          !rows.some((row) => {
            if (includedReports.includes(reportName as EReportName)) {
              return item.mls === row.mls;
            } else {
              return item.id === row.id;
            }
          })
      );
      setSelected(removedSelected);
    }
  };

  const handleClick = (
    event: React.MouseEvent<unknown> | React.TouchEvent<unknown>,
    row: TReportItem
  ) => {
    const isSelected = selected.some((item) => {
      if (includedReports.includes(reportName as EReportName)) {
        return item.mls === row.mls;
      } else {
        return item.id === row.id;
      }
    });

    let newSelected: TReportItem[] = [];

    if (!isSelected) {
      newSelected = [...selected, row];
    } else {
      newSelected = selected.filter((item) => {
        if (includedReports.includes(reportName as EReportName)) {
          return item.mls !== row.mls;
        } else {
          return item.id !== row.id;
        }
      });
    }

    setSelected(newSelected);
  };

  const isSelected = (row: TReportItem) =>
    selected.some((item) => {
      if (includedReports.includes(reportName as EReportName)) {
        return item.mls === row.mls;
      } else {
        return item.id === row.id;
      }
    });
  const firstOrderByValue = useRef(true);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'orderDirection',
        value: order,
      })
    );
  }, [order]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'orderBy',
        value: orderByValue,
      })
    );
    dispatch(
      setKeySettings({
        key: 'secondaryOrderBy',
        value: secondaryOrderByValue,
      })
    );

    if (firstOrderByValue.current) {
      firstOrderByValue.current = false;
    } else {
      orderByValue !== '' && dispatch(loadReport(false));
      orderByValue !== '' && dispatch(getSavedSearchList());
    }
  }, [orderByValue]);

  useEffect(() => {
    setOrderBy('');
    setSecondaryOrderBy('');
  }, [reportType]);

  useEffect(() => {
    if (report.items.length) {
      setTimeout(() => {
        adjustColumnPositions();
      }, 1000);
    }
  }, [report.items]);

  useEffect(() => {
    adjustColumnPositions();
  }, [reportFields, report.loading]);

  useEffect(() => {
    window.addEventListener('resize', adjustColumnPositions, false);
  }, []);

  const commonSettings = () => {
    const startDate = moment
      .utc(lastDate)
      .subtract(1, 'years')
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    dispatch(
      resetKeySettings({
        mls,
        timeFrame: ETimeFrames.YEAR,
        startDate,
        endDate: lastDate,
        reportUnit,
        orderDirection,
        chartType,
        perPage,
      })
    );
    dispatch(setKeySettings({ key: 'orderDirection', value: null }));
  };

  const agentMenuItemFunc = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleClose();
    commonSettings();
    dispatch(
      setKeySettings({ key: 'reportName', value: EReportName.PERFORMANCE })
    );
    dispatch(setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS }));
    dispatch(
      setKeySettings({
        key: 'agentIds',
        value: selected.map((item) => item.id).join(','),
      })
    );
    dispatch(loadReport());
    dispatch(initConfig());
    dispatch(getSavedSearchList());
  };

  const agentsTransactionsMenuItemFunc = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleClose();
    commonSettings();
    dispatch(
      setKeySettings({
        key: 'reportName',
        value: EReportName.AGENT_TRANSACTIONS,
      })
    );
    dispatch(setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS }));
    dispatch(
      setKeySettings({
        key: 'agentIds',
        value: selected.map((item) => item.id).join(','),
      })
    );
    dispatch(loadReport());
    dispatch(initConfig());
    dispatch(getSavedSearchList());
  };

  const officeMenuItemFunc = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleClose();
    commonSettings();
    dispatch(
      setKeySettings({ key: 'reportName', value: EReportName.PERFORMANCE })
    );
    dispatch(setKeySettings({ key: 'reportUnit', value: EReportUnit.OFFICES }));
    dispatch(
      setKeySettings({
        key: 'officeIds',
        value: selected
          .map((item) =>
            reportName === EReportName.OFFICES ||
            reportName === EReportName.OFFICES_REGISTERED
              ? item.id
              : item.officeId
          )
          .join(','),
      })
    );
    dispatch(loadReport());
    dispatch(initConfig());
    dispatch(getSavedSearchList());
  };

  const createMenuItem = (
    name: string,
    params: Record<string, any>,
    func: (e: React.MouseEvent<HTMLElement>) => void
  ) => ({
    name,
    params,
    func,
  });

  const agentMenuItem = createMenuItem(
    'Agents Performance Graphs',
    {
      agentIds: selected.map((item) => item.id).join(','),
      reportName: EReportName.PERFORMANCE,
      reportUnit: EReportUnit.AGENTS,
    },
    agentMenuItemFunc
  );

  const agentsTransactionsMenuItem = createMenuItem(
    'Agents Transactions',
    {
      agentIds: selected.map((item) => item.id).join(','),
      reportName: EReportName.AGENT_TRANSACTIONS,
      reportUnit: '',
    },
    agentsTransactionsMenuItemFunc
  );

  const officeMenuItem = createMenuItem(
    'Offices Performance Graphs',
    {
      officeIds: selected
        .map((item) =>
          reportName === EReportName.OFFICES ||
          reportName === EReportName.OFFICES_REGISTERED
            ? item.id
            : item.officeId
        )
        .join(','),
      reportName: EReportName.PERFORMANCE,
      reportUnit: EReportUnit.OFFICES,
    },
    officeMenuItemFunc
  );

  const includedReportsForProfiling = [
    EReportName.AGENTS,
    EReportName.AGENTS_REGISTERED,
    EReportName.AGENTS_NEW_REGISTERED,
    EReportName.AGENTS_COMPARATIVE,
    EReportName.AGENTS_NEW_ACTIVE,
    EReportName.AGENT_TRANSACTIONS,
    EReportName.AGENT_ROSTER_MIGRATION,
    EReportName.AGENTS_YEARBOOK,
    EReportName.AGENTS_STATUS,
    EReportName.OFFICES,
    EReportName.OFFICES_REGISTERED,
    EReportName.OFFICES_REGISTERED_AGENT_COUNT,
    EReportName.OFFICES_COMPARATIVE_PERFORMANCE,
    EReportName.OFFICE_MIGRATION,
  ];

  const excludedReportsForProfilingForAgent = [
    EReportName.OFFICES,
    EReportName.OFFICES_REGISTERED,
    EReportName.OFFICES_REGISTERED_AGENT_COUNT,
    EReportName.OFFICES_COMPARATIVE_PERFORMANCE,
    EReportName.OFFICE_MIGRATION,
  ];

  const CustomDrawer = styled(Drawer)({
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  });

  const arrayOfSelect: any[] = [
    { id: 'Contact', name: 'Contact' },
    { id: 'Meeting', name: 'Meeting' },
    { id: 'Follow Up', name: 'Follow Up' },
    { id: 'Commitment', name: 'Commitment' },
  ];

  return (
    <>
      {reportName !== EReportName.MARKET_COMPARATIVE &&
      reportName !== EReportName.MARKET &&
      reportName !== EReportName.PERFORMANCE &&
      reportName !== EReportName.OFFICES_TOP_TEN &&
      reportName !== EReportName.FIRMS_TOP_TEN &&
      reportName !== EReportName.BRANDS_TOP_TEN ? (
        <TableContainer sx={{ height: '100%' }}>
          <Table
            stickyHeader
            className={reportName}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              selected={selected}
              numSelected={selected.length}
              headCells={headCells}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {report.items.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    {![
                      EReportName.AGENTS_DISTRIBUTION,
                      EReportName.OFFICES_DISTRIBUTION,
                      EReportName.FIRMS_DISTRIBUTION,
                      EReportName.BRANDS_DISTRIBUTION,
                      EReportName.MARKET_PERFORMANCE_DISTRIBUTION,
                      EReportName.MARKET_COMPARATIVE_DISTRIBUTION,
                      EReportName.AGENT_DISTRIBUTION,
                      EReportName.OFFICE_DISTRIBUTION,
                      EReportName.FIRM_DISTRIBUTION,
                      EReportName.BRAND_DISTRIBUTION,
                      EReportName.AGENT_TRANSACTIONS,
                      EReportName.OFFICE_TRANSACTIONS,
                      EReportName.FIRM_TRANSACTIONS,
                      EReportName.BRAND_TRANSACTIONS,
                      EReportName.AGENT_HISTORY,
                    ].includes(reportName as EReportName) && (
                      <TableCell
                        sx={{ width: '60px', padding: '5px 15px' }}
                        // @ts-ignore
                        onClick={(event) => handleClick(event, row)}
                        onTouchStart={(event) => handleClick(event, row)}
                      >
                        <StyledCheckbox
                          color={
                            selected?.length > maxQtyOfRecordSelection
                              ? 'error'
                              : 'primary'
                          }
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          size="small"
                          sx={{
                            padding: '5px',
                          }}
                        />
                      </TableCell>
                    )}
                    <CustomTableCell
                      row={row}
                      headCells={headCells}
                      orderBy={orderBy}
                      modifiedStartDate={modifiedStartDate}
                      modifiedEndDate={modifiedEndDate}
                      handleOpenModal={handleOpenModal}
                    />

                    {location.pathname === '/hotsheets' && (
                      <>
                        <TableCell>
                          <div
                            style={{
                              display: 'inline-block',
                              background: `#D2EED8`,
                              padding: `5px`,
                              borderRadius: `5px`,
                              color: `#000`,
                            }}
                          >
                            Yes
                          </div>
                        </TableCell>
                        <TableCell>
                          <CustomSelect
                            label={''}
                            placeholder={t('select')}
                            value={''}
                            choices={arrayOfSelect}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              style={{ cursor: 'pointer' }}
                              src={NotesNote}
                              alt="NotesNote"
                              onClick={() => handleButtonClick(row.id)}
                              onTouchStart={() => handleButtonClick(row.id)}
                            />
                          </div>
                          <CustomDrawer
                            anchor="right"
                            open={drawerStates[row.id] || false}
                            onClose={() => toggleDrawer(row.id)(false)}
                          >
                            <Notes row={row} toggleDrawer={toggleDrawer} />
                          </CustomDrawer>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
              {report.items.length && reportFields.length ? (
                <>
                  <TotalsRow headCells={headCells} />
                </>
              ) : report.loading ? (
                <ProgressLoader
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                  }}
                  isInsideTable={true}
                />
              ) : (
                <EmptyReport isInsideTable={true} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {report.loading ? (
            <ProgressLoader
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            />
          ) : (
            <EmptyReport />
          )}
        </>
      )}
      {includedReportsForProfiling.includes(reportName as EReportName) &&
        !!selected?.length &&
        selected?.length <= maxQtyOfRecordSelection && (
          <Draggable positionOffset={{ x: '0', y: '0' }} bounds="parent">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '15px',
                width: '400px',
                height: '100px',
                position: 'fixed',
                bottom: '150px',
                left: '25%',
                cursor: 'move',
                zIndex: '11',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
                background: '#fff',
              }}
            >
              <p className="records-selected">
                <Trans
                  i18nKey="records_selected"
                  count={selected?.length}
                  components={{ span: <span /> }}
                ></Trans>
              </p>
              <Button
                className="profiling-btn"
                variant="outlined"
                onClick={handleOpen}
                onTouchStart={handleOpen}
              >
                {t('profile')}
              </Button>
              <IconButton
                aria-label="close"
                onClick={() => setSelected([])}
                onTouchStart={() => setSelected([])}
                sx={{
                  color: '#000',
                  background: '#F2F2F2',
                  width: '38px',
                  height: '38px',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Draggable>
        )}
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        classes={{ root: 'report-graphs-modal' }}
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            onTouchStart={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2
            style={{
              marginTop: 0,
              textAlign: 'center',
              fontSize: '16px',
              marginBottom: '5px',
            }}
          >
            {t('select_profiling_report')}
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!excludedReportsForProfilingForAgent.includes(
              reportName as EReportName
            ) && (
              <MenuModal
                name="Agents"
                menuItem={[agentMenuItem, agentsTransactionsMenuItem]}
              />
            )}
            <MenuModal name="Offices" menuItem={[officeMenuItem]} />
          </div>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        classes={{ root: 'coc-modal saved-search-modal' }}
      >
        <Box
          sx={{
            ...modalStyle,
            p: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h3 style={{ marginTop: 0, textAlign: 'center' }}>
            {t('messages.export_success')}
          </h3>
          <h3
            style={{ marginTop: 0, marginBottom: '25px', textAlign: 'center' }}
          >
            {t('messages.check_downloads_folder')}
          </h3>
          <Button
            style={{ fontWeight: 'bold' }}
            className="filled-btn"
            variant="outlined"
            onClick={handleCloseModal}
            onTouchStart={handleCloseModal}
          >
            {t('ok')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ReportTable;
