import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';

import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import { AppDispatch } from '@/store';
import { EReportName } from '@/store/enums';
import { getKeySettings } from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import { loadReportMapItems } from '@/store/reportSlice';
import { getReport } from '@/store/reportSlice';
import { Box } from '@mui/material';

import { createMarker, createMarkerClusterer, MapData } from './reportMapUtils';

import './ReportMap.scss';

let mapView: GoogleMapReact | null = null;

const ReportMap: React.FC = () => {
  const currMls = useSelector(getCurrentMls);
  const { mapItems, mapLoading } = useSelector(getReport);
  const { reportName, reportType } = useSelector(getKeySettings);
  const [mapReady, setMapReady] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onMapLoaded = () => {
    if (!mapReady) {
      setMapReady(true);
    }
  };

  useEffect(() => {
    dispatch(loadReportMapItems());
  }, [dispatch, reportName]);

  const handleApiLoaded = (
    map: google.maps.Map,
    maps: typeof google.maps,
    mapData: MapData[]
  ) => {
    const processedLongitudes: { [key: number]: boolean } = {};

    const markers = mapData.map((data) =>
      createMarker(map, data, processedLongitudes, reportType, reportName)
    );

    createMarkerClusterer(markers, map);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        margin: '10px',
        backgroundColor: '#fff',
        padding: 0,
      }}
      data-report-height
    >
      <div style={{ height: '100%', width: '100%' }}>
        {mapLoading ? (
          <ProgressLoader style={{ height: 'calc(100% - 95px)' }} />
        ) : (
          <>
            {!mapReady && (
              <ProgressLoader
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: '1',
                  top: '0',
                  left: '0',
                  padding: '0',
                }}
              />
            )}
            <GoogleMapReact
              ref={(ref) => (mapView = ref)}
              bootstrapURLKeys={{
                key: 'AIzaSyCGkrUhMmZFcuL4f-Z15klnZumEIxdmwCY',
                libraries: ['geometry', 'drawing', 'places'],
              }}
              defaultCenter={{ lat: currMls.latitude, lng: currMls.longitude }}
              defaultZoom={10}
              onTilesLoaded={onMapLoaded}
              options={{
                clickableIcons: false,
                controlSize: 30,
                disableDoubleClickZoom: true,
                gestureHandling: 'greedy',
                keyboardShortcuts: false,
                panControl: false,
                scrollwheel: true,
                zoomControl: true,
              }}
              shouldUnregisterMapOnUnmount
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                handleApiLoaded(map, maps, mapItems);
              }}
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default ReportMap;
