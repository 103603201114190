import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ROUTE } from '@/router/consts';
import { authRoutes, publicRoutes } from '@/routes';
import { store } from '@/store';
import { getUser } from '@/store/authSlice';

const AppRouter = () => {
  const user = useSelector(getUser);
  const navigate = useNavigate();

  useEffect(() => {
    store.subscribe(() => {
      if (store.getState().auth.user === null) {
        navigate(ROUTE.MAIN);
      }
    });
  }, []);

  return (
    <Routes>
      {user &&
        authRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      {publicRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default AppRouter;
