import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';

import MarkerImage2 from '@/assets/images/marker4.png';
import MarkerImage3 from '@/assets/images/marker5.png';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import { AppDispatch } from '@/store';
import { getKeySettings } from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import {
  getReport,
  loadMapClusterTransactionItems,
  loadMapCountTransactionItems,
  setMapItems,
} from '@/store/reportSlice';
import { loadMapTransactionItems } from '@/store/reportSlice';
import { IKeySettings, TMapPerformanceTotals } from '@/store/types';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import {
  addBoundsChangeListener,
  createClusterer,
  createMarkers,
  debounce,
  dispatchBounds,
} from './utils';

import './ReportMap.scss';

const style = {
  position: 'relative',
  display: 'flex',
  margin: '10px',
  height: 'calc(100% - 100px)',
  width: 'calc(100% - 20px)',
  bgcolor: '#fff',
  p: 0,
};

let currWindow: any;

const mapQtyMaxCountsTransactionsItems = 7000;

const ReportMapPerformance = () => {
  const currMls = useSelector(getCurrentMls);
  const { mapItems, mapLoading, loading } = useSelector(getReport);
  const [mapReady, setMapReady] = useState(false);
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();
  const [clusterer, setClusterer] = useState<MarkerClusterer | null>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [showMapProgress, setShowMapProgress] = useState<boolean>(true);

  const onMapLoaded = () => {
    if (!mapReady) {
      setMapReady(true);
    }
  };

  useEffect(() => {
    if (!mapInstance) return;

    const newMarkers = createMarkers(
      mapInstance,
      mapItems,
      keySettings,
      currWindow
    );

    setMarkers(newMarkers);

    if (clusterer) {
      clusterer.clearMarkers();
      clusterer.addMarkers(newMarkers);
    }

    return () => {
      if (clusterer) {
        clusterer.clearMarkers();
      }

      markers?.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });
    };
  }, [mapItems, mapInstance]);

  const handleApiLoaded = (
    map: google.maps.Map,
    mapData: Array<TMapPerformanceTotals>,
    keySettings: IKeySettings,
    dispatch: AppDispatch
  ) => {
    const newMarkers = createMarkers(map, mapData, keySettings, currWindow);
    setMarkers(newMarkers);

    const newClusterer = createClusterer(newMarkers, map);
    setClusterer(newClusterer as MarkerClusterer | null);

    const debouncedDispatch = debounce(
      async (boundsArray: number[]): Promise<void> => {
        await dispatchBounds(
          map,
          dispatch,
          boundsArray,
          setShowMapProgress,
          loadMapCountTransactionItems,
          loadMapTransactionItems,
          loadMapClusterTransactionItems,
          setMapItems,
          mapQtyMaxCountsTransactionsItems
        );
      },
      300
    );

    addBoundsChangeListener(map, debouncedDispatch);
  };

  return (
    <Box sx={style} data-report-height>
      <div style={{ height: '100%', width: '100%' }}>
        {showMapProgress && (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: '-4px',
              left: '0',
              zIndex: '1',
              width: '100%',
            }}
          />
        )}
        {mapLoading ? (
          <ProgressLoader style={{ height: 'calc(100% - 95px)' }} />
        ) : (
          <>
            {!mapReady && (
              <ProgressLoader
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: '1',
                  top: '0',
                  left: '0',
                  padding: '0',
                }}
              />
            )}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCGkrUhMmZFcuL4f-Z15klnZumEIxdmwCY',
                libraries: ['geometry', 'drawing', 'places'],
              }}
              defaultCenter={{
                lat: currMls.latitude,
                lng: currMls.longitude,
              }}
              defaultZoom={10}
              onTilesLoaded={onMapLoaded}
              options={{
                clickableIcons: false,
                controlSize: 30,
                disableDoubleClickZoom: true,
                gestureHandling: 'greedy',
                keyboardShortcuts: false,
                panControl: false,
                scrollwheel: true,
                zoomControl: true,
                minZoom: 5,
              }}
              shouldUnregisterMapOnUnmount
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map }) => {
                setMapInstance(map);
                handleApiLoaded(map, mapItems, keySettings, dispatch);
              }}
            />
            <div>
              <strong style={{ display: 'flex', alignItems: 'center' }}>
                Legend:{' '}
                <img
                  src={MarkerImage2}
                  alt="Units Cluster"
                  style={{
                    filter: 'grayscale(100%)',
                    WebkitFilter: 'grayscale(100%)',
                    position: 'relative',
                    top: '4px',
                  }}
                />
                – Units Cluster{' '}
                <span
                  style={{
                    color: 'red',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      position: 'relative',
                      top: '4px',
                    }}
                    src={MarkerImage2}
                    alt="Listing End"
                  />
                  – Listing End
                </span>{' '}
                <span
                  style={{
                    color: 'blue',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      position: 'relative',
                      top: '4px',
                    }}
                    src={MarkerImage3}
                    alt="Selling End"
                  />
                  – Selling End
                </span>
              </strong>
            </div>
          </>
        )}
      </div>
    </Box>
  );
};

export default ReportMapPerformance;
