import React, { useEffect, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getColorForSeries } from '@/components/Account/utils';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import { Debounce, palettecolors } from '@/components/ReportChart/utils';
import i18n from '@/i18n';
import StyledSelect from '@/mui-styled-components/select';
import { AppDispatch } from '@/store';
import { EReportName, EReportTypes } from '@/store/enums';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getReport, loadReport } from '@/store/reportSlice';
import { EGraphTypes } from '@/store/types';
import { InputLabel, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import './ReportChart.scss';

//@ts-ignore
FusionCharts.options.license({
  key: process.env.REACT_APP_FUSION_CHARTS_KEY || '',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

interface IGraphItem {
  firmName: string;
  officeName: string;
  officeAddress: string;
  seriesOne: number | null;
  seriesTwo: number | null;
  seriesThree: number | null;
}

const orderByList = (
  reportType: EReportTypes
): { value: string; label: string }[] => {
  let criteria = [
    { value: 'listingUnit', label: 'field.list_units' },
    { value: 'listingUnitMarketShare', label: 'field.list_units_market_share' },
    { value: 'sellingUnit', label: 'field.sold_units' },
    { value: 'sellingUnitMarketShare', label: 'field.sold_units_market_share' },
    { value: 'totalUnit', label: 'field.total_units' },
    { value: 'totalUnitMarketShare', label: 'field.total_units_market_share' },
    { value: 'listingVolume', label: 'field.list_volume' },
    {
      value: 'listingVolumeMarketShare',
      label: 'field.list_volume_market_share',
    },
    { value: 'sellingVolume', label: 'field.sold_volume' },
    {
      value: 'sellingVolumeMarketShare',
      label: 'field.sold_volume_market_share',
    },
    { value: 'totalVolume', label: 'field.total_volume' },
    {
      value: 'totalVolumeMarketShare',
      label: 'field.total_volume_market_share',
    },
    { value: 'avgPrice', label: 'field.average_price' },
  ];

  if (
    reportType === EReportTypes.LISTING ||
    reportType === EReportTypes.SELLING
  ) {
    criteria = [
      { value: 'totalUnit', label: 'field.units' },
      { value: 'totalUnitMarketShare', label: 'field.units_market_share' },
      { value: 'totalVolume', label: 'field.volume' },
      { value: 'totalVolumeMarketShare', label: 'field.volume_market_share' },
      { value: 'avgPrice', label: 'field.average_price' },
      { value: 'avgRatio', label: 'field.sold_asked_ratio' },
      { value: 'avgDOM', label: 'field.average_dom' },
      {
        value: 'totalUnitAvgPrice',
        label: 'field.units_sorted_by_average_price',
      },
      {
        value: 'totalUnitAvgRatio',
        label: 'field.units_sorted_by_sold_asked_ratio',
      },
      { value: 'totalUnitAvgDOM', label: 'field.units_sorted_by_average_dom' },
      {
        value: 'totalVolumeAvgPrice',
        label: 'field.volume_sorted_by_average_price',
      },
      {
        value: 'totalVolumeAvgRatio',
        label: 'field.volume_sorted_by_sold_asked_ratio',
      },
      {
        value: 'totalVolumeAvgDOM',
        label: 'field.volume_sorted_by_average_dom',
      },
    ];
  }

  return criteria;
};

const getGraphType = (graphType: EGraphTypes) => {
  if (graphType === EGraphTypes.STACKED) {
    return 'stackedbar2d';
  } else if (graphType === EGraphTypes.GROUPED) {
    return 'msbar2d';
  } else if (graphType === EGraphTypes.SIMPLE) {
    return 'stackedbar2d';
  }
};

const seriesMappings = {
  stacked: {
    listingUnit: {
      seriesOne: 'field.list_units',
      seriesTwo: 'field.sold_units',
    },
    sellingUnit: {
      seriesOne: 'field.list_units',
      seriesTwo: 'field.sold_units',
    },
    totalUnit: {
      seriesOne: 'field.list_units',
      seriesTwo: 'field.sold_units',
    },
    listingVolume: {
      seriesOne: 'field.list_volume',
      seriesTwo: 'field.sold_volume',
    },
    sellingVolume: {
      seriesOne: 'field.list_volume',
      seriesTwo: 'field.sold_volume',
    },
    totalVolume: {
      seriesOne: 'field.list_volume',
      seriesTwo: 'field.sold_volume',
    },
  },
  grouped: {
    listingUnitMarketShare: {
      seriesOne: 'field.list_units_market_share',
      seriesTwo: 'field.sold_units_market_share',
      seriesThree: 'field.total_units_market_share',
    },
    sellingUnitMarketShare: {
      seriesOne: 'field.list_units_market_share',
      seriesTwo: 'field.sold_units_market_share',
      seriesThree: 'field.total_units_market_share',
    },
    totalUnitMarketShare: {
      seriesOne: 'field.list_units_market_share',
      seriesTwo: 'field.sold_units_market_share',
      seriesThree: 'field.total_units_market_share',
    },
    listingVolumeMarketShare: {
      seriesOne: 'field.list_volume_market_share',
      seriesTwo: 'field.sold_volume_market_share',
      seriesThree: 'field.total_volume_market_share',
    },
    sellingVolumeMarketShare: {
      seriesOne: 'field.list_volume_market_share',
      seriesTwo: 'field.sold_volume_market_share',
      seriesThree: 'field.total_volume_market_share',
    },
    totalVolumeMarketShare: {
      seriesOne: 'field.list_volume_market_share',
      seriesTwo: 'field.sold_volume_market_share',
      seriesThree: 'field.total_volume_market_share',
    },
  },
  simple: {
    totalUnit: {
      seriesOne: 'field.units',
    },
    totalUnitMarketShare: {
      seriesOne: 'field.units_market_share',
    },
    totalVolume: {
      seriesOne: 'field.volume',
    },
    totalVolumeMarketShare: {
      seriesOne: 'field.volume_market_share',
    },
    avgPrice: {
      seriesOne: 'field.average_price',
    },
    avgRatio: {
      seriesOne: 'field.sold_asked_ratio',
    },
    avgDOM: {
      seriesOne: 'field.average_dom',
    },
    totalUnitAvgPrice: {
      seriesOne: 'field.units_sorted_by_average_price',
    },
    totalUnitAvgRatio: {
      seriesOne: 'field.units_sorted_by_sold_asked_ratio',
    },
    totalUnitAvgDOM: {
      seriesOne: 'field.units_sorted_by_average_dom',
    },
    totalVolumeAvgPrice: {
      seriesOne: 'field.volume_sorted_by_average_price',
    },
    totalVolumeAvgRatio: {
      seriesOne: 'field.volume_sorted_by_sold_asked_ratio',
    },
    totalVolumeAvgDOM: {
      seriesOne: 'field.volume_sorted_by_average_dom',
    },
  },
};

const generateDataset = (
  items: any,
  graphType: EGraphTypes,
  orderBy: string,
  seriesMappings: any
) => {
  const dataset = [];

  if (graphType && orderBy && items && Array.isArray(items)) {
    //@ts-ignore
    const seriesMapping = seriesMappings[graphType][orderBy];

    for (const series in seriesMapping) {
      const seriesTranslation = i18n.t(seriesMapping[series]);

      if (items.some((item) => item[seriesTranslation] !== null)) {
        dataset.push({
          seriesname: seriesTranslation,
          data: items
            .filter((item) => item[seriesTranslation] !== null)
            .map((item) => ({
              value: item[series],
              toolText: '$label {br} $dataValue - $seriesName',
              color: getColorForSeries(series),
              anchorBorderColor: getColorForSeries(series),
              anchorBgColor: getColorForSeries(series),
              legendBgColor: getColorForSeries(series),
              legendBorderColor: getColorForSeries(series),
            })),
        });
      }
    }
  }
  return dataset;
};

const ReportChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { reportName, reportType } = useSelector(getKeySettings);
  //@ts-ignore
  const { items, graphType, orderBy, loading } = useSelector(getReport);
  const [height, setHeight] = useState<number>(0);
  const [baseFontSize, setBaseFontSize] = useState<number>(12);

  const handleChangeOrderBy = (e: any) => {
    dispatch(
      setKeySettings({
        key: 'orderBy',
        value: e.target.value,
      })
    );

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const handleBaseFontSize = () => {
    let newBaseFontSize = baseFontSize;

    window.screen.width <= 1366
      ? (newBaseFontSize = 7)
      : window.screen.width <= 1440
      ? (newBaseFontSize = 9)
      : (newBaseFontSize = 12);

    setBaseFontSize(newBaseFontSize);
  };

  const handleChartResize = () => {
    const reportHeightWrapper = document.querySelector('[data-report-height]');
    const reportHeaderPerformanceWrapper = document.querySelector(
      '[data-report-header]'
    );
    const reportChartPerformanceWrapper = document.querySelector(
      '[data-report-chart]'
    );

    let newHeight = height;

    if (
      reportHeightWrapper &&
      reportHeaderPerformanceWrapper &&
      reportChartPerformanceWrapper
    ) {
      newHeight =
        reportHeightWrapper.clientHeight -
        reportHeaderPerformanceWrapper.clientHeight -
        reportChartPerformanceWrapper.clientHeight -
        5;
    } else if (window.innerWidth <= 1679) {
      newHeight = 320;
    } else {
      newHeight = 580;
    }

    setHeight(newHeight);
  };

  const handleWindowResize = Debounce(() => {
    handleChartResize();
    handleBaseFontSize();
  }, 1000);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const chartConfigs = {
    type: getGraphType(graphType),
    width: '100%',
    height: height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        palettecolors: palettecolors(),
        baseFontSize: baseFontSize,
        theme: 'fusion',
        exportEnabled: '1',
        exportFormats: 'PNG|JPG|PDF|SVG',
        exportFileName: reportName + '-graph',
        formatNumberScale: '0',
        decimalSeparator: '.',
        thousandSeparator: ',',
        showSum: '1',
        showValues: graphType === EGraphTypes.GROUPED ? '1' : '0',
        numberSuffix:
          orderBy === 'listingUnitMarketShare' ||
          orderBy === 'sellingUnitMarketShare' ||
          orderBy === 'totalUnitMarketShare' ||
          orderBy === 'listingVolumeMarketShare' ||
          orderBy === 'sellingVolumeMarketShare' ||
          orderBy === 'totalVolumeMarketShare' ||
          orderBy === 'avgRatio' ||
          orderBy === 'totalUnitAvgRatio' ||
          orderBy === 'totalVolumeAvgRatio'
            ? '%'
            : '',
        setAdaptiveYMin: '1',
      },
      categories: [
        {
          category: items.map((i) => {
            return {
              label:
                reportName === EReportName.OFFICES_TOP_TEN
                  ? `${i.officeName} - ${i.officeAddress}`
                  : reportName === EReportName.FIRMS_TOP_TEN
                  ? `${i.firmName}`
                  : reportName === EReportName.BRANDS_TOP_TEN
                  ? `${i.brandName}`
                  : '',
            };
          }),
        },
      ],
      dataset: generateDataset(items, graphType, orderBy, seriesMappings),
    },
  };

  const orderByLabel = orderByList(reportType).find(
    (item) => item.value === orderBy
  )?.label;

  return (
    <>
      <div
        className="report-chart-header report-chart-top-ten"
        data-report-chart
      >
        <div>
          {t(`reports.${reportName}`)} {t('chart.by')} {t(`${orderByLabel}`)} -{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {reportType === EReportTypes.COMBINED
              ? t('reports.listing_and_selling_combined')
              : t('reports.' + reportType)}
          </span>
        </div>
        {orderBy && (
          <FormControl
            sx={{ border: 'none', minWidth: '200px', margin: '15px' }}
          >
            <InputLabel
              id="order-simple-select-label"
              sx={{
                '&.Mui-focused': {
                  color: `var(--blue-color)`,
                },
              }}
            >
              {t('chart.order_by')}
            </InputLabel>
            <StyledSelect
              labelId="order-simple-select-label"
              value={orderBy}
              onChange={handleChangeOrderBy}
              label={t('chart.order_by')}
            >
              {orderByList(reportType).map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <Trans i18nKey={item.label}></Trans>
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        )}
      </div>
      <div className="report-chart">
        {loading && (
          <ProgressLoader
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              padding: '0',
            }}
          />
        )}
        {height && <ReactFC {...chartConfigs} />}
      </div>
    </>
  );
};

export default ReportChart;
