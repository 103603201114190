import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Grid, Paper } from '@mui/material';

import payPalLogo from '../../assets/images/pay-pal.jpg';
import paymentLogo from '../../assets/images/payment-logo.jpg';

const PaymentInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="payment-wrap">
      <div className="payment-text">
        <Trans
          i18nKey="payment_text"
          values={{
            invoice: 602211,
            price: '$1500 price + $0 tax = $1500 USD.',
          }}
        />
      </div>
      <Grid
        container
        columnSpacing={4}
        sx={{ marginTop: '25px', marginBottom: '25px' }}
      >
        <Grid item xs={6}>
          <Paper elevation={0} className="elevation-block">
            <img src={paymentLogo} alt="payment logo" />
            <Button className="filled-btn">{t('buttons.buy_now')}</Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={0} className="elevation-block">
            <img src={payPalLogo} alt="pay pal logo" />
            <Button className="filled-btn">{t('buttons.buy_now')}</Button>
          </Paper>
        </Grid>
      </Grid>
      <div className="invoice-text">
        <Trans i18nKey="payment_invoice_text" />
      </div>
      <div className="invoice-table-wrap">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Invoice number</th>
              <th>Invoice date</th>
              <th>Price</th>
              <th>Tax</th>
              <th>Total price</th>
              <th>Paid</th>
              <th>Paid by</th>
              <th style={{ width: '166px' }}>Cheque or transaction number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>602211</td>
              <td>15,07,2022</td>
              <td>1,500.00</td>
              <td>0.00</td>
              <td>1,500.00</td>
              <td>No</td>
              <td>Online</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentInfo;
