import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AppliedSettings from '@/components/KeyReports/AppliedSettings';
import ShortInfo from '@/components/KeyReports/ShortInfo';
import { renderItemsShortInfo } from '@/components/KeyReports/utils';
import { KeySettings } from '@/components/KeySettings';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import ReportChart from '@/components/ReportChart/ReportChart';
import ReportChartMarketTrends from '@/components/ReportChart/ReportChartMarketTrends';
import ReportChartPerformance from '@/components/ReportChart/ReportChartPerformance';
import ReportTable from '@/components/ReportTable/ReportTable';
import ReportTablePagination from '@/components/ReportTable/ReportTablePagination';
import { adjustColumnPositions } from '@/components/ReportTable/utils';
import { ReportTypesPanel } from '@/components/ReportTypesPanel';
import ReportMap from '@/components/ReportTypesPanel/ReportMap';
import ReportMapPerformance from '@/components/ReportTypesPanel/ReportMapPerformance';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import { EReportName } from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings } from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import { getConfig } from '@/store/reportFieldsSlice';
import { getReport } from '@/store/reportSlice';
import { HeadCell, ReportTableData } from '@/store/types';
import { IconButton, Popover } from '@mui/material';

import ReportChartMarketComparative from '../ReportChart/ReportChartMarketComparative';
import { ReportProfileButton } from '../ReportProfileButton/ReportProfileButton';

import './KeyReports.scss';
import '../Display/Count.scss';
import '../SpriteIcon/SpriteIcon.scss';

function ReportHeader() {
  const { t } = useTranslation();
  const {
    reportUnit,
    reportName,
    startDate,
    endDate,
    agentIds,
    firmIds,
    officeIds,
    brandIds,
  } = useSelector(getKeySettings);
  const { firstDate, lastDate } = useSelector(getFilterLists);
  const { legalName, lastUpdate, dateType } = useSelector(getCurrentMls);
  const keySettings = useSelector(getKeySettings);
  const { shortInfo } = useSelector(getReport);
  const dataCompiledKey =
    dateType === 'Sold Date' ? 'data_compiled_sold' : 'data_compiled_closed';
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleOpen = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen2 = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const reportKey =
    dateType === 'Sold Date' ? 'report_sold_date' : 'report_closed_date';
  const reportTitle = () => {
    if (reportName === EReportName.PERFORMANCE) {
      if (agentIds?.length) return t(`reports.agent-${reportName}`);
      if (officeIds?.length) return t(`reports.office-${reportName}`);
      if (firmIds?.length) return t(`reports.firm-${reportName}`);
      if (brandIds?.length) return t(`reports.brand-${reportName}`);
    }
    if (reportName === EReportName.AGENT_DISTRIBUTION) {
      if (officeIds?.length) return t(`reports.office-${reportName}`);
      if (firmIds?.length) return t(`reports.firm-${reportName}`);
      if (brandIds?.length) return t(`reports.brand-${reportName}`);
    }

    return t(`reports.${reportName}`);
  };
  const reportMembers = () => {
    if (
      [
        EReportName.AGENTS_DISTRIBUTION,
        EReportName.OFFICES_DISTRIBUTION,
        EReportName.FIRMS_DISTRIBUTION,
        EReportName.BRANDS_DISTRIBUTION,
        EReportName.AGENT_DISTRIBUTION,
        EReportName.OFFICE_DISTRIBUTION,
        EReportName.FIRM_DISTRIBUTION,
        EReportName.BRAND_DISTRIBUTION,
        EReportName.AGENT_TRANSACTIONS,
        EReportName.OFFICE_TRANSACTIONS,
        EReportName.FIRM_TRANSACTIONS,
        EReportName.BRAND_TRANSACTIONS,
        EReportName.AGENT_HISTORY,
        EReportName.PERFORMANCE,
      ].includes(reportName as EReportName)
    ) {
      let members = null;
      let key = '';

      if (shortInfo) {
        if (shortInfo.agents.length) {
          members = shortInfo.agents;
          key = 'agentName';
        } else if (shortInfo.offices.length) {
          members = shortInfo.offices;
          key = 'officeName';
        } else if (shortInfo.firms.length) {
          members = shortInfo.firms;
          key = 'firmName';
        } else if (shortInfo.brands.length) {
          members = shortInfo.brands;
          key = 'brandName';
        }
      }

      if (members && members.length > 1) {
        return (
          <span className="report-members count">
            <Trans
              i18nKey={reportUnit + '_profiled'}
              count={members.length}
              components={{ span: <span /> }}
            ></Trans>
            <IconButton
              className="info-button"
              aria-owns={open2 ? 'members-mouse-over-popover' : undefined}
              aria-haspopup="true"
              aria-label={t('buttons.info')}
              aria-describedby="members-mouse-over-popover"
              onMouseEnter={handleOpen2}
              onTouchStart={handleOpen2}
              onMouseLeave={handleClose2}
              onTouchEnd={handleClose2}
              disableFocusRipple={true}
              disableRipple={true}
            >
              <SpriteIcon icon="info" className="info" />
            </IconButton>
          </span>
        );
      } else if (members) {
        const member = members[0];

        return (
          <span className="report-members">
            <ReportProfileButton
              row={null}
              id={member.id.toString()}
              label={member.name}
              fieldKey={key}
              allowFilter={false}
            ></ReportProfileButton>
          </span>
        );
      }
    }
  };

  const reportHeaderBottom = () => {
    const appliedSettings = AppliedSettings();
    const shortInfo = ShortInfo();
    if (appliedSettings || shortInfo) {
      return (
        <div className="report-header-bottom">
          <AppliedSettings />
          <ShortInfo />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="report-header" data-report-header>
      <div className="report-header-top">
        <span className="report-name">
          {reportTitle()}
          <IconButton
            className="info-button"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            aria-label={t('buttons.info')}
            aria-describedby="mouse-over-popover"
            onMouseEnter={handleOpen}
            onTouchStart={handleOpen}
            onMouseLeave={handleClose}
            onTouchEnd={handleClose}
            disableFocusRipple
            disableRipple
          >
            <SpriteIcon icon="info" className="info" />
          </IconButton>
        </span>
        {reportMembers()}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className="info-popper"
        >
          <div
            className="info-content"
            style={{
              maxWidth: 'calc(100% + 100px)',
              width: 'calc(100% + 100px)',
            }}
          >
            <Trans
              i18nKey={dataCompiledKey}
              components={{ text: <span /> }}
              values={{
                name: legalName ? legalName.replace('&reg;', '®') : '',
                date: moment.utc(lastUpdate).locale(i18n.language).format('ll'),
                from: moment.utc(firstDate).locale(i18n.language).format('ll'),
                to: moment.utc(lastDate).locale(i18n.language).format('ll'),
              }}
            />
          </div>
        </Popover>
        <Popover
          id="members-mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className="info-popper"
        >
          <div
            className="info-content"
            style={{
              maxWidth: 'calc(100% + 100px)',
              width: 'calc(100% + 100px)',
            }}
          >
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.agents || [],
              null,
              'agents'
            )}
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.offices || [],
              null,
              'offices'
            )}
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.firms || [],
              null,
              'firms'
            )}
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.brands || [],
              null,
              'brands'
            )}
          </div>
        </Popover>
      </div>
      <div className="report-header-middle">
        <Trans
          i18nKey={reportKey}
          components={{ text: <span /> }}
          values={{
            from: moment.utc(startDate).locale(i18n.language).format('ll'),
            to: moment.utc(endDate).locale(i18n.language).format('ll'),
          }}
        />
      </div>
      {reportHeaderBottom()}
    </div>
  );
}

const KeyReports = () => {
  const filters = useSelector(getFilterLists);
  const report = useSelector(getReport);
  const reportFields = useSelector(getConfig);
  const { reportName, reportType } = useSelector(getKeySettings);

  const [open, setOpen] = useState(true);
  const [headCells, setHeadCells] = useState<Array<HeadCell>>([]);
  const [showColumnValues, setShowColumnValues] = useState<boolean>(false);

  const toggleColumnVisibility = (id: string, boolean: boolean) => {
    if (headCells.length) {
      setHeadCells((cells) =>
        cells.map((item) => {
          if (item.id === id) {
            item.visible = !item.visible;
          }

          return item;
        })
      );
    }
    //@ts-ignore
    if (JSON.parse(sessionStorage.getItem('HeadCells')).data.length) {
      const headCellsFromStorage = sessionStorage.getItem('HeadCells');
      //@ts-ignore
      const { data } = JSON.parse(headCellsFromStorage);
      sessionStorage.setItem(
        'HeadCells',
        JSON.stringify({
          report: reportName,
          reportType: reportType,
          data: data.map((item: any) => {
            if (item.id === id) {
              item.visible = boolean;
            }

            return item;
          }),
        })
      );
      adjustColumnPositions();
    }
  };

  const checkIfIdNotEqual = (arr1: any, arr2: any, key: any): boolean => {
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const result = [] as Array<HeadCell>;

    reportFields.forEach((item, index) => {
      return (result[index] = {
        id: item.id as keyof ReportTableData,
        numeric: item.type === 'n',
        label: item.label,
        visible: true,
      });
    });

    setHeadCells(result);
    //@ts-ignore
    const sessionStorageData = JSON.parse(sessionStorage.getItem('HeadCells'));
    if (
      reportName !== sessionStorageData?.report ||
      reportType !== sessionStorageData?.reportType ||
      !sessionStorageData?.data.length ||
      checkIfIdNotEqual(result, sessionStorageData?.data, 'id')
    ) {
      sessionStorage.setItem(
        'HeadCells',
        JSON.stringify({
          report: reportName,
          reportType: reportType,
          data: result,
        })
      );
    }
  }, [reportFields]);

  return (
    <>
      {filters.loaded ? (
        <div data-filters-opened={open} className="content-wrap reports-wrap">
          <KeySettings changeFiltersPanelState={setOpen} />
          <div className="report-container">
            <ReportTypesPanel
              headCells={headCells}
              toggleColumnVisibility={toggleColumnVisibility}
            />
            <div
              className="report"
              data-report-height
              style={{
                width: open
                  ? `calc(100vw - ${350}px)`
                  : `calc(100vw - ${50}px)`,
              }}
            >
              <ReportHeader />
              <>
                {report.displayMap &&
                [
                  EReportName.PERFORMANCE,
                  EReportName.AGENT_TRANSACTIONS,
                  EReportName.OFFICE_TRANSACTIONS,
                  EReportName.FIRM_TRANSACTIONS,
                  EReportName.BRAND_TRANSACTIONS,
                ]
                  .map((enumValue: EReportName) => enumValue.toString())
                  .includes(reportName) ? (
                  <ReportMapPerformance />
                ) : report.displayMap ? (
                  <ReportMap />
                ) : (reportName === EReportName.OFFICES_TOP_TEN &&
                    report.items.length &&
                    reportFields.length) ||
                  (reportName === EReportName.FIRMS_TOP_TEN &&
                    report.items.length &&
                    reportFields.length) ||
                  (reportName === EReportName.BRANDS_TOP_TEN &&
                    report.items.length &&
                    reportFields.length) ? (
                  <ReportChart />
                ) : reportName === EReportName.PERFORMANCE &&
                  report.items.length &&
                  reportFields.length ? (
                  <ReportChartPerformance
                    showColumnValues={showColumnValues}
                    setShowColumnValues={setShowColumnValues}
                  />
                ) : reportName === EReportName.MARKET &&
                  report.items.length &&
                  reportFields.length ? (
                  <ReportChartMarketTrends
                    showColumnValues={showColumnValues}
                    setShowColumnValues={setShowColumnValues}
                  />
                ) : reportName === EReportName.MARKET_COMPARATIVE &&
                  report.items.length &&
                  reportFields.length ? (
                  <ReportChartMarketComparative
                    showColumnValues={showColumnValues}
                    setShowColumnValues={setShowColumnValues}
                  />
                ) : (
                  <>
                    <ReportTable headCells={headCells} rows={report.items} />
                    {reportName !== EReportName.PERFORMANCE &&
                      reportName !== EReportName.MARKET &&
                      reportName !== EReportName.MARKET_COMPARATIVE && (
                        <ReportTablePagination />
                      )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      ) : (
        <ProgressLoader />
      )}
    </>
  );
};

export default KeyReports;
