import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledCheckbox from '@/mui-styled-components/checkbox';
import {
  ECombinedRequiredFieldsForAgents,
  ECombinedRequiredFieldsForBrands,
  ECombinedRequiredFieldsForFirms,
  ECombinedRequiredFieldsForOffices,
  EReportUnit,
} from '@/store/enums';
import { getKeySettings } from '@/store/keySettingsSlice';
import { HeadCell } from '@/store/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 970,
  bgcolor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface IProps {
  headCells: Array<HeadCell>;
  toggleColumnVisibility: (id: string, boolean: boolean) => void;
}

const ReportTypeSettings = (props: IProps) => {
  const { headCells, toggleColumnVisibility } = props;
  const { reportUnit } = useSelector(getKeySettings);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const getInitialStateFromStorage = () => {
    const initialState: Record<string, boolean> = {};
    const headCellsFromStorage = sessionStorage.getItem('HeadCells');
    let parsedData = { data: [] };

    if (headCellsFromStorage) {
      parsedData = JSON.parse(headCellsFromStorage);
    }

    const HeadCells = parsedData.data.length > 0 ? parsedData.data : headCells;

    HeadCells.forEach((item: any) => {
      initialState[item.id] = item.visible;
    });

    return initialState;
  };

  const [localCheckboxState, setLocalCheckboxState] = useState(
    getInitialStateFromStorage
  );

  useEffect(() => {
    if (open) {
      setLocalCheckboxState(getInitialStateFromStorage);
    }
  }, [open]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const onCheckboxChange = (id: string) => {
    setLocalCheckboxState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSave = () => {
    Object.keys(localCheckboxState).forEach((id) => {
      toggleColumnVisibility(id, localCheckboxState[id]);
    });
    handleClose();
  };

  const getCombinedRequiredFields = () => {
    switch (reportUnit) {
      case EReportUnit.AGENTS:
        return ECombinedRequiredFieldsForAgents;
      case EReportUnit.FIRMS:
        return ECombinedRequiredFieldsForFirms;
      case EReportUnit.OFFICES:
        return ECombinedRequiredFieldsForOffices;
      case EReportUnit.BRANDS:
        return ECombinedRequiredFieldsForBrands;
      default:
        return {};
    }
  };

  const requiredShow = (id: string): boolean => {
    const values = getCombinedRequiredFields();
    const fields = Object.values(values).map((item) => item as string);
    return fields.includes(id);
  };

  const renderHeadCells = () => {
    const headCellsFromStorage = sessionStorage.getItem('HeadCells');
    //@ts-ignore
    const { data } = JSON.parse(headCellsFromStorage);
    const HeadCells = data || headCells;

    return HeadCells?.slice(1).map(
      (item: any, index: any) =>
        !requiredShow(item.id) && (
          <div key={index}>
            <label>
              <StyledCheckbox
                size="small"
                checked={localCheckboxState[item.id] || false}
                onChange={() => onCheckboxChange(item.id)}
              />
              {item.label}
            </label>
          </div>
        )
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        className="settings-btn"
        aria-label={t('buttons.show_select_dashboard_content_menu')}
        onClick={handleOpen}
        onTouchStart={handleOpen}
      >
        <SpriteIcon icon="settings" />
      </Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        classes={{ root: 'columns-settings-modal' }}
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            onTouchStart={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2 style={{ marginTop: 0, textAlign: 'center' }}>
            {t('reports.settings_modal_title')}
          </h2>
          <div style={{ columnCount: 3, columnGap: 30 }}>
            {renderHeadCells()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              onTouchStart={handleSave}
            >
              {t('reports.save_button')}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReportTypeSettings;
