import { AxiosInstance } from 'axios';

import { UserProfile } from '@/store/types';

export default class Users {
  constructor(private clientFactory: () => AxiosInstance) {}

  public async getUser(id: string): Promise<UserProfile> {
    const response = await this.clientFactory().get(`/users/${id}`);

    if (response && response.status === 200) {
      return response.data.data;
    }

    throw response;
  }
}
