import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { apiClient } from '@/ApiClient';
import { loadConfig } from '@/modules/config';
import { store } from '@/store';

import App from './App';

import './assets/fonts/style.css';
import './index.scss';

import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

loadConfig('/env.json').then((config) => {
  apiClient.setBaseUrl(config.baseUrl);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
});
