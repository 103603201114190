import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ChartTypeSelect from '@/components/ReportChart/ChartTypeSelect';
import DataSelect from '@/components/ReportChart/DataSelect';
import TimePeriodSelect from '@/components/ReportChart/TimePeriodSelect';
import {
  Debounce,
  handleResize,
  QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW,
} from '@/components/ReportChart/utils';
import { AppDispatch } from '@/store';
import { getSavedSearchList, setKeySettings } from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import { initConfig } from '@/store/reportFieldsSlice';
import { getReport, loadReport } from '@/store/reportSlice';
import { EChartTypes, EGraphTypes } from '@/store/types';
import { SelectChangeEvent } from '@mui/material';

import {
  dataLabel,
  dataList,
  generateCategories,
  generateCategoriesDualSimple,
  generateDataset,
  generateDualSimpleChartConfig,
  getChartConfig,
  seriesMappings,
  timePeriodOptions,
} from './utilsMarketTrends';

import './ReportChartPerfomance.scss';

//@ts-ignore
FusionCharts.options.license({
  key: process.env.REACT_APP_FUSION_CHARTS_KEY || '',
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

interface Props {
  showColumnValues: boolean;
  setShowColumnValues: Dispatch<SetStateAction<boolean>>;
}

const ReportChartMarketTrends = ({
  showColumnValues,
  setShowColumnValues,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const mls = useSelector(getCurrentMls);
  //@ts-ignore
  const { items, graphType, data, chartType, timePeriod } =
    useSelector(getReport);
  const [height, setHeight] = useState<number>(0);

  const handleChangeData = (e: SelectChangeEvent<string | undefined>) => {
    const selectedValue = e.target.value;

    dispatch(
      setKeySettings({
        key: 'data',
        value: selectedValue,
      })
    );

    dispatch(
      initConfig({ orderBy: selectedValue || '', timePeriod: timePeriod || '' })
    );
    dispatch(loadReport(false, false));
    dispatch(getSavedSearchList());
  };

  const handleTimePeriod = (e: SelectChangeEvent<string | undefined>) => {
    dispatch(
      setKeySettings({
        key: 'timePeriod',
        value: e.target.value,
      })
    );

    dispatch(
      initConfig({ orderBy: data || '', timePeriod: e.target.value || '' })
    );
    dispatch(loadReport(false, false));
    dispatch(getSavedSearchList());
  };

  const handleChartType = (e: SelectChangeEvent<string | undefined>) => {
    setShowColumnValues(e.target.value === EChartTypes.COLUMN_COMPACT_VIEW);

    dispatch(
      setKeySettings({
        key: 'chartType',
        value:
          e.target.value === EChartTypes.COLUMN_COMPACT_VIEW
            ? EChartTypes.COLUMN
            : e.target.value,
      })
    );

    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const handleWindowResize = Debounce(() => {
    handleResize(data, height, setHeight);
  }, 1000);

  useEffect(() => {
    if (items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW) {
      setShowColumnValues(false);
    }

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const chartConfigs = getChartConfig(
    items,
    chartType,
    showColumnValues,
    height,
    data
  );
  chartConfigs.dataSource.dataset = generateDataset(
    items,
    graphType,
    data,
    seriesMappings
  );
  chartConfigs.dataSource.categories = generateCategories(items, data);

  const chartConfigsSeriesTwo = getChartConfig(
    items,
    chartType,
    showColumnValues,
    height,
    data
  );
  chartConfigsSeriesTwo.dataSource.dataset = generateDualSimpleChartConfig(
    items,
    graphType,
    data,
    seriesMappings
  );
  chartConfigsSeriesTwo.dataSource.categories = generateCategoriesDualSimple(
    items,
    data
  );

  return (
    <>
      <div
        className="report-chart-header report-chart-performance"
        data-report-chart
      >
        <div>{dataLabel(timePeriod, data)}</div>
        <div className="report-chart-panel">
          {data && (
            <DataSelect
              data={data}
              dataList={() => dataList(undefined, mls.name)}
              handleChangeData={handleChangeData}
            />
          )}
          {data !== 'avgRatioWOMMOM' &&
          data !== 'totalUnitWOMMOM' &&
          data !== 'inventory' &&
          timePeriod ? (
            <TimePeriodSelect
              timePeriod={timePeriod}
              handleTimePeriod={handleTimePeriod}
              options={timePeriodOptions}
            />
          ) : null}
          {chartType && (
            <ChartTypeSelect
              chartType={chartType}
              showColumnValues={showColumnValues}
              handleChartType={handleChartType}
              items={items}
            />
          )}
        </div>
      </div>
      <div className="report-chart">
        {height && (
          <>
            <ReactFC {...chartConfigs} />
            {graphType === EGraphTypes.DUAL_SIMPLE && (
              <ReactFC {...chartConfigsSeriesTwo} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ReportChartMarketTrends;
