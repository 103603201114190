const updateURL = (url) => window.history.pushState(null, null, url);

export const getURLParameter = (key) => {
  const url = new URL(window.location);

  return url.searchParams.get(key);
};

export const addURLParameter = (key, value) => {
  const url = new URL(window.location);

  url.searchParams.set(key, value);

  updateURL(url);
};

export const deleteURLParameter = (key) => {
  const url = new URL(window.location);

  url.searchParams.delete(key);

  updateURL(url);
};

export const objToQueryString = (data) => {
  const result = {};
  const recurse = (cur, prop) => {
    if (Object(cur) !== cur) {
      if (cur) result[prop] = cur;
    } else if (Array.isArray(cur)) {
      recurse(cur.join(), prop);
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '[' + p + ']' : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  };

  recurse(data, '');

  return Object.entries(result)
    .map((elem) => elem[0].replace(/\[[0-9]+\]/, '[]') + '=' + elem[1])
    .join('&');
};

export const objToQueryStringSaved = (data) => {
  const result = {};
  const recurse = (cur, prop) => {
    if (Object(cur) !== cur) {
      if (cur) result[prop] = cur;
    } else if (Array.isArray(cur)) {
      if (cur.length) {
        recurse(cur.join(), prop);
      } else {
        result[prop] = '[]';
      }
    } else {
      for (const p in cur) {
        recurse(cur[p], prop ? prop + '[' + p + ']' : p);
      }
    }
  };

  recurse(data, '');

  return Object.entries(result)
    .map((elem) => elem[0] + '=' + elem[1])
    .join('&');
};
