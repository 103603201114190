import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomAutocomplete from '@/components/CustomElements/CustomAutocomplete';
import { isPropertyIncluded } from '@/components/KeySettings/utils';
import useDebounce from '@/hooks/useDebounce';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { getReport, resetReport } from '@/store/reportSlice';
import { onlyNumbers } from '@/validation';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const PropertySettings = () => {
  const { t } = useTranslation();
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const { classList, typeList, styleList } = useSelector(getFilterLists);
  const { mls, propertyType, type, style, priceOver, priceUnder } =
    useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState(false);
  const [propertyTypeValue, setPropertyType] =
    useState<Array<string | number>>(propertyType);
  const [typeValue, setType] = useState<Array<string | number>>(type);
  const [styleValue, setStyle] = useState<Array<string | number>>(style);
  const [priceMin, setPriceMin] = useState<number | string>(priceOver || '');
  const [priceMax, setPriceMax] = useState<number | string>(priceUnder || '');

  const hasPriceProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'price'
  );
  const hasPropertyTypeProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'propertyType'
  );
  const hasTypeProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'type'
  );
  const hasStyleProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'style'
  );

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { ref: inputPriceMinRef, ...inputPriceMinProps } = register('priceMin');
  const { ref: inputPriceMaxRef, ...inputPriceMaxProps } = register('priceMax');

  const debouncedPropertyType = useDebounce(propertyTypeValue);
  const debouncedType = useDebounce(typeValue);
  const debouncedStyle = useDebounce(styleValue);
  const debouncedPriceMin = useDebounce(priceMin);
  const debouncedPriceMax = useDebounce(priceMax);

  const updatePriceMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const value = e.target.value ? parseInt(e.target.value) : '';
      setPriceMin(value);
      setValue('priceMin', value, { shouldValidate: true });
    }
  };

  const updatePriceMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const value = e.target.value ? parseInt(e.target.value) : '';
      setPriceMax(value);
      setValue('priceMax', value, { shouldValidate: true });
    }
  };

  const updatePropertyType = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setPropertyType(value);
    } else {
      setPropertyType([]);
    }
  };

  const updateType = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setType(value);
    } else {
      setType([]);
    }
  };

  const updateStyle = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setStyle(value);
    } else {
      setStyle([]);
    }
  };

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'propertyType',
        value: debouncedPropertyType,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedPropertyType]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'type',
        value: debouncedType,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedType]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'style',
        value: debouncedStyle,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedStyle]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'priceOver',
        value: debouncedPriceMin !== '' ? debouncedPriceMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedPriceMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'priceUnder',
        value: debouncedPriceMax !== '' ? debouncedPriceMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedPriceMax]);

  useEffect(() => {
    setPropertyType([]);
    setStyle([]);
    setType([]);
  }, [mls]);

  useEffect(() => {
    setPropertyType(propertyType);
  }, [propertyType]);

  useEffect(() => {
    setType(type);
  }, [type]);

  useEffect(() => {
    setStyle(style);
  }, [style]);

  useEffect(() => {
    setPriceMin(priceOver || '');
  }, [priceOver]);

  useEffect(() => {
    setPriceMax(priceUnder || '');
  }, [priceUnder]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      if (
        !!priceMin ||
        !!priceMax ||
        propertyTypeValue.length ||
        typeValue.length ||
        styleValue.length
      ) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [propertyTypeValue, typeValue, styleValue, priceMin, priceMax]);

  return (
    <Accordion
      expanded={expanded}
      sx={{ boxShadow: 'none', padding: 0, border: 'none', margin: 0 }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ boxShadow: 'none', padding: 0, minHeight: 1, margin: 0 }}
      >
        <div className="d-flex" style={{ alignItems: 'center', flex: 1 }}>
          <h3>{t('key_settings.property')}</h3> <div className="line" />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {hasPriceProperty ? (
          <div className="d-flex" style={{ paddingTop: 10 }}>
            <div
              className="d-flex flex-column"
              style={{ width: 'calc(50% - 10px)', marginRight: '10px' }}
            >
              <label htmlFor="price-over">{t('key_settings.price_over')}</label>
              <StyledTextField
                inputRef={inputPriceMinRef}
                error={!!errors.priceMin}
                //@ts-ignore
                helperText={errors?.priceMin?.message}
                {...inputPriceMinProps}
                id="price-over"
                placeholder={t('key_settings.min')}
                value={priceMin}
                fullWidth={false}
                onChange={updatePriceMin}
                className="w-100 form-input"
                FormHelperTextProps={{ style: { paddingBottom: '10px' } }}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ width: 'calc(50% - 10px)', marginLeft: '10px' }}
            >
              <label htmlFor="price-under">
                {t('key_settings.price_under')}
              </label>
              <StyledTextField
                inputRef={inputPriceMaxRef}
                error={!!errors.priceMax}
                //@ts-ignore
                helperText={errors?.priceMax?.message}
                {...inputPriceMaxProps}
                id="price-under"
                placeholder={t('key_settings.max')}
                value={priceMax}
                fullWidth={false}
                onChange={updatePriceMax}
                className="w-100 form-input"
              />
            </div>
          </div>
        ) : null}
        {hasPropertyTypeProperty && classList.length && (
          <CustomAutocomplete
            label={t('key_settings.prop_type')}
            placeholder={t('select')}
            value={propertyTypeValue}
            choices={classList}
            visibleValues={1}
            style={{ paddingTop: 10 }}
            setValue={updatePropertyType}
          />
        )}
        {hasTypeProperty && typeList.length && (
          <CustomAutocomplete
            label={t('key_settings.type')}
            placeholder={t('select')}
            value={typeValue}
            choices={typeList}
            visibleValues={1}
            setValue={updateType}
          />
        )}
        {hasStyleProperty && styleList.length && (
          <CustomAutocomplete
            label={t('key_settings.style')}
            placeholder={t('select')}
            value={styleValue}
            choices={styleList}
            setValue={updateStyle}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PropertySettings;
