import moment from 'moment';

import MarkerClusterImage2 from '@/assets/images/marker_cluster3.png';
import MarkerImage2 from '@/assets/images/marker4.png';
import MarkerImage3 from '@/assets/images/marker5.png';
import { AppDispatch } from '@/store';
import { EListTypes } from '@/store/enums';
import { IKeySettings, TMapPerformanceTotals } from '@/store/types';
import MarkerClusterer from '@googlemaps/markerclustererplus';

interface CustomMarker extends google.maps.Marker {
  totalCounts?: number | null;
}

export const createMarkers = (
  map: google.maps.Map,
  mapData: Array<TMapPerformanceTotals>,
  keySettings: IKeySettings,
  currWindow: google.maps.InfoWindow | null
): google.maps.Marker[] => {
  const coordinatesCounter: Record<string, number> = {};

  return mapData?.map(
    ({
      latitude,
      longitude,
      brandId,
      firmId,
      officeName,
      officeAddress,
      firmName,
      brandName,
      listType,
      agentName,
      mls,
      unitAddress,
      unitPrice,
      unitRatio,
      unitDOM,
      className,
      closedDate,
      count,
    }) => {
      const coordinateKey = `${latitude},${longitude}`;

      if (coordinatesCounter[coordinateKey]) {
        latitude += 0.00005 * coordinatesCounter[coordinateKey];

        coordinatesCounter[coordinateKey]++;
      } else {
        coordinatesCounter[coordinateKey] = 1;
      }

      google.maps.event.addListener(map, 'click', function () {
        if (currWindow) {
          currWindow.close();
        }
      });

      const contentInfoWindow = () => {
        let content = '';
        let ariaLabel = '';

        if (keySettings.agentIds?.length) {
          content = agentName;
          ariaLabel = agentName;
        } else if (keySettings.officeIds?.length) {
          content = `${officeName} - ${officeAddress}`;
          ariaLabel = `${officeName} - ${officeAddress}`;
        } else if (keySettings.firmIds?.length) {
          content = firmName;
          ariaLabel = firmName;
        } else if (keySettings.brandIds?.length) {
          content = brandName;
          ariaLabel = brandName;
        }
        return {
          content: `
            <div style="font-size: 14px">
              <strong>${content}</strong></br>
              MLS Number: ${mls}</br>
              ${unitAddress}</br>
              <strong>Sold Price: ${unitPrice}, Sold/Asked Ratio: ${unitRatio}%, DOM: ${unitDOM}</strong></br>
              Class: ${className}, Closed Date: ${moment
            .utc(closedDate)
            ?.format('MM/DD/YYYY')}
            </div>
          `,
          ariaLabel,
        };
      };

      const infoWindowOptions = contentInfoWindow();
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowOptions.content,
        ariaLabel: infoWindowOptions.ariaLabel,
        disableAutoPan: true,
      });

      const titleMarker = () => {
        let content = '';

        if (keySettings.agentIds?.length) {
          content = agentName;
        } else if (keySettings.officeIds?.length) {
          content = `${officeName} - ${officeAddress}`;
        } else if (keySettings.firmIds?.length) {
          content = firmName;
        } else if (keySettings.brandIds?.length) {
          content = brandName;
        }

        return `${content}\nMLS Number: ${mls}\n${unitAddress}\nSold Price: ${unitPrice}, Sold/Asked Ratio: ${unitRatio}%, DOM: ${unitDOM}\nClass: ${className}, Closed Date: ${moment
          .utc(closedDate)
          ?.format('MM/DD/YYYY')}`;
      };

      const isValidCount = count !== undefined && count !== null;

      const marker: CustomMarker = new google.maps.Marker({
        position: { lat: longitude, lng: latitude },
        map,
        icon: {
          url: isValidCount
            ? MarkerClusterImage2
            : listType === EListTypes.LISTING
            ? MarkerImage2
            : MarkerImage3,
          labelOrigin: new google.maps.Point(35, 25),
        },
        title: isValidCount
          ? `Cluster of ${count.toString()} units`
          : titleMarker(),
        label: {
          text: isValidCount ? String(count) : ' ',
          color: 'rgba(0,0,0,0.9)',
          fontSize: '14px',
          fontWeight: 'bold',
        },
      });

      marker.totalCounts = count;

      marker.addListener('click', function () {
        if (count) {
          const currZoom = map.getZoom() || 0;
          map.setZoom(currZoom + 1);
          //@ts-ignore
          map.panTo(this.getPosition());
          return;
        }
        if (currWindow) {
          currWindow.close();
        }

        infoWindow.open({ anchor: marker, map });
        currWindow = infoWindow;
      });

      return marker;
    }
  );
};

let clusterIndex = 0;

export const createClusterer = (
  markers: CustomMarker[],
  map: google.maps.Map
): MarkerClusterer | null => {
  const clusterer = new MarkerClusterer(map, markers, {
    clusterClass: 'custom-cluster',
  });

  clusterer.setCalculator((markers: CustomMarker[]) => {
    let newMarkers = 0;

    if (markers[0].totalCounts === undefined) {
      return {
        text: markers.length.toString(),
        index: clusterIndex++,
        title: `Cluster of ${markers.length.toString()} units`,
      };
    }

    markers.forEach((marker) => {
      newMarkers += marker.totalCounts ?? 0;
    });

    return {
      text: newMarkers.toString(),
      index: clusterIndex++,
      title: `Cluster of ${newMarkers.toString()} units`,
    };
  });

  return clusterer;
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timerId: NodeJS.Timeout;

  return (...args: Parameters<T>): void => {
    clearTimeout(timerId);
    timerId = setTimeout(() => func.apply(this, args), delay);
  };
};

export const dispatchBounds = async (
  map: google.maps.Map,
  dispatch: AppDispatch,
  boundsArray: number[],
  setShowMapProgress: React.Dispatch<React.SetStateAction<boolean>>,
  loadMapCountTransactionItems: any,
  loadMapTransactionItems: any,
  loadMapClusterTransactionItems: any,
  setMapItems: any,
  mapQtyMaxCountsTransactionsItems: number
): Promise<void> => {
  setShowMapProgress(true);
  if (dispatch) {
    try {
      const result: any = await dispatch(
        loadMapCountTransactionItems(boundsArray)
      );

      if (result <= mapQtyMaxCountsTransactionsItems) {
        await dispatch(loadMapTransactionItems(boundsArray));

        setShowMapProgress(false);
      } else {
        await dispatch(loadMapClusterTransactionItems(boundsArray));

        setShowMapProgress(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const addBoundsChangeListener = (
  map: google.maps.Map,
  debouncedDispatch: (...args: any[]) => void
): void => {
  const dispatchBoundsWrapper = () => {
    const bounds = map.getBounds();
    const ne = bounds?.getNorthEast();
    const sw = bounds?.getSouthWest();
    const neArray: number[] = [ne?.lat(), ne?.lng()].filter(
      (coord) => typeof coord === 'number'
    ) as number[];
    const swArray: number[] = [sw?.lat(), sw?.lng()].filter(
      (coord) => typeof coord === 'number'
    ) as number[];
    const boundsArray: number[] = [...neArray, ...swArray];
    debouncedDispatch(boundsArray);
  };

  const keySettingsSubmitBtn = document.querySelector(
    '[data-keysettings-submit]'
  );
  const keySettingsResetBtn = document.querySelector(
    '[data-keysettings-reset]'
  );

  if (keySettingsSubmitBtn) {
    keySettingsSubmitBtn.addEventListener('click', dispatchBoundsWrapper);
  }

  if (keySettingsResetBtn) {
    keySettingsResetBtn.addEventListener('click', dispatchBoundsWrapper);
  }

  google.maps.event.addListener(map, 'bounds_changed', dispatchBoundsWrapper);
  google.maps.event.addListenerOnce(map, 'tilesloaded', dispatchBoundsWrapper);
};

export const filterQueryParams = (search: string): string => {
  const keysToKeep: string[] = [
    'county',
    'city',
    'zipCode',
    'district',
    'type',
    'propertyType',
    'style',
    'subdivision',
    'school',
    'officeCity',
    'officeZip',
    'officeCityInclude',
    'officeZipInclude',
    'officeCountyInclude',
    'officeCounty',
    'area',
    'officeArea',
    'mls',
    'timeFrame',
    'reportType',
    'reportName',
    'reportUnit',
    'startDate',
    'endDate',
    'firstDate',
    'lastDate',
    'priceOver',
    'priceUnder',
    'orderDirection',
    'orderBy',
    'data',
    'chartType',
    'timePeriod',
    'yearBuiltMin',
    'yearBuiltMax',
    'bedroomsMin',
    'bedroomsMax',
    'finishedSquareFootageMin',
    'finishedSquareFootageMax',
    'totalSquareFootageMin',
    'totalSquareFootageMax',
    'bathroomsFullMin',
    'bathroomsFullMax',
    'bathroomsHalfMin',
    'bathroomsHalfMax',
    'schoolType',
    'page',
    'perPage',
  ];

  const searchParams = new URLSearchParams(search);
  const filteredParams: { [key: string]: string } = {};
  //@ts-ignore
  for (const key of searchParams.keys()) {
    if (keysToKeep.includes(key)) {
      filteredParams[key] = searchParams.get(key) || '';
    }
  }

  return new URLSearchParams(filteredParams).toString();
};
