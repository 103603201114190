import i18n from '@/i18n';
import { store } from '@/store';
import { EPerPage, EReportName } from '@/store/enums';
import { EChartTypes } from '@/store/types';

export interface IColorSettings {
  id: number;
  name: string;
  current: boolean;
  mainPage: string;
  buttons: {
    default: string;
    active: string;
  };
  tableHeader: {
    bg: string;
    color: string;
  };
  tableRow: string;
  graphs: {
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
  };
  reportsLinks: string;
  headerFont: string;
}

export const ColorSchemaData: IColorSettings[] = [
  {
    id: 1,
    name: 'Name 1',
    current: false,
    mainPage: '#fff',
    buttons: { default: '#0C7CC4', active: '#0A619A' },
    tableHeader: { bg: '#fff', color: '#fff' },
    tableRow: '#ccc',
    graphs: {
      color1: '#FF4201',
      color2: '#0C7CC4',
      color3: '#FF9933',
      color4: '#ff33b8',
      color5: '#f1ff33',
    },
    reportsLinks: '#0A619A',
    headerFont: '#6D6D6D',
  },
  {
    id: 2,
    name: 'Name 2',
    current: false,
    mainPage: '#fff',
    buttons: { default: '#00CC6A', active: '#008545' },
    tableHeader: { bg: '#fff', color: '#fff' },
    tableRow: '#ccc',
    graphs: {
      color1: '#8C379A',
      color2: '#00CD6B',
      color3: '#FF8F01',
      color4: '#8533ff',
      color5: '#cc33ff',
    },
    reportsLinks: '#8C379A',
    headerFont: '#6D6D6D',
  },
  {
    id: 3,
    name: 'Name 3',
    current: false,
    mainPage: '#fff',
    buttons: { default: '#FF8F01', active: '#D27600' },
    tableHeader: { bg: '#fff', color: '#fff' },
    tableRow: '#ccc',
    graphs: {
      color1: '#0C7CC4',
      color2: '#00CD6B',
      color3: '#FF9933',
      color4: '#33a3ff',
      color5: '#4733ff',
    },
    reportsLinks: '#00CD6B',
    headerFont: '#6D6D6D',
  },
  {
    id: 4,
    name: 'Name 4',
    current: false,
    mainPage: '#fff',
    buttons: { default: '#8C379A', active: '#682873' },
    tableHeader: { bg: '#fff', color: '#fff' },
    tableRow: '#ccc',
    graphs: {
      color1: '#00CD6B',
      color2: '#8C379A',
      color3: '#FF9933',
      color4: '#5fff33',
      color5: '#33ffc9',
    },
    reportsLinks: '#FF9933',
    headerFont: '#6D6D6D',
  },
  {
    id: 5,
    name: 'Name 5',
    current: false,
    mainPage: '#fff',
    buttons: { default: '#FF4201', active: '#A62B00' },
    tableHeader: { bg: '#fff', color: '#fff' },
    tableRow: '#ccc',
    graphs: {
      color1: '#0C7CC4',
      color2: '#6D6D6D',
      color3: '#FF9933',
      color4: '#ebff33',
      color5: '#85ff33',
    },
    reportsLinks: '#ebff33',
    headerFont: '#6D6D6D',
  },
];

export const getColumns = (): Array<{
  field: keyof IColorSettings;
  headerName: string;
}> => [
  { field: 'name', headerName: 'Name' },
  { field: 'mainPage', headerName: 'Main page' },
  { field: 'buttons', headerName: 'Buttons' },
  { field: 'tableHeader', headerName: 'Table header' },
  { field: 'tableRow', headerName: 'Table row' },
  { field: 'graphs', headerName: 'Graphs' },
  { field: 'reportsLinks', headerName: 'Reports links' },
  { field: 'headerFont', headerName: 'Header font' },
];

export const activeClass = (value: boolean): string => (value ? 'active' : '');

interface IChoice {
  id: number | string;
  name: string;
}

export const namePositionChoices: IChoice[] = [
  { id: 1, name: 'First' },
  { id: 2, name: 'Last' },
];

export const graphsChoices: IChoice[] = Object.keys(EChartTypes)
  .slice(0, -1)
  .map((key) => ({
    id: EChartTypes[key as keyof typeof EChartTypes],
    name: ((str: string) => str.charAt(0).toUpperCase() + str.slice(1))(
      EChartTypes[key as keyof typeof EChartTypes]
    ),
  }));

export const trueFalseChoices: IChoice[] = [
  { id: 1, name: 'No' },
  { id: 2, name: 'Yes' },
];

export const reportsChoices: IChoice[] = [
  {
    id: EReportName.AGENTS,
    name: i18n.t(`reports.${EReportName.AGENTS}`),
  },
  {
    id: EReportName.OFFICES,
    name: i18n.t(`reports.${EReportName.OFFICES}`),
  },
  {
    id: EReportName.FIRMS,
    name: i18n.t(`reports.${EReportName.FIRMS}`),
  },
  {
    id: EReportName.BRANDS,
    name: i18n.t(`reports.${EReportName.BRANDS}`),
  },
  {
    id: EReportName.MARKET_COMPARATIVE,
    name: i18n.t(`reports.${EReportName.MARKET_COMPARATIVE}`),
  },
];

export const rowsPerPageChoices: IChoice[] = Object.keys(EPerPage).map(
  (key) => ({
    id: EPerPage[key as keyof typeof EPerPage],
    name: EPerPage[key as keyof typeof EPerPage],
  })
);

export const getColorByPropertyName = (
  propertyName: string
): string | undefined => {
  const currentScheme = ColorSchemaData.find(
    (item) =>
      item.id ===
      parseInt(store.getState().auth.settings.currentColorScheme, 10)
  );
  if (currentScheme) {
    switch (propertyName) {
      case 'mainPage':
        return currentScheme.mainPage;
      case 'buttons.default':
        return currentScheme.buttons.default;
      case 'buttons.active':
        return currentScheme.buttons.active;
      case 'tableHeader.bg':
        return currentScheme.tableHeader.bg;
      case 'tableHeader.color':
        return currentScheme.tableHeader.color;
      case 'tableRow':
        return currentScheme.tableRow;
      case 'graphs.color1':
        return currentScheme.graphs.color1;
      case 'graphs.color2':
        return currentScheme.graphs.color2;
      case 'graphs.color3':
        return currentScheme.graphs.color3;
      case 'graphs.color4':
        return currentScheme.graphs.color4;
      case 'graphs.color5':
        return currentScheme.graphs.color5;
      case 'reportsLinks':
        return currentScheme.reportsLinks;
      case 'headerFont':
        return currentScheme.headerFont;
      default:
        return undefined;
    }
  }
  return undefined;
};

export const getColorForSeries = (series: string) => {
  switch (series) {
    case 'seriesOne':
      return `${getColorByPropertyName('graphs.color1')}`;
    case 'seriesTwo':
      return `${getColorByPropertyName('graphs.color2')}`;
    case 'seriesThree':
      return `${getColorByPropertyName('graphs.color3')}`;
    case 'seriesFour':
      return `${getColorByPropertyName('graphs.color4')}`;
    case 'seriesFive':
      return `${getColorByPropertyName('graphs.color5')}`;
    default:
      return `${getColorByPropertyName('graphs.color1')}`;
  }
};
