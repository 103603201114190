import React, { useRef } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import { getProfile, updateContact, updatePassword } from '@/store/authSlice';
import { Button } from '@mui/material';

const AccountDetails = () => {
  const profile = useSelector(getProfile);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const emailInput = useRef<HTMLInputElement>();
  const phoneInput = useRef<HTMLInputElement>();
  const passInput = useRef<HTMLInputElement>();
  const newPassInput = useRef<HTMLInputElement>();
  const repeatInput = useRef<HTMLInputElement>();

  const handleContactClick = () => {
    if (emailInput.current && phoneInput.current) {
      const email = emailInput.current.value;
      const phone = phoneInput.current.value;

      if (email === profile.email && phone === profile.phone) {
        emailInput.current.setCustomValidity('Email or phone must be changed');
      } else {
        emailInput.current.setCustomValidity('');
      }

      if (
        emailInput.current.reportValidity() &&
        phoneInput.current.reportValidity()
      ) {
        dispatch(
          updateContact({
            email: email,
            phone: phone,
          })
        );
      }
    }
  };
  const handlePasswordClick = () => {
    if (passInput.current && newPassInput.current && repeatInput.current) {
      const pass = passInput.current.value;
      const newPass = newPassInput.current.value;
      const repeat = repeatInput.current.value;

      if (pass === newPass) {
        newPassInput.current.setCustomValidity(
          'New password must be different from current password'
        );
      } else if (newPass !== repeat) {
        repeatInput.current.setCustomValidity('New passwords do not match');
      } else {
        newPassInput.current.setCustomValidity('');
        repeatInput.current.setCustomValidity('');
      }

      if (
        passInput.current.reportValidity() &&
        newPassInput.current.reportValidity() &&
        repeatInput.current.reportValidity()
      ) {
        dispatch(
          updatePassword({
            pass: pass,
            newPass: newPass,
          })
        );
      }
    }
  };

  return (
    <>
      {profile ? (
        <div className="account-details">
          <div className="half-block">
            <div className="column">
              <h3>{t('account.details_title')}</h3>
              <div className="row">
                <div className="user-data d-flex flex-column w-50">
                  <label>{t('account.first_name')}</label>
                  <span>{profile.firstName}</span>
                </div>
                <div className="user-data d-flex flex-column w-50">
                  <label>{t('account.last_name')}</label>
                  <span>{profile.lastName}</span>
                </div>
              </div>
              <div className="row">
                <div className="user-data d-flex flex-column w-50">
                  <label>{t('account.username')}</label>
                  <span>{profile.username}</span>
                </div>
                <div className="user-data d-flex flex-column w-50">
                  <label>{t('account.logo')}</label>
                  <div>
                    {profile.logoSrc ? (
                      <img
                        className="logo"
                        src={profile.logoSrc}
                        alt="Logo"
                      ></img>
                    ) : (
                      <span>None</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="user-data d-flex flex-column w-50">
                  <label htmlFor="#user-email">{t('account.email')}</label>
                  <StyledTextField
                    id="user-email"
                    className="w-100 form-input"
                    type="email"
                    inputRef={emailInput}
                    sx={{ marginBottom: '5px' }}
                    defaultValue={profile.email}
                    required
                  />
                </div>
                <div className="user-data d-flex flex-column w-50">
                  <label htmlFor="#user-phone">{t('account.phone')}</label>
                  <StyledTextField
                    id="user-phone"
                    className="w-100 form-input"
                    type="tel"
                    inputRef={phoneInput}
                    defaultValue={profile.phone}
                  />
                </div>
              </div>
              <div className="submit">
                <Button
                  variant={'outlined'}
                  className="filled-btn"
                  onClick={handleContactClick}
                  onTouchStart={handleContactClick}
                >
                  {t('buttons.submit')}
                </Button>
              </div>
            </div>
          </div>
          <div className="half-block">
            <div className="column">
              <h3>{t('account.change_pass')}</h3>
              <div className="user-data d-flex flex-column">
                <label htmlFor="#pass">{t('account.current_pass')}</label>
                <StyledTextField
                  id="pass"
                  className="w-100 form-input"
                  type="password"
                  inputRef={passInput}
                  autoComplete="current-password"
                  required
                />
              </div>
              <div className="user-data d-flex flex-column">
                <label htmlFor="#new-pass">{t('account.new_pass')}</label>
                <StyledTextField
                  id="new-pass"
                  className="w-100 form-input"
                  type="password"
                  inputRef={newPassInput}
                  autoComplete="new-password"
                  required
                />
              </div>
              <div className="user-data d-flex flex-column">
                <label htmlFor="#repeat">{t('account.repeat_pass')}</label>
                <StyledTextField
                  id="repeat"
                  className="w-100 form-input"
                  type="password"
                  inputRef={repeatInput}
                  autoComplete="new-password"
                  required
                />
              </div>
              <div className="submit">
                <Button
                  variant={'outlined'}
                  className="filled-btn"
                  onClick={handlePasswordClick}
                  onTouchStart={handlePasswordClick}
                >
                  {t('buttons.submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="account-footer">
        <Trans
          i18nKey="account.footer"
          values={{
            term: profile.term ?? '',
            productName: profile.productName,
            lastDate: profile.lastDate ?? '',
            lastDateString: profile.lastDate
              ? moment.utc(profile.lastDate).locale(i18n.language).format('ll')
              : '',
            acceptDate: profile.acceptDate ?? '',
            acceptDateString: profile.acceptDate
              ? moment
                  .utc(profile.acceptDate)
                  .locale(i18n.language)
                  .format('lll')
              : '',
          }}
          components={{ time: <time /> }}
        />
      </div>
    </>
  );
};

export default AccountDetails;
