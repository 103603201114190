import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ReportTable from '@/components/ReportTable/ReportTable';
import ReportTablePagination from '@/components/ReportTable/ReportTablePagination';
import { AppDispatch } from '@/store';
import { EReportName } from '@/store/enums';
import { setKeySettings } from '@/store/keySettingsSlice';
import { setConfig } from '@/store/reportFieldsSlice';
import { setReport, setTotalItems, setTotals } from '@/store/reportSlice';
import { HeadCell, ReportTableData } from '@/store/types';

import ActionSummary from './ActionSummary';
import ButtonMenu from './ButtonMenu';
import HeaderRightMenu from './HeaderRightMenu';
import { items, reportFields, reportTotals } from './utils';

import './Hotsheets.scss';
import '../KeySettings/KeySettings.scss';

const Hotsheets = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [headCells, setHeadCells] = useState<Array<HeadCell>>([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const result = [] as Array<HeadCell>;

    reportFields.forEach((item, index) => {
      return (result[index] = {
        id: item.id as keyof ReportTableData,
        numeric: item.type === 'n',
        label: item.label,
        visible: true,
      });
    });
    dispatch(
      setReport({
        //@ts-ignore
        value: { items: items },
      })
    );
    //@ts-ignore
    dispatch(setTotals({ totals: reportTotals }));
    dispatch(setTotalItems(43499));
    dispatch(setKeySettings({ value: 'reportName', key: EReportName.AGENTS }));
    //@ts-ignore
    dispatch(setConfig({ data: reportFields }));
    setHeadCells(result);
  }, []);

  const buttons: Button[] = [{ label: 'Agent' }, { label: 'Office' }];

  return (
    <div
      className="full-page hotsheets"
      style={{ flexDirection: 'column', padding: 0, margin: 0 }}
    >
      <div className="section-header">
        <h2 className="headline-1">{t('hotsheets.title')}</h2>
        <div className="d-flex">
          <ButtonMenu
            buttons={buttons}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
        <HeaderRightMenu />
      </div>
      <div className="report-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 72px)',
            width: '100vw',
            backgroundColor: '#fff',
            overflow: 'hidden',
            transition: '0.4s ease-in-out',
          }}
          data-report-height
        >
          <ReportTable
            headCells={headCells}
            //@ts-ignore
            rows={items}
          />
          <ReportTablePagination />
        </div>
      </div>
      <ActionSummary />
    </div>
  );
};

export default Hotsheets;
