import React from 'react';

import i18n from '@/i18n';
import { EReportUnit } from '@/store/enums';
import { IKeySettings, TReportFields, TShortInfoItem } from '@/store/types';

interface DataEntry {
  Min?: string;
  Max?: string;
}

interface AggregatedData {
  [key: string]: DataEntry;
}

export const findFieldById = (
  fieldId: string,
  fetchData: TReportFields[]
): TReportFields | undefined => {
  let modifiedFieldId = fieldId.replace(/Min|Max/g, '').replace('sPer', 'Per');

  if (
    !['agents', 'firms', 'brands', 'offices'].includes(
      fieldId.replace(/Min|Max/g, '')
    )
  ) {
    modifiedFieldId = modifiedFieldId.replace(/s$/, '');
  }

  return fetchData.find(
    (item) =>
      (item.id === fieldId && item.type === 'n') ||
      (item.id.replace(/\d{4}$/, '') === modifiedFieldId && item.type === 'n')
  );
};

export const getLabelWithMinOrMax = (
  fieldId: string,
  fetchData: TReportFields[],
  paramValue?: string | number
): string => {
  const field = findFieldById(fieldId, fetchData);

  if (!field) return '';

  const label = field.label.trim();
  const minMax = fieldId.endsWith('Min') ? 'Min' : 'Max';

  if (
    typeof paramValue === 'number' ||
    (typeof paramValue === 'string' && !paramValue.includes(','))
  ) {
    let valueString = '';

    if (typeof paramValue === 'number') {
      valueString = formatNumberWithCommas(paramValue);
    } else if (
      typeof paramValue === 'string' &&
      !isNaN(parseFloat(paramValue))
    ) {
      valueString = formatNumberWithCommas(parseFloat(paramValue));
    } else {
      valueString = paramValue?.toString() ?? '';
    }

    return `${label} ${minMax} ${valueString}`;
  }

  if (typeof paramValue === 'string' && paramValue.includes(',')) {
    const values = paramValue.split(',');
    let output = '';

    for (let i = 0; i < values.length - 1; i += 2) {
      const year = values[i];
      const value = isNaN(parseFloat(values[i + 1]))
        ? values[i + 1]
        : formatNumberWithCommas(parseFloat(values[i + 1]));

      output += `${label.replace(
        /\s\(\d{4}\)/,
        ''
      )} (${year}) ${minMax}: ${value}<br>`;
    }

    return output;
  }

  return '';
};

export const formatNumberWithCommas = (number: number): string => {
  return number.toLocaleString('en-US');
};

const reformatData = (data: string[]): string[] => {
  const aggregatedData: AggregatedData = {};

  const flattenedData = data.flatMap((item) =>
    item.split('<br>').filter(Boolean)
  );

  flattenedData.forEach((item) => {
    const matchComplex = item.match(
      /(.*?)(?:\s\((\d{4})\))?\s(Min|Max):\s(-?[\d,]+)/
    );

    const matchSimple = item.match(/(.*?)\s(Min|Max)\s(-?[\d,]+)/);

    let type, year, quantifier, value;

    if (matchComplex) {
      [, type, year, quantifier, value] = matchComplex;
    } else if (matchSimple) {
      [, type, quantifier, value] = matchSimple;
    } else {
      return;
    }

    const key = year ? `${type} (${year})` : type;

    if (!aggregatedData[key]) {
      aggregatedData[key] = {};
    }

    aggregatedData[key][quantifier as 'Min' | 'Max'] = value;
  });

  const result: string[] = [];
  for (const [type, values] of Object.entries(aggregatedData)) {
    if (values.Min && values.Max) {
      result.push(
        `<span class="label">${type}:</span> from ${values.Min} to ${values.Max}`
      );
    } else if (values.Min) {
      result.push(`<span class="label">${type}:</span> from ${values.Min}`);
    } else if (values.Max) {
      result.push(`<span class="label">${type}:</span> to ${values.Max}`);
    }
  }

  return result;
};

export const getModifiedLabelsFromURL = (
  url: string,
  fetchData: TReportFields[]
): string[] => {
  const queryParams = new URLSearchParams(new URL(url).search);
  const labels: string[] = [];
  //@ts-ignore
  for (const param of queryParams.keys()) {
    const paramValue = queryParams.get(param);
    //@ts-ignore
    const label = getLabelWithMinOrMax(param, fetchData, paramValue);

    if (label) {
      labels.push(label);
    }
  }

  return reformatData(labels);
};

export const renderItemsShortInfo = (
  keySettings: IKeySettings,
  items: TShortInfoItem[],
  label: string | null,
  unit: EReportUnit | string
) => {
  if (!items.length) {
    return null;
  }

  let includeExcludeText = '';

  if (unit === EReportUnit.FIRMS) {
    includeExcludeText =
      keySettings.firmInclude === 'true'
        ? ''
        : keySettings.firmInclude === 'false'
        ? i18n.t('exclude')
        : '';
  } else if (unit === EReportUnit.OFFICES) {
    includeExcludeText =
      keySettings.officeIdsInclude === 'true'
        ? ''
        : keySettings.officeIdsInclude === 'false'
        ? i18n.t('exclude')
        : '';
  } else if (unit === EReportUnit.BRANDS) {
    includeExcludeText =
      keySettings.brandInclude === 'true'
        ? ''
        : keySettings.brandInclude === 'false'
        ? i18n.t('exclude')
        : '';
  } else {
    includeExcludeText = '';
  }

  const itemNames = items.map((item) => item?.name).join(', ');

  return (
    <>
      {label && (
        <>
          <span className="label">{label}</span>
          {' - '}
        </>
      )}
      {includeExcludeText ? `${includeExcludeText} ` : ''}
      {itemNames}
    </>
  );
};
