import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomAutocomplete from '@/components/CustomElements/CustomAutocomplete';
import CustomSelect from '@/components/CustomElements/CustomSelect';
import { isPropertyIncluded } from '@/components/KeySettings/utils';
import useDebounce from '@/hooks/useDebounce';
import { AppDispatch } from '@/store';
import { ESchoolTypes } from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { getReport, resetReport } from '@/store/reportSlice';
import { ILabelItem, IListItem } from '@/store/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SelectChangeEvent,
} from '@mui/material';

const schoolTypes: Array<ILabelItem> = [
  { type: ESchoolTypes.SCHOOL_JUNIOR, name: 'School Middle' },
  { type: ESchoolTypes.SCHOOL_ELEM, name: 'School Elementary' },
  { type: ESchoolTypes.SCHOOL_HIGH, name: 'School High' },
];

const SchoolTypeSettings = () => {
  const { t } = useTranslation();
  const { school0List, school1List, school2List } = useSelector(getFilterLists);
  const { school, schoolType } = useSelector(getKeySettings);
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState(false);
  const [list, setList] = useState<Array<IListItem>>(school0List);
  const [schoolValue, setSchool] = useState<Array<string | number>>(school);
  const [schoolTypeValue, setSchoolType] = useState<string>(schoolType);

  const hasSchoolProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'school'
  );

  const updateSchoolType = (
    event: SelectChangeEvent<string | number | null>
  ) => {
    const { value } = event.target;

    if (value) {
      setSchoolType(value.toString());
    }

    dispatch(
      setKeySettings({
        key: 'schoolType',
        value: value,
      })
    );

    setSchool([]);
  };

  const updateSchool = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setSchool(value);
    } else {
      setSchool([]);
    }
  };

  useEffect(() => {
    if (schoolValue.length) {
      setSchool([]);
    }

    const getChoices = () => {
      switch (schoolType) {
        case ESchoolTypes.SCHOOL_JUNIOR:
          return setList(() => school0List);
        case ESchoolTypes.SCHOOL_ELEM:
          return setList(() => school1List);
        case ESchoolTypes.SCHOOL_HIGH:
          return setList(() => school2List);
        default:
          return [];
      }
    };

    getChoices();
    // dispatch(resetReport({ reset: true }));
    setSchoolType(schoolType);
  }, [schoolType, school0List, school1List, school2List]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'school',
        value: schoolValue,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [schoolValue]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      if (schoolValue.length) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [schoolValue]);

  useEffect(() => {
    setSchool(school);
  }, [school]);

  return (
    <Accordion
      expanded={expanded}
      sx={{ boxShadow: 'none', padding: 0, border: 'none', margin: 0 }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ boxShadow: 'none', padding: 0, minHeight: 1, margin: 0 }}
      >
        <div className="d-flex" style={{ alignItems: 'center', flex: 1 }}>
          <h3>{t('key_settings.school_type')}</h3> <div className="line" />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {hasSchoolProperty ? (
          <>
            <CustomSelect
              placeholder={t('select')}
              value={schoolTypeValue}
              choices={schoolTypes}
              fullWidth={true}
              setValue={updateSchoolType}
            />

            {list.length ? (
              <CustomAutocomplete
                placeholder={t('select')}
                value={schoolValue}
                choices={list}
                style={{ paddingTop: 10 }}
                setValue={updateSchool}
              />
            ) : null}
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default SchoolTypeSettings;
