import { EOrder, EReportTypes, ESchoolTypes, ETimeFrames } from '@/store/enums';

export interface IDictionary<Type> {
  [key: string]: Type;
}

export interface IKeySettings {
  mls: number;
  page: number;
  perPage: number;
  chartType: string;
  savedSearchList: Array<IUserSearchList>;
  savedSearch: IUserSearch | null;
  reportType: EReportTypes;
  reportName: string;
  reportUnit: string;
  timeFrame: ETimeFrames | string;
  startDate: string | null;
  endDate: string | null;
  firstDate: string | null;
  lastDate: string | null;
  priceOver: string | null;
  priceUnder: string | null;
  yearBuiltMin: number | null;
  yearBuiltMax: number | null;
  bedroomsMin: number | null;
  bedroomsMax: number | null;
  finishedSquareFootageMin: number | null;
  finishedSquareFootageMax: number | null;
  totalSquareFootageMin: number | null;
  totalSquareFootageMax: number | null;
  bathroomsFullMin: number | null;
  bathroomsFullMax: number | null;
  bathroomsHalfMin: number | null;
  bathroomsHalfMax: number | null;
  orderDirection: EOrder;
  orderBy: keyof TReportItem;
  secondaryOrderBy: keyof TReportItem;
  schoolType: ESchoolTypes;
  school: Array<number>;
  subdivision: Array<number>;
  area: string;
  officeArea: string;
  data: string;
  county: Array<number>;
  city: Array<number>;
  zipCode: Array<number>;
  type: Array<number>;
  district: Array<number>;
  propertyType: Array<number>;
  style: Array<number>;
  officeCounty: Array<number>;
  officeCountyInclude: string;
  officeCity: Array<number>;
  officeCityInclude: string;
  officeZip: Array<number>;
  officeZipInclude: string;
  agentIds: Array<number>;
  listingAgentIds: Array<number>;
  sellingAgentIds: Array<number>;
  officeIds: Array<number>;
  officeIdsInclude: string;
  firmIds: Array<number>;
  firmInclude: string;
  brandIds: Array<number>;
  brandInclude: string;
  firmsMin: string;
  firmsMax: string;
  officesMin: string;
  officesMax: string;
  agentsMin: string;
  agentsMax: string;
  registeredAgentsMin: string;
  registeredAgentsMax: string;
  listingUnitsMin: string;
  listingUnitsMax: string;
  listingUnitsPerAgentMin: string;
  listingUnitsPerAgentMax: string;
  listingUnitsPerRegisteredAgentMin: string;
  listingUnitsPerRegisteredAgentMax: string;
  sellingUnitsMin: string;
  sellingUnitsMax: string;
  sellingUnitsPerAgentMin: string;
  sellingUnitsPerAgentMax: string;
  sellingUnitsPerRegisteredAgentMin: string;
  sellingUnitsPerRegisteredAgentMax: string;
  totalUnitsMin: string;
  totalUnitsMax: string;
  totalUnitsPerAgentMin: string;
  totalUnitsPerAgentMax: string;
  totalUnitsPerRegisteredAgentMin: string;
  totalUnitsPerRegisteredAgentMax: string;
  listingVolumeMin: string;
  listingVolumeMax: string;
  listingVolumePerAgentMin: string;
  listingVolumePerAgentMax: string;
  listingVolumePerRegisteredAgentMin: string;
  listingVolumePerRegisteredAgentMax: string;
  sellingVolumeMin: string;
  sellingVolumeMax: string;
  sellingVolumePerAgentMin: string;
  sellingVolumePerAgentMax: string;
  sellingVolumePerRegisteredAgentMin: string;
  sellingVolumePerRegisteredAgentMax: string;
  totalVolumeMin: string;
  totalVolumeMax: string;
  totalVolumePerAgentMin: string;
  totalVolumePerAgentMax: string;
  totalVolumePerRegisteredAgentMin: string;
  totalVolumePerRegisteredAgentMax: string;
  avgPriceMin: string;
  avgPriceMax: string;
  avgRatioMin: string;
  avgRatioMax: string;
  avgDOMMin: string;
  avgDOMMax: string;
  previousUnitsMin: string;
  previousUnitsMax: string;
  currentUnitsMin: string;
  currentUnitsMax: string;
  growthUnitsMin: string;
  growthUnitsMax: string;
  previousVolumeMin: string;
  previousVolumeMax: string;
  currentVolumeMin: string;
  currentVolumeMax: string;
  growthVolumeMin: string;
  growthVolumeMax: string;
  previousAvgPriceMin: string;
  previousAvgPriceMax: string;
  currentAvgPriceMin: string;
  currentAvgPriceMax: string;
  growthAvgPriceMin: string;
  growthAvgPriceMax: string;
  previousAvgRatioMin: string;
  previousAvgRatioMax: string;
  currentAvgRatioMin: string;
  currentAvgRatioMax: string;
  growthAvgRatioMin: string;
  growthAvgRatioMax: string;
  previousAvgDOMMin: string;
  previousAvgDOMMax: string;
  currentAvgDOMMin: string;
  currentAvgDOMMax: string;
  growthAvgDOMMin: string;
  growthAvgDOMMax: string;
  unitPriceMin: string;
  unitPriceMax: string;
  unitRatioMin: string;
  unitRatioMax: string;
  unitDOMMin: string;
  unitDOMMax: string;
  closedUnitsMin: string;
  closedUnitsMax: string;
  closedVolumeMin: string;
  closedVolumeMax: string;
  pendingUnitsMin: string;
  pendingUnitsMax: string;
  pendingVolumeMin: string;
  pendingVolumeMax: string;
  expiredUnitsMin: string;
  expiredUnitsMax: string;
  expiredVolumeMin: string;
  expiredVolumeMax: string;
  withdrawnUnitsMin: string;
  withdrawnUnitsMax: string;
  withdrawnVolumeMin: string;
  withdrawnVolumeMax: string;
  percentageUnitsMin: string;
  percentageUnitsMax: string;
  percentageVolumeMin: string;
  percentageVolumeMax: string;
}

export interface IKeySettingsInitialState {
  keySettings: IKeySettings;
}

export interface IUser {
  username: string;
  password: string;
}

export interface IUserData {
  email: string;
  id: number;
  status: string;
}

export interface IProfile {
  username: string;
  email: string;
  id: number;
  status: string;
  firstName: string | null;
  lastName: string | null;
  logoSrc: string | null;
  phone: string | null;
  term: number | null;
  productName: string | null;
  firstDate: string | null;
  lastDate: string | null;
  acceptDate: string | null;
}

export interface IContact {
  email: string;
  phone: string | null;
}

export interface IPassword {
  pass: string;
  newPass: string;
}

export interface IUserSettings {
  defaultMlsDatabase?: number;
  defaultReport?: string;
  defaultGraphType?: string;
  defaultRowsPerPage?: number;
  showPdfReportBackground?: boolean;
  showPdfReportLogos?: boolean;
  currentColorScheme: string;
}

export interface TokenData {
  token: string;
  refresh_token: string;
}

export interface Login extends TokenData {
  data: IProfile;
}

export interface UserProfile {
  createdAt: string;
  email: string;
  name: string;
  number: number;
  password: string;
  role: string;
  updatedAt: string;
  _id: string;
}

export interface IMls {
  name: string;
  year: boolean;
  area: boolean;
  map: boolean;
  dateType: string | null;
  street: boolean;
  legalName: string | null;
  regionID: number;
  firstMonth: string | null;
  lastMonth: string | null;
  lastUpdate: string | null;
  registered: boolean;
  rosterUpdate: string | null;
  shortForm: boolean;
  cleaning: boolean;
  dataName: string | null;
  active: boolean;
  extraCriteria: boolean;
  rets: string | null;
  latitude: number;
  longitude: number;
  mapOffice: boolean;
  geoCode: boolean;
  subdivision: string | null;
}

export interface IListItem {
  id: number | string;
  name: string;
}

export interface ILabelItem {
  type: string;
  name: string;
}

export interface ITimeFrameFilters {
  firstDate: string;
  lastDate: string;
}

export interface ModalProps {
  open: boolean;
  closeHandler: () => void;
}

export interface ReportTableData {
  id: number;
  name: string;
  brand: string;
  firm: string;
  address: string;
  listUnits: number;
  soldUnits: number;
  total: number;
}

export interface HeadCell {
  id: keyof ReportTableData;
  label: string;
  numeric: boolean;
  visible?: boolean;
}

export type TTotals = {
  avgDOM: number;
  avgPrice: number;
  avgRatio: number;
  listingUnit: number;
  listingUnitMarketShare: number;
  listingVolume: number;
  listingVolumeMarketShare: number;
  sellingUnit: number;
  sellingUnitMarketShare: number;
  sellingVolume: number;
  sellingVolumeMarketShare: number;
  totalUnit: number;
  totalUnitMarketShare: number;
  totalVolume: number;
  totalVolumeMarketShare: number;
};

export type TShortInfo = {
  agents: TShortInfoItem[];
  offices: TShortInfoItem[];
  firms: TShortInfoItem[];
  brands: TShortInfoItem[];
};

export type TShortInfoItem = {
  id: number;
  name: string;
};

export type TMapTotals = {
  id: number;
  firmId: number;
  brandId: number;
  longitude: number;
  latitude: number;
  agents: number;
  officeName: string;
  officeAddress: string;
  firmName: string;
  brandName: string;
};

export type TMapPerformanceTotals = {
  listType: string;
  agentId: number;
  agentName: string;
  officeId: number;
  officeName: string;
  officeAddress: string;
  firmId: number;
  firmName: string;
  brandId: number;
  brandName: string;
  mls: string;
  unitAddress: string;
  unitPrice: number;
  unitRatio: number;
  unitDOM: number;
  className: string;
  closedDate: string;
  longitude: number;
  latitude: number;
  count: number | null;
};

export type TReportItem = {
  agentEmail: string;
  agentId: number;
  officeId: number;
  firmId: number;
  brandId: number;
  id: number;
  mls: string;
  agentName: string;
  agentPhone1: string;
  agentPhone2: string;
  avgDOM: number;
  avgPrice: number;
  avgRatio: number | string;
  brandName: string;
  firmName: string;
  fractionOfTotalMLSUnits: number | string;
  fractionOfTotalMLSVolume: number | string;
  listingUnit: number;
  listingUnitMarketShare: number;
  listingVolume: number;
  listingVolumeMarketShare: number;
  officeAddress: string;
  officeCity: string;
  officeName: string;
  officePhone: string;
  officeZip: string;
  rankU: number;
  rankV: number;
  percentRankU: number | string;
  percentRankV: number | string;
  sellingUnit: number;
  sellingUnitMarketShare: number;
  sellingVolume: number;
  sellingVolumeMarketShare: number;
  totalUnit: number;
  totalUnitMarketShare: number;
  totalVolume: number;
  totalVolumeMarketShare: number;
  previousUnit: number;
  currentUnit: number;
  growthUnit: number;
  previousVolume: number;
  currentVolume: number;
  growthVolume: number;
  previousAvgPrice: number;
  currentAvgPrice: number;
  growthAvgPrice: number;
  previousAvgRatio: number;
  currentAvgRatio: number;
  growthAvgRatio: number;
  previousAvgDOM: number;
  currentAvgDOM: number;
  growthAvgDOM: number;
};

export interface IReportData {
  items: Array<TReportItem>;
  totals: TTotals;
  graphType: EGraphTypes;
  orderDirection: EOrder;
  reportType: EReportTypes;
  members: Array<TReportMembers>;
  orderBy: string;
  secondaryOrderBy: string;
  data: string;
  chartType: string;
  timePeriod: string;
}

export interface TReportMembers {
  agentName: string;
  brandName: string;
  firmName: string;
  officeName: string;
  officeAddress: string;
}

export interface IAvailableReportItem {
  name: string;
  key: string;
  unit: string;
  child: IAvailableReportItem[];
  changeReportDates: boolean;
}

export interface IAvailableReports {
  [category: string]: IAvailableReportItem[] | undefined;
}

export interface TReportTotalItems {
  listingEndItems: number;
  doubleEndItems: number;
  sellingEndItems: number;
  totalItems: number;
  totalUnitItems: number;
}

export enum EGraphTypes {
  STACKED = 'stacked',
  GROUPED = 'grouped',
  SIMPLE = 'simple',
  MULTIPLE = 'multiple',
  DUAL_SIMPLE = 'dual-simple',
  INVENTORY = 'inventory',
}

export enum EChartTypes {
  COLUMN = 'column',
  LINE = 'line',
  COLUMN_COMPACT_VIEW = 'columnCompactView',
}

export interface IGraphItem {
  officeName: string;
  officeAddress: string;
  seriesOne: number | null;
  seriesTwo: number | null;
  seriesThree: number | null;
}

export interface IGraphMarketTrendsItem {
  year: string;
  month: string;
  week: string;
  seriesOne: number | null;
  seriesTwo: number | null;
  seriesThree: number | null;
  seriesFour: number | null;
  seriesFive: number | null;
}

export interface IGraphMarketTrendsList {
  value: string;
  label: string;
  title: string;
  toolText: string;
}

export interface IExportMeta {
  type: boolean;
  bold: boolean;
  horizontal: string;
  vertical: string;
}

export type TReportFields = {
  id: string;
  label: string;
  type: string;
  meta: {
    xlsx: IExportMeta;
    pdf: IExportMeta;
    web: IExportMeta;
  };
};

export type TBrandInfo = {
  id: number;
  brandName: string;
};

export type TFirmInfo = {
  id: number;
  firmName: string;
};

export type TOfficeInfo = {
  id: number;
  officeName: string;
};

export type TSearchTransactionList = {
  count: number;
  longitude: string;
  latitude: string;
};

export interface IUserSearchList {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserSearch {
  id: number;
  name: string;
  data?: { [key: string]: string | number };
  createdAt: string;
  updatedAt: string;
}

export interface RowWithButton
  extends Record<string, string | number | JSX.Element> {
  [key: string]: string | number | JSX.Element;
}
