import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { AppDispatch } from '@/store';
import {
  deleteSavedSearch,
  getSavedSearchById,
  getSavedSearchState,
  setSavedSearch,
} from '@/store/keySettingsSlice';
import { IUserSearch } from '@/store/types';
import { IconButton, Menu, MenuItem } from '@mui/material';

import './SearchItem.scss';

const SearchItem = (props: {
  item: IUserSearch;
  handleOpenModal: () => void;
  handleCloseMenu: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const savedSearch = useSelector(getSavedSearchState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const selectElement = (item: IUserSearch) => {
    dispatch(setSavedSearch({ value: item }));
    dispatch(getSavedSearchById(item.id));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setAnchorEl(null);
    props.handleCloseMenu();
    props.handleOpenModal();
  };

  const handleDelete = (id: number) => {
    setAnchorEl(null);

    dispatch(deleteSavedSearch(id));
  };

  const { item } = props;

  return (
    <MenuItem className="menu-item" style={{ padding: 0 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          className={`${
            item?.id === savedSearch?.id ? 'font-weight-bold' : ''
          }`}
          style={{
            width: '100%',
            padding: '10px 0 10px 16px',
            maxWidth: '170px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onClick={() => {
            item?.id !== savedSearch?.id
              ? selectElement(item)
              : props.handleCloseMenu();
          }}
          onTouchStart={() => {
            item?.id !== savedSearch?.id
              ? selectElement(item)
              : props.handleCloseMenu();
          }}
        >
          {item.name}
        </div>
        <IconButton
          onClick={handleClick}
          onTouchStart={handleClick}
          style={{ marginRight: '16px' }}
        >
          <SpriteIcon icon="more_vert" />
        </IconButton>
        <Menu
          aria-labelledby="saved-search-select"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="w-100"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {item?.id === savedSearch?.id && (
            <MenuItem
              className="menu-item"
              onClick={handleRename}
              onTouchStart={handleRename}
            >
              {t('key_settings.modify_search')}
            </MenuItem>
          )}
          <MenuItem
            className="menu-item"
            onClick={() => handleDelete(item.id)}
            onTouchStart={() => handleDelete(item.id)}
          >
            {t('key_settings.delete_search')}
          </MenuItem>
        </Menu>
      </div>
    </MenuItem>
  );
};

export default SearchItem;
