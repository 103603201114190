import { apiClient } from '@/ApiClient';
import { AppDispatch } from '@/store/index';
import { IListItem, IMls } from '@/store/types';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  mls: {
    list: Array<IListItem>;
    current: IMls;
  };
}

export const mlsSlice = createSlice({
  name: 'mls',
  initialState: {
    list: [],
    current: null,
  },
  reducers: {
    setMlsList: (state, action) =>
      Object.assign(state || {}, {
        list: action.payload,
      }),
    setCurrentMls: (state, action) =>
      Object.assign(state || {}, {
        current: action.payload,
      }),
  },
});

export const { setMlsList, setCurrentMls } = mlsSlice.actions;

export const getMlsList = (state: InitialState) => state.mls.list;
export const getCurrentMls = (state: InitialState) => state.mls.current;

export default mlsSlice.reducer;

export const loadMlsList = (dispatch: AppDispatch) => {
  return apiClient
    .mlsList()
    .then((data) => {
      dispatch(setMlsList(data));

      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const loadCurrentMls = (id: number) => (dispatch: AppDispatch) => {
  return apiClient
    .mlsInfo(id)
    .then((data) => {
      dispatch(setCurrentMls(data));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
