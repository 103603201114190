import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW } from '@/components/ReportChart/utils';
import StyledSelect from '@/mui-styled-components/select';
import { EChartTypes } from '@/store/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface ChartTypeSelectProps {
  chartType: string;
  showColumnValues: boolean;
  handleChartType: (event: SelectChangeEvent<string | undefined>) => void;
  items: any[];
}

const chartTypeOptions = [
  { value: EChartTypes.LINE, label: 'chart.line' },
  { value: EChartTypes.COLUMN, label: 'chart.column' },
  {
    value: EChartTypes.COLUMN_COMPACT_VIEW,
    label: 'chart.column_compact_view',
  },
];

const ChartTypeSelect: React.FC<ChartTypeSelectProps> = ({
  chartType,
  showColumnValues,
  handleChartType,
  items,
}) => {
  const isColumnCompactViewDisabled =
    items.length <= QTY_ITEMS_TO_SHOW_COLUMN_COMPACT_VIEW;
  const { t } = useTranslation();

  return (
    <FormControl>
      <InputLabel
        id="chartType-select-label"
        sx={{
          '&.Mui-focused': {
            color: `var(--blue-color)`,
          },
        }}
      >
        <Trans i18nKey="chart.chart_type"></Trans>
      </InputLabel>
      <StyledSelect
        labelId="chartType-select-label"
        value={showColumnValues ? EChartTypes.COLUMN_COMPACT_VIEW : chartType}
        //@ts-ignore
        onChange={handleChartType}
        label={t('chart.chart_type')}
      >
        {chartTypeOptions.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            disabled={
              item.value === EChartTypes.COLUMN_COMPACT_VIEW &&
              isColumnCompactViewDisabled
            }
          >
            <Trans i18nKey={item.label}></Trans>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default ChartTypeSelect;
