import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getModifiedLabelsFromURL,
  renderItemsShortInfo,
} from '@/components/KeyReports/utils';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { EReportUnit } from '@/store/enums';
import { getKeySettings } from '@/store/keySettingsSlice';
import { getConfig } from '@/store/reportFieldsSlice';
import { getReport } from '@/store/reportSlice';
import { IKeySettings, TShortInfoItem } from '@/store/types';
import { IconButton, Popover } from '@mui/material';

import './AppliedSettings.scss';
import '../SpriteIcon/SpriteIcon.scss';

const ShortInfo = () => {
  const { shortInfo } = useSelector(getReport);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const reportFields = useSelector(getConfig);
  const keySettings = useSelector(getKeySettings);

  const isOpened = Boolean(anchorEl);

  const handleOpen = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const appliedShortInfo = useMemo(
    () =>
      [
        shortInfo?.agents?.length || 0,
        shortInfo?.offices?.length || 0,
        shortInfo?.firms?.length || 0,
        shortInfo?.brands?.length || 0,
      ].reduce((total, count) => total + count, 0),
    [shortInfo]
  );

  const url = window.location.href;
  const labelsWithMinOrMax = useMemo(
    () => getModifiedLabelsFromURL(url, reportFields),
    [url, reportFields]
  );

  const isNotEmpty = appliedShortInfo > 0 || labelsWithMinOrMax.length > 0;

  return isNotEmpty ? (
    <span className="count">
      <Trans
        i18nKey="reports.applied_column_filters"
        count={appliedShortInfo + labelsWithMinOrMax.length}
        components={{ span: <span /> }}
      ></Trans>{' '}
      <IconButton
        className="info-button"
        aria-owns={isOpened ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        aria-label={t('buttons.info')}
        aria-describedby="mouse-over-popover"
        onMouseEnter={handleOpen}
        onTouchStart={handleOpen}
        onMouseLeave={handleClose}
        onTouchEnd={handleClose}
        disableFocusRipple
        disableRipple
      >
        <SpriteIcon icon="info" className="info" />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="info-popper"
      >
        <div className="info-content">
          <p>
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.agents || [],
              'Agent',
              'agents'
            )}
          </p>
          <p>
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.offices || [],
              'Office',
              'offices'
            )}
          </p>
          <p>
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.firms || [],
              'Firm',
              'firms'
            )}
          </p>
          <p>
            {renderItemsShortInfo(
              keySettings,
              shortInfo?.brands || [],
              'Brand',
              'brands'
            )}
          </p>
          {labelsWithMinOrMax.map((item, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </div>
      </Popover>
    </span>
  ) : null;
};

export default ShortInfo;
