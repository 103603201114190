import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';

import {
  base64ToJson,
  BLUE_COLOR,
  CIRCLE_TYPE,
  FILL_OPACITY,
  POLYGON_TYPE,
  PURPLE_COLOR,
  RECTANGLE_TYPE,
  STROKE_WEIGHT,
} from '@/components/MapSearch/utils';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import { Box } from '@mui/material';

import './Map.scss';

let mapView: GoogleMapReact | null = null;

const Map: React.FC = () => {
  const { t } = useTranslation();
  const [mapReady, setMapReady] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const urlArea = urlParams.get('area');
  const urlOfficeArea = urlParams.get('officeArea');

  const parseArea = (urlArea: string) => {
    const area = base64ToJson(urlArea);

    if ([CIRCLE_TYPE, POLYGON_TYPE, RECTANGLE_TYPE].includes(area.type)) {
      return area;
    }

    return null;
  };

  const area = urlArea ? parseArea(urlArea) : null;
  const officeArea = urlOfficeArea ? parseArea(urlOfficeArea) : null;

  const onMapLoaded = () => {
    if (!mapReady) {
      setMapReady(true);
    }
  };

  const draw = (
    map: google.maps.Map,
    area: any,
    office: boolean
  ): google.maps.LatLngBounds | null => {
    let bounds = null;
    let fillColor;
    let zIndex;

    if (office) {
      fillColor = BLUE_COLOR;
      zIndex = 2;
    } else {
      fillColor = 'red';
      zIndex = 1;
    }

    if (area.type === CIRCLE_TYPE) {
      const circle = new google.maps.Circle({
        fillColor: fillColor,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        zIndex: zIndex,
        map,
        center: { lat: area.center[0], lng: area.center[1] },
        radius: area.radius,
      });
      bounds = circle.getBounds();
    } else if (area.type === POLYGON_TYPE) {
      new google.maps.Polygon({
        fillColor: fillColor,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        zIndex: zIndex,
        map,
        paths: area.path.map((coord: number[]) => {
          return { lat: coord[0], lng: coord[1] };
        }),
      });

      bounds = new google.maps.LatLngBounds();

      for (const coord of area.path) {
        bounds.extend({ lat: coord[0], lng: coord[1] });
      }
    } else if (area.type === RECTANGLE_TYPE) {
      const rectangle = new google.maps.Rectangle({
        fillColor: fillColor,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        zIndex: zIndex,
        map,
        bounds: {
          north: area.ne[0],
          south: area.sw[0],
          east: area.ne[1],
          west: area.sw[1],
        },
      });
      bounds = rectangle.getBounds();
    }

    return bounds;
  };

  const handleApiLoaded = (map: google.maps.Map) => {
    let areaBounds = null;
    let officeAreaBounds = null;

    if (area) {
      areaBounds = draw(map, area, false);
    }

    if (officeArea) {
      officeAreaBounds = draw(map, officeArea, true);
    }

    if (areaBounds && officeAreaBounds) {
      map.fitBounds(areaBounds.union(officeAreaBounds));
    } else if (areaBounds) {
      map.fitBounds(areaBounds);
    } else if (officeAreaBounds) {
      map.fitBounds(officeAreaBounds);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        margin: '10px',
        backgroundColor: '#fff',
        padding: 0,
      }}
      data-report-height
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 10px 0 10px',
        }}
      >
        {!area && !officeArea ? (
          <p>{t('map.missing')}</p>
        ) : (
          <>
            {!mapReady && (
              <ProgressLoader
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  zIndex: '1',
                  top: '0',
                  left: '0',
                  padding: '0',
                }}
              />
            )}
            <GoogleMapReact
              ref={(ref) => (mapView = ref)}
              bootstrapURLKeys={{
                key: 'AIzaSyCGkrUhMmZFcuL4f-Z15klnZumEIxdmwCY',
                libraries: ['geometry', 'drawing', 'places'],
              }}
              defaultCenter={{
                lat: 43.70660295607536,
                lng: -79.38770419682047,
              }}
              defaultZoom={10}
              onTilesLoaded={onMapLoaded}
              options={{
                clickableIcons: false,
                controlSize: 30,
                disableDoubleClickZoom: true,
                gestureHandling: 'greedy',
                keyboardShortcuts: false,
                panControl: false,
                scrollwheel: true,
                zoomControl: true,
              }}
              shouldUnregisterMapOnUnmount
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map }) => {
                handleApiLoaded(map);
              }}
            />
            <div>
              <strong
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '10px 0 10px',
                }}
              >
                Legend:{' '}
                <span
                  style={{
                    color: 'red',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Geography Area
                </span>{' '}
                <span
                  style={{
                    color: BLUE_COLOR,
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  Office Area
                </span>{' '}
                <span
                  style={{
                    color: PURPLE_COLOR,
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  Both Geography and Office Area
                </span>
              </strong>
            </div>
          </>
        )}
      </div>
    </Box>
  );
};

export default Map;
