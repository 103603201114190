export enum ROUTE {
  ACCOUNT = '/account',
  SETTINGS = '/settings',
  MAIN = '/',
  TOKEN_LOGIN = '/token-login',
  KEY_REPORTS = '/key-reports',
  SUMMARY = '/summary',
  MARKET_TRENDS = '/market-trends',
  KPM_ANALYSER = '/kpm-analyser',
  HELP = '/help',
  BOOKINGS = '/bookings',
  HOTSHEETS = '/hotsheets',
  MAP = '/map',
}
