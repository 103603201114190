import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { maxNumber, onlyNumbersWithOneComma } from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';

import './common.scss';

const listingUnitsPerRegisteredAgentValidationSchema = Yup.object().shape({
  listingUnitsPerRegisteredAgentMin: Yup.string()
    .nullable()
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
  listingUnitsPerRegisteredAgentMax: Yup.string()
    .nullable()
    .when('listingUnitsPerRegisteredAgentMin', {
      is: (max: string) =>
        max && parseFloat(max.toString().replace(/,/g, '')) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanListingUnitsPerRegisteredAgentMin',
        message: '',
        test() {
          const {
            listingUnitsPerRegisteredAgentMin,
            listingUnitsPerRegisteredAgentMax,
          } = this.parent;

          return listingUnitsPerRegisteredAgentMax !== ''
            ? parseFloat(
                listingUnitsPerRegisteredAgentMax.toString().replace(/,/g, '')
              ) >=
                parseFloat(
                  listingUnitsPerRegisteredAgentMin.toString().replace(/,/g, '')
                )
            : true;
        },
      }),
    })
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
});

const ListingUnitPerRegisteredAgentFilter = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    listingUnitsPerRegisteredAgentMin,
    setListingUnitsPerRegisteredAgentMin,
  ] = useState<string>('');
  const [
    listingUnitsPerRegisteredAgentMax,
    setListingUnitsPerRegisteredAgentMax,
  ] = useState<string>('');
  const open = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(listingUnitsPerRegisteredAgentValidationSchema),
  });

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = async () => {
    setListingUnitsPerRegisteredAgentMin('');
    setListingUnitsPerRegisteredAgentMax('');
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerRegisteredAgentMin',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerRegisteredAgentMax',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const applyFilter = async () => {
    handleClose();
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerRegisteredAgentMin',
        value: listingUnitsPerRegisteredAgentMin.replace(/,/g, ''),
      })
    );

    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerRegisteredAgentMax',
        value: listingUnitsPerRegisteredAgentMax.replace(/,/g, ''),
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const handleListingUnitsPerRegisteredAgentMin = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setListingUnitsPerRegisteredAgentMin(e.target.value);
      setValue('listingUnitsPerRegisteredAgentMin', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const handleListingUnitsPerRegisteredAgentMax = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setListingUnitsPerRegisteredAgentMax(e.target.value);
      setValue('listingUnitsPerRegisteredAgentMax', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const icon =
    keySettings.listingUnitsPerRegisteredAgentMin !== '' ||
    keySettings.listingUnitsPerRegisteredAgentMax !== ''
      ? 'filter'
      : 'search';
  const tooltipText = `${
    keySettings.listingUnitsPerRegisteredAgentMin !== ''
      ? t('from_selected', {
          quantity: keySettings.listingUnitsPerRegisteredAgentMin,
        })
      : ''
  }${' '}${
    keySettings.listingUnitsPerRegisteredAgentMax !== ''
      ? t('to_selected', {
          quantity: keySettings.listingUnitsPerRegisteredAgentMax,
        })
      : ''
  }${' '}${t('click_clear')}`;

  return (
    <div className="column-search-menu-wrap">
      <div
        onClick={(e) => {
          keySettings.listingUnitsPerRegisteredAgentMin !== '' ||
          keySettings.listingUnitsPerRegisteredAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
        onTouchStart={(e) => {
          keySettings.listingUnitsPerRegisteredAgentMin !== '' ||
          keySettings.listingUnitsPerRegisteredAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
      >
        {keySettings.listingUnitsPerRegisteredAgentMin !== '' ||
        keySettings.listingUnitsPerRegisteredAgentMax !== '' ? (
          <Tooltip title={tooltipText}>
            <IconButton aria-label="search" className="searchBtn">
              <SpriteIcon icon={icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
        )}
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 filter-block-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className="d-flex">
          <div className="d-flex flex-column">
            <label
              className="form-label"
              htmlFor="listingUnitsPerRegisteredAgentMin"
            >
              {t('min')}
            </label>
            <StyledTextField
              id="listingUnitsPerRegisteredAgentMin"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingUnitsPerRegisteredAgentMin}
              variant="outlined"
              sx={{ paddingLeft: '0', paddingRight: '6px', width: '64px' }}
              error={!!errors.listingUnitsPerRegisteredAgentMin}
              //@ts-ignore
              helperText={errors?.listingUnitsPerRegisteredAgentMin?.message}
              {...register('listingUnitsPerRegisteredAgentMin')}
              onChange={handleListingUnitsPerRegisteredAgentMin}
            />
          </div>
          <div className="d-flex flex-column">
            <label
              style={{ paddingLeft: '6px' }}
              className="form-label"
              htmlFor="listingUnitsPerRegisteredAgentMax"
            >
              {t('max')}
            </label>
            <StyledTextField
              id="listingUnitsPerRegisteredAgentMax"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingUnitsPerRegisteredAgentMax}
              variant="outlined"
              sx={{ paddingLeft: '6px', paddingRight: '0', width: '64px' }}
              error={!!errors.listingUnitsPerRegisteredAgentMax}
              //@ts-ignore
              helperText={errors?.listingUnitsPerRegisteredAgentMax?.message}
              {...register('listingUnitsPerRegisteredAgentMax')}
              onChange={handleListingUnitsPerRegisteredAgentMax}
            />
          </div>
        </form>
        <div
          className="filter-footer"
          style={{ padding: 0, marginTop: '10px' }}
        >
          <Button
            type="submit"
            style={{ width: '100%' }}
            className="filled-btn"
            disabled={
              listingUnitsPerRegisteredAgentMin === '' &&
              listingUnitsPerRegisteredAgentMax === ''
            }
            onClick={handleSubmit(applyFilter)}
            onTouchStart={handleSubmit(applyFilter)}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default ListingUnitPerRegisteredAgentFilter;
