import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducer';

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [applyMiddleware(thunkMiddleware)],
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
