import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import FreeDrawingImage from '@/assets/images/free_drawing.png';
import { optimizePolygon } from '@/components/MapSearch/optimizePolygon';
import i18n from '@/i18n';
import StyledCheckbox from '@/mui-styled-components/checkbox';
import { AppDispatch } from '@/store';
import { EReportName } from '@/store/enums';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import { loadReport } from '@/store/reportSlice';
import { ModalProps } from '@/store/types';
import { Button, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import {
  base64ToJson,
  BLUE_COLOR,
  CIRCLE_TYPE,
  circleToValue,
  FILL_OPACITY,
  getCenterByMapType,
  jsonToBase64,
  keysToResetAll,
  keysToResetBySearchType,
  OverlayType,
  POLYGON_TYPE,
  polygonToValue,
  RECTANGLE_TYPE,
  rectangleToValue,
  resetKeys,
  SEARCH_TYPE_AREA,
  SEARCH_TYPE_OFFICE_AREA,
  STROKE_WEIGHT,
} from './utils';

import './MapSearch.scss';

const style = {
  position: 'absolute' as const,
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 970,
  bgcolor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};

let mapView: google.maps.Map | any;
let polygonControllers: google.maps.Polygon;
let drawingManager: google.maps.drawing.DrawingManager;
let all_overlays: OverlayType[] = [];
let selectedFreeHand = false;

const MapSearch = (props: ModalProps & { searchType: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currMls = useSelector(getCurrentMls);
  const dateKey =
    currMls.dateType === 'Sold Date' ? 'sold_date' : 'closed_date';
  const { area, officeArea, startDate, endDate, reportName } =
    useSelector(getKeySettings);
  const { t } = useTranslation();
  const [isDrawable, setIsDrawable] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [polygonCoordinates, setPolygonCoordinates] = useState<
    string | string[]
  >([]);
  const [allOverlays, setAllOverlays] = useState<OverlayType[]>([]);
  const [value, setValue] = useState<string>('');
  const [also, setAlso] = useState<boolean>(false);

  useEffect(() => {
    if (value && props.open && mapReady && !isDrawable) {
      const message =
        props.searchType === SEARCH_TYPE_AREA
          ? t('map_search.info_geography')
          : props.searchType === SEARCH_TYPE_OFFICE_AREA
          ? t('map_search.info_only_offices')
          : '';

      if (message) {
        toast.info(message);
      }
    }
  }, [value]);

  useEffect(() => {
    setAlso(area === officeArea && area !== '' && officeArea !== '');
  }, [area, officeArea]);

  const onMapLoaded = () => {
    if (!mapReady) {
      setMapReady(true);
    }
  };

  const initializeFigures = (map: google.maps.Map) => {
    const coordinates =
      props.searchType === SEARCH_TYPE_AREA
        ? base64ToJson(area)
        : props.searchType === SEARCH_TYPE_OFFICE_AREA
        ? base64ToJson(officeArea)
        : null;

    const initCircle = () => {
      const circle = new mapView.maps_.Circle({
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        editable: true,
        zIndex: 1,
        map: map,
        center: new mapView.maps_.LatLng(
          coordinates.center[0],
          coordinates.center[1]
        ),
        radius: coordinates.radius,
      });
      all_overlays.push({ type: CIRCLE_TYPE, overlay: circle });
      setAllOverlays(all_overlays);
      setValue(circleToValue(circle));
      circle.addListener('center_changed', () =>
        setValue(circleToValue(circle))
      );
      circle.addListener('radius_changed', () =>
        setValue(circleToValue(circle))
      );
    };

    const initRectangle = () => {
      const rectangle = new mapView.maps_.Rectangle({
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        editable: true,
        zIndex: 1,
        map: map,
        bounds: {
          north: coordinates.ne[0],
          south: coordinates.sw[0],
          east: coordinates.ne[1],
          west: coordinates.sw[1],
        },
      });
      all_overlays.push({ type: RECTANGLE_TYPE, overlay: rectangle });
      setAllOverlays(all_overlays);
      setValue(rectangleToValue(rectangle));
      rectangle.addListener('bounds_changed', () => {
        setValue(rectangleToValue(rectangle));
      });
    };

    const initPolygon = () => {
      const polygon = new mapView.maps_.Polygon({
        clickable: false,
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        geodesic: true,
        map: map,
        path: coordinates.path.map((el: number[]) => {
          return { lat: el[0], lng: el[1] };
        }),
      });
      all_overlays.push({ type: POLYGON_TYPE, overlay: polygon });
      setAllOverlays(all_overlays);
      setValue(polygonToValue(polygon));
      polygon.addListener('mouseup', () => setValue(polygonToValue(polygon)));
    };

    if (coordinates?.type === CIRCLE_TYPE) {
      initCircle();
    }

    if (coordinates?.type === RECTANGLE_TYPE) {
      initRectangle();
    }

    if (coordinates?.type === POLYGON_TYPE) {
      initPolygon();
    }
  };

  const drawFreeHand = () => {
    if (selectedFreeHand) return;
    selectedFreeHand = true;
    const poly = new mapView.maps_.Polyline({
      clickable: false,
      map: mapView.map_,
      strokeColor: '#42A5F5',
      strokeWeight: 3,
    });

    const move = mapView.maps_.event.addListener(
      mapView.map_,
      'mousemove',
      (e: google.maps.MapMouseEvent) => {
        poly.getPath().push(e.latLng);
      }
    );

    const removeListeners = () => {
      mapView.maps_.event.removeListener(move);
      mapView.maps_.event.clearListeners(mapView.googleMapDom_, 'mousedown');
      selectedFreeHand = false;
    };

    mapView.maps_.event.addListenerOnce(mapView.map_, 'mouseup', () => {
      removeListeners();
      const path = poly.getPath();
      poly.setMap(null);
      const polygon = new mapView.maps_.Polygon({
        clickable: false,
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        geodesic: true,
        map: mapView.map_,
        path,
      });
      polyComplete(polygon);
    });

    mapView.maps_.event.addListener(
      mapView.googleMapDom_,
      'mousedown',
      removeListeners
    );
  };

  const polyComplete = (poly: google.maps.Polygon) => {
    polygonControllers = poly;
    let bounds = '';
    const paths = poly.getPaths();

    for (const path of paths.getArray()) {
      const ar = path.getArray();

      for (let i = 0; i < ar.length; i++) {
        bounds += `[${ar[i].lat()}, ${ar[i].lng()}],`;
      }

      if (ar[0]) bounds += `[${ar[0].lat()}, ${ar[0].lng()}]`;
    }

    setPolygonCoordinates([bounds]);
    handleDrawable();
  };

  const enableDrawableHelper = () => {
    if (mapView) {
      mapView.maps_.event.clearListeners(mapView.googleMapDom_, 'mousedown');
      mapView.maps_.event.addDomListener(
        mapView.googleMapDom_,
        'mousedown',
        drawFreeHand
      );
    }
  };

  const handleOptimizePolygon = () => {
    const polygonValue = JSON.parse('[' + polygonCoordinates + ']');

    if (polygonValue.length > 0) {
      const optimizedPolygonValue = optimizePolygon(polygonValue);

      if (optimizedPolygonValue.length <= 150) {
        setValue(
          jsonToBase64({
            type: POLYGON_TYPE,
            path: optimizedPolygonValue,
          })
        );
      } else {
        deleteAllShape();
        toast.error(t('messages.qty_points_on_map_error'));
      }
    }
  };

  useEffect(() => {
    if (isDrawable) {
      deleteAllShape();
      handleResetDrawEvent();
      enableDrawableHelper();
    } else {
      handleOptimizePolygon();
    }
  }, [isDrawable]);

  const handleDrawable = () => {
    setIsDrawable(!isDrawable);

    for (let i = 0; i < all_overlays?.length; i++) {
      all_overlays[i].overlay.setMap(null);
    }

    setAllOverlays([]);
  };

  const handleResetDrawEvent = () => {
    setPolygonCoordinates([]);

    if (polygonControllers) {
      polygonControllers.setMap(null);
    }

    mapView.maps_.event.addDomListener(
      mapView.googleMapDom_,
      'mousedown',
      drawFreeHand
    );
  };

  const handleClearMap = () => {
    setPolygonCoordinates([]);
    if (polygonControllers) {
      polygonControllers.setMap(null);
    }
  };

  const deleteAllShape = () => {
    for (let i = 0; i < all_overlays?.length; i++) {
      all_overlays[i].overlay.setMap(null);
    }

    setAllOverlays([]);
    handleClearMap();
    setValue('');
  };

  const handleGoogleMapApi = (map: google.maps.Map, google: any) => {
    drawingManager = new google.drawing.DrawingManager({
      drawingMode: '',
      drawingControl: true,
      drawingControlOptions: {
        position: google.ControlPosition.RIGHT,
        drawingModes: [
          google.drawing.OverlayType.CIRCLE,
          google.drawing.OverlayType.POLYGON,
          google.drawing.OverlayType.RECTANGLE,
        ],
      },
      circleOptions: {
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
      rectangleOptions: {
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
      polygonOptions: {
        fillColor: BLUE_COLOR,
        fillOpacity: FILL_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });

    drawingManager.setMap(map);

    initializeFigures(map);

    let selectedShape:
      | google.maps.Circle
      | google.maps.Rectangle
      | google.maps.Polygon
      | null;

    const clearSelection = () => {
      if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
      }
    };

    const setSelection = (
      shape: google.maps.Circle | google.maps.Rectangle | google.maps.Polygon
    ) => {
      clearSelection();
      selectedShape = shape;
      shape.setEditable(true);
    };

    google.event.addListener(drawingManager, 'drawingmode_changed', () => {
      handleClearMap();

      if (drawingManager.getDrawingMode() != null) {
        for (let i = 0; i < all_overlays?.length; i++) {
          deleteAllShape();
        }

        setAllOverlays([]);
      }
    });

    google.event.addListener(
      drawingManager,
      'overlaycomplete',
      function (e: any) {
        all_overlays = allOverlays;

        if (Array.isArray(all_overlays)) {
          all_overlays.push(e);
        }

        setAllOverlays(all_overlays);

        if (e.type === google.drawing.OverlayType.CIRCLE) {
          setValue(circleToValue(e.overlay));
          e.overlay.addListener('center_changed', () =>
            setValue(circleToValue(e.overlay))
          );
          e.overlay.addListener('radius_changed', () =>
            setValue(circleToValue(e.overlay))
          );
        }

        if (e.type === google.drawing.OverlayType.RECTANGLE) {
          setValue(rectangleToValue(e.overlay));
          e.overlay.addListener('bounds_changed', () => {
            setValue(rectangleToValue(e.overlay));
          });
        }

        if (e.type === google.drawing.OverlayType.POLYGON) {
          setValue(polygonToValue(e.overlay));
          e.overlay.addListener('mouseup', () =>
            setValue(polygonToValue(e.overlay))
          );
        }

        if (e.type !== google.drawing.OverlayType.MARKER) {
          drawingManager.setDrawingMode(null);
          const newShape = e.overlay;
          newShape.type = e.type;
          google.event.addListener(newShape, 'click', function () {
            setSelection(newShape);
          });
          setSelection(newShape);
        }
      }
    );

    const customControlDiv = document.createElement('div') as any;
    const custom = document.createElement('div') as any;

    const control = document.createElement('div') as any;
    control.style.cssText = `
    background: none padding-box rgb(255, 255, 255);
    border: 1px solid #ccc;
    color: #000;
    margin: -33px 129px 0 0;
    cursor: pointer;
    text-align: left;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    padding: 4px;
`;
    control.title = 'Clear';
    custom.appendChild(control);

    const controlText = document.createElement('div') as any;
    controlText.style.cssText = `
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding: 2px 4px;
`;
    controlText.innerHTML = 'Clear';
    control.appendChild(controlText);

    google.event.addDomListener(control, 'click', deleteAllShape);
    CustomControl(customControlDiv);

    customControlDiv.index = 1;
    custom.index = 2;
    map.controls[google.ControlPosition.RIGHT].push(customControlDiv);
    map.controls[google.ControlPosition.RIGHT].push(custom);
  };

  const CustomControl = (controlDiv: HTMLElement): void => {
    const controlUI = document.createElement('div');
    controlUI.style.cssText = `
    background: none padding-box rgb(255, 255, 255);
    border: 1px solid #ccc;
    color: #000;
    margin: -33px 100px 0 0;
    cursor: pointer;
    text-align: left;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
`;
    controlUI.title = 'Draw free hand';
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement('div');
    controlText.style.cssText = `
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding: 2px 4px;
`;
    controlText.innerHTML = `<img src="${FreeDrawingImage}" style="position:relative;top:4px;" alt="free_drawing_icon"/>`;
    controlUI.appendChild(controlText);

    google.maps.event.addDomListener(controlUI, 'click', handleDrawable);
  };

  const handleSubmit = () => {
    // @ts-ignore
    const keysToReset = keysToResetBySearchType[props.searchType];
    if (keysToReset) {
      resetKeys(dispatch, keysToReset);
    }
    if (also) {
      if (keysToResetAll) {
        resetKeys(dispatch, keysToResetAll);
      }
      dispatch(
        setKeySettings({
          key: SEARCH_TYPE_AREA,
          value,
        })
      );
      dispatch(
        setKeySettings({
          key: SEARCH_TYPE_OFFICE_AREA,
          value,
        })
      );
    } else {
      dispatch(
        setKeySettings({
          key: props.searchType,
          value,
        })
      );
    }
    props.closeHandler();
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const center = getCenterByMapType(props, area, officeArea, currMls);

  return (
    <Modal
      // keepMounted
      open={props.open}
      onClose={() => {
        props.closeHandler();
        setValue('');
        setMapReady(false);
      }}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      classes={{ root: 'mapSearch-modal' }}
    >
      <Box sx={style} data-report-height>
        <div style={{ height: '600px', width: '100%' }}>
          <GoogleMapReact
            ref={(ref: any) => (mapView = ref)}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_API_KEY || '',
              libraries: ['geometry', 'drawing', 'places'],
            }}
            defaultCenter={center}
            defaultZoom={10}
            onTilesLoaded={onMapLoaded}
            draggable={!isDrawable}
            options={{
              clickableIcons: false,
              controlSize: 30,
              disableDoubleClickZoom: true,
              // fullscreenControl: false,
              gestureHandling: 'greedy',
              keyboardShortcuts: false,
              panControl: false,
              scrollwheel: true,
              zoomControl: true,
            }}
            shouldUnregisterMapOnUnmount
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleGoogleMapApi(map, maps)}
          />
        </div>
        <div className="mapPanel">
          <div className="panel-title">{t('map_search.title')}</div>
          <div className="panel-filter">
            <label>{t(dateKey)}:</label>
            <p>
              {t('map_search.dates', {
                from: moment.utc(startDate).locale(i18n.language).format('ll'),
                to: moment.utc(endDate).locale(i18n.language).format('ll'),
              })}
            </p>
          </div>
          <hr className="divider" />
          <p>{t('map_search.search_area')}</p>
          <p className="panel-description">{t('map_search.draw')}</p>
          {value && (
            <div className="panel-filter type">
              <label>Type: </label>
              <p>
                {base64ToJson(value)?.type === POLYGON_TYPE
                  ? 'Polygon/Free shape'
                  : base64ToJson(value)?.type}
              </p>
            </div>
          )}

          {reportName !== EReportName.PERFORMANCE && (
            <p style={{ marginTop: '10px' }}>
              <StyledCheckbox
                size="small"
                style={{ padding: '0 10px 0 0' }}
                checked={also}
                onChange={() => {
                  setAlso(!also);
                }}
              />
              {(props.searchType === SEARCH_TYPE_AREA &&
                t('map_search.also_offices')) ||
                (props.searchType === SEARCH_TYPE_OFFICE_AREA &&
                  t('map_search.also_geography'))}
            </p>
          )}
          <div className="actions">
            <Button
              variant={'outlined'}
              className="outlined-btn"
              onClick={handleSubmit}
              onTouchStart={handleSubmit}
              disabled={!value}
            >
              {t('map_search.submit')}
            </Button>
            <Button
              className="text-btn"
              onClick={() => {
                props.closeHandler();
                setValue('');
                setMapReady(false);
              }}
              onTouchStart={() => {
                props.closeHandler();
                setValue('');
                setMapReady(false);
              }}
            >
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MapSearch;
