import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CodeOfConductModal from '@/components/Footer/CodeOfConductModal';
import { getProfile } from '@/store/authSlice';
import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import './Footer.scss';

const Footer = () => {
  const user = useSelector(getProfile);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box className="app-footer" sx={{ flexBasis: 52 }}>
      <AppBar position="static" sx={{ backgroundColor: '#fff' }}>
        <Toolbar
          className="footerMenu"
          sx={{ height: '52px', minHeight: '52px!important' }}
        >
          <p className="copyright">{t('footer.copyright')}</p>
          {user ? (
            <Button onClick={handleOpen} onTouchStart={handleOpen}>
              {t('footer.coc')}
            </Button>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
      <CodeOfConductModal open={open} closeHandler={handleClose} />
    </Box>
  );
};

export default Footer;
