import React, { useId } from 'react';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledSelect from '@/mui-styled-components/select';
import { ILabelItem, IListItem } from '@/store/types';
import { MenuItem, SelectChangeEvent } from '@mui/material';

interface IProps {
  value: string | number | null;
  label?: string;
  choices: Array<IListItem | ILabelItem>;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  setValue?: (value: SelectChangeEvent<string | number | null>) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CustomSelect = (props: IProps) => {
  const { label, style, value, choices, setValue, fullWidth, placeholder } =
    props;
  const id = useId();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: fullWidth ? '265px' : 'auto',
      },
    },
  };

  const instanceOfListItem = (value: any): value is IListItem => {
    return 'id' in value;
  };

  return (
    <div className="custom-select" style={style}>
      <label htmlFor={id}>{label}</label>
      <StyledSelect
        id={id}
        labelId={id}
        variant="outlined"
        className="form-select"
        value={value}
        displayEmpty
        IconComponent={() => <SpriteIcon icon="arrow_down" />}
        //@ts-ignore
        onChange={setValue}
        MenuProps={MenuProps}
        //@ts-ignore
        renderValue={(selected: string | number | null) => {
          if (!selected) {
            return <span className="placeholder">{placeholder}</span>;
          }

          const item = choices.find((item) => {
            let property;

            if (instanceOfListItem(item)) {
              property = item.id;
            } else {
              property = item.type;
            }

            return property === selected;
          });

          return item ? item.name : selected;
        }}
      >
        {choices.length &&
          choices.map((item: IListItem | ILabelItem, index) => {
            let value;

            if (instanceOfListItem(item)) {
              value = item.id;
            } else {
              value = item.type;
            }

            return (
              <MenuItem key={index} value={value}>
                {item.name}
              </MenuItem>
            );
          })}
      </StyledSelect>
    </div>
  );
};

export default CustomSelect;
