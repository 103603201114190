import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)`
  & .MuiInput-underline::after {
    border-color: var(--blue-color);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--blue-color-hover);
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--blue-color);
  }
`;

export default StyledTextField;
