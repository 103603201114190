import React from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import { getColorByPropertyName } from '@/components/Account/utils';
import { EReportName, EReportUnit } from '@/store/enums';
import { initFilterLists } from '@/store/filterListsSlice';
import { AppDispatch, store } from '@/store/index';
import { initKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { loadMlsList } from '@/store/mlsSlice';
import {
  EChartTypes,
  IContact,
  IListItem,
  IPassword,
  IProfile,
  IUser,
  IUserData,
  IUserSettings,
} from '@/store/types';
import TokenService from '@/TokenStorage';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  auth: {
    user: IUserData;
    profile: IProfile;
    settings: IUserSettings;
    authReady: number;
  };
}

const setColorScheme = () => {
  document.documentElement.style.setProperty(
    '--blue-color',
    `${getColorByPropertyName('buttons.default')}`
  );
  document.documentElement.style.setProperty(
    '--blue-color-hover',
    `${getColorByPropertyName('buttons.active')}`
  );
  document.documentElement.style.setProperty(
    '--reports-links',
    `${getColorByPropertyName('reportsLinks')}`
  );
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    profile: null,
    settings: {
      currentColorScheme: '2',
      defaultMlsDatabase: null,
      defaultTimeFrame: null,
      defaultReportName: null,
      defaultLastNamePosition: 'Last',
      pdfReportsBorder: 0,
      defaultGraphs: 'Line',
      logosOnPDFReports: 0,
    },
    authReady: false,
  },
  reducers: {
    authReady: (state) => Object.assign(state || {}, { authReady: true }),
    setUser: (state, action) =>
      Object.assign(state || {}, {
        user: action.payload,
      }),
    setProfile: (state, action) =>
      Object.assign(state || {}, {
        profile: action.payload,
      }),
    setSettings: (state, action) =>
      Object.assign(state || {}, {
        settings: action.payload,
      }),
    resetUser: (state) => {
      Object.assign(state || {}, { user: null });
    },
    clearUserData: (state) => {
      TokenService.removeRefreshToken();
      return Object.assign(state || {}, { user: null, profile: null });
    },
    tokenLogin: (state, action) => {
      TokenService.setRefreshToken(action.payload.refresh_token);
      return Object.assign(state || {}, {
        user: action.payload.data,
        authReady: true,
      });
    },
  },
});

export const {
  authReady,
  setUser,
  resetUser,
  clearUserData,
  tokenLogin,
  setSettings,
  setProfile,
} = authSlice.actions;

export const getUser = (state: InitialState) => state.auth.user;
export const getProfile = (state: InitialState) => state.auth.profile;
export const getSettings = (state: InitialState) => state.auth.settings;
export const getAuthReady = (state: InitialState) => state.auth.authReady;

export default authSlice.reducer;

export const init = () => (dispatch: AppDispatch) => {
  const refreshToken = TokenService.getRefreshToken();
  dispatch(onMissingToken());

  if (refreshToken) {
    dispatch(setRefreshToken(refreshToken));
    dispatch(refresh());
  } else {
    dispatch(authReady());
  }
};

export const loadProfile = (dispatch: AppDispatch) => {
  return apiClient
    .me()
    .then(async (userData) => {
      dispatch(setProfile(userData));
      await loadMlsList(dispatch);

      return Promise.resolve(userData);
    })
    .catch((error) => {
      dispatch(resetUser());
      return Promise.reject(error);
    });
};

export const updateContact = (contact: IContact) => (dispatch: AppDispatch) => {
  return apiClient
    .setContact(contact)
    .then(async (data) => {
      dispatch(setProfile(data));
      toast.success(<Trans i18nKey="messages.contact_success" />);
      return Promise.resolve(data);
    })
    .catch((error) => {
      const errorMessage =
        'messages.' + error.response.data.details.exception.message;
      toast.error(<Trans i18nKey={errorMessage} />);
      return Promise.reject(error);
    });
};

export const updatePassword =
  (password: IPassword) => (dispatch: AppDispatch) => {
    return apiClient
      .setPassword(password)
      .then(async (data) => {
        toast.success(<Trans i18nKey="messages.password_success" />);
        return Promise.resolve(data);
      })
      .catch((error) => {
        const errorMessage =
          'messages.' + error.response.data.details.exception.message;
        toast.error(<Trans i18nKey={errorMessage} />);
        return Promise.reject(error);
      });
  };

export const loadProfileSettings = (dispatch: AppDispatch) => {
  return apiClient
    .getDefaults()
    .then(async (data) => {
      const {
        defaultMlsDatabase,
        defaultReport,
        defaultGraphType,
        defaultRowsPerPage,
        showPdfReportBackground,
        showPdfReportLogos,
        currentColorScheme,
      } = data;
      let reportUnitValue;
      const mls = store.getState().mls;
      const list: Array<IListItem> = mls.list;
      const urlReportName = (): string | null => {
        const urlSearchParams = new URLSearchParams(location.search);
        return urlSearchParams.get('reportName');
      };

      const reportName = urlReportName();
      const reportNameEmpty = reportName === null || reportName === '';

      dispatch(
        setSettings({
          defaultMlsDatabase: defaultMlsDatabase || list[0].id,
          defaultReport: defaultReport || EReportName.AGENTS,
          defaultGraphType: defaultGraphType || EChartTypes.COLUMN,
          defaultRowsPerPage: defaultRowsPerPage || '25',
          showPdfReportBackground: showPdfReportBackground || false,
          showPdfReportLogos: showPdfReportLogos || true,
          currentColorScheme: currentColorScheme || '1',
        })
      );
      dispatch(
        setKeySettings({
          key: 'perPage',
          value: Number(defaultRowsPerPage || '25'),
        })
      );
      dispatch(
        setKeySettings({
          key: 'chartType',
          value: defaultGraphType || EChartTypes.COLUMN,
        })
      );
      if (reportNameEmpty) {
        if (defaultReport === EReportName.AGENTS) {
          reportUnitValue = EReportUnit.AGENTS;
        } else if (defaultReport === EReportName.OFFICES) {
          reportUnitValue = EReportUnit.OFFICES;
        } else if (defaultReport === EReportName.FIRMS) {
          reportUnitValue = EReportUnit.FIRMS;
        } else if (defaultReport === EReportName.BRANDS) {
          reportUnitValue = EReportUnit.BRANDS;
        } else {
          reportUnitValue = EReportUnit.AGENTS;
        }

        dispatch(
          setKeySettings({
            key: 'reportUnit',
            value: reportUnitValue,
          })
        );
        dispatch(
          setKeySettings({
            key: 'reportName',
            value: defaultReport || EReportName.AGENTS,
          })
        );
      }
      setColorScheme();

      await dispatch(initKeySettings());
      dispatch(initFilterLists());

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(resetUser());
      return Promise.reject(error);
    });
};

export const updateDefaults =
  (settings: IUserSettings) => (dispatch: AppDispatch) => {
    return apiClient
      .setDefaults(settings)
      .then(async (data) => {
        dispatch(
          setSettings({
            ...data,
          })
        );
        setColorScheme();
        toast.success(<Trans i18nKey="messages.settings_success" />);
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch(resetUser());
        return Promise.reject(error);
      });
  };

export const handleLogin = (user: IUser) => (dispatch: AppDispatch) => {
  return apiClient
    .login(user.username, user.password)
    .then(async (userData) => {
      TokenService.setRefreshToken(userData.refresh_token);
      dispatch(authReady());
      dispatch(setUser(userData));
      await loadProfile(dispatch);

      return userData;
    })
    .then(async (userData) => {
      await loadProfileSettings(dispatch);

      return Promise.resolve(userData);
    })
    .catch((error) => {
      toast.error(<Trans i18nKey="messages.invalid_credentials" />);
      dispatch(resetUser());
      return Promise.reject(error);
    });
};

export const handleExternalLogin =
  (user: string, expires: string, hash: string) => (dispatch: AppDispatch) => {
    return apiClient
      .externalLogin(user, expires, hash)
      .then(async (userData) => {
        TokenService.setRefreshToken(userData.refresh_token);
        dispatch(authReady());
        dispatch(setUser(userData));
        await loadProfile(dispatch);

        return userData;
      })
      .then(async (userData) => {
        await loadProfileSettings(dispatch);

        return Promise.resolve(userData);
      })
      .catch((error) => {
        toast.error(<Trans i18nKey="messages.invalid_credentials" />);
        dispatch(resetUser());
        return Promise.reject(error);
      });
  };

export const onMissingToken = () => (dispatch: AppDispatch) => {
  return apiClient.onMissingToken(() => {
    dispatch(clearUserData());
  });
};

export const setRefreshToken = (refreshToken: string) => () => {
  return apiClient.setRefreshToken(refreshToken);
};

export const refresh = () => (dispatch: AppDispatch) => {
  return apiClient
    .refresh()
    .then(async (data) => {
      dispatch(tokenLogin(data));
      await loadProfile(dispatch);

      return data;
    })
    .then(async (data) => {
      await loadProfileSettings(dispatch);

      return Promise.resolve(data);
    })
    .catch((error) => {
      toast.error(<Trans i18nKey="messages.authentication_error" />);
      dispatch(authReady());
      return Promise.reject(error);
    });
};
