import React, { useId } from 'react';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledSelect from '@/mui-styled-components/select';
import { MenuItem, SelectChangeEvent } from '@mui/material';

interface IProps {
  value: string;
  label?: string;
  choices: Array<string>;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  setValue: (value: SelectChangeEvent<string | number | null>) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CustomSelect = (props: IProps) => {
  const { label, style, value, choices, setValue, fullWidth, placeholder } =
    props;
  const id = useId();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: fullWidth ? '265px' : 'auto',
      },
    },
  };

  return (
    <div className="custom-select" style={style}>
      <label htmlFor={id}>{label}</label>
      <StyledSelect
        id={id}
        labelId={id}
        variant="outlined"
        className="form-select"
        value={value}
        displayEmpty
        IconComponent={() => <SpriteIcon icon="arrow_down" />}
        //@ts-ignore
        onChange={setValue}
        MenuProps={MenuProps}
        //@ts-ignore
        renderValue={(selected: string | number | null) => {
          if (!selected) {
            return <span className="placeholder">{placeholder}</span>;
          }

          const item = choices.find((item) => item === selected);

          return item ?? selected;
        }}
      >
        {choices.length &&
          choices.map((item: string) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
      </StyledSelect>
    </div>
  );
};

export default CustomSelect;
