import { apiClient } from '@/ApiClient';
import { EReportName } from '@/store/enums';
import { AppDispatch, store } from '@/store/index';
import { TReportFields } from '@/store/types';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  reportFields: {
    config: Array<TReportFields>;
  };
}

export const reportFieldsSlice = createSlice({
  name: 'reportFields',
  initialState: {
    config: [],
  },
  reducers: {
    setConfig: (
      state,
      action: {
        payload: {
          data: Array<TReportFields>;
        };
        type: string;
      }
    ) => Object.assign(state || {}, { config: action.payload.data }),

    resetConfig: (state) => Object.assign(state, { config: [] }),
  },
});

export const { setConfig, resetConfig } = reportFieldsSlice.actions;

export const getConfig = (state: InitialState) => state.reportFields.config;

export default reportFieldsSlice.reducer;

let loadReportFieldsController: AbortController | undefined;

export const loadReportFields = (
  dataProp?: string | { orderBy: string; timePeriod: string } | undefined
) => {
  if (loadReportFieldsController) {
    loadReportFieldsController.abort();
  }

  loadReportFieldsController = new AbortController();
  const signal = loadReportFieldsController.signal;

  const { mls, reportName, reportType, startDate, endDate, data } =
    store.getState().keySettings;

  if (reportName === EReportName.PERFORMANCE) {
    return apiClient.getGraphsFieldsConfig(reportName, reportType, mls, signal);
  } else if (reportName === EReportName.MARKET) {
    const orderBy =
      typeof dataProp === 'object'
        ? dataProp.orderBy || 'totalUnit'
        : 'totalUnit';
    const timePeriod =
      typeof dataProp === 'object'
        ? dataProp.timePeriod || 'monthly'
        : 'monthly';

    return apiClient.getGraphsMarketTrendsFieldsConfig(
      reportName,
      orderBy,
      timePeriod,
      mls,
      signal
    );
  } else if (
    [
      EReportName.AGENTS_DISTRIBUTION,
      EReportName.OFFICES_DISTRIBUTION,
      EReportName.FIRMS_DISTRIBUTION,
      EReportName.BRANDS_DISTRIBUTION,
    ].includes(reportName)
  ) {
    return apiClient.getDistributionFieldsConfig(
      reportName,
      data || 'price10000',
      reportType,
      mls,
      signal
    );
  } else if (
    reportName === EReportName.MARKET_PERFORMANCE_DISTRIBUTION ||
    reportName === EReportName.MARKET_COMPARATIVE_DISTRIBUTION
  ) {
    return apiClient.getMarketDistributionFieldsConfig(
      reportName,
      data || 'price10000',
      mls,
      signal
    );
  } else if (reportName === EReportName.MARKET_COMPARATIVE) {
    return apiClient.getGraphsMarketComparativeFieldsConfig(
      reportName,
      (dataProp as string) || 'twoYears',
      mls,
      signal
    );
  } else if (reportName === EReportName.AGENTS_YEARBOOK) {
    return apiClient.getYearBookFieldsConfig(
      reportName,
      reportType,
      mls,
      startDate,
      signal
    );
  } else if (
    reportName === EReportName.OFFICES_REGISTERED_AGENT_COUNT ||
    reportName === EReportName.FIRMS_REGISTERED_AGENT_COUNT ||
    reportName === EReportName.BRANDS_REGISTERED_AGENT_COUNT
  ) {
    return apiClient.getRegisteredAgentCountFieldsConfig(
      reportName,
      reportType,
      mls,
      endDate,
      signal
    );
  } else {
    return apiClient.getFieldsConfig(reportName, reportType, mls, signal);
  }
};

export const initConfig =
  (data?: string | { orderBy: string; timePeriod: string } | undefined) =>
  async (dispatch: AppDispatch) => {
    loadReportFields(data)
      .then(async (data) => {
        await dispatch(resetConfig());
        dispatch(setConfig({ data }));
      })
      .catch((error) => {
        dispatch(resetConfig());
        return Promise.reject(error);
      });
  };
