type PropertyData = {
  [key: string]: string[];
};

export const isPropertyIncluded = (
  object: PropertyData,
  propertyName: string
) => {
  for (const key in object) {
    if (object[key].includes(propertyName)) {
      return true;
    }
  }
  return false;
};

export const isObjectPropertyNotEmpty = (
  object: PropertyData,
  propertyName: string
) => {
  if (Object.prototype.hasOwnProperty.call(object, propertyName)) {
    const propertyValue = object[propertyName];
    return Array.isArray(propertyValue)
      ? propertyValue.length > 0
      : Object.keys(propertyValue).length > 0;
  }
  return false;
};
