import axios from 'axios';

interface Config {
  baseUrl: string;
}

export const loadConfig = async (configUrl: string): Promise<Config> => {
  const client = axios.create();
  const response = await client.get<Config>(configUrl);

  return response.data;
};
