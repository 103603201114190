import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ColorSettings from '@/components/Account/ColorSettings';
import CustomSelect from '@/components/CustomElements/CustomSelect';
import InputFile from '@/components/CustomElements/InputFile';
import StyledCheckbox from '@/mui-styled-components/checkbox';
import { AppDispatch } from '@/store';
import { getSettings, setSettings, updateDefaults } from '@/store/authSlice';
import { EOrder, EReportName, EReportTypes } from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import {
  getKeySettings,
  resetFilters,
  resetMLSDependentSettings,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getMlsList, loadCurrentMls } from '@/store/mlsSlice';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
} from '@mui/material';

import { graphsChoices, reportsChoices, rowsPerPageChoices } from './utils';

const Settings: React.FC = () => {
  const settings = useSelector(getSettings);
  const mlsList = useSelector(getMlsList);
  const filters = useSelector(getFilterLists);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { reportName, mls } = useSelector(getKeySettings);

  const handleSettings = useCallback(
    (fieldName: string) =>
      (event: SelectChangeEvent<string | number | null | boolean>) => {
        const value =
          (event.target as HTMLInputElement).type === 'checkbox'
            ? (event.target as HTMLInputElement).checked
            : (event.target as HTMLInputElement).value;

        dispatch(
          setSettings({
            ...settings,
            [fieldName]: value !== '' ? value : null,
          })
        );
      },
    [dispatch, settings]
  );

  const updateKeySettingsToEmptyArray = (keys: string[]) => {
    const actions = keys.map((key) => setKeySettings({ key, value: [] }));
    actions.forEach((action) => dispatch(action));
  };

  const load = async () => {
    await dispatch(
      updateDefaults({
        defaultMlsDatabase: Number(settings.defaultMlsDatabase),
        defaultReport: settings.defaultReport,
        defaultGraphType: settings.defaultGraphType,
        defaultRowsPerPage: Number(settings.defaultRowsPerPage),
        showPdfReportBackground: settings.showPdfReportBackground,
        showPdfReportLogos: settings.showPdfReportLogos,
        currentColorScheme: settings.currentColorScheme,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'perPage',
        value: Number(settings.defaultRowsPerPage),
      })
    );
    await dispatch(
      setKeySettings({
        key: 'chartType',
        value: settings.defaultGraphType,
      })
    );

    await dispatch(resetMLSDependentSettings());

    await dispatch(
      setKeySettings({
        key: 'mls',
        value: Number(settings.defaultMlsDatabase),
      })
    );
    await dispatch(loadCurrentMls(Number(settings.defaultMlsDatabase)));

    await dispatch(
      setKeySettings({
        key: 'reportType',
        value: EReportTypes.COMBINED,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'reportName',
        value: settings.defaultReport,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'orderBy',
        value: null,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'timePeriod',
        value: null,
      })
    );
    updateKeySettingsToEmptyArray(['agentIds', 'officeIds', 'firmIds', 'data']);
    await dispatch(
      setKeySettings({
        key: 'orderDirection',
        value: null,
      })
    );
    await dispatch(resetFilters(settings.defaultReport));
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    // dispatch(
    //   setKeySettings({
    //     key: 'reportUnit',
    //     value: unit,
    //   })
    // );
    // await dispatch(initConfig());
    // await dispatch(loadReport());
  };

  return (
    <>
      {settings && (
        <div
          className="content-wrap settings-wrap"
          style={{ overflow: 'auto' }}
        >
          <div className="section-header">
            <h2 className="headline-1">{t('account.settings')}</h2>
          </div>
          <div className="account-settings">
            <h3>{t('account.settings_title')}</h3>
            <Grid container columnSpacing={4} sx={{ width: '100%' }}>
              <Grid item xs={12} md={3} lg={2}>
                <CustomSelect
                  label={t('account.report_default')}
                  placeholder={t('select')}
                  value={settings.defaultReport || ''}
                  choices={reportsChoices}
                  setValue={handleSettings('defaultReport')}
                />
                <CustomSelect
                  label={t('account.rows_per_page')}
                  placeholder={t('select')}
                  value={settings.defaultRowsPerPage || ''}
                  choices={rowsPerPageChoices}
                  setValue={handleSettings('defaultRowsPerPage')}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <CustomSelect
                  label={t('account.default_mls')}
                  placeholder={t('select')}
                  value={settings.defaultMlsDatabase || ''}
                  choices={mlsList}
                  setValue={handleSettings('defaultMlsDatabase')}
                />
                <CustomSelect
                  label={t('account.default_graphs')}
                  placeholder={t('select')}
                  value={settings.defaultGraphType || ''}
                  choices={graphsChoices}
                  setValue={handleSettings('defaultGraphType')}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={2} style={{ alignSelf: 'end' }}>
                <div>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        style={{ paddingTop: 0 }}
                        checked={settings.showPdfReportBackground || false}
                        onChange={handleSettings('showPdfReportBackground')}
                      />
                    }
                    label={t('account.show_pdf_report_bg_label')}
                    style={{ alignItems: 'flex-start' }}
                  />
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        style={{ paddingTop: 0 }}
                        checked={settings.showPdfReportLogos || false}
                        onChange={handleSettings('showPdfReportLogos')}
                      />
                    }
                    label={t('account.show_pdf_report_logos')}
                    style={{ alignItems: 'flex-start' }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3} lg={2} style={{ alignSelf: 'end' }}>
                <div>
                  <Button
                    className="filled-btn ml-auto"
                    onClick={() => {
                      load();
                    }}
                    onTouchStart={() => {
                      load();
                    }}
                  >
                    {t('buttons.submit')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="account-settings">
            <h3>{t('account.colors_title')}</h3>
            <ColorSettings
              setCurrentColorScheme={(value) => {
                dispatch(
                  updateDefaults({
                    ...settings,
                    currentColorScheme: value?.toString(),
                  })
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
