import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { passwordRequirements } from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export const loginValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required(i18n.t('requirements.required'))
    .min(3, i18n.t('requirements.min_length', { length: 3 }))
    .max(50, i18n.t('requirements.max_length', { length: 50 })),
  password: passwordRequirements.required(i18n.t('requirements.required')),
});

export interface LoginDialogProps {
  open: boolean;
  onLogin: (username: string, password: string) => void;
  onClose: () => void;
}

interface FormData {
  username: string;
  password: string;
}

const LoginDialog = (props: LoginDialogProps) => {
  const { onLogin, onClose, open } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(loginValidationSchema) });
  const clickLogin = handleSubmit((data: FormData) => {
    onLogin(data.username, data.password);
  });
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        {t('authentication.login')}
      </DialogTitle>

      <form onSubmit={clickLogin} style={{ minWidth: '300px' }}>
        <DialogContent>
          <label htmlFor="#userName" style={{ display: 'block' }}>
            {t('authentication.username')}
          </label>
          <StyledTextField
            id="userName"
            type="text"
            variant="outlined"
            className="form-input"
            error={!!errors.username}
            helperText={errors?.username?.message}
            autoFocus
            fullWidth
            {...register('username')}
          />
          <label
            htmlFor="#userPassword"
            style={{ display: 'block', marginTop: 20 }}
          >
            {t('authentication.password')}
          </label>
          <StyledTextField
            id="userPassword"
            type="password"
            variant="outlined"
            className="form-input"
            error={!!errors.password}
            helperText={errors?.password?.message}
            fullWidth
            {...register('password')}
          />
        </DialogContent>

        <DialogActions sx={{ padding: '0 24px 20px 24px' }}>
          <Button className="filled-btn" type="submit" onClick={clickLogin}>
            {t('authentication.login')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoginDialog;
