import React from 'react';

import logo from '@/assets/images/logo.svg';
import { CircularProgress } from '@mui/material';

import './ProgressLoader.scss';

interface Props {
  style?: React.CSSProperties;
  size?: number;
  isInsideTable?: boolean;
}

const ProgressLoader = ({
  style,
  size = 200,
  isInsideTable = false,
}: Props) => {
  const WrapperElement = isInsideTable ? 'tr' : 'div';
  const ChildElement = isInsideTable ? 'td' : 'div';

  return (
    <WrapperElement className="content-wrap progress-loader" style={style}>
      <ChildElement className="progress-content">
        <div
          className="logo-wrap"
          style={{ height: `${size}px`, width: `${size}px` }}
        >
          <CircularProgress
            variant={'determinate'}
            size={size}
            thickness={1}
            value={100}
            sx={{ color: '#e0e0e0' }}
          />
          <CircularProgress
            variant={'indeterminate'}
            size={size}
            thickness={1}
            value={100}
            sx={{ color: 'var(--blue-color)' }}
          />
          <img src={logo} alt="Logo Redatum" />
        </div>
      </ChildElement>
    </WrapperElement>
  );
};

export default ProgressLoader;
