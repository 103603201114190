import { AxiosInstance } from 'axios';

import {
  IKeySettings,
  IUserSearch,
  IUserSearchList,
  UserProfile,
} from '@/store/types';

export default class UserSearches {
  constructor(private clientFactory: () => AxiosInstance) {}

  public async postUserSearch(query: string): Promise<IUserSearch> {
    const response = await this.clientFactory().post(
      `/api/user-search/create?${query}`
    );

    if (response && response.status === 200) {
      return response.data;
    }

    throw response;
  }

  public async getUserSearch(id: number): Promise<IKeySettings> {
    const response = await this.clientFactory().get(
      `/api/user-search/${id}/details`
    );

    if (response && response.status === 200) {
      return response.data.data;
    }

    throw response;
  }

  public async putUserSearch(id: string, query: string): Promise<IUserSearch> {
    const response = await this.clientFactory().put(
      `/api/user-search/${id}?${query}`
    );

    if (response && response.status === 200) {
      return response.data;
    }

    throw response;
  }

  public async deleteUserSearch(id: number): Promise<UserProfile> {
    const response = await this.clientFactory().delete(
      `/api/user-search/${id}`
    );

    if (response && response.status === 204) {
      return response.data;
    }

    throw response;
  }

  public async getUserSearchList(id: number): Promise<IUserSearchList[]> {
    const response = await this.clientFactory().get(
      `/api/user-search/list/${id}`
    );

    if (response && response.status === 200) {
      return response.data;
    }

    throw response;
  }
}
