import simplify from 'simplify-js';

const MAX_POLYGONS = 150;

const coordsToPoints = (coords: Array<Array<number>>) =>
  coords.map((latLng) => {
    const [x, y] = latLng;

    return { x, y };
  });

export const optimizePolygon = (multiPolygonValue: any) => {
  if (multiPolygonValue.length <= MAX_POLYGONS) {
    return multiPolygonValue;
  }

  const points = coordsToPoints(multiPolygonValue);
  const sx = points.map((item) => item.x);
  const sy = points.map((item) => item.y);
  const maxX = Math.max(...sx);
  const minX = Math.min(...sx);
  const maxY = Math.max(...sy);
  const minY = Math.min(...sy);
  let optimizePoints: Array<{ x: number; y: number }> = [];
  const factor = Math.min(Math.abs(maxX - minX), Math.abs(maxY - minY)) / 400;
  const step = factor / 16;
  let accuracy = factor;

  while (accuracy < 0.01) {
    optimizePoints = simplify(points, accuracy, true);

    if (optimizePoints.length < MAX_POLYGONS) {
      break;
    }

    accuracy += step;
  }

  return optimizePoints.map((item) => [item.x, item.y]);
};
