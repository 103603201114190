import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import map from '@/assets/images/map.png';
import CustomAutocomplete from '@/components/CustomElements/CustomAutocomplete';
import { isPropertyIncluded } from '@/components/KeySettings/utils';
import MapSearch from '@/components/MapSearch/MapSearch';
import useDebounce from '@/hooks/useDebounce';
import StyledRadio from '@/mui-styled-components/radio';
import { AppDispatch } from '@/store';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { getCurrentMls } from '@/store/mlsSlice';
import { getReport, resetReport } from '@/store/reportSlice';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const OnlyOfficesSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const currMls = useSelector(getCurrentMls);
  const [expanded, setExpanded] = useState(false);
  const { labels, cityList, zipList, countyList } = useSelector(getFilterLists);
  const {
    officeCounty,
    officeCity,
    officeZip,
    officeCountyInclude,
    officeCityInclude,
    officeZipInclude,
    officeArea,
  } = useSelector(getKeySettings);

  const [countyValue, setCountyValue] =
    useState<Array<string | number>>(officeCounty);
  const [cityValue, setCityValue] =
    useState<Array<string | number>>(officeCity);
  const [zipValue, setZip] = useState<Array<string | number>>(officeZip);
  const [countyInclude, setCountyInclude] = useState<string>(
    officeCountyInclude === 'true' ? 'include' : 'exclude'
  );
  const [cityInclude, setCityInclude] = useState<string>(
    officeCityInclude === 'true' ? 'include' : 'exclude'
  );
  const [zipInclude, setZipInclude] = useState<string>(
    officeZipInclude === 'true' ? 'include' : 'exclude'
  );

  const hasOfficeMapProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'officeMap'
  );
  const hasOfficeCountyProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'officeCounty'
  );
  const hasOfficeCityProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'officeCity'
  );
  const hasOfficeZipProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'officeZip'
  );

  const debouncedCounty = useDebounce(countyValue);
  const debouncedCity = useDebounce(cityValue);
  const debouncedZip = useDebounce(zipValue);

  const handleOpen = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const updateCounty = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setCountyValue(value);
    } else {
      setCountyValue([]);
    }
  };

  const updateCity = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setCityValue(value);
    } else {
      setCityValue([]);
    }
  };

  const updateZip = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setZip(value);
    } else {
      setZip([]);
    }
  };

  const handleCountyInclude = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;

    setCountyInclude(value);

    await dispatch(
      setKeySettings({
        key: 'officeCountyInclude',
        value: value === 'include' ? 'true' : 'false',
      })
    );
  };

  const handleCityInclude = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;

    setCityInclude(value);

    await dispatch(
      setKeySettings({
        key: 'officeCityInclude',
        value: value === 'include' ? 'true' : 'false',
      })
    );
  };

  const handleZipInclude = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;

    setZipInclude(value);

    await dispatch(
      setKeySettings({
        key: 'officeZipInclude',
        value: value === 'include' ? 'true' : 'false',
      })
    );
  };

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'officeCounty',
        value: debouncedCounty,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedCounty]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'officeCity',
        value: debouncedCity,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedCity]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'officeZip',
        value: debouncedZip,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedZip]);

  useEffect(() => {
    setCountyValue(officeCounty);
  }, [officeCounty]);

  useEffect(() => {
    setCityValue(officeCity);
  }, [officeCity]);

  useEffect(() => {
    setZip(officeZip);
  }, [officeZip]);

  useEffect(() => {
    setCountyInclude(officeCountyInclude === 'true' ? 'include' : 'exclude');
  }, [officeCountyInclude]);

  useEffect(() => {
    setCityInclude(officeCityInclude === 'true' ? 'include' : 'exclude');
  }, [officeCityInclude]);

  useEffect(() => {
    setZipInclude(officeZipInclude === 'true' ? 'include' : 'exclude');
  }, [officeZipInclude]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (countyValue.length || cityValue.length || zipValue.length) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [countyValue, cityValue, zipValue]);

  return (
    <Accordion
      expanded={expanded}
      sx={{ boxShadow: 'none', padding: 0, border: 'none', margin: 0 }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ boxShadow: 'none', padding: 0, minHeight: 1, margin: 0 }}
      >
        <div className="d-flex" style={{ alignItems: 'center', flex: 1 }}>
          <h3>{t('key_settings.only_office')}</h3>
          <div className="line" />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <div className="d-flex flex-column">
          {hasOfficeMapProperty ? (
            <>
              <div className="map-wrap">
                <img src={map} alt="map" />
                <button
                  type="button"
                  className="btn-rounded"
                  onClick={handleOpen}
                  onTouchStart={handleOpen}
                >
                  {!officeArea
                    ? t('key_settings.search_by_map')
                    : t('key_settings.search_by_map_applied')}
                </button>
              </div>
              <MapSearch
                open={open}
                closeHandler={handleClose}
                searchType="officeArea"
              />
            </>
          ) : null}
          {hasOfficeCountyProperty &&
          countyList.length &&
          currMls &&
          currMls.regionID === 2 ? (
            <>
              <CustomAutocomplete
                label={labels.find((item) => item.type === 'county')?.name}
                placeholder={t('select')}
                value={countyValue}
                choices={countyList}
                style={{ paddingTop: 10 }}
                setValue={updateCounty}
                disabled={!!officeArea}
              />
              <RadioGroup
                row
                aria-labelledby="county-include"
                defaultValue="include"
                name="count-include-group"
                value={countyInclude}
                onChange={handleCountyInclude}
              >
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="include"
                  control={<StyledRadio />}
                  label={t('include')}
                  disabled={!!officeArea}
                />
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="exclude"
                  control={<StyledRadio />}
                  label={t('exclude')}
                  disabled={!!officeArea}
                />
              </RadioGroup>
            </>
          ) : null}
          {hasOfficeCityProperty && cityList.length ? (
            <>
              <CustomAutocomplete
                label={labels.find((item) => item.type === 'city')?.name}
                placeholder={t('select')}
                value={cityValue}
                choices={cityList}
                style={{ width: '100%' }}
                visibleValues={1}
                setValue={updateCity}
                disabled={!!officeArea}
              />
              <RadioGroup
                row
                aria-labelledby="office-include"
                defaultValue="female"
                name="office-include-group"
                value={cityInclude}
                onChange={handleCityInclude}
              >
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="include"
                  control={<StyledRadio />}
                  label={t('include')}
                  disabled={!!officeArea}
                />
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="exclude"
                  control={<StyledRadio />}
                  label={t('exclude')}
                  disabled={!!officeArea}
                />
              </RadioGroup>
            </>
          ) : null}
          {hasOfficeZipProperty && zipList.length ? (
            <>
              <CustomAutocomplete
                label={labels.find((item) => item.type === 'zipCode')?.name}
                placeholder={t('select')}
                value={zipValue}
                choices={zipList}
                style={{ width: '100%' }}
                visibleValues={1}
                setValue={updateZip}
                disabled={!!officeArea}
              />
              <RadioGroup
                row
                aria-labelledby="office-include"
                defaultValue="female"
                name="office-include-group"
                value={zipInclude}
                onChange={handleZipInclude}
              >
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="include"
                  control={<StyledRadio />}
                  label={t('include')}
                  disabled={!!officeArea}
                />
                <FormControlLabel
                  classes={{ label: 'font-size-s' }}
                  value="exclude"
                  control={<StyledRadio />}
                  label={t('exclude')}
                  disabled={!!officeArea}
                />
              </RadioGroup>
            </>
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default OnlyOfficesSettings;
