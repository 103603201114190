import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { maxNumber, onlyNumbersWithOneComma } from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';

import './common.scss';

const listingUnitsPerAgentValidationSchema = Yup.object().shape({
  listingUnitsPerAgentMin: Yup.string()
    .nullable()
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
  listingUnitsPerAgentMax: Yup.string()
    .nullable()
    .when('listingUnitsPerAgentMin', {
      is: (max: string) =>
        max && parseFloat(max.toString().replace(/,/g, '')) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanListingUnitsPerAgentMin',
        message: '',
        test() {
          const { listingUnitsPerAgentMin, listingUnitsPerAgentMax } =
            this.parent;

          return listingUnitsPerAgentMax !== ''
            ? parseFloat(
                listingUnitsPerAgentMax.toString().replace(/,/g, '')
              ) >=
                parseFloat(listingUnitsPerAgentMin.toString().replace(/,/g, ''))
            : true;
        },
      }),
    })
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
});

const ListingUnitPerAgentFilter = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [listingUnitsPerAgentMin, setListingUnitsPerAgentMin] =
    useState<string>('');
  const [listingUnitsPerAgentMax, setListingUnitsPerAgentMax] =
    useState<string>('');
  const open = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(listingUnitsPerAgentValidationSchema),
  });

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = async () => {
    setListingUnitsPerAgentMin('');
    setListingUnitsPerAgentMax('');
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerAgentMin',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerAgentMax',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const applyFilter = async () => {
    handleClose();
    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerAgentMin',
        value: listingUnitsPerAgentMin.replace(/,/g, ''),
      })
    );

    await dispatch(
      setKeySettings({
        key: 'listingUnitsPerAgentMax',
        value: listingUnitsPerAgentMax.replace(/,/g, ''),
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const handleListingUnitsPerAgentMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setListingUnitsPerAgentMin(e.target.value);
      setValue('listingUnitsPerAgentMin', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const handleListingUnitsPerAgentMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setListingUnitsPerAgentMax(e.target.value);
      setValue('listingUnitsPerAgentMax', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const icon =
    keySettings.listingUnitsPerAgentMin !== '' ||
    keySettings.listingUnitsPerAgentMax !== ''
      ? 'filter'
      : 'search';
  const tooltipText = `${
    keySettings.listingUnitsPerAgentMin !== ''
      ? t('from_selected', { quantity: keySettings.listingUnitsPerAgentMin })
      : ''
  }${' '}${
    keySettings.listingUnitsPerAgentMax !== ''
      ? t('to_selected', { quantity: keySettings.listingUnitsPerAgentMax })
      : ''
  }${' '}${t('click_clear')}`;

  return (
    <div className="column-search-menu-wrap">
      <div
        onClick={(e) => {
          keySettings.listingUnitsPerAgentMin !== '' ||
          keySettings.listingUnitsPerAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
        onTouchStart={(e) => {
          keySettings.listingUnitsPerAgentMin !== '' ||
          keySettings.listingUnitsPerAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
      >
        {keySettings.listingUnitsPerAgentMin !== '' ||
        keySettings.listingUnitsPerAgentMax !== '' ? (
          <Tooltip title={tooltipText}>
            <IconButton aria-label="search" className="searchBtn">
              <SpriteIcon icon={icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
        )}
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 filter-block-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className="d-flex">
          <div className="d-flex flex-column">
            <label className="form-label" htmlFor="listingUnitsPerAgentMin">
              {t('min')}
            </label>
            <StyledTextField
              id="listingUnitsPerAgentMin"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingUnitsPerAgentMin}
              variant="outlined"
              sx={{ paddingLeft: '0', paddingRight: '6px', width: '64px' }}
              error={!!errors.listingUnitsPerAgentMin}
              //@ts-ignore
              helperText={errors?.listingUnitsPerAgentMin?.message}
              {...register('listingUnitsPerAgentMin')}
              onChange={handleListingUnitsPerAgentMin}
            />
          </div>
          <div className="d-flex flex-column">
            <label
              style={{ paddingLeft: '6px' }}
              className="form-label"
              htmlFor="listingUnitsPerAgentMax"
            >
              {t('max')}
            </label>
            <StyledTextField
              id="listingUnitsPerAgentMax"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingUnitsPerAgentMax}
              variant="outlined"
              sx={{ paddingLeft: '6px', paddingRight: '0', width: '64px' }}
              error={!!errors.listingUnitsPerAgentMax}
              //@ts-ignore
              helperText={errors?.listingUnitsPerAgentMax?.message}
              {...register('listingUnitsPerAgentMax')}
              onChange={handleListingUnitsPerAgentMax}
            />
          </div>
        </form>
        <div
          className="filter-footer"
          style={{ padding: 0, marginTop: '10px' }}
        >
          <Button
            type="submit"
            style={{ width: '100%' }}
            className="filled-btn"
            disabled={
              listingUnitsPerAgentMin === '' && listingUnitsPerAgentMax === ''
            }
            onClick={handleSubmit(applyFilter)}
            onTouchStart={handleSubmit(applyFilter)}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default ListingUnitPerAgentFilter;
