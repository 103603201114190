import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '@/assets/images/logo.svg';

const Main = () => {
  const { t } = useTranslation();

  return (
    <div
      className="content-wrap full-page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>
        {t('main.welcome')}{' '}
        <img src={logo} alt="logo" style={{ verticalAlign: 'middle' }} />
      </h1>
      <p>{t('main.login')}</p>
    </div>
  );
};

export default Main;
