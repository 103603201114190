import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/store';
import { EPerPage, EReportName } from '@/store/enums';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { getReport, loadReport } from '@/store/reportSlice';
import { TReportTotalItems } from '@/store/types';
import { MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';

import './ReportTablePagination.scss';

const ReportTablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const keySettings = useSelector(getKeySettings);
  const { t } = useTranslation();
  const report = useSelector(getReport);
  const [page, setPage] = useState<number>(keySettings.page);
  const [rowsPerPage, setRowsPerPage] = useState<number>(keySettings.perPage);
  const [pages, setPages] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: SelectChangeEvent<number | string>
  ) => {
    setRowsPerPage(
      typeof event.target.value === 'string'
        ? parseInt(event.target.value, 10)
        : event.target.value
    );
  };

  const firstPageValue = useRef(true);
  const firstPerPageValue = useRef(true);

  useEffect(() => {
    setPage(keySettings.page);
  }, [keySettings.page]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'page',
        value: page,
      })
    );

    if (firstPageValue.current) {
      firstPageValue.current = false;
    } else {
      dispatch(loadReport(false));
      dispatch(getSavedSearchList());
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'perPage',
        value: rowsPerPage,
      })
    );

    if (firstPerPageValue.current) {
      firstPerPageValue.current = false;
    } else {
      dispatch(
        setKeySettings({
          key: 'page',
          value: 1,
        })
      );
      dispatch(loadReport(false));
      dispatch(getSavedSearchList());
    }
  }, [rowsPerPage]);

  useEffect(() => {
    setRowsPerPage(keySettings.perPage);
  }, [keySettings.perPage]);

  useEffect(() => {
    if (
      typeof report.totalItems === 'object' &&
      report.totalItems?.totalItems
    ) {
      setPages(() =>
        Math.ceil(
          // @ts-ignore
          (report.totalItems.totalItems as TReportTotalItems) /
            keySettings.perPage
        )
      );
    } else if (typeof report.totalItems === 'number') {
      setPages(() =>
        Math.ceil((report.totalItems as number) / keySettings.perPage)
      );
    }
  }, [report.totalItems]);

  return (
    <div className="report-table-pagination">
      <Pagination count={pages} page={page} onChange={handleChangePage} />
      <Select
        value={rowsPerPage}
        onChange={handleChangeRowsPerPage}
        sx={{ border: 'none' }}
      >
        {Object.values(EPerPage).map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <span>{t('per_page')}</span>
    </div>
  );
};

export default ReportTablePagination;
