import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from '@/router/consts';
import { AppDispatch } from '@/store';
import { handleLogin } from '@/store/authSlice';

import LoginDialog from './LoginDialog';

const LoginButton = () => {
  const [isLoginDialogOpened, setIsLoginDialogOpened] =
    useState<boolean>(false);
  const openLoginDialog = () => setIsLoginDialogOpened(true);
  const closeLoginDialog = () => setIsLoginDialogOpened(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const login = (username: string, password: string) => {
    dispatch(handleLogin({ username, password }));
    closeLoginDialog();
    navigate(ROUTE.KEY_REPORTS, { replace: true });
  };
  const { t } = useTranslation();

  return (
    <>
      <a style={{ marginLeft: 'auto', height: 52 }} onClick={openLoginDialog}>
        {t('authentication.buttons.login')}
      </a>
      <LoginDialog
        open={isLoginDialogOpened}
        onLogin={login}
        onClose={closeLoginDialog}
      />
    </>
  );
};

export default LoginButton;
