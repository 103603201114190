import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { apiClient } from '@/ApiClient';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import useDebounce from '@/hooks/useDebounce';
import StyledCheckbox from '@/mui-styled-components/checkbox';
import StyledRadio from '@/mui-styled-components/radio';
import StyledTextField from '@/mui-styled-components/text-field';
import { objToQueryString } from '@/router/router';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { TBrandInfo } from '@/store/types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';

import './BrandFilter.scss';
import '../Display/Count.scss';

const BrandFilter = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [search, setSearch] = useState<string>('');
  const [include, setInclude] = useState<string>(
    keySettings.brandInclude ? 'include' : 'exclude'
  );
  const [items, setItems] = useState<Array<TBrandInfo>>([]);
  const [selected, setSelected] = useState<readonly number[]>(
    keySettings.brandIds
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const debouncedSearch = useDebounce(search, 800);

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setSearch('');
    setSelected([]);
  };
  const handleClose = () => {
    if (selected.length) {
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClick = (
    event: React.MouseEvent<unknown> | React.TouchEvent<unknown>,
    id: number
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleBrandInclude = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;

    setInclude(value);

    await dispatch(
      setKeySettings({
        key: 'brandInclude',
        value: value === 'include' ? 'true' : 'false',
      })
    );
  };

  const handleClear = async () => {
    setSelected([]);
    await dispatch(
      setKeySettings({
        key: 'brandIds',
        value: [],
      })
    );
    await dispatch(
      setKeySettings({
        key: 'brandInclude',
        value: 'true',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const clearSearch = () => {
    setSelected([]);
    setSearch('');
    setItems([]);
  };

  const applyFilter = async () => {
    handleClose();
    const brandIds = selected.map((item) => item);

    await dispatch(
      setKeySettings({
        key: 'brandIds',
        value: brandIds,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  useEffect(() => {
    setSelected([]);
    setItems([]);

    const searchResults = () => {
      setIsLoading(true);
      // eslint-disable-next-line
      const {
        mls,
        reportName,
        timeFrame,
        brandInclude,
        savedSearch,
        savedSearchList,
        ...fields
      } = keySettings;

      const query = objToQueryString({
        ...fields,
        brand: debouncedSearch,
      });

      apiClient
        .searchBrand(mls, reportName, query)
        .then((data) => {
          setIsLoading(false);
          setItems(data);
        })
        .catch((error) => {
          setIsLoading(false);
          return Promise.reject(error);
        });
    };

    if (debouncedSearch.length > 2) {
      searchResults();
    } else {
      setItems([]);
    }
  }, [debouncedSearch]);

  const icon = keySettings.brandIds.length ? 'filter' : 'search';
  const tooltipText = `${t('brands_selected_tooltip', {
    count: Array.isArray(keySettings.brandIds)
      ? keySettings.brandIds.length
      : 1,
  })}. ${t('click_clear')}`;

  return (
    <div className="column-search-menu-wrap">
      <div
        onClick={(e) => {
          keySettings.brandIds.length ? handleClear() : handleOpen(e);
        }}
        onTouchStart={(e) => {
          keySettings.brandIds.length ? handleClear() : handleOpen(e);
        }}
      >
        {keySettings.brandIds.length ? (
          <Tooltip title={tooltipText}>
            <IconButton aria-label="search" className="searchBtn">
              <SpriteIcon icon={icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
        )}
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 brand-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="d-flex align-items-center">
          <SpriteIcon icon="search" />
          <StyledTextField
            id="column-search-field"
            className="form-input"
            value={search}
            placeholder={t('type_to_search')}
            variant="standard"
            sx={{ minWidth: 228, paddingLeft: '10px', paddingRight: '10px' }}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search && search.length ? (
            <IconButton
              className="clear-search"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={clearSearch}
              onTouchStart={clearSearch}
            >
              <SpriteIcon icon="cancel" />
            </IconButton>
          ) : null}
        </div>
        <TableContainer sx={{ minHeight: 210, maxHeight: 210 }}>
          {items.length ? (
            <Table aria-labelledby="tableTitle" size="medium">
              <TableBody>
                {items.map((row: TBrandInfo, index) => {
                  const isItemSelected = isSelected(row.id);
                  const keys = Object.keys(row).map(
                    (item: string) => item as keyof TBrandInfo
                  );

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      onClick={(event) => handleClick(event, row.id)}
                      onTouchStart={(event) => handleClick(event, row.id)}
                    >
                      <TableCell
                        sx={{ width: '40px !important', padding: '5px' }}
                      >
                        <StyledCheckbox
                          color="primary"
                          checked={isItemSelected}
                          size="small"
                          sx={{
                            padding: '5px',
                          }}
                        />
                      </TableCell>

                      {keys.map((item, index) =>
                        item !== 'id' ? (
                          <TableCell
                            key={index}
                            sx={{
                              width: 'auto !important',
                              padding: '5px !important',
                            }}
                          >
                            {row[item]}
                          </TableCell>
                        ) : null
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : isLoading ? (
            <ProgressLoader size={100} style={{ minHeight: '210px' }} />
          ) : (
            <div
              style={{
                width: '100%',
                minHeight: 210,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p>{t('messages.no_results')}</p>
            </div>
          )}
        </TableContainer>
        <div className="filter-footer">
          <RadioGroup
            row
            aria-labelledby="brand-include"
            defaultValue="female"
            name="brand-include-group"
            value={include}
            onChange={handleBrandInclude}
          >
            <FormControlLabel
              value="include"
              control={<StyledRadio />}
              label={t('include')}
            />
            <FormControlLabel
              value="exclude"
              control={<StyledRadio />}
              label={t('exclude')}
            />
          </RadioGroup>
        </div>
        <div className="filter-footer">
          <span className="count text">
            <Trans
              i18nKey="brands_selected"
              count={selected.length}
              components={{ span: <span /> }}
            ></Trans>
          </span>
          <Button
            className="filled-btn"
            disabled={!selected.length}
            onClick={applyFilter}
            onTouchStart={applyFilter}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default BrandFilter;
