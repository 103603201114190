import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from '@/components/Footer/Footer';
import { Menu } from '@/components/Menu';
import ProgressLoader from '@/components/ProgressLoader/ProgressLoader';
import AppRouter from '@/router/AppRouter';
import { AppDispatch } from '@/store';
import { getAuthReady, init } from '@/store/authSlice';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authReady = useSelector(getAuthReady);
  moment.locale('en');

  useEffect(() => {
    dispatch(init());
  }, []);

  return (
    <>
      {authReady ? (
        <BrowserRouter>
          <Menu />
          <AppRouter />
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      ) : (
        <ProgressLoader />
      )}
    </>
  );
};

export default App;
