import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AccountDetails from '@/components/Account/AccountDetails';
import PaymentInfo from '@/components/Account/PaymentInfo';
import { getUser } from '@/store/authSlice';
import { Button } from '@mui/material';

import './Account.scss';

const Account = () => {
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const [activeDetails, setActiveDetails] = useState(true);

  const title = activeDetails
    ? t('account.account_title')
    : t('account.payment_title');
  return (
    <>
      {user && (
        <div className="content-wrap account-wrap">
          <div className="section-header">
            <h2 className="headline-1">{title}</h2>
            {/*<div style={{ marginLeft: 'auto', display: 'flex' }}>*/}
            {/*  <Button*/}
            {/*    variant="outlined"*/}
            {/*    className={activeDetails ? 'active' : ''}*/}
            {/*    onClick={() => setActiveDetails(true)}*/}
            {/*  >*/}
            {/*    {t('account.buttons.details')}*/}
            {/*  </Button>*/}
            {/*  <Button*/}
            {/*    variant="outlined"*/}
            {/*    className={!activeDetails ? 'active' : ''}*/}
            {/*    onClick={() => setActiveDetails(false)}*/}
            {/*  >*/}
            {/*    {t('account.buttons.info')}*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
          {activeDetails && <AccountDetails />}
          {!activeDetails && <PaymentInfo />}
        </div>
      )}
    </>
  );
};

export default Account;
