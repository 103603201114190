import React from 'react';
import { useTranslation } from 'react-i18next';

import SavedSearchWidget from '../SavedSearchWidget/SavedSearchWidget';

import './SavedSearches.scss';

const SavedSearches = () => {
  const { t } = useTranslation();
  return (
    <div className="saved-searches-select">
      <label className="form-label" htmlFor="saved">
        {t('key_settings.saved_searches')}
      </label>
      <div className="d-flex w-100">
        <SavedSearchWidget />
      </div>
    </div>
  );
};

export default SavedSearches;
