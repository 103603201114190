import React from 'react';

import { Button } from '@mui/material';

type Button = {
  label: string;
};

const ButtonMenu: React.FC<{
  buttons: Button[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}> = ({ buttons, activeIndex, setActiveIndex }) => (
  <>
    {buttons.map((button, index) => (
      <Button
        key={index}
        variant="outlined"
        className={activeIndex === index ? 'active' : ''}
        onClick={() => setActiveIndex(index)}
        onTouchStart={() => setActiveIndex(index)}
      >
        {button.label}
      </Button>
    ))}
  </>
);

export default ButtonMenu;
