import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StyledSelect from '@/mui-styled-components/select';
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface TimePeriodOption {
  value: string;
  label: string;
}

interface TimePeriodSelectProps {
  timePeriod: string;
  handleTimePeriod: (event: SelectChangeEvent<string | undefined>) => void;
  options: TimePeriodOption[];
}

const TimePeriodSelect: React.FC<TimePeriodSelectProps> = ({
  timePeriod,
  handleTimePeriod,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <InputLabel
        id="timePeriod-select-label"
        sx={{
          '&.Mui-focused': {
            color: `var(--blue-color)`,
          },
        }}
      >
        <Trans i18nKey="chart.time_period"></Trans>
      </InputLabel>
      <StyledSelect
        labelId="timePeriod-select-label"
        value={timePeriod}
        //@ts-ignore
        onChange={handleTimePeriod}
        label={t('chart.time_period')}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <Trans i18nKey={item.label}></Trans>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default TimePeriodSelect;
