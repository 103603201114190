import React from 'react';

import url from '@/assets/images/sprite.svg';

const SpriteIcon = (props: {
  icon: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: any) => void;
  onTouchStart?: (event: any) => void;
}) => {
  const { icon, style, onClick, onTouchStart, className, ...otherProps } =
    props;

  return (
    <svg
      viewBox="0 0 16 16"
      className={`sprite-icon sprite-${icon} ${className ? className : ''}`}
      style={style}
      {...otherProps}
      onClick={onClick}
      onTouchStart={onTouchStart}
    >
      <use xlinkHref={`${url}#${icon}`} />
    </svg>
  );
};

export default SpriteIcon;
