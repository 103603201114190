import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import logo from '@/assets/images/logo.svg';
import LoginButton from '@/Authentication/LoginButton';
import LanguageMenu from '@/components/Menu/LanguageMenu';
import UserMenu from '@/components/Menu/UserMenu';
import { ROUTE } from '@/router/consts';
import { getProfile } from '@/store/authSlice';
import { AppBar, Box, Link, Toolbar } from '@mui/material';

import './Menu.scss';

const showLangMenu = false;

const MenuApp = () => {
  const user = useSelector(getProfile);
  const [params, setParams] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      setParams(window.location.search);
    }

    if (window.location.pathname !== ROUTE.MAP && !user) {
      navigate(ROUTE.MAIN, { replace: true });
    } else if (window.location.pathname === ROUTE.MAIN) {
      navigate(ROUTE.KEY_REPORTS, { replace: true });
    }
  }, [user]);

  return (
    <Box className="app-header" sx={{ flexBasis: 52 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: '#fff', boxShadow: 'none' }}
      >
        <Toolbar
          className="mainMenu"
          sx={{ height: '52px', minHeight: '52px!important' }}
        >
          <Link href={ROUTE.MAIN}>
            <img src={logo} alt="logo" />
          </Link>
          {user ? (
            <>
              <NavLink to={ROUTE.KEY_REPORTS} style={{ marginRight: 'auto' }}>
                {t('menu.key_reports')}
              </NavLink>
              {/*<NavLink to={ROUTE.SUMMARY}>*/}
              {/*  {t('menu.summary')}*/}
              {/*</NavLink>*/}
              {/*<NavLink to={ROUTE.MARKET_TRENDS}>*/}
              {/*  {t('menu.market_trends')}*/}
              {/*</NavLink>*/}
              {/*<NavLink to={ROUTE.KPM_ANALYSER}>*/}
              {/*  {t('menu.kpm_analyser')}*/}
              {/*</NavLink>*/}
              {/*<NavLink to={ROUTE.HOTSHEETS} style={{ marginRight: 'auto' }}>*/}
              {/*  {t('menu.hot_sheets')}*/}
              {/*</NavLink>*/}
              {/*<NavLink to={ROUTE.HELP} className="menuBtn ml-4">*/}
              {/*  {t('menu.help')}*/}
              {/*</NavLink>*/}
              {/*<NavLink to={ROUTE.BOOKINGS} className="menuBtn">*/}
              {/*  {t('menu.bookings')}*/}
              {/*</NavLink>*/}
              {showLangMenu && <LanguageMenu />}
              <UserMenu />
            </>
          ) : (
            <LoginButton />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuApp;
