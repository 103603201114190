import React from 'react';
import { useTranslation } from 'react-i18next';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { Grid, Paper } from '@mui/material';

import './Help.scss';

const Help = () => {
  const { t } = useTranslation();

  return (
    <div
      className="content-wrap full-page"
      style={{ flexDirection: 'column', padding: 0 }}
    >
      <div className="section-header">
        <h2 className="headline-1">{t('help.title')}</h2>
      </div>
      <div className="help-content">
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="elevation-block">
              <div className="circle-wrap">
                <SpriteIcon icon="mail_outline" />
              </div>
              <h4>{t('help.newsletters')}</h4>
              <span>{t('help.newsletters_text')}</span>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="elevation-block">
              <div className="circle-wrap">
                <SpriteIcon icon="copy" />
              </div>
              <h4>{t('help.documents')}</h4>
              <span>{t('help.documents_text')}</span>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="elevation-block">
              <div className="circle-wrap">
                <SpriteIcon icon="play_circle" />
              </div>
              <h4>{t('help.videos')}</h4>
              <span>{t('help.videos_text')}</span>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Help;
