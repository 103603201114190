import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalProps } from '@/store/types';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 970,
  bgcolor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CodeOfConductModal = (props: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={props.closeHandler}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      classes={{ root: 'coc-modal' }}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={props.closeHandler}
          onTouchStart={props.closeHandler}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <h2 id="keep-mounted-modal-title" style={{ marginTop: 0 }}>
          {t('footer.coc_title')}
        </h2>
        <p style={{ fontWeight: 'bold', color: '#000' }}>
          {t('footer.coc_sub_title')}
        </p>
        <Trans id="keep-mounted-modal-description" i18nKey="coc_text" />
      </Box>
    </Modal>
  );
};

export default CodeOfConductModal;
