import moment from 'moment';

import { apiClient } from '@/ApiClient';
import { EFilters, ETimeFrames } from '@/store/enums';
import { AppDispatch, store } from '@/store/index';
import { ILabelItem, IListItem, IProfile } from '@/store/types';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  filterLists: {
    labels: Array<ILabelItem>;
    timeFrameList: Array<string>;
    firstDate: string | null;
    lastDate: string | null;
    cityList: Array<IListItem>;
    classList: Array<IListItem>;
    countyList: Array<IListItem>;
    districtList: Array<IListItem>;
    subdivisionList: Array<IListItem>;
    zipList: Array<IListItem>;
    typeList: Array<IListItem>;
    styleList: Array<IListItem>;
    school0List: Array<IListItem>;
    school1List: Array<IListItem>;
    school2List: Array<IListItem>;
    loaded: boolean;
  };
}

export const filterListsSlice = createSlice({
  name: 'filterLists',
  initialState: {
    labels: [],
    school0List: [],
    school1List: [],
    school2List: [],
    cityList: [],
    classList: [],
    countyList: [],
    districtList: [],
    subdivisionList: [],
    zipList: [],
    typeList: [],
    styleList: [],
    timeFrameList: [],
    firstDate: null,
    lastDate: null,
    loaded: false,
  },
  reducers: {
    setFilters: (
      state,
      action: {
        payload: {
          key: string;
          value: any;
        };
        type: string;
      }
    ) =>
      Object.assign(state || {}, {
        [action.payload.key]: action.payload.value,
      }),
  },
});

export const { setFilters } = filterListsSlice.actions;

export const getFilterLists = (state: InitialState) => state.filterLists;

export default filterListsSlice.reducer;

export const loadFilters = (mlsId: number, filter: string) => {
  return apiClient.loadFilters(mlsId, filter);
};

export const loadTimeFrameFilters = (mlsId: number) => {
  return apiClient.loadTimeFrameFilters(mlsId);
};

export const loadLabels = (mlsId: number) => {
  return apiClient.mlsLabels(mlsId);
};

export const loadFilterLists =
  (rerender = true) =>
  async (dispatch: AppDispatch) => {
    if (rerender) {
      dispatch(setFilters({ key: `loaded`, value: false }));
    }

    const mlsId = store.getState().keySettings.mls;

    if (!mlsId) {
      return;
    }

    const promises = [];

    const labelsPromise = await loadLabels(mlsId);
    dispatch(setFilters({ key: 'labels', value: labelsPromise }));

    const timeFramePromise = await loadTimeFrameFilters(mlsId);
    const data = timeFramePromise;

    const user = store.getState().auth.profile;
    const userStart = user
      ? (user as IProfile).firstDate
        ? moment.utc((user as IProfile).firstDate)
        : null
      : null;
    const userEnd = user
      ? (user as IProfile).lastDate
        ? moment.utc((user as IProfile).lastDate)
        : null
      : null;

    const startDatabase = moment.utc(data.firstDate);
    const endDatabase = moment.utc(data.lastDate);

    let startYear = moment.utc(data.firstDate).year();
    let endYear = moment.utc(data.lastDate).year();
    let startDate = moment.utc(data.firstDate).format('YYYY-MM-DD');
    let endDate = moment.utc(data.lastDate).format('YYYY-MM-DD');

    if (userStart) {
      if (userStart > startDatabase) {
        // @ts-ignore
        startYear = moment.utc(user.firstDate).year();
        // @ts-ignore
        startDate = moment.utc(user.firstDate).format('YYYY-MM-DD');
      } else {
        startYear = moment.utc(data.firstDate).year();
      }
    }

    if (userEnd) {
      if (userEnd < endDatabase) {
        // @ts-ignore
        endYear = moment.utc(user.lastDate).year();
        // @ts-ignore
        endDate = moment.utc(user.lastDate).format('YYYY-MM-DD');
      } else {
        endYear = moment.utc(data.lastDate).year();
      }
    }

    const arr = Array.from(
      { length: endYear - startYear },
      (_, i) => `${startYear + 1 + i}`
    );
    arr.unshift(startYear.toString());

    const defaults = Object.values(ETimeFrames);

    dispatch(
      setFilters({
        key: `timeFrameList`,
        value: [...defaults, ...arr.reverse()],
      })
    );

    dispatch(setFilters({ key: `firstDate`, value: startDate }));
    dispatch(setFilters({ key: `lastDate`, value: endDate }));

    promises.push(timeFramePromise);

    for (const item of Object.values(EFilters)) {
      if (item !== EFilters.FILTERS_TIME_FRAME) {
        const promise = await loadFilters(mlsId, item);
        dispatch(setFilters({ key: `${item}List`, value: promise }));
        promises.push(promise);
      }
    }

    await Promise.all(promises);
    dispatch(setFilters({ key: `loaded`, value: true }));

    return endDate;
  };

export const initFilterLists = () => (dispatch: AppDispatch) => {
  dispatch(loadFilterLists());
};
