import * as Yup from 'yup';

import i18n from '../i18n/index';

export const maxNumber = 99999999999;

export const phoneNumberRegexp = /^\+[0-9]{1,12}$/;

// length: min: 6, max: 256;
const passwordRegexp = /(?=^.{6,256}$)(?![\n]).*$/;

export const onlyNumbersWithOneDot = (value: any, isMinusSign = false) =>
  new RegExp(
    `^${
      isMinusSign ? '-?' : ''
    }(?!0{1,}\\d)([0-9]+(,\\d{3})*([.|,][0-9]{0,3})?|-?)$`,
    'g'
  ).test(value);

export const onlyNumbersWithOneComma = (value: any, isMinusSign = false) =>
  new RegExp(
    `^${
      isMinusSign ? '-?' : ''
    }(?!0{1,}\\d)([0-9]+(,\\d{3})*([,][0-9]{0,3})?|-?)$`,
    'g'
  ).test(value);

export const onlyNumbers = (value: any, isMinusSign = false) =>
  new RegExp(`^${isMinusSign ? '-?' : ''}([0-9]+|-?)$`, 'g').test(value);

export const emailRequirements = Yup.string()
  .email(i18n.t('requirements.email'))
  .max(200, i18n.t('requirements.max_length', { length: 200 }));

export const firstNameRequirements = Yup.string().max(
  50,
  i18n.t('requirements.max_length', { length: 50 })
);

export const lastNameRequirements = Yup.string().max(
  50,
  i18n.t('requirements.max_length', { length: 50 })
);

export const passwordRequirements = Yup.string().matches(passwordRegexp, {
  message: i18n.t('requirements.password'),
  excludeEmptyString: true,
});

export const phoneRequirements = Yup.string().matches(phoneNumberRegexp, {
  message: i18n.t('requirements.phone'),
  excludeEmptyString: true,
});
