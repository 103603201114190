import moment from 'moment/moment';

import { AppDispatch } from '@/store';
import { EOrder, EReportName, EReportUnit } from '@/store/enums';
import {
  getSavedSearchList,
  resetFilters,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { RowWithButton } from '@/store/types';

interface Params {
  reportName: string;
  orderDirection: EOrder;
  [key: string]: string | EOrder;
}

export const getParamsForPerformance = (
  key: string,
  reportUnit: string | EReportUnit,
  row: RowWithButton | null,
  id: string
): Params => {
  const params: Params = {
    reportName: 'performance',
    orderDirection: EOrder.ASC,
  };

  switch (key) {
    case 'agentName':
      setParamsForAgent(params, row ? getIdFromRowForAgent(row) : id);
      break;
    case 'brandName':
      setParamsForBrand(
        params,
        row ? getIdFromRowForBrand(row, reportUnit) : id
      );
      break;
    case 'firmName':
      setParamsForFirm(params, row ? getIdFromRowForFirm(row, reportUnit) : id);
      break;
    case 'officeName':
      setParamsForOffice(
        params,
        row ? getIdFromRowForOffice(row, reportUnit) : id
      );
      break;
  }

  return params;
};

export const getParamsForTransactions = (
  key: string,
  reportUnit: string | EReportUnit,
  row: RowWithButton | null,
  id: string
): Record<string, string> => {
  const params: Record<string, string> = {};

  switch (key) {
    case 'agentName':
      setParamsForAgent(params, row ? getIdFromRowForAgent(row) : id);
      params.reportName = EReportName.AGENT_TRANSACTIONS;
      params.reportUnit = EReportUnit.AGENTS;
      break;
    case 'brandName':
      setParamsForBrand(
        params,
        row ? getIdFromRowForBrand(row, reportUnit) : id
      );
      params.reportName = EReportName.BRAND_TRANSACTIONS;
      params.reportUnit = EReportUnit.BRANDS;
      break;
    case 'firmName':
      setParamsForFirm(params, row ? getIdFromRowForFirm(row, reportUnit) : id);
      params.reportName = EReportName.FIRM_TRANSACTIONS;
      params.reportUnit = EReportUnit.FIRMS;
      break;
    case 'officeName':
      setParamsForOffice(
        params,
        row ? getIdFromRowForOffice(row, reportUnit) : id
      );
      params.reportName = EReportName.OFFICE_TRANSACTIONS;
      params.reportUnit = EReportUnit.OFFICES;
      break;
  }

  return params;
};

export const getParamsForHistory = (
  key: string,
  row: RowWithButton | null,
  id: string
): Record<string, string> => {
  const params: Record<string, string> = {};

  switch (key) {
    case 'agentName':
      setParamsForAgent(params, row ? getIdFromRowForAgent(row) : id);
      params.reportName = EReportName.AGENT_HISTORY;
      params.reportUnit = EReportUnit.AGENTS;
      break;
  }

  return params;
};

export const getParamsForPerformanceDistribution = (
  key: string,
  reportUnit: string | EReportUnit,
  data: string,
  row: RowWithButton | null,
  id: string
): Record<string, string> => {
  const params: Record<string, string> = {};

  switch (key) {
    case 'agentName':
      setParamsForAgent(params, row ? getIdFromRowForAgent(row) : id);
      params.reportName = EReportName.AGENTS_DISTRIBUTION;
      params.reportUnit = EReportUnit.AGENTS;
      params.data = data;
      break;
    case 'brandName':
      setParamsForBrand(
        params,
        row ? getIdFromRowForBrand(row, reportUnit) : id
      );
      params.reportName = EReportName.BRANDS_DISTRIBUTION;
      params.reportUnit = EReportUnit.BRANDS;
      params.data = data;
      break;
    case 'firmName':
      setParamsForFirm(params, row ? getIdFromRowForFirm(row, reportUnit) : id);
      params.reportName = EReportName.FIRMS_DISTRIBUTION;
      params.reportUnit = EReportUnit.FIRMS;
      params.data = data;
      break;
    case 'officeName':
      setParamsForOffice(
        params,
        row ? getIdFromRowForOffice(row, reportUnit) : id
      );
      params.reportName = EReportName.OFFICES_DISTRIBUTION;
      params.reportUnit = EReportUnit.OFFICES;
      params.data = data;
      break;
  }

  return params;
};

export const getParamsForDistribution = (
  key: string,
  reportUnit: string | EReportUnit,
  row: RowWithButton | null,
  id: string
): Record<string, string> => {
  const params: Record<string, string> = {};

  switch (key) {
    case 'brandName':
      setParamsForBrand(
        params,
        row ? getIdFromRowForBrand(row, reportUnit) : id
      );
      params.reportName = EReportName.AGENT_DISTRIBUTION;
      params.reportUnit = EReportUnit.BRANDS;
      break;
    case 'firmName':
      setParamsForFirm(params, row ? getIdFromRowForFirm(row, reportUnit) : id);
      params.reportName = EReportName.AGENT_DISTRIBUTION;
      params.reportUnit = EReportUnit.FIRMS;
      break;
    case 'officeName':
      setParamsForOffice(
        params,
        row ? getIdFromRowForOffice(row, reportUnit) : id
      );
      params.reportName = EReportName.AGENT_DISTRIBUTION;
      params.reportUnit = EReportUnit.OFFICES;
      break;
  }

  return params;
};

export const getParamsForFilter = (
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  row: RowWithButton | null
): Record<string, string> => {
  const params: Record<string, string> = getBaseParams(reportName, reportUnit);

  switch (key) {
    case 'agentName':
      setParamsForAgent(params, row ? getIdFromRowForAgent(row) : '');
      break;
    case 'brandName':
      setParamsForBrand(
        params,
        row ? getIdFromRowForBrand(row, reportUnit) : ''
      );
      break;
    case 'firmName':
      setParamsForFirm(params, row ? getIdFromRowForFirm(row, reportUnit) : '');
      break;
    case 'officeName':
      setParamsForOffice(
        params,
        row ? getIdFromRowForOffice(row, reportUnit) : ''
      );
      break;
  }

  return params;
};

function getBaseParams(
  reportName: string | EReportName,
  reportUnit: string | EReportUnit
): Record<string, string> {
  const params: Record<string, string> = {
    reportName,
    reportUnit,
  };

  return params;
}

function getIdFromRowForAgent(row: RowWithButton): string {
  return row.id?.toString() ?? '';
}

function getIdFromRowForBrand(
  row: RowWithButton,
  reportUnit: string | EReportUnit
): string {
  if (reportUnit === EReportUnit.BRANDS) {
    return row.id?.toString() ?? '';
  }

  return row.brandId?.toString() ?? '';
}

function getIdFromRowForFirm(
  row: RowWithButton,
  reportUnit: string | EReportUnit
): string {
  if (reportUnit === EReportUnit.FIRMS) {
    return row.id?.toString() ?? '';
  }

  return row.firmId?.toString() ?? '';
}

function getIdFromRowForOffice(
  row: RowWithButton,
  reportUnit: string | EReportUnit
): string {
  if (reportUnit === EReportUnit.OFFICES) {
    return row.id?.toString() ?? '';
  }

  return row.officeId?.toString() ?? '';
}

function setKeySettingsForAgent(id: string, dispatch: AppDispatch): void {
  dispatch(setKeySettings({ key: 'agentIds', value: id }));
}

function setKeySettingsForBrand(id: string, dispatch: AppDispatch): void {
  dispatch(setKeySettings({ key: 'brandIds', value: id }));
}

function setKeySettingsForFirm(id: string, dispatch: AppDispatch): void {
  dispatch(setKeySettings({ key: 'firmIds', value: id }));
}

function setKeySettingsForOffice(id: string, dispatch: AppDispatch): void {
  dispatch(setKeySettings({ key: 'officeIds', value: id }));
}

function setParamsForAgent(
  params: Record<string, string | EOrder>,
  id: string
): void {
  params.agentIds = id;
  params.brandIds = '';
  params.officeIds = '';
  params.firmIds = '';
}

function setParamsForBrand(
  params: Record<string, string | EOrder>,
  id: string
): void {
  params.brandIds = id;
  params.agentIds = '';
  params.officeIds = '';
  params.firmIds = '';
}

function setParamsForFirm(
  params: Record<string, string | EOrder>,
  id: string
): void {
  params.firmids = id;
  params.agentIds = '';
  params.brandIds = '';
  params.officeIds = '';
}

function setParamsForOffice(
  params: Record<string, string | EOrder>,
  id: string
): void {
  params.officeIds = id;
  params.agentIds = '';
  params.brandIds = '';
  params.firmIds = '';
}

export const handlingFilter = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  chartType: string,
  perPage: number,
  row: RowWithButton | null
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  switch (key) {
    case 'agentName':
      setKeySettingsForAgent(row ? getIdFromRowForAgent(row) : '', dispatch);
      break;
    case 'brandName':
      setKeySettingsForBrand(
        row ? getIdFromRowForBrand(row, reportUnit) : '',
        dispatch
      );
      break;
    case 'firmName':
      setKeySettingsForFirm(
        row ? getIdFromRowForFirm(row, reportUnit) : '',
        dispatch
      );
      break;
    case 'officeName':
      setKeySettingsForOffice(
        row ? getIdFromRowForOffice(row, reportUnit) : '',
        dispatch
      );
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

export const handlingPerformance = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  chartType: string,
  perPage: number,
  row: RowWithButton | null,
  id: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  dispatch(setKeySettings({ key: 'reportName', value: 'performance' }));
  dispatch(setKeySettings({ key: 'orderDirection', value: null }));

  switch (key) {
    case 'agentName':
      setKeySettingsForAgent(row ? getIdFromRowForAgent(row) : id, dispatch);
      break;
    case 'brandName':
      setKeySettingsForBrand(
        row ? getIdFromRowForBrand(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'firmName':
      setKeySettingsForFirm(
        row ? getIdFromRowForFirm(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'officeName':
      setKeySettingsForOffice(
        row ? getIdFromRowForOffice(row, reportUnit) : id,
        dispatch
      );
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

export const handlingTransactions = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  chartType: string,
  perPage: number,
  row: RowWithButton | null,
  id: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  switch (key) {
    case 'agentName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENT_TRANSACTIONS,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      setKeySettingsForAgent(row ? getIdFromRowForAgent(row) : id, dispatch);
      break;
    case 'brandName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.BRAND_TRANSACTIONS,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.BRANDS })
      );
      setKeySettingsForBrand(
        row ? getIdFromRowForBrand(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'firmName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.FIRM_TRANSACTIONS,
        })
      );
      dispatch(setKeySettings({ key: 'reportUnit', value: EReportUnit.FIRMS }));
      setKeySettingsForFirm(
        row ? getIdFromRowForFirm(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'officeName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.OFFICE_TRANSACTIONS,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.OFFICES })
      );
      setKeySettingsForOffice(
        row ? getIdFromRowForOffice(row, reportUnit) : id,
        dispatch
      );
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

export const handlingHistory = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  chartType: string,
  perPage: number,
  row: RowWithButton | null,
  id: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  switch (key) {
    case 'agentName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENT_HISTORY,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      setKeySettingsForAgent(row ? getIdFromRowForAgent(row) : id, dispatch);
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

export const handlingPerformanceDistribution = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  data: string,
  chartType: string,
  perPage: number,
  row: RowWithButton | null,
  id: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  switch (key) {
    case 'agentName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENTS_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      dispatch(setKeySettings({ key: 'data', value: data }));
      setKeySettingsForAgent(row ? getIdFromRowForAgent(row) : id, dispatch);
      break;
    case 'brandName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.BRANDS_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.BRANDS })
      );
      dispatch(setKeySettings({ key: 'data', value: data }));
      setKeySettingsForBrand(
        row ? getIdFromRowForBrand(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'firmName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.FIRMS_DISTRIBUTION,
        })
      );
      dispatch(setKeySettings({ key: 'reportUnit', value: EReportUnit.FIRMS }));
      dispatch(setKeySettings({ key: 'data', value: data }));
      setKeySettingsForFirm(
        row ? getIdFromRowForFirm(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'officeName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.OFFICES_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.OFFICES })
      );
      dispatch(setKeySettings({ key: 'data', value: data }));
      setKeySettingsForOffice(
        row ? getIdFromRowForOffice(row, reportUnit) : id,
        dispatch
      );
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

export const handlingDistribution = (
  dispatch: AppDispatch,
  key: string,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  chartType: string,
  perPage: number,
  row: RowWithButton | null,
  id: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  switch (key) {
    case 'brandName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENT_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      setKeySettingsForBrand(
        row ? getIdFromRowForBrand(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'firmName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENT_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      setKeySettingsForFirm(
        row ? getIdFromRowForFirm(row, reportUnit) : id,
        dispatch
      );
      break;
    case 'officeName':
      dispatch(
        setKeySettings({
          key: 'reportName',
          value: EReportName.AGENT_DISTRIBUTION,
        })
      );
      dispatch(
        setKeySettings({ key: 'reportUnit', value: EReportUnit.AGENTS })
      );
      setKeySettingsForOffice(
        row ? getIdFromRowForOffice(row, reportUnit) : id,
        dispatch
      );
      break;
  }

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};
