import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { maxNumber, onlyNumbersWithOneDot } from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';

import './common.scss';

const listingVolumePerRegisteredAgentValidationSchema = Yup.object().shape({
  listingVolumePerRegisteredAgentMin: Yup.string()
    .nullable()
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
  listingVolumePerRegisteredAgentMax: Yup.string()
    .nullable()
    .when('listingVolumePerRegisteredAgentMin', {
      is: (max: string) =>
        max && parseFloat(max.toString().replace(/,/g, '')) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanListingVolumePerRegisteredAgentMin',
        message: '',
        test() {
          const {
            listingVolumePerRegisteredAgentMin,
            listingVolumePerRegisteredAgentMax,
          } = this.parent;

          return listingVolumePerRegisteredAgentMax !== ''
            ? parseFloat(
                listingVolumePerRegisteredAgentMax.toString().replace(/,/g, '')
              ) >=
                parseFloat(
                  listingVolumePerRegisteredAgentMin
                    .toString()
                    .replace(/,/g, '')
                )
            : true;
        },
      }),
    })
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
});

const ListingVolumePerRegisteredAgentFilter = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    listingVolumePerRegisteredAgentMin,
    setListingVolumePerRegisteredAgentsMin,
  ] = useState<string>('');
  const [
    listingVolumePerRegisteredAgentMax,
    setListingVolumePerRegisteredAgentsMax,
  ] = useState<string>('');
  const open = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(listingVolumePerRegisteredAgentValidationSchema),
  });

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = async () => {
    setListingVolumePerRegisteredAgentsMin('');
    setListingVolumePerRegisteredAgentsMax('');
    await dispatch(
      setKeySettings({
        key: 'listingVolumePerRegisteredAgentMin',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'listingVolumePerRegisteredAgentMax',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const applyFilter = async () => {
    handleClose();
    await dispatch(
      setKeySettings({
        key: 'listingVolumePerRegisteredAgentMin',
        value: listingVolumePerRegisteredAgentMin.replace(/,/g, ''),
      })
    );

    await dispatch(
      setKeySettings({
        key: 'listingVolumePerRegisteredAgentMax',
        value: listingVolumePerRegisteredAgentMax.replace(/,/g, ''),
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const handleListingVolumePerRegisteredAgentsMin = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === '' || onlyNumbersWithOneDot(e.target.value)) {
      setListingVolumePerRegisteredAgentsMin(e.target.value);
      setValue('listingVolumePerRegisteredAgentMin', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const handleListingVolumePerRegisteredAgentsMax = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === '' || onlyNumbersWithOneDot(e.target.value)) {
      setListingVolumePerRegisteredAgentsMax(e.target.value);
      setValue('listingVolumePerRegisteredAgentMax', e.target.value, {
        shouldValidate: true,
      });
    }
  };

  const icon =
    keySettings.listingVolumePerRegisteredAgentMin !== '' ||
    keySettings.listingVolumePerRegisteredAgentMax !== ''
      ? 'filter'
      : 'search';
  const tooltipText = `${
    keySettings.listingVolumePerRegisteredAgentMin !== ''
      ? t('from_selected', {
          quantity: keySettings.listingVolumePerRegisteredAgentMin,
        })
      : ''
  }${' '}${
    keySettings.listingVolumePerRegisteredAgentMax !== ''
      ? t('to_selected', {
          quantity: keySettings.listingVolumePerRegisteredAgentMax,
        })
      : ''
  }${' '}${t('click_clear')}`;

  return (
    <div className="column-search-menu-wrap">
      <div
        onClick={(e) => {
          keySettings.listingVolumePerRegisteredAgentMin !== '' ||
          keySettings.listingVolumePerRegisteredAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
        onTouchStart={(e) => {
          keySettings.listingVolumePerRegisteredAgentMin !== '' ||
          keySettings.listingVolumePerRegisteredAgentMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
      >
        {keySettings.listingVolumePerRegisteredAgentMin !== '' ||
        keySettings.listingVolumePerRegisteredAgentMax !== '' ? (
          <Tooltip title={tooltipText}>
            <IconButton aria-label="search" className="searchBtn">
              <SpriteIcon icon={icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
        )}
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 filter-block-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className="d-flex">
          <div className="d-flex flex-column">
            <label
              className="form-label"
              htmlFor="listingVolumePerRegisteredAgentMin"
            >
              {t('min')}
            </label>
            <StyledTextField
              id="listingVolumePerRegisteredAgentMin"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingVolumePerRegisteredAgentMin}
              variant="outlined"
              sx={{ paddingLeft: '0', paddingRight: '6px', width: '64px' }}
              error={!!errors.listingVolumePerRegisteredAgentMin}
              //@ts-ignore
              helperText={errors?.listingVolumePerRegisteredAgentMin?.message}
              {...register('listingVolumePerRegisteredAgentMin')}
              onChange={handleListingVolumePerRegisteredAgentsMin}
            />
          </div>
          <div className="d-flex flex-column">
            <label
              style={{ paddingLeft: '6px' }}
              className="form-label"
              htmlFor="listingVolumePerRegisteredAgentMax"
            >
              {t('max')}
            </label>
            <StyledTextField
              id="listingVolumePerRegisteredAgentMax"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={listingVolumePerRegisteredAgentMax}
              variant="outlined"
              sx={{ paddingLeft: '6px', paddingRight: '0', width: '64px' }}
              error={!!errors.listingVolumePerRegisteredAgentMax}
              //@ts-ignore
              helperText={errors?.listingVolumePerRegisteredAgentMax?.message}
              {...register('listingVolumePerRegisteredAgentMax')}
              onChange={handleListingVolumePerRegisteredAgentsMax}
            />
          </div>
        </form>
        <div
          className="filter-footer"
          style={{ padding: 0, marginTop: '10px' }}
        >
          <Button
            type="submit"
            style={{ width: '100%' }}
            className="filled-btn"
            disabled={
              listingVolumePerRegisteredAgentMin === '' &&
              listingVolumePerRegisteredAgentMax === ''
            }
            onClick={handleSubmit(applyFilter)}
            onTouchStart={handleSubmit(applyFilter)}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default ListingVolumePerRegisteredAgentFilter;
