import React, { useState } from 'react';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CustomSelect from '@/components/CustomElements/CustomSelect';
import AdvancedSettings from '@/components/KeySettings/AdvancedSettings';
import DateSettings from '@/components/KeySettings/DateSettings';
import GeographySettings from '@/components/KeySettings/GeographySettings';
import OnlyOfficesSettings from '@/components/KeySettings/OnlyOfficesSettings';
import PropertySettings from '@/components/KeySettings/PropertySettings';
import SavedSearches from '@/components/KeySettings/SavedSearches';
import SchoolTypeSettings from '@/components/KeySettings/SchoolTypeSettings';
import { isObjectPropertyNotEmpty } from '@/components/KeySettings/utils';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { addURLParameter } from '@/router/router';
import { AppDispatch, store } from '@/store';
import { getSettings } from '@/store/authSlice';
import { EReportName, ETimeFrames } from '@/store/enums';
import { getFilterLists, loadFilterLists } from '@/store/filterListsSlice';
import {
  getKeySettings,
  getSavedSearchList,
  resetFilters,
  resetKeySettings,
  resetMLSDependentSettings,
  resetToDefaultReport,
  setKeySettings,
  setSavedSearch,
} from '@/store/keySettingsSlice';
import { getMlsList, loadCurrentMls } from '@/store/mlsSlice';
import { initConfig } from '@/store/reportFieldsSlice';
import {
  getReport,
  loadReport,
  resetReport,
  setLoading,
} from '@/store/reportSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SelectChangeEvent } from '@mui/material';

import './KeySettings.scss';

const keySettingsValidationSchema = Yup.object().shape({
  priceMax: Yup.string()
    .nullable()
    .when('priceMin', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanPriceMin',
        message: '',
        test() {
          const { priceMin, priceMax } = this.parent;

          return priceMax !== ''
            ? parseInt(priceMax) >= parseInt(priceMin)
            : true;
        },
      }),
    }),
  bedroomsMaxValue: Yup.string()
    .nullable()
    .when('bedroomsMinValue', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanBedroomsMin',
        message: '',
        test() {
          const { bedroomsMinValue, bedroomsMaxValue } = this.parent;

          return bedroomsMaxValue !== ''
            ? parseInt(bedroomsMaxValue) >= parseInt(bedroomsMinValue)
            : true;
        },
      }),
    }),
  bathsFullMax: Yup.string()
    .nullable()
    .when('bathsFullMin', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanBathsFullMin',
        message: '',
        test() {
          const { bathsFullMin, bathsFullMax } = this.parent;

          return bathsFullMax !== ''
            ? parseInt(bathsFullMax) >= parseInt(bathsFullMin)
            : true;
        },
      }),
    }),
  bathsHalfMax: Yup.string()
    .nullable()
    .when('bathsHalfMin', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanBathsHalfMin',
        message: '',
        test() {
          const { bathsHalfMin, bathsHalfMax } = this.parent;

          return bathsHalfMax !== ''
            ? parseInt(bathsHalfMax) >= parseInt(bathsHalfMin)
            : true;
        },
      }),
    }),
  totalSqFMaxValue: Yup.string()
    .nullable()
    .when('totalSqFMinValue', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanTotalSqFMin',
        message: '',
        test() {
          const { totalSqFMinValue, totalSqFMaxValue } = this.parent;

          return totalSqFMaxValue !== ''
            ? parseInt(totalSqFMaxValue) >= parseInt(totalSqFMinValue)
            : true;
        },
      }),
    }),
  finishedSqFMax: Yup.string()
    .nullable()
    .when('finishedSqFMin', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanFinishedSqFMin',
        message: '',
        test() {
          const { finishedSqFMin, finishedSqFMax } = this.parent;

          return finishedSqFMax !== ''
            ? parseInt(finishedSqFMax) >= parseInt(finishedSqFMin)
            : true;
        },
      }),
    }),
  yearMax: Yup.string()
    .nullable()
    .when('yearMin', {
      is: (max: string) => max && parseInt(max) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanYearMin',
        message: '',
        test() {
          const { yearMin, yearMax } = this.parent;

          return yearMax !== '' ? parseInt(yearMax) >= parseInt(yearMin) : true;
        },
      }),
    }),
});

const KeySettings = (props: {
  changeFiltersPanelState: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const mlsList = useSelector(getMlsList);
  const { mls, reportUnit, reportName, orderDirection, chartType, perPage } =
    useSelector(getKeySettings);
  const { lastDate } = useSelector(getFilterLists);
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const dispatch = useDispatch<AppDispatch>();
  const [canSubmit, setCanSubmit] = useState(true);
  const settings = useSelector(getSettings);
  const methods = useForm({
    resolver: yupResolver(keySettingsValidationSchema),
  });
  const isAdvancedSettingsNotEmpty = isObjectPropertyNotEmpty(
    userAvailableAppliedSettings,
    'Advanced Settings'
  );
  const isGeographyNotEmpty = isObjectPropertyNotEmpty(
    userAvailableAppliedSettings,
    'Geography'
  );
  const isOnlyOfficesLocatedInNotEmpty = isObjectPropertyNotEmpty(
    userAvailableAppliedSettings,
    'Only Offices Located In'
  );
  const isPropertyNotEmpty = isObjectPropertyNotEmpty(
    userAvailableAppliedSettings,
    'Property'
  );
  const isSchoolTypeNotEmpty = isObjectPropertyNotEmpty(
    userAvailableAppliedSettings,
    'School Type'
  );

  const handlePanelClose = () => {
    props.changeFiltersPanelState(false);
  };

  const handlePanelOpen = () => {
    props.changeFiltersPanelState(true);
  };

  const changeMls = async (
    event: SelectChangeEvent<string | number | null>
  ) => {
    const { value } = event.target;

    if (!value) {
      return;
    }

    await dispatch(setLoading());
    await dispatch(resetReport({ reset: false }));
    await dispatch(resetMLSDependentSettings());

    await dispatch(
      setKeySettings({
        key: 'mls',
        value: parseInt(value.toString()),
      })
    );

    addURLParameter('mls', parseInt(value.toString()));

    const endDate = await dispatch(loadFilterLists(false));

    const startDate = moment
      .utc(endDate)
      .subtract(1, 'years')
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    await dispatch(
      setKeySettings({
        key: 'startDate',
        value: startDate,
      })
    );

    await dispatch(
      setKeySettings({
        key: 'endDate',
        value: endDate,
      })
    );

    await dispatch(
      setKeySettings({
        key: 'timeFrame',
        value: ETimeFrames.YEAR,
      })
    );

    await dispatch(
      setKeySettings({
        key: 'orderBy',
        value: null,
      })
    );

    await dispatch(loadCurrentMls(parseInt(value.toString())));
    await dispatch(resetFilters(null));
    await dispatch(resetToDefaultReport());
    await dispatch(
      setKeySettings({
        key: 'reportName',
        value: settings.defaultReport,
      })
    );
    await dispatch(loadReport(false));
    await dispatch(setSavedSearch({ value: null }));
    await dispatch(getSavedSearchList());
  };

  const handleReset = async () => {
    const formValues = methods.getValues();
    const cleareadFormValues = Object.fromEntries(
      Object.keys(formValues).map((key) => [key, ''])
    );
    methods.clearErrors();
    // @ts-ignore
    methods.reset(cleareadFormValues);
    const startDate = moment
      .utc(lastDate)
      .subtract(1, 'years')
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    await dispatch(
      resetKeySettings({
        mls,
        timeFrame: ETimeFrames.YEAR,
        startDate,
        endDate: lastDate,
        reportUnit,
        reportName,
        orderDirection,
        chartType,
        perPage,
      })
    );
    await dispatch(loadReport());

    dispatch(initConfig());
    dispatch(getSavedSearchList());
  };

  const changeCanSubmit = (value: boolean) => {
    setCanSubmit(value);
  };

  const handleSubmit = async () => {
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    await dispatch(resetFilters(reportName));
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className={'filters-wrap'}>
          <div className="filters">
            <form>
              <div className="filters-header">
                <h3>{t('key_settings.title')}</h3>
                <div
                  onClick={handlePanelClose}
                  onTouchStart={handlePanelClose}
                  className={'cursor-pointer'}
                >
                  <SpriteIcon icon="collapse" />
                </div>
              </div>
              {mlsList.length && (
                <CustomSelect
                  label={t('key_settings.market')}
                  placeholder={t('select')}
                  value={mls || ''}
                  choices={mlsList}
                  fullWidth={true}
                  style={{ paddingTop: 10 }}
                  setValue={changeMls}
                />
              )}
              <SavedSearches />

              <DateSettings onChangeDate={changeCanSubmit} />
              {isGeographyNotEmpty ? <GeographySettings /> : null}
              {isPropertyNotEmpty ? <PropertySettings /> : null}
              {isOnlyOfficesLocatedInNotEmpty &&
              reportName !== EReportName.PERFORMANCE ? (
                <OnlyOfficesSettings />
              ) : null}
              {isAdvancedSettingsNotEmpty ? <AdvancedSettings /> : null}
              {isSchoolTypeNotEmpty ? <SchoolTypeSettings /> : null}
            </form>
          </div>
          <div className="filters-footer">
            <Button
              type="submit"
              variant={'outlined'}
              className="filled-btn"
              disabled={!canSubmit}
              onClick={methods.handleSubmit(handleSubmit)}
              onTouchStart={methods.handleSubmit(handleSubmit)}
              data-keysettings-submit
            >
              {t('key_settings.submit')}
            </Button>

            <Button
              className="text-btn"
              onClick={handleReset}
              onTouchStart={handleReset}
              data-keysettings-reset
            >
              {t('key_settings.reset')}
            </Button>
          </div>

          <div
            onClick={handlePanelOpen}
            onTouchStart={handlePanelOpen}
            className="open-filters"
          >
            <div className="open-filters-title">
              <span>{t('key_settings.filters')}</span>
            </div>
            <SpriteIcon icon="collapse_filled" />
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default KeySettings;
