import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import map from '@/assets/images/map.png';
import CustomAutocomplete from '@/components/CustomElements/CustomAutocomplete';
import { isPropertyIncluded } from '@/components/KeySettings/utils';
import MapSearch from '@/components/MapSearch/MapSearch';
import useDebounce from '@/hooks/useDebounce';
import { AppDispatch } from '@/store';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { getReport, resetReport } from '@/store/reportSlice';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const GeographySettings = () => {
  const { t } = useTranslation();
  const {
    countyList,
    labels,
    cityList,
    zipList,
    districtList,
    subdivisionList,
  } = useSelector(getFilterLists);
  const { county, city, zipCode, district, subdivision, area } =
    useSelector(getKeySettings);
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [countyValue, setCountyValue] =
    useState<Array<string | number>>(county);
  const [cityValue, setCityValue] = useState<Array<string | number>>(city);
  const [zip, setZip] = useState<Array<string | number>>(zipCode);
  const [districtValue, setDistrictValue] =
    useState<Array<string | number>>(district);
  const [subdivisionValue, setSubdivisionValue] =
    useState<Array<string | number>>(subdivision);

  const hasMapProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'map'
  );
  const hasCountyProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'county'
  );
  const hasCityProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'city'
  );
  const hasZipCodeProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'zipCode'
  );
  const hasDistrictProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'district'
  );
  const hasSubdivisionProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'subdivision'
  );

  const debouncedCounty = useDebounce(countyValue);
  const debouncedCity = useDebounce(cityValue);
  const debouncedZip = useDebounce(zip);
  const debouncedDistrict = useDebounce(districtValue);
  const debouncedSubdivision = useDebounce(subdivisionValue);

  const handleOpen = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const updateCounty = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setCountyValue(value);
    } else {
      setCountyValue([]);
    }
  };

  const updateCity = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setCityValue(value);
    } else {
      setCityValue([]);
    }
  };

  const updateZip = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setZip(value);
    } else {
      setZip([]);
    }
  };

  const updateDistrict = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setDistrictValue(value);
    } else {
      setDistrictValue([]);
    }
  };

  const updateSubdivision = (value: (string | number)[] | null) => {
    if (value && Array.isArray(value)) {
      setSubdivisionValue(value);
    } else {
      setSubdivisionValue([]);
    }
  };

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'county',
        value: debouncedCounty,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedCounty]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'city',
        value: debouncedCity,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedCity]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'zipCode',
        value: debouncedZip,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedZip]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'district',
        value: debouncedDistrict,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedDistrict]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'subdivision',
        value: debouncedSubdivision,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedSubdivision]);

  useEffect(() => {
    setCountyValue(county);
  }, [county]);

  useEffect(() => {
    setCityValue(city);
  }, [city]);

  useEffect(() => {
    setZip(zipCode);
  }, [zipCode]);

  useEffect(() => {
    setDistrictValue(district);
  }, [district]);

  useEffect(() => {
    setSubdivisionValue(subdivision);
  }, [subdivision]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      if (
        countyValue.length ||
        cityValue.length ||
        subdivisionValue.length ||
        zip.length ||
        districtValue.length
      ) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [countyValue, cityValue, subdivisionValue, districtValue, zip]);

  return (
    <Accordion
      expanded={expanded}
      sx={{ boxShadow: 'none', padding: '15px 0 0 0', border: 'none' }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ boxShadow: 'none', padding: 0, minHeight: 1, margin: 0 }}
      >
        <div className="d-flex" style={{ alignItems: 'center', flex: 1 }}>
          <h3>{t('key_settings.geography')}</h3> <div className="line" />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {hasMapProperty ? (
          <>
            <div className="map-wrap">
              <img src={map} alt="map" />
              <button
                type="button"
                className="btn-rounded"
                onClick={handleOpen}
                onTouchStart={handleOpen}
              >
                {!area
                  ? t('key_settings.search_by_map')
                  : t('key_settings.search_by_map_applied')}
              </button>
            </div>
            <MapSearch
              open={open}
              closeHandler={handleClose}
              searchType="area"
            />
          </>
        ) : null}
        {hasCountyProperty && countyList.length ? (
          <CustomAutocomplete
            label={labels.find((item) => item.type === 'county')?.name}
            placeholder={t('select')}
            value={countyValue}
            choices={countyList}
            setValue={updateCounty}
            disabled={!!area}
          />
        ) : null}
        {hasCityProperty && cityList.length ? (
          <CustomAutocomplete
            label={labels.find((item) => item.type === 'city')?.name}
            placeholder={t('select')}
            value={cityValue}
            choices={cityList}
            visibleValues={1}
            setValue={updateCity}
            disabled={!!area}
          />
        ) : null}
        {hasZipCodeProperty && zipList.length ? (
          <CustomAutocomplete
            label={labels.find((item) => item.type === 'zipCode')?.name}
            placeholder={t('select')}
            value={zip}
            choices={zipList}
            visibleValues={1}
            setValue={updateZip}
            disabled={!!area}
          />
        ) : null}
        {hasDistrictProperty && districtList.length ? (
          <CustomAutocomplete
            label={labels.find((item) => item.type === 'district')?.name}
            placeholder={t('select')}
            value={districtValue}
            choices={districtList}
            setValue={updateDistrict}
            disabled={!!area}
          />
        ) : null}
        {hasSubdivisionProperty && subdivisionList.length ? (
          <CustomAutocomplete
            label={labels.find((item) => item.type === 'subdivision')?.name}
            placeholder={t('select')}
            value={subdivision}
            choices={subdivisionList}
            setValue={updateSubdivision}
            disabled={!!area}
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default GeographySettings;
