export const reportTotals = {
  listingUnit: 137898,
  listingVolume: 151863335240,
  sellingUnit: 127056,
  sellingVolume: 142657452839,
  totalUnit: 264954,
  totalVolume: 294520788079,
  avgPrice: 1111592,
  avgRatio: 109.19,
  avgDOM: 14,
};
export const reportFields = [
  {
    id: 'agentName',
    label: 'Agent Name',
    type: 's',
    meta: {
      xlsx: {
        total: true,
        freeze: true,
        bold: true,
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'brandName',
    label: 'Current Brand',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'firmName',
    label: 'Current Firm',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'officeName',
    label: 'Current Office',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'officeAddress',
    label: 'Current Office Address',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'officeCity',
    label: 'Office City',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'left',
        vertical: 'center',
      },
    },
  },
  {
    id: 'listingUnit',
    label: 'List Units',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'sellingUnit',
    label: 'Sold Units',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'totalUnit',
    label: 'Total Units',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'rankU',
    label: 'Rank Units (#)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'percentRankU',
    label: 'Top % Rank Units (#)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'listingVolume',
    label: 'List Volume',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'sellingVolume',
    label: 'Sold Volume',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'totalVolume',
    label: 'Total Volume',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'rankV',
    label: 'Rank Volume ($)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'percentRankV',
    label: 'Top % Rank Volume ($)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'avgPrice',
    label: 'Average Price',
    type: 'n',
    meta: {
      xlsx: {
        total: true,
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'officeZip',
    label: 'Office Postal Code',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'officePhone',
    label: 'Office Phone',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'agentEmail',
    label: 'Agent Email',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'agentPhone1',
    label: 'Agent Phone 1',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'agentPhone2',
    label: 'Agent Phone 2',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'agentId',
    label: 'Agent ID',
    type: 's',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'fractionOfTotalMLSUnits',
    label: 'Fraction of Total MLS Units (%)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'fractionOfTotalMLSVolume',
    label: 'Fraction of Total MLS Volume (%)',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'totalMlsUnit',
    label: 'Total MLS Units',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
  {
    id: 'totalMlsVolume',
    label: 'Total MLS Volume',
    type: 'n',
    meta: {
      xlsx: {
        horizontal: 'right',
        vertical: 'center',
      },
    },
  },
];
export const items = [
  {
    id: 62372,
    agentId: '3055794',
    agentName: 'Mark Faris',
    agentEmail: '',
    agentPhone1: '',
    agentPhone2: '',
    officeId: 3179,
    officeName: 'Faris Team Real Estate',
    officeAddress: '6288 Yonge Street',
    officeCounty: null,
    officeCity: 'Innisfil',
    officeZip: 'L0L1K0',
    officePhone: '705-797-8485',
    firmId: 2446,
    firmName: 'Faris Team Real Estate',
    brandId: 1966,
    brandName: 'Faris Team Real Estate',
    listingUnit: 765,
    listingVolume: 734508532,
    sellingUnit: 310,
    sellingVolume: 288631736,
    totalUnit: 1075,
    totalVolume: 1023140268,
    avgPrice: 951758,
    avgRatio: 104.61,
    avgDOM: 16,
    rankU: 1,
    rankV: 1,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 1075,
    totalMlsVolume: 1023140268,
  },
  {
    id: 59035,
    agentId: '3082997',
    agentName: 'Rob Golfi',
    agentEmail: 'rgolfi@trebnet.com',
    agentPhone1: '905-575-7700',
    agentPhone2: '905-575-7700',
    officeId: 3433,
    officeName: 'RE/MAX Escarpment Golfi Realty Inc.',
    officeAddress: '1 Markland St #2',
    officeCounty: null,
    officeCity: 'Hamilton',
    officeZip: 'L8P2J5',
    officePhone: '905-575-7700',
    firmId: 1119,
    firmName: 'RE/MAX Escarpment Realty Inc.',
    brandId: 1,
    brandName: 'RE/MAX',
    listingUnit: 665,
    listingVolume: 559312045,
    sellingUnit: 210,
    sellingVolume: 180109596,
    totalUnit: 875,
    totalVolume: 739421641,
    avgPrice: 845053,
    avgRatio: 110.12,
    avgDOM: 13,
    rankU: 2,
    rankV: 3,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 875,
    totalMlsVolume: 739421641,
  },
  {
    id: 17320,
    agentId: '3012008',
    agentName: 'Conrad Guy Zurini',
    agentEmail: 'conrad@rmxemail.com',
    agentPhone1: '905-639-3044',
    agentPhone2: '905-639-3044',
    officeId: 1387,
    officeName: 'RE/MAX Escarpment Realty Inc.',
    officeAddress: '2180 Itabashi Way #4B',
    officeCounty: null,
    officeCity: 'Burlington',
    officeZip: 'L7M5A5',
    officePhone: '905-639-3044',
    firmId: 1119,
    firmName: 'RE/MAX Escarpment Realty Inc.',
    brandId: 1,
    brandName: 'RE/MAX',
    listingUnit: 762,
    listingVolume: 702781233,
    sellingUnit: 1,
    sellingVolume: 1200000,
    totalUnit: 763,
    totalVolume: 703981233,
    avgPrice: 922649,
    avgRatio: 112.56,
    avgDOM: 11,
    rankU: 3,
    rankV: 4,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 763,
    totalMlsVolume: 703981233,
  },
  {
    id: 27316,
    agentId: '3036231',
    agentName: 'Peggy Hill',
    agentEmail: 'peggy@peggyhill.com',
    agentPhone1: '705-739-4455',
    agentPhone2: '705-739-4455',
    officeId: 3939,
    officeName: 'RE/MAX Hallmark Peggy Hill Group Realty',
    officeAddress: '374 Huronia Road',
    officeCounty: null,
    officeCity: 'Barrie',
    officeZip: 'L4N8Y9',
    officePhone: '705-739-4455',
    firmId: 1,
    firmName: 'RE/MAX Hallmark Realty Ltd.',
    brandId: 1,
    brandName: 'RE/MAX',
    listingUnit: 484,
    listingVolume: 454869480,
    sellingUnit: 190,
    sellingVolume: 164815831,
    totalUnit: 674,
    totalVolume: 619685311,
    avgPrice: 919414,
    avgRatio: 108.52,
    avgDOM: 12,
    rankU: 4,
    rankV: 6,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 674,
    totalMlsVolume: 619685311,
  },
  {
    id: 9990,
    agentId: '3013442',
    agentName: 'Sam Allan McDadi',
    agentEmail: 'sam@mcdadi.com',
    agentPhone1: '905-502-1500',
    agentPhone2: '905-502-1500',
    officeId: 1874,
    officeName: 'Sam McDadi Real Estate Inc.',
    officeAddress: '110 - 5805 Whittle Rd',
    officeCounty: null,
    officeCity: 'Mississauga',
    officeZip: 'L4Z2J1',
    officePhone: '905-502-1500',
    firmId: 1539,
    firmName: 'Sam McDadi Real Estate Inc.',
    brandId: 1226,
    brandName: 'Sam McDadi Real Estate Inc.',
    listingUnit: 380,
    listingVolume: 578110522,
    sellingUnit: 217,
    sellingVolume: 312737775,
    totalUnit: 597,
    totalVolume: 890848297,
    avgPrice: 1492208,
    avgRatio: 106.44,
    avgDOM: 14,
    rankU: 5,
    rankV: 2,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 597,
    totalMlsVolume: 890848297,
  },
  {
    id: 69882,
    agentId: '3111723',
    agentName: 'Louay Kadri',
    agentEmail: 'Louay.kadri@purplebricks.ca',
    agentPhone1: '1866-336-9637',
    agentPhone2: '1866-336-9637',
    officeId: 1845,
    officeName: 'Fairsquare Group Realty',
    officeAddress: '1250 South Service Rd #a14',
    officeCounty: null,
    officeCity: 'Stoney Creek',
    officeZip: 'L8E5R9',
    officePhone: '1866-336-9637',
    firmId: 1511,
    firmName: 'Fairsquare Group Realty',
    brandId: 1201,
    brandName: 'Fairsquare Group Realty',
    listingUnit: 510,
    listingVolume: 460239625,
    sellingUnit: 0,
    sellingVolume: 0,
    totalUnit: 510,
    totalVolume: 460239625,
    avgPrice: 902431,
    avgRatio: 105.21,
    avgDOM: 23,
    rankU: 6,
    rankV: 8,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 510,
    totalMlsVolume: 460239625,
  },
  {
    id: 13171,
    agentId: '3109406',
    agentName: 'Michael Muraco',
    agentEmail: 'Michael@remaxnc.ca',
    agentPhone1: '905-687-9600',
    agentPhone2: '905-687-9600',
    officeId: 4015,
    officeName: 'RE/MAX Niagara Realty Ltd.',
    officeAddress: '261 Martindale Rd Unit 12A',
    officeCounty: null,
    officeCity: 'St. Catharines',
    officeZip: 'L2W1A2',
    officePhone: '905-687-9600',
    firmId: 2962,
    firmName: 'RE/MAX Niagara Realty Ltd.',
    brandId: 1,
    brandName: 'RE/MAX',
    listingUnit: 385,
    listingVolume: 282903034,
    sellingUnit: 0,
    sellingVolume: 0,
    totalUnit: 385,
    totalVolume: 282903034,
    avgPrice: 734813,
    avgRatio: 105.11,
    avgDOM: 17,
    rankU: 7,
    rankV: 18,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 385,
    totalMlsVolume: 282903034,
  },
  {
    id: 85781,
    agentId: '3112988',
    agentName: 'Dean Michael Serravalle',
    agentEmail: null,
    agentPhone1: null,
    agentPhone2: null,
    officeId: 19372,
    officeName: 'Revel Realty Inc.',
    officeAddress: null,
    officeCounty: null,
    officeCity: null,
    officeZip: null,
    officePhone: null,
    firmId: null,
    firmName: null,
    brandId: 1978,
    brandName: 'Revel Realty Inc.',
    listingUnit: 340,
    listingVolume: 268577045,
    sellingUnit: 19,
    sellingVolume: 15676700,
    totalUnit: 359,
    totalVolume: 284253745,
    avgPrice: 791793,
    avgRatio: 105.35,
    avgDOM: 16,
    rankU: 8,
    rankV: 16,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 359,
    totalMlsVolume: 284253745,
  },
  {
    id: 7903,
    agentId: '3016008',
    agentName: 'Frank Leo',
    agentEmail: 'fleo@trebnet.com',
    agentPhone1: '416-745-2300',
    agentPhone2: '416-745-2300',
    officeId: 2860,
    officeName: 'RE/MAX West Realty Inc.',
    officeAddress: '2234 Bloor Street West',
    officeCounty: null,
    officeCity: 'Toronto',
    officeZip: 'M6S1N6',
    officePhone: '416-760-0600',
    firmId: 1828,
    firmName: 'RE/MAX West Realty Inc.',
    brandId: 1,
    brandName: 'RE/MAX',
    listingUnit: 321,
    listingVolume: 361269377,
    sellingUnit: 28,
    sellingVolume: 27204000,
    totalUnit: 349,
    totalVolume: 388473377,
    avgPrice: 1113104,
    avgRatio: 109.62,
    avgDOM: 19,
    rankU: 9,
    rankV: 9,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 349,
    totalMlsVolume: 388473377,
  },
  {
    id: 34204,
    agentId: '3051532',
    agentName: 'James R. Tasca',
    agentEmail: 'james@icisource.ca',
    agentPhone1: '888-517-6424',
    agentPhone2: '888-517-6424',
    officeId: 1974,
    officeName: 'ICI Source Real Asset Services Inc.',
    officeAddress: '2 Robert Speck Pkwy #750',
    officeCounty: null,
    officeCity: 'Mississauga',
    officeZip: 'L4Z1H8',
    officePhone: '888-517-6424',
    firmId: 1639,
    firmName: 'ICI Source Real Asset Services Inc.',
    brandId: 1308,
    brandName: 'ICI Source Real Asset Services Inc.',
    listingUnit: 240,
    listingVolume: 164309451,
    sellingUnit: 87,
    sellingVolume: 50292500,
    totalUnit: 327,
    totalVolume: 214601951,
    avgPrice: 656275,
    avgRatio: 100.3,
    avgDOM: 34,
    rankU: 10,
    rankV: 33,
    percentRankU: 1,
    percentRankV: 1,
    fractionOfTotalMLSUnits: 100,
    fractionOfTotalMLSVolume: 100,
    totalMlsUnit: 327,
    totalMlsVolume: 214601951,
  },
];
