import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { apiClient } from '@/ApiClient';
import StyledTextField from '@/mui-styled-components/text-field';
import { objToQueryStringSaved } from '@/router/router';
import { AppDispatch } from '@/store';
import {
  deleteSavedSearch,
  getKeySettings,
  getSavedSearchList,
  getSavedSearchState,
  setSavedSearch,
} from '@/store/keySettingsSlice';
import { ModalProps } from '@/store/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import './SavedSearchModal.scss';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 320,
  bgcolor: '#fff',
  boxShadow: 24,
  p: '30px',
};

const SavedSearchModal = (props: ModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const savedSearch = useSelector(getSavedSearchState);
  const { t } = useTranslation();
  const { open, closeHandler } = props;
  const [name, setName] = useState<string>('');
  const keySettings = useSelector(getKeySettings);

  useEffect(() => {
    savedSearch ? setName(savedSearch.name) : setName('');
  }, [savedSearch]);

  const saveSearch = () => {
    const {
      mls,
      reportName,
      timeFrame,
      savedSearch,
      savedSearchList,
      agentIds,
      officeIds,
      officeIdsInclude,
      firmIds,
      firmInclude,
      brandIds,
      brandInclude,
      firmsMin,
      firmsMax,
      officesMin,
      officesMax,
      agentsMin,
      agentsMax,
      registeredAgentsMin,
      registeredAgentsMax,
      listingUnitsMin,
      listingUnitsMax,
      listingUnitsPerAgentMin,
      listingUnitsPerAgentMax,
      listingUnitsPerRegisteredAgentMin,
      listingUnitsPerRegisteredAgentMax,
      sellingUnitsMin,
      sellingUnitsMax,
      sellingUnitsPerAgentMin,
      sellingUnitsPerAgentMax,
      sellingUnitsPerRegisteredAgentMin,
      sellingUnitsPerRegisteredAgentMax,
      totalUnitsMin,
      totalUnitsMax,
      totalUnitsPerAgentMin,
      totalUnitsPerAgentMax,
      totalUnitsPerRegisteredAgentMin,
      totalUnitsPerRegisteredAgentMax,
      listingVolumeMin,
      listingVolumeMax,
      listingVolumePerAgentMin,
      listingVolumePerAgentMax,
      listingVolumePerRegisteredAgentMin,
      listingVolumePerRegisteredAgentMax,
      sellingVolumeMin,
      sellingVolumeMax,
      sellingVolumePerAgentMin,
      sellingVolumePerAgentMax,
      sellingVolumePerRegisteredAgentMin,
      sellingVolumePerRegisteredAgentMax,
      totalVolumeMin,
      totalVolumeMax,
      totalVolumePerAgentMin,
      totalVolumePerAgentMax,
      totalVolumePerRegisteredAgentMin,
      totalVolumePerRegisteredAgentMax,
      avgPriceMin,
      avgPriceMax,
      avgRatioMin,
      avgRatioMax,
      avgDOMMin,
      avgDOMMax,
      previousUnitsMin,
      previousUnitsMax,
      currentUnitsMin,
      currentUnitsMax,
      growthUnitsMin,
      growthUnitsMax,
      previousVolumeMin,
      previousVolumeMax,
      currentVolumeMin,
      currentVolumeMax,
      growthVolumeMin,
      growthVolumeMax,
      previousAvgPriceMin,
      previousAvgPriceMax,
      currentAvgPriceMin,
      currentAvgPriceMax,
      growthAvgPriceMin,
      growthAvgPriceMax,
      previousAvgRatioMin,
      previousAvgRatioMax,
      currentAvgRatioMin,
      currentAvgRatioMax,
      growthAvgRatioMin,
      growthAvgRatioMax,
      previousAvgDOMMin,
      previousAvgDOMMax,
      currentAvgDOMMin,
      currentAvgDOMMax,
      growthAvgDOMMin,
      growthAvgDOMMax,
      unitPriceMin,
      unitPriceMax,
      unitRatioMin,
      unitRatioMax,
      unitDOMMin,
      unitDOMMax,
      closedUnitsMin,
      closedUnitsMax,
      closedVolumeMin,
      closedVolumeMax,
      pendingUnitsMin,
      pendingUnitsMax,
      pendingVolumeMin,
      pendingVolumeMax,
      expiredUnitsMin,
      expiredUnitsMax,
      expiredVolumeMin,
      expiredVolumeMax,
      withdrawnUnitsMin,
      withdrawnUnitsMax,
      withdrawnVolumeMin,
      withdrawnVolumeMax,
      percentageUnitsMin,
      percentageUnitsMax,
      percentageVolumeMin,
      percentageVolumeMax,
      ...fields
    } = keySettings;
    const queryString = objToQueryStringSaved({
      ...fields,
      name: name,
      mlsId: mls,
    });

    !savedSearch &&
      apiClient
        .userSearch()
        // @ts-ignore
        .postUserSearch(queryString)
        .then(async (reqData) => {
          const { data, ...fields } = reqData;
          closeHandler();
          dispatch(getSavedSearchList());
          dispatch(setSavedSearch({ value: fields }));
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    savedSearch &&
      apiClient
        .userSearch()
        // @ts-ignore
        .putUserSearch(savedSearch.id, queryString)
        .then(async (reqData) => {
          const { data, ...fields } = reqData;
          closeHandler();
          dispatch(getSavedSearchList());
          dispatch(setSavedSearch({ value: fields }));
        })
        .catch((error) => {
          return Promise.reject(error);
        });
  };

  const updateName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDelete = (id: number) => {
    closeHandler();
    dispatch(deleteSavedSearch(id));
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={closeHandler}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      classes={{ root: 'coc-modal saved-search-modal' }}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={closeHandler}
          onTouchStart={closeHandler}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <h3 style={{ marginTop: 0, marginBottom: '25px', textAlign: 'center' }}>
          {t('key_settings.save_search_criteria')}
        </h3>
        <p style={{ marginTop: 0, marginBottom: '5px', fontSize: '12px' }}>
          {t('key_settings.saved_search')}
        </p>
        <div className="saved-search-modal_content">
          <StyledTextField
            inputProps={{
              style: {
                height: '32px',
                padding: '4px 10px',
                boxSizing: 'border-box',
              },
            }}
            variant="outlined"
            sx={{ marginBottom: '25px' }}
            value={name}
            onChange={updateName}
          />
          {savedSearch ? (
            <>
              <Button
                disabled={!name}
                sx={{ marginBottom: '12px', fontWeight: 700 }}
                variant={'outlined'}
                className="filled-btn"
                onClick={saveSearch}
                onTouchStart={saveSearch}
              >
                {t('key_settings.modify_search')}
              </Button>
              <Button
                disabled={!name}
                sx={{ fontWeight: 700 }}
                variant={'outlined'}
                className="filled-btn red"
                onClick={() => handleDelete(savedSearch.id)}
                onTouchStart={() => handleDelete(savedSearch.id)}
              >
                {t('key_settings.delete_search')}
              </Button>
            </>
          ) : (
            <Button
              disabled={!name}
              sx={{ fontWeight: 700 }}
              variant={'outlined'}
              className="filled-btn"
              onClick={saveSearch}
              onTouchStart={saveSearch}
            >
              {t('key_settings.save')}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default SavedSearchModal;
