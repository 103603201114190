import * as React from 'react';
import { useId } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import StyledCheckbox from '@/mui-styled-components/checkbox';
import StyledTextField from '@/mui-styled-components/text-field';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Chip } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet}
      {`#${dataSet[2] + 1} - ${dataSet[1]}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CustomAutocomplete(props: {
  choices: any;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  value: any;
  style?: React.CSSProperties;
  setValue: (value: any) => void;
  visibleValues?: number;
  selectWidth?: number;
}) {
  const id = useId();

  return (
    <div style={props.style}>
      <label htmlFor={id}>{props.label}</label>
      <Autocomplete
        popupIcon={
          <KeyboardArrowDownIcon
            sx={{ width: '12px', height: '24px', stroke: 'var(--blue-color)' }}
          />
        }
        id={id}
        disabled={props.disabled || false}
        multiple
        disableCloseOnSelect
        disableListWrap
        size="small"
        value={props.choices.filter((val: any) => props.value.includes(val.id))}
        ListboxProps={{
          style: {
            minHeight: '50px',
          },
        }}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={option.name}
                />
              ))}

              <span style={{ fontSize: '12px', height: '18px' }}>
                {numTags > limitTags && ` +${numTags - limitTags}`}
              </span>
            </>
          );
        }}
        sx={{
          paddingTop: '0',
          paddingBottom: '0',
          marginTop: '5px',
          marginBottom: '10px',
          minHeight: '30px',
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
            {
              padding: '0 4px 0 6px',
            },
          '& .MuiAutocomplete-tag': {
            margin: '0',
            height: '17px',
          },
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            minHeight: '30px',
          },
          '& .MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall': {
            height: '17px',
            fontSize: '12px',
          },
          '& .Mui-focused .MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall': {
            margin: '0 1px 1px 0',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'var(--blue-color-hover)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--blue-color)',
            },
          },
        }}
        //@ts-ignore
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={props.choices}
        getOptionLabel={(option: any) => option.name || ''}
        onChange={(_, newValue: any) => {
          props.setValue(newValue.map((val: any) => val.id));
        }}
        renderOption={(props, option, { selected }) => (
          <span {...props}>
            <StyledCheckbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, padding: 0 }}
              checked={selected}
            />
            {option.name}
          </span>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            sx={{
              minHeight: '30px',
            }}
            placeholder={props.placeholder}
          />
        )}
        componentsProps={{
          paper: {
            sx: {
              width: props.selectWidth || 'auto',
            },
          },
        }}
      />
    </div>
  );
}
