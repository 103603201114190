import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { ESchoolTypes } from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import { getKeySettings } from '@/store/keySettingsSlice';
import { IDictionary, IKeySettings, IListItem } from '@/store/types';
import { IconButton, Popover } from '@mui/material';

import './AppliedSettings.scss';
import '../Display/Count.scss';
import '../SpriteIcon/SpriteIcon.scss';

const AppliedSettings = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const {
    countyList,
    labels,
    cityList,
    zipList,
    subdivisionList,
    districtList,
    classList,
    typeList,
    styleList,
    school0List,
    school1List,
    school2List,
  } = useSelector(getFilterLists);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [appliedSettings, setAppliedSettings] = useState<
    IDictionary<boolean | string | undefined>
  >({});
  const open = Boolean(anchorEl);

  const handleOpen = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const keys = Object.keys(keySettings).map(
      (key: string) => key as keyof IKeySettings
    );

    const settings: IDictionary<boolean> = {};

    keys.map((item) => {
      if (item === 'county' && !!keySettings[item].length) {
        settings['county'] = true;
      }

      if (item === 'city' && !!keySettings[item].length) {
        settings['city'] = true;
      }

      if (item === 'zipCode' && !!keySettings[item].length) {
        settings['zipCode'] = true;
      }

      if (item === 'district' && !!keySettings[item].length) {
        settings['district'] = true;
      }

      if (item === 'subdivision' && !!keySettings[item].length) {
        settings['subdivision'] = true;
      }

      if (['priceOver', 'priceUnder'].includes(item) && !!keySettings[item]) {
        settings['price'] = true;
      }

      if (item === 'propertyType' && !!keySettings[item].length) {
        settings['propertyType'] = true;
      }

      if (item === 'type' && !!keySettings[item].length) {
        settings['type'] = true;
      }

      if (item === 'style' && !!keySettings[item].length) {
        settings['style'] = true;
      }

      if (
        ['yearBuiltMin', 'yearBuiltMax'].includes(item) &&
        !!keySettings[item]
      ) {
        settings['year'] = true;
      }

      if (
        ['bedroomsMin', 'bedroomsMax'].includes(item) &&
        !!keySettings[item]
      ) {
        settings['bedrooms'] = true;
      }

      if (
        ['finishedSquareFootageMin', 'finishedSquareFootageMax'].includes(
          item
        ) &&
        !!keySettings[item]
      ) {
        settings['finishedSquareFootage'] = true;
      }

      if (
        ['totalSquareFootageMin', 'totalSquareFootageMax'].includes(item) &&
        !!keySettings[item]
      ) {
        settings['totalSquareFootage'] = true;
      }

      if (
        ['bathroomsFullMin', 'bathroomsFullMax'].includes(item) &&
        !!keySettings[item]
      ) {
        settings['bathroomsFull'] = true;
      }

      if (
        ['bathroomsHalfMin', 'bathroomsHalfMax'].includes(item) &&
        !!keySettings[item]
      ) {
        settings['bathroomsHalf'] = true;
      }

      if (item === 'school' && !!keySettings[item].length) {
        settings['school'] = true;
      }

      if (item === 'officeCounty' && !!keySettings[item].length) {
        settings['officeCounty'] = true;
      }

      if (item === 'officeCity' && !!keySettings[item].length) {
        settings['officeCity'] = true;
      }

      if (item === 'officeZip' && !!keySettings[item].length) {
        settings['officeZip'] = true;
      }

      if (item === 'area' && !!keySettings[item].length) {
        settings['area'] = true;
      }

      if (item === 'officeArea' && !!keySettings[item].length) {
        settings['officeArea'] = true;
      }
    });

    setAppliedSettings(settings);
  }, [keySettings]);

  function ItemWithPluralLabel(props: {
    single: keyof IKeySettings;
    itemKey?: keyof IKeySettings;
    plural: string;
    list: Array<IListItem>;
    excludeInclude?: keyof IKeySettings;
  }) {
    const { single, plural, list, itemKey, excludeInclude } = props;
    const settingsItem = itemKey
      ? (keySettings[itemKey] as Array<number>)
      : (keySettings[single] as Array<number>);
    const labelKey = settingsItem.length > 1 ? plural : single;

    return (
      <p>
        <span className="label">
          {labels.find((item) => item.type === labelKey)?.name}
        </span>
        -{' '}
        {excludeInclude && keySettings[excludeInclude] === 'true'
          ? ''
          : excludeInclude && keySettings[excludeInclude] === 'false'
          ? t('exclude')
          : ''}
        {settingsItem.map((id, index) => (
          <span key={index}>{list.find((item) => item.id === id)?.name}</span>
        ))}
        ;
      </p>
    );
  }

  function PriceItem() {
    let labelKey = 'applied_settings.price_between';

    if (keySettings.priceOver && !keySettings.priceUnder) {
      labelKey = 'applied_settings.price_more';
    }

    if (!keySettings.priceOver && keySettings.priceUnder) {
      labelKey = 'applied_settings.price_less';
    }

    return (
      <p>
        <Trans
          i18nKey={labelKey}
          components={{ text: <span className="label" /> }}
          values={{
            from: keySettings.priceOver,
            to: keySettings.priceUnder,
          }}
        />
      </p>
    );
  }

  function PropertyItem(props: {
    single: keyof IKeySettings;
    plural: string;
    list: Array<IListItem>;
  }) {
    const { single, list, plural } = props;
    const settingsItem = keySettings[single] as Array<number>;
    const labelKey =
      settingsItem.length > 1
        ? `applied_settings.${plural}`
        : `applied_settings.${single}`;

    return (
      <p>
        <Trans
          i18nKey={labelKey}
          components={{ text: <span className="label" /> }}
        />
        {settingsItem.map((id, index) => (
          <span key={index}>{list.find((item) => item.id === id)?.name}</span>
        ))}
        ;
      </p>
    );
  }

  function FromToItem(props: {
    from: keyof IKeySettings;
    to: keyof IKeySettings;
    keyB: string;
    keyF: string;
    keyT: string;
  }) {
    const { from, to, keyB, keyF, keyT } = props;
    let labelKey = `applied_settings.${keyB}`;

    if (keySettings[from] && !keySettings[to]) {
      labelKey = `applied_settings.${keyF}`;
    }

    if (!keySettings[from] && keySettings[to]) {
      labelKey = `applied_settings.${keyT}`;
    }

    return (
      <p>
        <Trans
          i18nKey={labelKey}
          components={{ text: <span className="label" /> }}
          values={{
            from: keySettings[from],
            to: keySettings[to],
          }}
        />
      </p>
    );
  }

  function SchoolItem() {
    const settingsItem = keySettings.school as Array<number>;
    const getSchoolList = (type: string) => {
      switch (type) {
        case ESchoolTypes.SCHOOL_JUNIOR:
          return school0List;
        case ESchoolTypes.SCHOOL_ELEM:
          return school1List;
        case ESchoolTypes.SCHOOL_HIGH:
          return school2List;
        default:
          return [];
      }
    };
    const list = getSchoolList(keySettings.schoolType);
    let labelKey = `applied_settings.school_${keySettings.schoolType}`;

    if (keySettings.school.length > 1) {
      labelKey = `applied_settings.schools_${keySettings.schoolType}`;
    }

    return (
      <p>
        <Trans
          i18nKey={labelKey}
          components={{ text: <span className="label" /> }}
        />
        {settingsItem.map((id, index) => (
          <span key={index}>{list.find((item) => item.id === id)?.name}</span>
        ))}
        ;
      </p>
    );
  }

  const settingsCount = Object.keys(appliedSettings).length;

  return settingsCount ? (
    <span className="count">
      <Trans
        i18nKey="reports.applied_settings"
        count={settingsCount}
        components={{ span: <span /> }}
      ></Trans>
      <IconButton
        className="info-button"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        aria-label={t('buttons.info')}
        aria-describedby="mouse-over-popover"
        onMouseEnter={handleOpen}
        onTouchStart={handleOpen}
        onMouseLeave={handleClose}
        onTouchEnd={handleClose}
        disableFocusRipple
        disableRipple
      >
        <SpriteIcon icon="info" className="info" />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="info-popper"
      >
        <div className="info-content">
          {appliedSettings.area ? (
            <p>{t('key_settings.search_by_map_geography')}</p>
          ) : null}
          {appliedSettings.county ||
          appliedSettings.city ||
          appliedSettings.zipCode ||
          appliedSettings.subdivision ||
          appliedSettings.district ? (
            <p>
              <span>{t('key_settings.geography')}</span>
            </p>
          ) : null}
          {appliedSettings.county ? (
            <ItemWithPluralLabel
              single="county"
              plural="counties"
              list={countyList}
            />
          ) : null}
          {appliedSettings.city ? (
            <ItemWithPluralLabel
              single="city"
              plural="cities"
              list={cityList}
            />
          ) : null}
          {appliedSettings.zipCode ? (
            <ItemWithPluralLabel
              single="zipCode"
              plural="zipCodes"
              list={zipList}
            />
          ) : null}
          {appliedSettings.subdivision ? (
            <ItemWithPluralLabel
              single="subdivision"
              plural="subdivisions"
              list={subdivisionList}
            />
          ) : null}
          {appliedSettings.district ? (
            <ItemWithPluralLabel
              single="district"
              plural="districts"
              list={districtList}
            />
          ) : null}
          {appliedSettings.price ? <PriceItem /> : null}
          {appliedSettings.propertyType ? (
            <PropertyItem
              single="propertyType"
              plural="propertyTypes"
              list={classList}
            />
          ) : null}
          {appliedSettings.type ? (
            <PropertyItem single="type" plural="types" list={typeList} />
          ) : null}
          {appliedSettings.style ? (
            <PropertyItem single="style" plural="styles" list={styleList} />
          ) : null}
          {appliedSettings.year ? (
            <FromToItem
              from="yearBuiltMin"
              to="yearBuiltMax"
              keyB="year_built_between"
              keyF="year_built_after"
              keyT="year_built_before"
            />
          ) : null}
          {appliedSettings.finishedSquareFootage ? (
            <FromToItem
              from="finishedSquareFootageMin"
              to="finishedSquareFootageMax"
              keyB="finished_between"
              keyF="finished_after"
              keyT="finished_before"
            />
          ) : null}
          {appliedSettings.totalSquareFootage ? (
            <FromToItem
              from="totalSquareFootageMin"
              to="totalSquareFootageMax"
              keyB="total_between"
              keyF="total_after"
              keyT="total_before"
            />
          ) : null}
          {appliedSettings.officeArea ? (
            <p>{t('key_settings.search_by_map_offices')}</p>
          ) : null}
          {appliedSettings.officeCity ||
          appliedSettings.officeZip ||
          appliedSettings.officeCounty ? (
            <p>
              <span>{t('key_settings.only_office')}</span>
            </p>
          ) : null}
          {appliedSettings.officeCity ? (
            <>
              <ItemWithPluralLabel
                single="city"
                plural="cities"
                itemKey="officeCity"
                list={cityList}
                excludeInclude="officeCityInclude"
              />
            </>
          ) : null}
          {appliedSettings.officeZip ? (
            <ItemWithPluralLabel
              single="zipCode"
              plural="zipCodes"
              itemKey="officeZip"
              list={zipList}
              excludeInclude="officeZipInclude"
            />
          ) : null}
          {appliedSettings.officeCounty ? (
            <ItemWithPluralLabel
              single="county"
              plural="counties"
              itemKey="officeCounty"
              list={countyList}
              excludeInclude="officeCountyInclude"
            />
          ) : null}
          {appliedSettings.bedrooms ? (
            <FromToItem
              from="bedroomsMin"
              to="bedroomsMax"
              keyB="bedrooms_between"
              keyF="bedrooms_after"
              keyT="bedrooms_before"
            />
          ) : null}
          {appliedSettings.bathroomsFull ? (
            <FromToItem
              from="bathroomsFullMin"
              to="bathroomsFullMax"
              keyB="baths_full_between"
              keyF="baths_full_after"
              keyT="baths_full_before"
            />
          ) : null}
          {appliedSettings.bathroomsHalf ? (
            <FromToItem
              from="bathroomsHalfMin"
              to="bathroomsHalfMax"
              keyB="baths_half_between"
              keyF="baths_half_after"
              keyT="baths_half_before"
            />
          ) : null}
          {appliedSettings.school ? <SchoolItem /> : null}
        </div>
      </Popover>
    </span>
  ) : null;
};

export default AppliedSettings;
