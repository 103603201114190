import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from '@/router/consts';
import { AppDispatch } from '@/store';
import { clearUserData, getProfile } from '@/store/authSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Divider, Menu, MenuItem } from '@mui/material';

const UserMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(getProfile);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const handleNavigateToAccount = useCallback(() => {
    navigate(ROUTE.ACCOUNT, { replace: true });
    setAnchorEl(null);
  }, [navigate]);

  const handleNavigateToSettings = useCallback(() => {
    navigate(ROUTE.SETTINGS, { replace: true });
    setAnchorEl(null);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    navigate(ROUTE.MAIN, { replace: true });
    dispatch(clearUserData());
  }, [navigate]);

  const handleMenu = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <a
        className={'menuBtn'}
        tabIndex={0}
        onClick={handleMenu}
        onTouchStart={handleMenu}
      >
        {user.firstName} {user.lastName}
        <KeyboardArrowDownIcon />
      </a>
      <Menu
        id="menu-appbar"
        className="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleNavigateToAccount}
          onTouchStart={handleNavigateToAccount}
        >
          {t('menu.account')}
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem
          onClick={handleNavigateToSettings}
          onTouchStart={handleNavigateToSettings}
        >
          {t('menu.settings')}
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem onClick={handleLogout} onTouchStart={handleLogout}>
          {t('menu.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
