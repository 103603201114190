import React, { useState } from 'react';

import ActionSummaryImg from '@/assets/images/action-summary.png';
import HotsheetExample from '@/assets/images/hotsheet-example.png';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { IconButton } from '@mui/material';

import './ActionSummary.scss';

const ActionSummary: React.FC = () => {
  const [isAnimatedDivOpen, setIsAnimatedDivOpen] = useState(false);

  const handleButtonClick = () => {
    setIsAnimatedDivOpen(true);
  };

  const handleCloseButtonClick = () => {
    setIsAnimatedDivOpen(false);
  };

  return (
    <div className="action-summary">
      <div className="action-summary-btn" onClick={handleButtonClick}>
        <img
          style={{ marginBottom: '5px' }}
          src={ActionSummaryImg}
          alt="ActionSummary"
        />
        Action Summary
      </div>

      {isAnimatedDivOpen && (
        <div className="animated-div">
          <IconButton
            className="action-summary-close"
            onClick={handleCloseButtonClick}
            onTouchStart={handleCloseButtonClick}
          >
            <SpriteIcon icon="close" />
          </IconButton>
          <p>Action Summary</p>
          <p>Prospects: 10</p>
          <p>Units: 10</p>
          <p>Volume: 344,001,598</p>
          <img src={HotsheetExample} alt="" />
        </div>
      )}
    </div>
  );
};

export default ActionSummary;
