import { Select } from '@mui/material';
import { styled } from '@mui/system';

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: var(--blue-color-hover);
    }
    &.Mui-focused fieldset {
      border-color: var(--blue-color);
    }
  }
`;

export default StyledSelect;
