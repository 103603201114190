import { Dispatch } from 'redux';
import { Buffer } from 'buffer';

import { setKeySettings } from '@/store/keySettingsSlice';

export type OverlayType = {
  type: string;
  overlay: any;
};

export const SEARCH_TYPE_AREA = 'area';
export const SEARCH_TYPE_OFFICE_AREA = 'officeArea';
export const CIRCLE_TYPE = 'circle';
export const RECTANGLE_TYPE = 'rectangle';
export const POLYGON_TYPE = 'polygon';
export const BLUE_COLOR = '#1E90FF';
export const PURPLE_COLOR = '#8E61B6';
export const FILL_OPACITY = 0.3;
export const STROKE_WEIGHT = 2;

export const keysToResetBySearchType = {
  area: ['county', 'city', 'zipCode', 'district', 'subdivision'],
  officeArea: [
    'officeCounty',
    'officeCity',
    'officeZip',
    'officeCityInclude',
    'officeZipInclude',
    'officeCountyInclude',
  ],
};

export const keysToResetAll = [
  'county',
  'city',
  'zipCode',
  'district',
  'subdivision',
  'officeCounty',
  'officeCity',
  'officeZip',
  'officeCityInclude',
  'officeZipInclude',
  'officeCountyInclude',
];

export const jsonToBase64 = (object: any) => {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString('base64');
};

export const base64ToJson = (base64String: string) => {
  if (base64String === '') return '';
  const json = Buffer.from(base64String, 'base64').toString();
  return JSON.parse(json);
};

export const circleToValue = (circle: any) => {
  const center = circle.getCenter().toUrlValue().split(',').map(parseFloat);
  const radius = parseFloat(circle.getRadius().toFixed(6));
  return jsonToBase64({
    type: 'circle',
    center,
    radius,
  });
};

export const rectangleToValue = (rectangle: any) => {
  const bounds = rectangle.getBounds().toUrlValue().split(',').map(parseFloat);
  const sw = [bounds[0], bounds[1]];
  const ne = [bounds[2], bounds[3]];

  return jsonToBase64({
    type: 'rectangle',
    sw,
    ne,
  });
};

export const polygonToValue = (polygon: any) => {
  const bounds: any = [];
  const paths = polygon.getPaths();

  paths.forEach((path: any) => {
    const ar = path.getArray();

    for (let i = 0, l = ar.length; i < l; i++) {
      bounds.push([ar[i].lat(), ar[i].lng()]);
    }

    if (ar[0]) {
      bounds.push([ar[0].lat(), ar[0].lng()]);
    }
  });

  return jsonToBase64({
    type: 'polygon',
    path: bounds,
  });
};

export const resetKeys = (dispatch: Dispatch, keysToReset: string[]): void => {
  keysToReset.forEach((key) => {
    if (
      ['officeCityInclude', 'officeZipInclude', 'officeCountyInclude'].includes(
        key
      )
    ) {
      dispatch(setKeySettings({ key, value: 'true' }));
    } else {
      dispatch(setKeySettings({ key, value: '' }));
    }
  });
};

interface DecodedArea {
  type: string;
  path?: any[];
  center?: { lat: number; lng: number };
  sw?: { lat: number; lng: number };
}

interface MLSData {
  latitude: number;
  longitude: number;
}

export const getCenterByMapType = (
  props: { searchType: string },
  area: string,
  officeArea: string,
  currMls: MLSData
) => {
  const decodedArea: DecodedArea =
    props.searchType === 'area' ? base64ToJson(area) : base64ToJson(officeArea);

  switch (decodedArea.type) {
    case 'polygon':
      return decodedArea.path ? decodedArea.path[0] : null;
    case 'circle':
      return decodedArea.center ? decodedArea.center : null;
    case 'rectangle':
      return decodedArea.sw ? decodedArea.sw : null;
    default:
      return {
        lat: currMls.latitude,
        lng: currMls.longitude,
      };
  }
};
