import moment from 'moment';

import { getColorForSeries } from '@/components/Account/utils';
import i18n from '@/i18n';
import { EGraphTypes, IGraphMarketTrendsList } from '@/store/types';

import { capitalizedTimePeriod, getChartType, palettecolors } from './utils';

const timePeriodOptions = [
  { value: 'monthly', label: 'chart.monthly' },
  { value: 'yearly', label: 'chart.yearly' },
];

const dataList = (
  timePeriod?: string,
  mlsCurrentName?: string
): IGraphMarketTrendsList[] => {
  const data = [
    {
      value: 'totalUnit',
      label: 'field.units',
      title: `Market ${capitalizedTimePeriod(timePeriod)} Trends by Sold Units`,
      toolText: '$label {br} Units Sold: $dataValue',
    },
    {
      value: 'totalVolume',
      label: 'field.volume',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Sold Volume`,
      toolText: '$label {br} Volume Sold: $dataValue',
    },
    {
      value: 'avgPrice',
      label: 'field.average_price',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Average Sold Price`,
      toolText: '$label {br} Average Price Sold: $dataValue',
    },
    {
      value: 'medianPrice',
      label: 'field.median_price',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Median Sold Price`,
      toolText: '$label {br} Median Price Sold: $dataValue',
    },
    {
      value: 'avgRatio',
      label: 'field.sold_asked_ratio',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Average Sold/Asked Ratio of Sold Units`,
      toolText: '$label {br} Average Sold To Asked Ratio: $dataValue',
    },
    {
      value: 'medianRatio',
      label: 'field.median_sold_asked_ratio',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Median Sold/Asked Ratio of Sold Units`,
      toolText: '$label {br} Median Sold To Asked Ratio: $dataValue',
    },
    {
      value: 'avgDOM',
      label: 'field.average_dom',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Average Days on Market of Sold Units`,
      toolText: '$label {br} Average Days On Market: $dataValue',
    },
    {
      value: 'medianDOM',
      label: 'field.median_dom',
      title: `Market ${capitalizedTimePeriod(
        timePeriod
      )} Trends by Median Days on Market of Sold Units`,
      toolText: '$label {br} Media Days on Market: $dataValue',
    },
    {
      value: 'totalUnitWOMMOM',
      label: 'field.units_by_wom_and_mom',
      title: 'Sold Units by Weeks and Months on Market',
      toolText: '$label, $dataValue',
    },
    {
      value: 'avgRatioWOMMOM',
      label: 'field.sold_asked_ratio_by_wom_and_mom',
      title: 'Sold/Asked Ratio by Weeks and Months on Market',
      toolText: '$label, $dataValue',
    },
  ];

  if (mlsCurrentName !== 'Toronto') {
    data.push({
      value: 'inventory',
      label: 'field.inventory',
      title: 'Market Inventory - Units by Status',
      toolText: '$seriesName, $label, $dataValue',
    });
  }

  return data;
};

const seriesMappings = {
  multiple: {
    inventory: {
      seriesOne: 'series.month_end_inventory',
      seriesTwo: 'series.list',
      seriesThree: 'series.pending',
      seriesFour: 'series.closed_sold',
      seriesFive: 'series.expired',
    },
  },
  'dual-simple': {
    totalUnitWOMMOM: {
      seriesOne: 'field.sold_units_by_mom',
      seriesTwo: 'field.sold_units_by_wom',
    },
    avgRatioWOMMOM: {
      seriesOne: 'field.sold_asked_ratio_by_mom',
      seriesTwo: 'field.sold_asked_ratio_by_wom',
    },
  },
  simple: {
    totalUnit: {
      seriesOne: 'field.units',
    },
    totalVolume: {
      seriesOne: 'field.volume',
    },
    avgPrice: {
      seriesOne: 'field.average_price',
    },
    medianPrice: {
      seriesOne: 'field.median_price',
    },
    avgRatio: {
      seriesOne: 'field.sold_asked_ratio',
    },
    medianRatio: {
      seriesOne: 'field.median_sold_asked_ratio',
    },
    avgDOM: {
      seriesOne: 'field.average_dom',
    },
    medianDOM: {
      seriesOne: 'field.median_dom',
    },
  },
};

const getChartConfig = (
  items: Array<any>,
  chartType: string,
  showColumnValues: boolean,
  height: number,
  data: string
) => {
  const commonConfig = {
    type: getChartType(chartType, items, showColumnValues),
    width: '100%',
    height: height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        palettecolors: palettecolors(),
        theme: 'fusion',
        exportEnabled: '1',
        exportFormats: 'PNG|JPG|PDF|SVG',
        exportFileName: 'market-graph',
        formatNumberScale: '0',
        decimalSeparator: '.',
        thousandSeparator: ',',
        showSum: `${Number(!showColumnValues)}`,
        showValues: '0',
        numberSuffix:
          data === 'medianRatio' ||
          data === 'avgRatio' ||
          data === 'avgRatioWOMMOM'
            ? '%'
            : '',
        numberPrefix:
          data === 'avgPrice' ||
          data === 'medianPrice' ||
          data === 'totalVolume'
            ? '$'
            : '',
        drawcrossline: '1',
        setAdaptiveYMin: '1',
      },
      categories: [{}],
      dataset: [{}],
    },
  };

  return commonConfig;
};

const generateCategories = (items: Array<any>, data: string) => {
  let categories = [];

  if (data === 'totalUnitWOMMOM' || data === 'avgRatioWOMMOM') {
    categories = [
      {
        category: items.map((i: any) => {
          return {
            label: `${i.week ? `Week ${i.week}` : ''}`,
          };
        }),
      },
    ];
  } else {
    categories = [
      {
        category: items.map((i: any) => {
          return {
            label: `${
              i.month && !i.week
                ? moment.utc(`${i.month}`, 'M').format('MMM')
                : ''
            } ${i.year ? moment.utc(`${i.year}`, 'YYYY').format('YYYY') : ''} ${
              i.week ? `Week ${i.week}` : ''
            }`,
          };
        }),
      },
    ];
  }

  return categories;
};

const generateCategoriesDualSimple = (items: Array<any>, data: string) => {
  let categories = [];

  if (data === 'totalUnitWOMMOM' || data === 'avgRatioWOMMOM') {
    categories = [
      {
        category: items.map((i: any) => {
          return {
            label: `${i.month ? `Month ${i.month}` : ''}`,
          };
        }),
      },
    ];
  } else {
    categories = [
      {
        category: items.map((i: any) => {
          return {
            label: `${
              i.month && !i.week
                ? moment.utc(`${i.month}`, 'M').format('MMM')
                : ''
            } ${i.year ? moment.utc(`${i.year}`, 'YYYY').format('YYYY') : ''} ${
              i.week ? `Week ${i.week}` : ''
            }`,
          };
        }),
      },
    ];
  }

  return categories;
};

const generateDataset = (
  items: any,
  graphType: EGraphTypes,
  data: string,
  seriesMappings: any
) => {
  const dataset = [];

  if (graphType && data && items && Array.isArray(items)) {
    //@ts-ignore
    const seriesMapping =
      seriesMappings[graphType] && seriesMappings[graphType][data];

    for (const series in seriesMapping) {
      const seriesTranslation = i18n.t(seriesMapping[series]);

      if (items.some((item) => item[seriesTranslation] !== null)) {
        if (graphType === EGraphTypes.DUAL_SIMPLE && series !== 'seriesTwo') {
          continue;
        }

        dataset.push({
          seriesname: seriesTranslation,
          data: items
            .filter((item) => item[seriesTranslation] !== null)
            .map((item) => ({
              value: item[series],
              toolText: dataList().find((item) => item.value === data)
                ?.toolText,
            })),
          color: getColorForSeries(series),
          anchorBorderColor: getColorForSeries(series),
          anchorBgColor: getColorForSeries(series),
          legendBgColor: getColorForSeries(series),
          legendBorderColor: getColorForSeries(series),
        });
      }
    }
  }

  return dataset;
};

const generateDualSimpleChartConfig = (
  items: any,
  graphType: EGraphTypes,
  data: string,
  seriesMappings: any
) => {
  const dataset = [];

  if (
    graphType &&
    data &&
    items &&
    Array.isArray(items) &&
    graphType === EGraphTypes.DUAL_SIMPLE
  ) {
    //@ts-ignore
    const seriesMapping = seriesMappings['dual-simple'][data];
    const seriesTranslation = i18n.t(seriesMapping['seriesOne']);

    if (seriesTranslation && items.some((item) => item['seriesOne'] !== null)) {
      dataset.push({
        seriesname: seriesTranslation,
        data: items
          .filter((item) => item['seriesOne'] !== null)
          .map((item) => ({
            value: item['seriesOne'],
            toolText: dataList().find((item) => item.value === data)?.toolText,
          })),
      });
    }
  }

  return dataset;
};

export const dataLabel = (timePeriod: string, data: string) =>
  dataList(timePeriod).find((item) => item.value === data)?.title;

export {
  dataList,
  generateCategories,
  generateCategoriesDualSimple,
  generateDataset,
  generateDualSimpleChartConfig,
  getChartConfig,
  seriesMappings,
  timePeriodOptions,
};
