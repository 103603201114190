import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTE } from '@/router/consts';
import { AppDispatch } from '@/store';
import { handleExternalLogin } from '@/store/authSlice';

const TokenLogin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const extractUser = (): string | null => {
    const urlSearchParams = new URLSearchParams(location.search);
    return urlSearchParams.get('user');
  };
  const extractExpires = (): string | null => {
    const urlSearchParams = new URLSearchParams(location.search);
    return urlSearchParams.get('expires');
  };
  const extractHash = (): string | null => {
    const urlSearchParams = new URLSearchParams(location.search);
    return urlSearchParams.get('hash');
  };

  const loginWithToken = (
    user: string | null,
    expires: string | null,
    hash: string | null
  ) => {
    if (user && expires && hash) {
      dispatch(handleExternalLogin(user, expires, hash));
      navigate(ROUTE.KEY_REPORTS, { replace: true });
    } else {
      console.error('URL requires user, expires, and hash parameters');
    }
  };

  useEffect(() => {
    const user = extractUser();
    const expires = extractExpires();
    const hash = extractHash();

    loginWithToken(user, expires, hash);
  }, [dispatch, location.search]);

  return <div>Logging in...</div>;
};

export default TokenLogin;
