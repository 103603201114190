import LocalStorageHelper from './Authentication/storageHelper';

export default {
  getRefreshToken(): string | null {
    return LocalStorageHelper.get('refreshToken');
  },

  setRefreshToken(refreshToken: string): void {
    LocalStorageHelper.add('refreshToken', refreshToken);
  },

  removeRefreshToken(): void {
    LocalStorageHelper.remove('refreshToken');
  },
};
