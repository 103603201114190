import { Radio } from '@mui/material';
import { styled } from '@mui/system';

const StyledRadio = styled(Radio)`
  color: var(--blue-color);

  &.Mui-checked {
    color: var(--blue-color);
  }
`;

export default StyledRadio;
