export const findAndCheckFreeze = (array: any[], idToFind: string): boolean => {
  const foundItem = array.find((item) => item.id === idToFind);
  return (
    !!foundItem &&
    //@ts-ignore
    Object.values(foundItem.meta).some((format) => format.freeze === true)
  );
};

export const adjustColumnPositions = async () => {
  const tableParent = document.querySelector(
    '.MuiTableContainer-root'
  ) as HTMLElement;

  let tableTr: any[] = [];
  tableTr = Array.from(tableParent?.querySelectorAll('tr') || []);

  const forEachPromise = () => {
    return new Promise<void>((resolve) => {
      tableTr.forEach((tr, index) => {
        const thElements = tr.querySelectorAll('th');
        const tdElements = tr.querySelectorAll('td');
        thElements.forEach((th: HTMLElement) => {
          th.style.left = 'auto';
        });
        tdElements.forEach((td: HTMLElement) => {
          td.style.left = 'auto';
        });
        const th = tr.querySelector('th');
        const td = tr.querySelector('td');
        if (th) th.classList.add('freeze');
        if (td) td.classList.add('freeze');

        if (index === tableTr.length - 1) {
          resolve();
        }
      });
    });
  };

  await forEachPromise();

  const stickColEl = tableParent?.querySelectorAll('.freeze') || [];
  const tableThFreeze = Array.from(
    tableParent?.querySelectorAll('th.freeze') || []
  );
  const tableThFreezeLength = tableThFreeze.length;

  let totalOffset = 0;
  let groupIndex = 0;

  (stickColEl as NodeListOf<Element>).forEach((el, index) => {
    if (index % tableThFreezeLength === 0) {
      groupIndex++;
      totalOffset = 0;
    }

    el.setAttribute('style', `left: ${totalOffset}px`);
    totalOffset += el.getBoundingClientRect().width;
  });
};
