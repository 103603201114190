import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const HeaderRightMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="circle-button"
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        <div className="dots-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Export
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Export all
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Email to Agents
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          View Combined Stats
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Delete From Hotsheet
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Combined stats
        </MenuItem>
        <MenuItem onClick={handleClose} onTouchStart={handleClose}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderRightMenu;
