import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { maxNumber, onlyNumbersWithOneComma } from '@/validation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';

import './common.scss';

const totalUnitsValidationSchema = Yup.object().shape({
  totalUnitsMin: Yup.string()
    .nullable()
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
  totalUnitsMax: Yup.string()
    .nullable()
    .when('totalUnitsMin', {
      is: (max: string) =>
        max && parseFloat(max.toString().replace(/,/g, '')) >= 0,
      then: Yup.string().test({
        name: 'isNotLessThanTotalUnitsMin',
        message: '',
        test() {
          const { totalUnitsMin, totalUnitsMax } = this.parent;

          return totalUnitsMax !== ''
            ? parseFloat(totalUnitsMax.toString().replace(/,/g, '')) >=
                parseFloat(totalUnitsMin.toString().replace(/,/g, ''))
            : true;
        },
      }),
    })
    .test(
      'maxNumber',
      i18n.t('max_number_qty', { qty: maxNumber }),
      (val: any) =>
        val ? parseFloat(val.toString().replace(/,/g, '')) <= maxNumber : true
    ),
});

const TotalUnitFilter = () => {
  const { t } = useTranslation();
  const keySettings = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [totalUnitsMin, setTotalUnitsMin] = useState<string>('');
  const [totalUnitsMax, setTotalUnitsMax] = useState<string>('');
  const open = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(totalUnitsValidationSchema),
  });

  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = async () => {
    setTotalUnitsMin('');
    setTotalUnitsMax('');
    await dispatch(
      setKeySettings({
        key: 'totalUnitsMin',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'totalUnitsMax',
        value: '',
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const applyFilter = async () => {
    handleClose();
    await dispatch(
      setKeySettings({
        key: 'totalUnitsMin',
        value: totalUnitsMin.replace(/,/g, ''),
      })
    );

    await dispatch(
      setKeySettings({
        key: 'totalUnitsMax',
        value: totalUnitsMax.replace(/,/g, ''),
      })
    );
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(loadReport(false));
    dispatch(getSavedSearchList());
  };

  const handleTotalUnitsMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setTotalUnitsMin(e.target.value);
      setValue('totalUnitsMin', e.target.value, { shouldValidate: true });
    }
  };

  const handleTotalUnitsMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbersWithOneComma(e.target.value)) {
      setTotalUnitsMax(e.target.value);
      setValue('totalUnitsMax', e.target.value, { shouldValidate: true });
    }
  };

  const icon =
    keySettings.totalUnitsMin !== '' || keySettings.totalUnitsMax !== ''
      ? 'filter'
      : 'search';
  const tooltipText = `${
    keySettings.totalUnitsMin !== ''
      ? t('from_selected', { quantity: keySettings.totalUnitsMin })
      : ''
  }${' '}${
    keySettings.totalUnitsMax !== ''
      ? t('to_selected', { quantity: keySettings.totalUnitsMax })
      : ''
  }${' '}${t('click_clear')}`;

  return (
    <div className="column-search-menu-wrap">
      <div
        onClick={(e) => {
          keySettings.totalUnitsMin !== '' || keySettings.totalUnitsMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
        onTouchStart={(e) => {
          keySettings.totalUnitsMin !== '' || keySettings.totalUnitsMax !== ''
            ? handleClear()
            : handleOpen(e);
        }}
      >
        {keySettings.totalUnitsMin !== '' ||
        keySettings.totalUnitsMax !== '' ? (
          <Tooltip title={tooltipText}>
            <IconButton aria-label="search" className="searchBtn">
              <SpriteIcon icon={icon} />
            </IconButton>
          </Tooltip>
        ) : (
          <SpriteIcon aria-label="search" className="searchBtn" icon={icon} />
        )}
      </div>
      <Menu
        aria-labelledby="column-search"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="column-search-menu w-100 filter-block-search"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form className="d-flex">
          <div className="d-flex flex-column">
            <label className="form-label" htmlFor="totalUnitsMin">
              {t('min')}
            </label>
            <StyledTextField
              id="totalUnitsMin"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={totalUnitsMin}
              variant="outlined"
              sx={{ paddingLeft: '0', paddingRight: '6px', width: '64px' }}
              error={!!errors.totalUnitsMin}
              //@ts-ignore
              helperText={errors?.totalUnitsMin?.message}
              {...register('totalUnitsMin')}
              onChange={handleTotalUnitsMin}
            />
          </div>
          <div className="d-flex flex-column">
            <label
              style={{ paddingLeft: '6px' }}
              className="form-label"
              htmlFor="totalUnitsMax"
            >
              {t('max')}
            </label>
            <StyledTextField
              id="totalUnitsMax"
              inputProps={{
                style: {
                  height: '28px',
                  padding: '4px 10px',
                  boxSizing: 'border-box',
                },
              }}
              value={totalUnitsMax}
              variant="outlined"
              sx={{ paddingLeft: '6px', paddingRight: '0', width: '64px' }}
              error={!!errors.totalUnitsMax}
              //@ts-ignore
              helperText={errors?.totalUnitsMax?.message}
              {...register('totalUnitsMax')}
              onChange={handleTotalUnitsMax}
            />
          </div>
        </form>
        <div
          className="filter-footer"
          style={{ padding: 0, marginTop: '10px' }}
        >
          <Button
            type="submit"
            style={{ width: '100%' }}
            className="filled-btn"
            disabled={totalUnitsMin === '' && totalUnitsMax === ''}
            onClick={handleSubmit(applyFilter)}
            onTouchStart={handleSubmit(applyFilter)}
          >
            {t('buttons.apply')}
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default TotalUnitFilter;
