import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SavedSearchModal from '@/components/SavedSearchWidget/SavedSearchModal';
import SearchItem from '@/components/SavedSearchWidget/SearchItem';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import StyledTextField from '@/mui-styled-components/text-field';
import {
  getSavedSearchListState,
  getSavedSearchState,
} from '@/store/keySettingsSlice';
import { IUserSearchList } from '@/store/types';
import { Box, Button, Fade, Menu, Popper } from '@mui/material';

import './SavedSearchWidget.scss';

const SavedSearches = () => {
  const savedSearchList = useSelector(getSavedSearchListState);
  const savedSearch = useSelector(getSavedSearchState);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [filteredList, setFilteredList] = useState(savedSearchList);
  const [anchorPopperEl, setAnchorPopperEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [placement, setPlacement] = useState();
  const open = Boolean(anchorEl);

  useEffect(() => {
    handleClose();
  }, [savedSearch]);

  useEffect(() => {
    setFilteredList(savedSearchList);
  }, [savedSearchList]);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setFilteredList([...savedSearchList]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterBySearch = (event: any) => {
    const query = event.target.value;

    let updatedList = [...savedSearchList];
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setFilteredList(updatedList);
  };

  const handlePopper = (newPlacement: any) => (event: any) => {
    if (localStorage.getItem('popperVisible') === 'false') return;
    setAnchorPopperEl(event.currentTarget);
    setOpenPopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <>
      <div className="saved-search-widget w-100 cursor-pointer">
        <div
          className="field-wrap"
          onClick={handleClick}
          onTouchStart={handleClick}
        >
          <StyledTextField
            sx={{ color: '#000000', pointerEvents: 'none' }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000',
              },
            }}
            id="saved-search-select"
            className="form-select"
            placeholder={t('select')}
            value={savedSearch?.name || ''}
            disabled
          />
          <SpriteIcon icon="arrow_down" />
        </div>
        <Menu
          aria-labelledby="saved-search-select"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="w-100"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StyledTextField
            variant="standard"
            sx={{ minWidth: 228, paddingLeft: '10px', paddingRight: '10px' }}
            onChange={filterBySearch}
            onKeyDown={(e) => e.stopPropagation()}
          />
          {filteredList?.map((item: IUserSearchList) => (
            <SearchItem
              key={item.id}
              item={item}
              handleOpenModal={handleOpenModal}
              handleCloseMenu={handleClose}
            />
          ))}
        </Menu>
      </div>
      <Popper
        open={openPopper}
        anchorEl={anchorPopperEl}
        placement={placement}
        transition
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                border: 0,
                p: 1,
                bgcolor: '#F7F9D2',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <SpriteIcon
                style={{
                  width: '12px',
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer',
                }}
                icon="close"
                onClick={() => {
                  setOpenPopper(false);
                }}
                onTouchStart={() => {
                  setOpenPopper(false);
                }}
              />
              <p style={{ fontSize: '12px', marginBottom: '0' }}>
                {t('key_settings.popover')}
              </p>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#0C7CC4',
                    fontWeight: '700',
                    cursor: 'pointer',
                    textAlign: 'right',
                  }}
                  onClick={() => {
                    localStorage.setItem('popperVisible', 'false');
                    setOpenPopper(false);
                  }}
                  onTouchStart={() => {
                    localStorage.setItem('popperVisible', 'false');
                    setOpenPopper(false);
                  }}
                >
                  {t('key_settings.dont_show_again')}
                </p>
              </div>
            </Box>
          </Fade>
        )}
      </Popper>
      <Button
        variant="outlined"
        onMouseEnter={handlePopper('right')}
        startIcon={<SpriteIcon icon="save" />}
        className="save-search-btn"
        aria-label={t('key_settings.save_search')}
        onClick={handleOpenModal}
        onTouchStart={handleOpenModal}
      />
      <SavedSearchModal open={openModal} closeHandler={handleCloseModal} />
    </>
  );
};

export default SavedSearches;
