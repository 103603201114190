import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import StyledRadio from '@/mui-styled-components/radio';
import { getSettings } from '@/store/authSlice';
import { Radio, RadioGroup } from '@mui/material';

import {
  activeClass,
  ColorSchemaData,
  getColumns,
  IColorSettings,
} from './utils';

import './ColorSettings.scss';

interface ColorSettingsProps {
  setCurrentColorScheme: Dispatch<SetStateAction<number>>;
}

const ColorSettings: React.FC<ColorSettingsProps> = ({
  setCurrentColorScheme,
}) => {
  const [data, setData] = useState<IColorSettings[]>(ColorSchemaData);
  const { currentColorScheme } = useSelector(getSettings);

  const currentColorSchemeId = data.find(
    (item) => item.id === parseInt(currentColorScheme, 10)
  )?.id;

  const onSchemeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const newValue = parseInt(value, 10);
    const newData = data.map((item) => {
      return {
        ...item,
        current: item.id === newValue,
      };
    });

    setData(newData);
    setCurrentColorScheme(newValue);
  };

  return (
    <div className="colors-settings table-responsive">
      {data.length > 0 && (
        <RadioGroup value={currentColorSchemeId} onChange={onSchemeChange}>
          <table>
            <thead>
              <tr>
                {getColumns().map((item) => (
                  <th key={item.field}>{item.headerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className={`${activeClass(item.current)}`}>
                  <td>
                    <StyledRadio
                      value={item.id}
                      name="color-schema"
                      sx={{
                        padding: 0,
                      }}
                    />
                    {item.name}
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.mainPage }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.buttons.default }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.buttons.active }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.tableHeader.bg }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.tableHeader.color }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.tableRow }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.graphs.color1 }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.graphs.color2 }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.graphs.color3 }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.graphs.color4 }}
                    />
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.graphs.color5 }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.reportsLinks }}
                    />
                  </td>
                  <td>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: item.headerFont }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </RadioGroup>
      )}
    </div>
  );
};

export default ColorSettings;
