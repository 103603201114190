import { combineReducers } from 'redux';

import userReducer from '@/store/authSlice';
import filterListsReducer from '@/store/filterListsSlice';
import keySettingsReducer from '@/store/keySettingsSlice';
import mlsReducer from '@/store/mlsSlice';
import reportFieldsReducer from '@/store/reportFieldsSlice';
import reportReducer from '@/store/reportSlice';

const rootReducer = combineReducers({
  auth: userReducer,
  mls: mlsReducer,
  keySettings: keySettingsReducer,
  filterLists: filterListsReducer,
  report: reportReducer,
  reportFields: reportFieldsReducer,
});

export default rootReducer;
