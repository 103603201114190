import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CustomStringSelect from '@/components/CustomElements/CustomStringSelect';
import i18n from '@/i18n';
import StyledTextField from '@/mui-styled-components/text-field';
import { addURLParameter } from '@/router/router';
import { AppDispatch } from '@/store';
import { ETimeFrames } from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import {
  getKeySettings,
  getSavedSearchList,
  setKeySettings,
} from '@/store/keySettingsSlice';
import { loadReport } from '@/store/reportSlice';
import { SelectChangeEvent } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateSettings = (props: { onChangeDate: (value: boolean) => void }) => {
  const { onChangeDate } = props;
  const { t } = useTranslation();
  const { timeFrame, startDate, endDate } = useSelector(getKeySettings);
  const [minDate, setMinDate] = useState<Moment | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Moment | undefined>(undefined);
  const [errorMinDate, setErrorMinDate] = useState(false);
  const [errorMaxDate, setErrorMaxDate] = useState(false);
  const [timeFrameValue, setTimeFrameValue] = useState<ETimeFrames | string>(
    timeFrame
  );
  const { timeFrameList, lastDate, firstDate } = useSelector(getFilterLists);
  const dispatch = useDispatch<AppDispatch>();

  const handleChangeStartDate = (value: Moment | null) => {
    const date = moment(value)?.format('YYYY-MM-DD');

    if (timeFrame !== ETimeFrames.CUSTOM) {
      setTimeFrameValue(ETimeFrames.CUSTOM);
      dispatch(
        setKeySettings({
          key: 'timeFrame',
          value: ETimeFrames.CUSTOM,
        })
      );
    }

    if (moment(value).format() > moment(endDate).format()) {
      toast.error(t('messages.error_date'));
      setErrorMinDate(true);
      onChangeDate(false);
    } else {
      dispatch(
        setKeySettings({
          key: 'startDate',
          value: date || null,
        })
      );

      setErrorMinDate(false);
      setErrorMaxDate(false);
      onChangeDate(true);

      dispatch(loadReport());
      dispatch(getSavedSearchList());
    }
  };

  const handleChangeEndDate = (value: Moment | null) => {
    const date = moment(value)?.format('YYYY-MM-DD');

    if (timeFrame !== ETimeFrames.CUSTOM) {
      setTimeFrameValue(ETimeFrames.CUSTOM);
      dispatch(
        setKeySettings({
          key: 'timeFrame',
          value: ETimeFrames.CUSTOM,
        })
      );
    }

    if (moment(value).format() < moment(startDate).format()) {
      toast.error(t('messages.error_date'));
      setErrorMaxDate(true);
      onChangeDate(false);
    } else {
      dispatch(setKeySettings({ key: 'endDate', value: date || null }));
      setErrorMaxDate(false);
      setErrorMinDate(false);
      onChangeDate(true);

      dispatch(loadReport());
      dispatch(getSavedSearchList());
    }
  };

  const handleSelectTimeFrame = (
    event: SelectChangeEvent<string | number | null>
  ) => {
    setTimeFrameValue(event.target.value as ETimeFrames);
    dispatch(
      setKeySettings({
        key: 'timeFrame',
        value: event.target.value,
      })
    );

    addURLParameter('timeFrame', event.target.value);
  };

  useEffect(() => {
    const dateFrom = moment.utc(lastDate).format('YYYY-MM-DD');
    setMinDate(moment.utc(firstDate));
    setMaxDate(moment.utc(lastDate));

    dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );

    const changeDates = (value: number, years = false) => {
      dispatch(
        setKeySettings({
          key: 'endDate',
          value: dateFrom,
        })
      );
      dispatch(
        setKeySettings({
          key: 'startDate',
          value: moment
            .utc(dateFrom)
            .subtract(value, years ? 'years' : 'months')
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
        })
      );

      dispatch(loadReport());
      dispatch(getSavedSearchList());
    };

    const setCustomFrame = () => {
      dispatch(
        setKeySettings({
          key: 'timeFrame',
          value: ETimeFrames.CUSTOM,
        })
      );

      dispatch(loadReport());
      dispatch(getSavedSearchList());
    };

    const setYearRange = (value: number) => {
      if (value === moment.utc().year()) {
        dispatch(
          setKeySettings({
            key: 'endDate',
            value: moment.utc(lastDate).format('YYYY-MM-DD'),
          })
        );
      } else {
        dispatch(
          setKeySettings({
            key: 'endDate',
            value: moment.utc(new Date(`${value}-12-31`)).format('YYYY-MM-DD'),
          })
        );
      }

      dispatch(
        setKeySettings({
          key: 'startDate',
          value: moment.utc(new Date(`${value}-01-01`)).format('YYYY-MM-DD'),
        })
      );

      dispatch(loadReport());
      dispatch(getSavedSearchList());
    };

    switch (timeFrameValue.toLowerCase()) {
      case ETimeFrames.YEAR:
        return changeDates(1, true);
      case ETimeFrames.HALF_YEAR:
        return changeDates(6);
      case ETimeFrames.THREE_MONTHS:
        return changeDates(3);
      case ETimeFrames.MONTH:
        return changeDates(1);
      case ETimeFrames.CUSTOM:
        return setCustomFrame();
      default:
        return setYearRange(parseInt(timeFrameValue));
    }
  }, [timeFrameValue]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setTimeFrameValue(timeFrame);
    }
  }, [timeFrame]);

  return (
    <>
      {timeFrameList.length && (
        <CustomStringSelect
          label={t('key_settings.time_frame')}
          placeholder={t('select')}
          value={timeFrameValue}
          choices={timeFrameList}
          fullWidth={true}
          style={{ paddingTop: 10 }}
          setValue={handleSelectTimeFrame}
        />
      )}

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={i18n.language}
      >
        <div className="d-flex" style={{ paddingTop: 10 }}>
          <div className="form-date" style={{ marginRight: '10px' }}>
            <label htmlFor="date-start">Start Date</label>
            <DesktopDatePicker
              PaperProps={{
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'var(--blue-color)!important',
                    },
                  },
                  '& .PrivatePickersYear-yearButton': {
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'var(--blue-color)!important',
                    },
                  },
                },
              }}
              inputFormat="MM/DD/yyyy"
              minDate={minDate}
              maxDate={maxDate}
              value={startDate}
              onChange={handleChangeStartDate}
              renderInput={(params) => (
                <StyledTextField {...params} error={errorMinDate} />
              )}
            />
          </div>
          <div className="form-date" style={{ marginLeft: '10px' }}>
            <label htmlFor="date-start">End Date</label>
            <DesktopDatePicker
              PaperProps={{
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'var(--blue-color)!important',
                    },
                  },
                  '& .PrivatePickersYear-yearButton': {
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'var(--blue-color)!important',
                    },
                  },
                },
              }}
              inputFormat="MM/DD/yyyy"
              minDate={minDate}
              maxDate={maxDate}
              value={endDate}
              onChange={handleChangeEndDate}
              renderInput={(params) => (
                <StyledTextField {...params} error={errorMaxDate} />
              )}
            />
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
};

export default DateSettings;
