import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isPropertyIncluded } from '@/components/KeySettings/utils';
import useDebounce from '@/hooks/useDebounce';
import StyledTextField from '@/mui-styled-components/text-field';
import { AppDispatch } from '@/store';
import { getKeySettings, setKeySettings } from '@/store/keySettingsSlice';
import { getReport, resetReport } from '@/store/reportSlice';
import { onlyNumbers } from '@/validation';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const AdvancedSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { userAvailableAppliedSettings } = useSelector(getReport);
  const {
    yearBuiltMin,
    yearBuiltMax,
    bedroomsMin,
    bedroomsMax,
    totalSquareFootageMin,
    totalSquareFootageMax,
    finishedSquareFootageMin,
    finishedSquareFootageMax,
    bathroomsFullMin,
    bathroomsFullMax,
    bathroomsHalfMin,
    bathroomsHalfMax,
  } = useSelector(getKeySettings);
  const [expanded, setExpanded] = useState(false);
  const [yearMin, setYearMin] = useState<number | string>(yearBuiltMin || '');
  const [yearMax, setYearMax] = useState<number | string>(yearBuiltMax || '');
  const [bedroomsMinValue, setBedroomsMinValue] = useState<number | string>(
    bedroomsMin || ''
  );
  const [bedroomsMaxValue, setBedroomsMaxValue] = useState<number | string>(
    bedroomsMax || ''
  );
  const [totalSqFMinValue, setTotalSqFMinValue] = useState<number | string>(
    totalSquareFootageMin || ''
  );
  const [totalSqFMaxValue, setTotalSqFMaxValue] = useState<number | string>(
    totalSquareFootageMax || ''
  );
  const [finishedSqFMin, setFinishedSqFMin] = useState<number | string>(
    finishedSquareFootageMin || ''
  );
  const [finishedSqFMax, setFinishedSqFMax] = useState<number | string>(
    finishedSquareFootageMax || ''
  );
  const [bathsHalfMin, setBathsHalfMin] = useState<number | string>(
    bathroomsHalfMin || ''
  );
  const [bathsHalfMax, setBathsHalfMax] = useState<number | string>(
    bathroomsHalfMax || ''
  );
  const [bathsFullMin, setBathsFullMin] = useState<number | string>(
    bathroomsFullMin || ''
  );
  const [bathsFullMax, setBathsFullMax] = useState<number | string>(
    bathroomsFullMax || ''
  );

  const hasBedroomsProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'bedrooms'
  );
  const hasBathroomsFullProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'bathroomsFull'
  );
  const hasBathroomsHalfProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'bathroomsHalf'
  );
  const hasFinishedSquareFootageProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'finishedSquareFootage'
  );
  const hasTotalSquareFootageProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'totalSquareFootage'
  );
  const hasYearProperty = isPropertyIncluded(
    userAvailableAppliedSettings,
    'year'
  );

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { ref: inputBedroomsMinRef, ...inputBedroomsMinProps } =
    register('bedroomsMinValue');
  const { ref: inputBedroomsMaxRef, ...inputBedroomsMaxProps } =
    register('bedroomsMaxValue');
  const { ref: inputBathsFullMinRef, ...inputBathsFullMinProps } =
    register('bathsFullMin');
  const { ref: inputBathsFullMaxRef, ...inputBathsFullMaxProps } =
    register('bathsFullMax');
  const { ref: inputBathsHalfMinRef, ...inputBathsHalfMinProps } =
    register('bathsHalfMin');
  const { ref: inputBathsHalfMaxRef, ...inputBathsHalfMaxProps } =
    register('bathsHalfMax');
  const { ref: inputTotalSqFMinValueRef, ...inputTotalSqFMinValueProps } =
    register('totalSqFMinValue');
  const { ref: inputTotalSqFMaxValueRef, ...inputTotalSqFMaxValueProps } =
    register('totalSqFMaxValue');
  const { ref: inputFinishedSqFMinRef, ...inputFinishedSqFMinProps } =
    register('finishedSqFMin');
  const { ref: inputFinishedSqFMaxRef, ...inputFinishedSqFMaxProps } =
    register('finishedSqFMax');
  const { ref: inputYearMinRef, ...inputYearMinProps } = register('yearMin');
  const { ref: inputYearMaxRef, ...inputYearMaxProps } = register('yearMax');

  const debouncedYearMin = useDebounce(yearMin);
  const debouncedYearMax = useDebounce(yearMax);
  const debouncedBedroomsMin = useDebounce(bedroomsMinValue);
  const debouncedBedroomsMax = useDebounce(bedroomsMaxValue);
  const debouncedTotalSqFMin = useDebounce(totalSqFMinValue);
  const debouncedTotalSqFMax = useDebounce(totalSqFMaxValue);
  const debouncedFinishedSqFMin = useDebounce(finishedSqFMin);
  const debouncedFinishedSqFMax = useDebounce(finishedSqFMax);
  const debouncedBathsHalfMin = useDebounce(bathsHalfMin);
  const debouncedBathsHalfMax = useDebounce(bathsHalfMax);
  const debouncedBathsFullMin = useDebounce(bathsFullMin);
  const debouncedBathsFullMax = useDebounce(bathsFullMax);

  const updateMinYear = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      if (value.length < 5) {
        setYearMin(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
        setValue('yearMin', value, { shouldValidate: true });
      }
    }
  };

  const updateMaxYear = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      if (value.length < 5) {
        setYearMax(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
        setValue('yearMax', value, { shouldValidate: true });
      }
    }
  };

  const updateBedroomsMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBedroomsMinValue(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bedroomsMinValue', value, { shouldValidate: true });
    }
  };

  const updateBedroomsMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBedroomsMaxValue(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bedroomsMaxValue', value, { shouldValidate: true });
    }
  };

  const updateTotalMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setTotalSqFMinValue(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('totalSqFMinValue', value, { shouldValidate: true });
    }
  };

  const updateTotalMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setTotalSqFMaxValue(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('totalSqFMaxValue', value, { shouldValidate: true });
    }
  };

  const updateFinishedMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setFinishedSqFMin(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('finishedSqFMin', value, { shouldValidate: true });
    }
  };

  const updateFinishedMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setFinishedSqFMax(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('finishedSqFMax', value, { shouldValidate: true });
    }
  };

  const updateBathsHalfMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBathsHalfMin(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bathsHalfMin', value, { shouldValidate: true });
    }
  };

  const updateBathsHalfMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBathsHalfMax(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bathsHalfMax', value, { shouldValidate: true });
    }
  };

  const updateBathsFullMin = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBathsFullMin(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bathsFullMin', value, { shouldValidate: true });
    }
  };

  const updateBathsFullMax = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || onlyNumbers(e.target.value)) {
      const { value } = e.target;

      setBathsFullMax(value.match(/^(?:[1-9]\d*)$/g) ? value : '');
      setValue('bathsFullMax', value, { shouldValidate: true });
    }
  };

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'yearBuiltMin',
        value: debouncedYearMin !== '' ? debouncedYearMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedYearMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'yearBuiltMax',
        value: debouncedYearMax !== '' ? debouncedYearMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedYearMax]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bedroomsMin',
        value: debouncedBedroomsMin !== '' ? debouncedBedroomsMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBedroomsMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bedroomsMax',
        value: debouncedBedroomsMax !== '' ? debouncedBedroomsMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBedroomsMax]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'totalSquareFootageMin',
        value: debouncedTotalSqFMin !== '' ? debouncedTotalSqFMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedTotalSqFMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'totalSquareFootageMax',
        value: debouncedTotalSqFMax !== '' ? debouncedTotalSqFMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedTotalSqFMax]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'finishedSquareFootageMin',
        value: debouncedFinishedSqFMin !== '' ? debouncedFinishedSqFMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedFinishedSqFMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'finishedSquareFootageMax',
        value: debouncedFinishedSqFMax !== '' ? debouncedFinishedSqFMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedFinishedSqFMax]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bathroomsHalfMin',
        value: debouncedBathsHalfMin !== '' ? debouncedBathsHalfMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBathsHalfMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bathroomsHalfMax',
        value: debouncedBathsHalfMax !== '' ? debouncedBathsHalfMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBathsHalfMax]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bathroomsFullMin',
        value: debouncedBathsFullMin !== '' ? debouncedBathsFullMin : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBathsFullMin]);

  useEffect(() => {
    dispatch(
      setKeySettings({
        key: 'bathroomsFullMax',
        value: debouncedBathsFullMax !== '' ? debouncedBathsFullMax : null,
      })
    );
    // dispatch(resetReport({ reset: true }));
  }, [debouncedBathsFullMax]);

  useEffect(() => {
    setYearMin(yearBuiltMin || '');
  }, [yearBuiltMin]);

  useEffect(() => {
    setYearMax(yearBuiltMax || '');
  }, [yearBuiltMax]);

  useEffect(() => {
    setBedroomsMinValue(bedroomsMin || '');
  }, [bedroomsMin]);

  useEffect(() => {
    setBedroomsMaxValue(bedroomsMax || '');
  }, [bedroomsMax]);

  useEffect(() => {
    setFinishedSqFMin(finishedSquareFootageMin || '');
  }, [finishedSquareFootageMin]);

  useEffect(() => {
    setFinishedSqFMax(finishedSquareFootageMax || '');
  }, [finishedSquareFootageMax]);

  useEffect(() => {
    setTotalSqFMinValue(totalSquareFootageMin || '');
  }, [totalSquareFootageMin]);

  useEffect(() => {
    setTotalSqFMaxValue(totalSquareFootageMax || '');
  }, [totalSquareFootageMax]);

  useEffect(() => {
    setBathsFullMin(bathroomsFullMin || '');
  }, [bathroomsFullMin]);

  useEffect(() => {
    setBathsFullMax(bathroomsFullMax || '');
  }, [bathroomsFullMax]);

  useEffect(() => {
    setBathsHalfMin(bathroomsHalfMin || '');
  }, [bathroomsHalfMin]);

  useEffect(() => {
    setBathsHalfMax(bathroomsHalfMax || '');
  }, [bathroomsHalfMax]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      if (
        !!yearMin ||
        !!yearMax ||
        !!bathsFullMin ||
        !!bathsFullMax ||
        !!bathsHalfMin ||
        !!bathsHalfMax ||
        !!bedroomsMinValue ||
        !!bedroomsMaxValue ||
        !!totalSqFMinValue ||
        !!totalSqFMaxValue ||
        !!finishedSqFMin ||
        !!finishedSqFMax
      ) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [
    yearMin,
    yearMax,
    bathsFullMin,
    bathsFullMax,
    bathsHalfMin,
    bathsHalfMax,
    finishedSqFMin,
    finishedSqFMax,
    bedroomsMinValue,
    bedroomsMaxValue,
    totalSqFMinValue,
    totalSqFMaxValue,
  ]);

  return (
    <Accordion
      expanded={expanded}
      sx={{ boxShadow: 'none', padding: 0, border: 'none' }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{ boxShadow: 'none', padding: 0, minHeight: 1, margin: 0 }}
      >
        <div className="d-flex" style={{ alignItems: 'center', flex: 1 }}>
          <h3>{t('key_settings.advanced')}</h3> <div className="line" />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {hasBedroomsProperty ? (
          <div>
            <label htmlFor="bedrooms">{t('key_settings.bedrooms')}</label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputBedroomsMinRef}
                error={!!errors.bedroomsMinValue}
                //@ts-ignore
                helperText={errors?.bedroomsMinValue?.message}
                {...inputBedroomsMinProps}
                id="bedrooms"
                placeholder={t('key_settings.min')}
                value={bedroomsMinValue || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateBedroomsMin}
              />
              <StyledTextField
                inputRef={inputBedroomsMaxRef}
                error={!!errors.bedroomsMaxValue}
                //@ts-ignore
                helperText={errors?.bedroomsMaxValue?.message}
                {...inputBedroomsMaxProps}
                placeholder={t('key_settings.max')}
                value={bedroomsMaxValue || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateBedroomsMax}
              />
            </div>
          </div>
        ) : null}
        {hasBathroomsHalfProperty ? (
          <div style={{ paddingTop: 10 }}>
            <label htmlFor="baths-full">{t('key_settings.baths_full')}</label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputBathsFullMinRef}
                error={!!errors.bathsFullMin}
                //@ts-ignore
                helperText={errors?.bathsFullMin?.message}
                {...inputBathsFullMinProps}
                id="baths-full"
                placeholder={t('key_settings.min')}
                value={bathsFullMin || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateBathsFullMin}
              />
              <StyledTextField
                inputRef={inputBathsFullMaxRef}
                error={!!errors.bathsFullMax}
                //@ts-ignore
                helperText={errors?.bathsFullMax?.message}
                {...inputBathsFullMaxProps}
                placeholder={t('key_settings.max')}
                value={bathsFullMax || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateBathsFullMax}
              />
            </div>
          </div>
        ) : null}
        {hasBathroomsFullProperty ? (
          <div style={{ paddingTop: 10 }}>
            <label htmlFor="baths-half">{t('key_settings.baths_half')}</label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputBathsHalfMinRef}
                error={!!errors.bathsHalfMin}
                //@ts-ignore
                helperText={errors?.bathsHalfMin?.message}
                {...inputBathsHalfMinProps}
                id="baths-half"
                placeholder={t('key_settings.min')}
                value={bathsHalfMin || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateBathsHalfMin}
              />
              <StyledTextField
                inputRef={inputBathsHalfMaxRef}
                error={!!errors.bathsHalfMax}
                //@ts-ignore
                helperText={errors?.bathsHalfMax?.message}
                {...inputBathsHalfMaxProps}
                placeholder={t('key_settings.max')}
                value={bathsHalfMax || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateBathsHalfMax}
              />
            </div>
          </div>
        ) : null}
        {hasFinishedSquareFootageProperty ? (
          <div style={{ paddingTop: 10 }}>
            <label htmlFor="finish-square">
              {t('key_settings.finish_square')}
            </label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputFinishedSqFMinRef}
                error={!!errors.finishedSqFMin}
                //@ts-ignore
                helperText={errors?.finishedSqFMin?.message}
                {...inputFinishedSqFMinProps}
                id="finish-square"
                placeholder={t('key_settings.min')}
                value={finishedSqFMin || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateFinishedMin}
              />
              <StyledTextField
                inputRef={inputFinishedSqFMaxRef}
                error={!!errors.finishedSqFMax}
                //@ts-ignore
                helperText={errors?.finishedSqFMax?.message}
                {...inputFinishedSqFMaxProps}
                placeholder={t('key_settings.max')}
                value={finishedSqFMax || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateFinishedMax}
              />
            </div>
          </div>
        ) : null}
        {hasTotalSquareFootageProperty ? (
          <div style={{ paddingTop: 10 }}>
            <label htmlFor="total-square">
              {t('key_settings.total_square')}
            </label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputTotalSqFMinValueRef}
                error={!!errors.totalSqFMinValue}
                //@ts-ignore
                helperText={errors?.totalSqFMinValue?.message}
                {...inputTotalSqFMinValueProps}
                id="total-square"
                placeholder={t('key_settings.min')}
                value={totalSqFMinValue || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateTotalMin}
              />
              <StyledTextField
                inputRef={inputTotalSqFMaxValueRef}
                error={!!errors.totalSqFMaxValue}
                //@ts-ignore
                helperText={errors?.totalSqFMaxValue?.message}
                {...inputTotalSqFMaxValueProps}
                placeholder={t('key_settings.max')}
                value={totalSqFMaxValue || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateTotalMax}
              />
            </div>
          </div>
        ) : null}
        {hasYearProperty ? (
          <div style={{ paddingTop: 10 }}>
            <label htmlFor="year_built">{t('key_settings.year_built')}</label>
            <div className="d-flex">
              <StyledTextField
                inputRef={inputYearMinRef}
                error={!!errors.yearMin}
                //@ts-ignore
                helperText={errors?.yearMin?.message}
                {...inputYearMinProps}
                id="year_built"
                placeholder={t('key_settings.min')}
                value={yearMin || ''}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginRight: '10px',
                }}
                onChange={updateMinYear}
              />
              <StyledTextField
                inputRef={inputYearMaxRef}
                error={!!errors.yearMax}
                //@ts-ignore
                helperText={errors?.yearMax?.message}
                {...inputYearMaxProps}
                placeholder={t('key_settings.max')}
                value={yearMax || ''}
                fullWidth={false}
                className="w-100 form-input"
                style={{
                  width: 'calc(50% - 10px)',
                  marginLeft: '10px',
                }}
                onChange={updateMaxYear}
              />
            </div>
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedSettings;
