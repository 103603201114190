import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StyledSelect from '@/mui-styled-components/select';
import { IGraphMarketTrendsList } from '@/store/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface DataSelectProps {
  data: string;
  dataList: (
    timePeriod?: string,
    mlsCurrentName?: string
  ) => IGraphMarketTrendsList[];
  handleChangeData: (event: SelectChangeEvent<string | undefined>) => void;
}

const DataSelect: React.FC<DataSelectProps> = ({
  data,
  dataList,
  handleChangeData,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      <InputLabel
        id="data-select-label"
        sx={{
          '&.Mui-focused': {
            color: `var(--blue-color)`,
          },
        }}
      >
        <Trans i18nKey="chart.data"></Trans>
      </InputLabel>
      <StyledSelect
        labelId="data-select-label"
        value={data}
        //@ts-ignore
        onChange={handleChangeData}
        label={t('chart.data')}
      >
        {dataList().map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <Trans i18nKey={item.label}></Trans>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default DataSelect;
