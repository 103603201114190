class LocalStorageHelper {
  add(key: string, item: string): void {
    localStorage.setItem(key, item);
  }

  get(key: string): string | null {
    return localStorage.getItem(key);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

export default new LocalStorageHelper();
